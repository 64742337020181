import { observer } from 'mobx-react-lite'
import { useQueryParams } from 'use-query-params'

import { ButtonGroup, DatePicker, DateRangePicker, LegacySelect, TextInput } from '@/components/base'
import { DEFAULT_FILTER_MAX_WIDTH } from '@/constants/filters'
import { INPUT_ERROR_TEXT } from '@/constants/inputErrorText'
import {
    CSV_BUILDER_PAGE_QUERY_PARAMS_CONFIG,
} from '@/pages/MainPage/CommonPages/CsvBuilderPage/CsvBuilderPage.constants'

import { FiltersProps } from './Filters.types'
import { FiltersContainer } from './FiltersContainer'

/**
 * You can't add specific types of inputs here like "AssetsSelect" or "LedgersSelect"
 * Only basic components like "Select" or "Input"
 *
 * @deprecated Use FiltersContainer + specific inputs instead
 */
export const Filters = observer((props: FiltersProps) => {
    const { className, required, inputs, hideTitle } = props

    const [params, updateParam] = useQueryParams({
        // FIXME: Remove it from here and type with default types if possible
        ...CSV_BUILDER_PAGE_QUERY_PARAMS_CONFIG,
    })

    return (
        <FiltersContainer
            className={className} required={required}
            hideTitle={hideTitle}
        >
            {inputs.filter(config => !config.hidden).map((config, i) => {
                const { type, props, showEmptyValueError } = config

                if (type === 'textInput') {
                    const { name } = config

                    // Expand onChange to update params
                    const onChange = (option) => {
                        name && (updateParam({ [name]: option.value }))
                        props.onChange?.(option)
                    }

                    return (
                        <TextInput
                            key={i}
                            width={DEFAULT_FILTER_MAX_WIDTH}
                            // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
                            value={params[name]}
                            {...props}
                            onChange={onChange}
                        />
                    )
                }

                if (type === 'select') {
                    const { name, props: { customValue, multiSelectCustomValues } } = config

                    // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
                    const showEmptyError = !(params[name] || customValue || multiSelectCustomValues?.length) && showEmptyValueError && required

                    // Expand onChange to update params
                    const onChange = (option) => {
                        name && (updateParam({ [name]: option.value }))
                        props.onChange?.(option)
                    }

                    // Set initial value in params
                    if (name && props.customValue) {
                        updateParam({ [name]: props.customValue })
                    }

                    return (
                        <LegacySelect
                            error={showEmptyError}
                            // @ts-expect-error TS(2322) FIXME: Type 'string | false | undefined' is not assignabl... Remove this comment to see the full error message
                            hint={showEmptyError && INPUT_ERROR_TEXT.empty}
                            // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
                            customValue={params[name]}
                            key={i}
                            width={DEFAULT_FILTER_MAX_WIDTH}
                            {...props}
                            onChange={onChange}
                        />
                    )
                }

                if (type === 'date') {
                    const { dateName } = config
                    const showEmptyError = !(params[dateName]) && showEmptyValueError && required

                    // Set query params if empty
                    if (props.date && dateName) {
                        updateParam({ [dateName]: props.date.toISOString() })
                    }

                    // Expand onChange to update params
                    const onChange = (date: Date | null) => {
                        updateParam({
                            [dateName]: date ? date.toISOString() : undefined,
                        })
                        props.onChange?.(date)
                    }

                    return (
                        <DatePicker
                            key={i}
                            {...props}
                            date={params[dateName] ? new Date(params[dateName]) : props.date}
                            onChange={onChange}
                            error={showEmptyError}
                            // @ts-expect-error TS(2322) FIXME: Type 'string | false | undefined' is not assignabl... Remove this comment to see the full error message
                            hint={showEmptyError && INPUT_ERROR_TEXT.empty}
                            maxWidth={DEFAULT_FILTER_MAX_WIDTH}
                        />
                    )
                }

                if (type === 'monthRange') {
                    const { endDateName, startDateName } = config

                    const showEmptyError = !(params[endDateName] || params[endDateName]) && showEmptyValueError && required

                    // Set query params if empty
                    if (props.dateFrom && startDateName) {
                        updateParam({ [startDateName]: props.dateFrom.toISOString() })
                    }
                    if (props.dateTo && endDateName) {
                        updateParam({ [endDateName]: props.dateTo.toISOString() })
                    }

                    // Expand onChange to update params
                    const onChange = (args) => {
                        const { dateFrom, dateTo } = args

                        updateParam({
                            [endDateName]: dateTo.toISOString(),
                            [startDateName]: dateFrom.toISOString(),
                        })
                        props.onChange?.(args)
                    }

                    return (
                        <DateRangePicker
                            error={showEmptyError}
                            // @ts-expect-error TS(2322) FIXME: Type 'string | false | undefined' is not assignabl... Remove this comment to see the full error message
                            hint={showEmptyError && INPUT_ERROR_TEXT.empty}
                            key={i}
                            {...props}
                            dateFrom={params[startDateName] ? new Date(params[startDateName]) : props.dateFrom}
                            dateTo={params[endDateName] ? new Date(params[endDateName]) : props.dateTo}
                            onChange={onChange}
                            type='month'
                        />
                    )
                }

                if (type === 'buttonsGroup') {
                    const { name, props } = config

                    // Expand onChange to update params
                    const onChange = (key) => {
                        updateParam({ [name]: key })
                        props.onSelect?.(key)
                    }

                    return (
                        <ButtonGroup
                            key={i}
                            {...props}
                            selected={props.selected ?? params[name]}
                            onSelect={onChange}
                        />
                    )
                }

                console.error('Unknown input type: ', type)
                return null
            })}
        </FiltersContainer>
    )
})
