import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { captureException } from '@sentry/react'
import mixpanel from 'mixpanel-browser'

import { MODEL_NAME_BY_ID } from '@/core/modes'
import { useMe } from '@/hooks/core/useMe'
import { setIntervalUntilAuthenticated } from '@/utils/setInterval'

export const useMicrosoftClarity = () => {
    const { isAuthenticated } = useAuth0()
    const [isClarityReady, setIsClarityReady] = useState(false)
    const { me } = useMe()

    useEffect(() => {
        // Wait for clarity to be ready
        const checkClarity = setIntervalUntilAuthenticated(() => {
            if (window.clarity && typeof window.clarity === 'function') {
                setIsClarityReady(true)
                clearInterval(checkClarity)
            }
        }, 500, isAuthenticated)
    }, [])

    useEffect(() => {
        try {
            if (!isClarityReady || !me.user || !me.companyUser) return

            window.clarity('identify', me.user.id as number, undefined, window.location.host, me.user.fullName)

            // Tags
            window.clarity('set', 'name', me.user.fullName)
            window.clarity('set', 'email', me.user.email as string)
            window.clarity('set', 'isStaff', me.companyUser.is_staff as boolean)
            window.clarity('set', 'isOwner', me.companyUser.is_owner as boolean)
            window.clarity('set', 'isManager', me.companyUser.is_property_manager as boolean)
            window.clarity('set', 'companyId', me.company.id)
            window.clarity('set', 'companyName', me.company.name as string)
            window.clarity('set', 'portalMode', MODEL_NAME_BY_ID[me.companyUserMode])
            window.clarity('set', 'host', window.location.host)
            window.clarity('set', 'userId', me.user.id)

            // eslint-disable-next-line no-console
            console.info('Clarity is ready')
        } catch (error) {
            captureException(error, {
                tags: {
                    clarity: true,
                },
            })
        }
    }, [isClarityReady])
}

export const useIDentifyMixPanel = () => {
    const { me } = useMe()

    useEffect(() => {
        mixpanel.identify(me.user.id)

        mixpanel.people.set({
            $name: me.user.fullName,
            $email: me.user.email,
            companyId: me.company.id,
            companyName: me.company.name,
            portalMode: MODEL_NAME_BY_ID[me.companyUserMode],
            host: window.location.host,
            isStaff: me.companyUser.is_staff as boolean,
            isOwner: me.companyUser.is_owner,
            isManager: me.companyUser.is_property_manager,
        })
    }, [])
}
