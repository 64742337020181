import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { AND, EQ, Query, ValueType } from 'mobx-orm'
import { observer } from 'mobx-react-lite'
import { generatePath, Outlet, useNavigate, useParams } from 'react-router'

import { SideModal, Button, FormBox, FormBoxList, LegacySelectItemData } from '@/components/base'
import { getBasicInformationFormFields } from '@/components/legacy/models/asset/AssetEditSideModal/AssetEditSideModal.constants'
import { AssetNotes } from '@/components/legacy/models/asset/AssetEditSideModal/AssetNotes'
import { ObjectFormLegacy, ObjectFormLegacyError } from '@/components/legacy/models/ObjectFormLegacy'
import { FieldOptions } from '@/components/legacy/models/ObjectFormLegacy/ObjectFormLegacy.types'
import { ADMIN_ROUTES_CONFIG } from '@/core/routes'
import { useMe } from '@/hooks/core/useMe'
import { Asset } from '@/models/asset'
import { AssetRentRoll } from '@/models/asset_rent_roll/AssetRentRoll'
import { AssetTrialBalance } from '@/models/asset_trial_balance/AssetTrialBalance'
import { Company } from '@/models/company'
import { TrialBalanceLedger } from '@/models/ledger'
import { RentRollLedger } from '@/models/rent_roll'
import { isQueriesReady, queriesLoad } from '@/utils/models'
import { getRouteConfig } from '@/utils/routing/getRouteConfig'

import styles from './AssetEditSideModal.module.scss'
import { AssetEditSideModalProps } from './AssetEditSideModal.types'

export const AssetEditSideModal = observer((props: AssetEditSideModalProps) => {
    const { className } = props

    const { me } = useMe()

    const backPath = me.isAdministratorMode ? ADMIN_ROUTES_CONFIG.ASSETS.path : getRouteConfig('ADMIN_CENTER_ASSETS').path

    const { assetId: assetIdParam } = useParams()
    const isCreateMode = assetIdParam === undefined
    const assetId = Number(assetIdParam)

    const assetObj = useMemo(
        () => isCreateMode
            ? new Asset({ company_id: me.company.id })
            : Asset.get(assetId) as Asset,
        [assetIdParam],
    )

    // No need to have state here
    const linkedTrialBalanceLedgers = useRef(null) // null - never changed
    const linkedRentRollLedgers = useRef(null) // null - never changed

    const ledgerLinksFilter = useMemo(
        () => AND(
            EQ('asset_id', assetId, ValueType.NUMBER),
            EQ('ledger__type', assetObj.type, ValueType.STRING),
        ),
        [assetId, assetObj.type],
    )
    const ledgersFilter = useMemo(
        () => EQ('type', assetObj.type, ValueType.STRING),
        [assetObj.type],
    )

    const [isSavingMainObj, setIsSavingMainObj] = useState<boolean>(false)
    const [isSavingLinkedLedgers, setIsSavingLinkedLedgers] = useState<boolean>(false)
    const [fieldErrors, setFieldsErrors] = useState<ObjectFormLegacyError[]>([])
    const [companyQuery] = useState(() => Company.getQuery() as Query<Asset>)
    const [trialBalanceLedgerQuery] = useState(() => TrialBalanceLedger.getQuery({ filter: ledgersFilter }) as Query<TrialBalanceLedger>)
    const [rentRollLedgerQuery] = useState(() => RentRollLedger.getQuery({ filter: ledgersFilter }) as Query<RentRollLedger>)
    const [assetRentrollQuery] = useState(() => AssetRentRoll.getQuery({ filter: ledgerLinksFilter }) as Query<AssetRentRoll>)
    const [assetTrialBalanceQuery] = useState(() => AssetTrialBalance.getQuery({ filter: ledgerLinksFilter }) as Query<AssetTrialBalance>)

    useEffect(() => {
        queriesLoad(
            assetRentrollQuery,
            assetTrialBalanceQuery,
        )
    }, [])

    const navigate = useNavigate()

    const generatedBackPath = generatePath(backPath)

    const handleRenrollLedgerChange = async (items: LegacySelectItemData[] | null) => {
        if (items === null) {
            return
        }

        const assetId = assetObj.id
        const createdItems = items.filter(item => !assetRentrollQuery.items.find(el => (el.ledger_id === item.value && el.asset_id === assetId)))
        const removedItems = assetRentrollQuery.items.filter(item => !items.find(el => (item.ledger_id === el.value && item.asset_id === assetId)))

        const promises = []

        createdItems.forEach(item => {
            const obj = new AssetRentRoll({
                asset_id: assetId,
                ledger_id: item.value,
            })
            // @ts-expect-error TS(2345) FIXME: Argument of type 'Promise<any>' is not assignable ... Remove this comment to see the full error message
            promises.push(obj.save())
        })

        removedItems.forEach(item => {
            // @ts-expect-error TS(2345) FIXME: Argument of type 'Promise<any>' is not assignable ... Remove this comment to see the full error message
            promises.push(item.delete())
        })

        return await Promise.all(promises)
    }

    const handleTrialBalanceLedgerChange = async (items: LegacySelectItemData[] | null) => {
        if (items === null) {
            return
        }

        const assetId = assetObj.id
        const createdItems = items.filter(item => !assetTrialBalanceQuery.items.find(el => (el.ledger_id === item.value && el.asset_id === assetId)))
        const removedItems = assetTrialBalanceQuery.items.filter(item => !items.find(el => (item.ledger_id === el.value && item.asset_id === assetId)))

        const promises = []

        createdItems.forEach(item => {
            const obj = new AssetTrialBalance({
                asset_id: assetId,
                ledger_id: item.value,
            })
            // @ts-expect-error TS(2345) FIXME: Argument of type 'Promise<any>' is not assignable ... Remove this comment to see the full error message
            promises.push(obj.save())
        })

        removedItems.forEach(item => {
            // @ts-expect-error TS(2345) FIXME: Argument of type 'Promise<any>' is not assignable ... Remove this comment to see the full error message
            promises.push(item.delete())
        })

        return await Promise.all(promises)
    }

    const updateLinkedLedgers = useCallback(() => {
        // Promises arr for make if faster with parallel requests
        const promises = []

        // @ts-expect-error TS(2345) FIXME: Argument of type 'Promise<never[] | undefined>' is... Remove this comment to see the full error message
        promises.push(handleRenrollLedgerChange(linkedRentRollLedgers.current))
        // @ts-expect-error TS(2345) FIXME: Argument of type 'Promise<never[] | undefined>' is... Remove this comment to see the full error message
        promises.push(handleTrialBalanceLedgerChange(linkedTrialBalanceLedgers.current))

        return Promise.all(promises)
            .finally(() => setIsSavingLinkedLedgers(false))
    }, [])

    const submit = async () => {
        let isAssetObjSaved = false

        setIsSavingMainObj(true)
        try {
            await assetObj.save()
            isAssetObjSaved = true
        } catch (err) {
            setFieldsErrors(err.response.data)
        } finally {
            setIsSavingMainObj(false)
        }

        if (isAssetObjSaved) {
            // Update ledgers separately because they have separated Models
            await updateLinkedLedgers()

            if (isCreateMode) {
                window.location.href = generatedBackPath
            } else {
                navigate(generatedBackPath)
            }
        }
    }

    const rentrollLedgerOptions = useMemo(() => {
        return rentRollLedgerQuery.items.map((item) => ({ label: item.name, value: item.id }))
    }, [rentRollLedgerQuery.items])
    const trialBalanceLedgerOptions = useMemo(() => {
        return trialBalanceLedgerQuery.items.map((item) => ({ label: item.name, value: item.id }))
    }, [trialBalanceLedgerQuery.items])

    // @ts-expect-error TS(2322) FIXME: Type '(number | undefined)[]' is not assignable to... Remove this comment to see the full error message
    const selectedTrialBalanceLedgers: number[] = assetTrialBalanceQuery.items.map((item) => item.ledger_id)
    // @ts-expect-error TS(2322) FIXME: Type '(number | undefined)[]' is not assignable to... Remove this comment to see the full error message
    const selectedRentRollLedgers: number[] = assetRentrollQuery.items.map((item) => item.ledger_id)

    const basicInformaitonFormFields = useMemo(() => getBasicInformationFormFields({
        trialBalanceLedgerOptions,
        rentrollLedgerOptions,
        selectedTrialBalanceLedgers,
        selectedRentRollLedgers,
        // Don't save ledgers here. Only put it in temporary cache
        // @ts-expect-error TS(2322) FIXME: Type 'LegacySelectItemData[]' is not assignable to... Remove this comment to see the full error message
        onRentrollLedgerChange: (items: LegacySelectItemData[]) => (linkedRentRollLedgers.current = items),
        // @ts-expect-error TS(2322) FIXME: Type 'LegacySelectItemData[]' is not assignable to... Remove this comment to see the full error message
        onTrialBalanceLedgerChange: (items: LegacySelectItemData[]) => (linkedTrialBalanceLedgers.current = items),
    }),
    [rentrollLedgerOptions, trialBalanceLedgerOptions, selectedRentRollLedgers, selectedTrialBalanceLedgers])

    const getFields = () => {
        if (me.isAdministratorMode) {
            const companyOptions = companyQuery.items.map((item) => ({ label: item.name, value: item.id }))
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            return new Map<string, FieldOptions>([
                ...basicInformaitonFormFields,
                ['type', {
                    type: 'asset-type-select',
                    title: 'Asset Type',
                    readonly: false,
                }],
                ['company_id', {
                    type: 'select-new',
                    inputProps: {
                        label: 'Company',
                        placeholder: 'Company',
                        required: true,
                        options: companyOptions,
                    },
                }],
            ])
        } else {
            return basicInformaitonFormFields
        }
    }

    return (
        <SideModal
            title={isCreateMode ? 'Create New Asset' : 'Edit Asset'}
            closePath={backPath}
            className={className}
            haveCloseButton
            submitButton={{
                onClick: submit,
                text: 'Save',
                loading: isSavingMainObj || isSavingLinkedLedgers,
            }}
            isLoading={isQueriesReady(
                rentRollLedgerQuery, trialBalanceLedgerQuery, assetTrialBalanceQuery, assetRentrollQuery,
            )}
        >
            <FormBoxList
                noBottom={!isCreateMode}
                content={[
                    {
                        title: 'Basic Information',
                        body: (
                            <ObjectFormLegacy
                                obj={assetObj}
                                fields={getFields()}
                                hideButtons
                                customFieldErrors={fieldErrors}
                            />
                        ),
                    },
                ]}
            />
            {!isCreateMode && (
                <FormBox
                    noTop
                    title='Notes'
                    body={
                        <AssetNotes/>
                    }
                />
            )}

            {(!isCreateMode && me.isAdministratorMode) && (
                <Button
                    className={styles.deleteButton}
                    theme='destructive'
                    text='Delete Asset'
                    onClick={() => {
                        navigate(generatePath(ADMIN_ROUTES_CONFIG.ASSETS_DELETE.path, {
                            assetId: assetIdParam,
                        }))
                    }}
                />
            )}
            <Outlet/>
        </SideModal>
    )
})
