import { useEffect, useMemo, useState } from 'react'

import { EQ } from 'mobx-orm'

import { useAssetsCountQuery } from '@/api/asset/asset'
import { Tabs } from '@/components/base'
import { useInputState } from '@/hooks/useInputState'
import { AssetType, ASSET_TYPE_LABEL } from '@/models/core'

import { ASSET_TYPE_SELECT_TABS_QUERY_PARAM } from './AssetTypeTabsInput.constants'
import { AssetTypeSelectInputProps } from './AssetTypeTabsInput.types'

const { name: queryParamName, type: paramType } = ASSET_TYPE_SELECT_TABS_QUERY_PARAM

export const AssetTypeTabsInput = (props: AssetTypeSelectInputProps) => {
    const {
        paramName = queryParamName,
        onChange,
    } = props

    const [param, setParam] = useInputState({ name: paramName, type: paramType, syncLocalStorage: false })
    const [tabsOptions, setTabsOptions] = useState(() => new Map())

    const allAssetTypes = Object.values(AssetType)

    /**
     * Need this hack to avoid fetching all assets
     * @see WEBDEV-1578
     */
    const assetQueriesByType = allAssetTypes.map((type: AssetType) => (
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useAssetsCountQuery({
            filter: EQ('type', type),
        })
    ))

    const assetByTypeCount = assetQueriesByType.map((query) => query.data)

    const availableTypes: AssetType[] = useMemo(() => {
        return assetByTypeCount.reduce<AssetType[]>((acc, count, index) => {
            if (count) {
                acc.push(allAssetTypes[index])
            }

            return acc
        }, [])
        // This array cant be memoized and we need to check stringified version
    }, [assetByTypeCount.join(',')])

    useEffect(() => {
        if ((!param || !availableTypes.includes(param as AssetType)) && availableTypes.length) {
            setParam(availableTypes[0])
        }

        const options = availableTypes.reduce((acc, type) => {
            acc.set(type, ASSET_TYPE_LABEL.get(type))

            return acc
        }, new Map())

        setTabsOptions(options)
    }, [availableTypes])

    if (availableTypes.length < 2) {
        return null
    }

    return (
        <Tabs
            options={tabsOptions}
            value={param}
            onChange={(value: AssetType) => {
                setParam(value)
                onChange?.(value)
            }}
        />
    )
}
