import { model, field, many, foreign } from 'mobx-orm'

import { HistoryModel } from '@/models/core/HistoryModel'

import { CompanyUserInLedger } from './CompanyUserInLedger'
import { TrialBalanceLedger as Ledger } from './Ledger'
import { api } from '../../http-adapter'
import { CompanyUser } from '../company'
import { AccessLevel } from '../core'

@api('company-user-in-ledger-history')
@model
export class CompanyUserInLedgerHistory extends HistoryModel {
    @field readonly company_user_in_ledger_id: number
    @field readonly ledger_id:                 number
    @field readonly level:                     number
    @field readonly company_user_id:           number

    @foreign(Ledger) readonly ledger:            Ledger
    @foreign(CompanyUser) readonly company_user: CompanyUser

    get level_name (): string {
        return AccessLevel[this.level]
    }
}
many(CompanyUserInLedgerHistory, 'company_user_in_ledger_id')(CompanyUserInLedger, 'history')
