import { ArrayParam } from 'use-query-params'

import { MONTH_RANGE_END_QUERY_PARAM, MONTH_RANGE_START_QUERY_PARAM } from '@/components/baseInputs'

export const DATA_SUBMISSION_TYPE_QUERY_PARAM = {
    name: 'type',
    type: ArrayParam,
}

export const DATA_SUBMISSION_PERIOD_START_QUERY_PARAM = MONTH_RANGE_START_QUERY_PARAM
export const DATA_SUBMISSION_PERIOD_END_QUERY_PARAM = MONTH_RANGE_END_QUERY_PARAM
