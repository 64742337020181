import React from 'react'

import { observer } from 'mobx-react-lite'

import { FiltersContainer } from '@/components/base'
import { TextFilter } from '@/components/legacy/models/core/filters/TextFilter'
import { DEFAULT_FILTER_MAX_WIDTH } from '@/constants/filters'

import { UploadsDataSyncFiltersProps } from './UploadsDataSyncFilters.types'

export const UploadsDataSyncFilters = observer((props: UploadsDataSyncFiltersProps) => {
    const { filterAssetsByName } = props

    return (
        <FiltersContainer hideTitle={false}>
            <TextFilter filter={filterAssetsByName} placeholder='Search by Name' maxWidth={DEFAULT_FILTER_MAX_WIDTH}/>
        </FiltersContainer>
    )
})
