import { Model, model, field, foreign } from 'mobx-orm'

import { Company } from '@/models/company'

import { api } from '../../http-adapter'

@api('cut-off-date')
@model
export class CutOffDate extends Model {
    @field company_id: number
    @field month_ind:  number  // year * 12 + (__zero-based__ month number)
    @field date:       string  // yyyy-MM-dd

    @foreign(Company) readonly company: Company
}
