import { FC } from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { Icon } from '@/components/base'

import { AccountCategoryInputReadonlyProps } from './AccountCategoryReadOnly.types'
import styles from '../AccountCategoryInput.module.scss'

const AccountCategoryReadOnly: FC<AccountCategoryInputReadonlyProps> = observer(({ value, placeholder }) => {
    const path = [...value.parents.reverse(), value]

    return (
        <div className={styles.readonly}>
            {path.map((category, index) => {
                const icon = category.parent_id
                    ? <div>–</div>
                    : <Icon name='chartOfAccounts'/>
                const itemStyle = { paddingLeft: index * 16 }
                return (
                    <div
                        className={cn(styles.treeItem, { [styles.lastItem]: index === path.length - 1 })}
                        style={itemStyle}
                        key={index}
                    >
                        {icon}
                        <div className={styles.categoryName}>{category.name}</div>
                    </div>
                )
            })}
            <div
                className={cn(styles.treeItem, styles.newItemPlaceholder)}
                style={{ marginLeft: path.length * 16 }}
            >
                {placeholder}
            </div>
        </div>
    )
})

export default AccountCategoryReadOnly
