import { getRequestQueries } from '@/api/utils/getRequestQueries'
import { CompanyUserInAsset, CompanyUserInAssetGroup } from '@/models/asset'
import { CompanyUserInCompanyUserGroup } from '@/models/company'
import { CompanyUserInLedger } from '@/models/ledger'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchCompanyUserQueryParams {}

export interface CompanyUserItem {
    id: number
    user_id?: number
    company_id: number
    first_name?: string
    last_name?: string
    username?: string
    is_staff?: boolean
    is_active?: boolean
    is_owner?: boolean
    is_property_manager?: boolean
    links: CompanyUserInCompanyUserGroup[]
    ledger_personal_permissions: CompanyUserInLedger[]
    asset_personal_permissions: CompanyUserInAsset[]
    asset_group_personal_permissions: CompanyUserInAssetGroup[]
}

export const {
    useItemsQuery: useCompanyUserQuery,
    useItemUpdateMutation: useCompanyUserUpdateMutation,
    useItemByIdQuery: useCompanyUserByIdQuery,
    useItemsCountQuery: useCompanyUserCountQuery,
} = getRequestQueries<CompanyUserItem, FetchCompanyUserQueryParams>({
    url: 'company-user',
})
