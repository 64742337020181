import { ChangeEvent, useCallback, useEffect, useState } from 'react'

import debounce from 'lodash/debounce'

import { TextInput } from '@/components/base/TextInput'
import { useInputState } from '@/hooks'

import { TEXT_INPUT_QUERY_PARAM, TEXT_SEARCH_DEBOUNCE_TIME } from './TextSearchInput.constants'
import { TextSearchInputProps } from './TextSearchInput.types'

const { type: queryParamType, name: queryParamName } = TEXT_INPUT_QUERY_PARAM

export const TextSearchInput = (props: TextSearchInputProps) => {
    const {
        placeholder = 'Search',
        paramName = queryParamName,
        debounce: delay = TEXT_SEARCH_DEBOUNCE_TIME,
        syncLocalStorage,
        ...rest
    } = props

    const [param, setParam] = useInputState({ name: paramName, type: queryParamType, syncLocalStorage })
    const [value, setValue] = useState('')

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSetParam = useCallback(debounce(setParam, delay), [delay, setParam])

    const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const updatedValue = e.target.value
        setValue(updatedValue)
        debouncedSetParam(updatedValue)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setValue(param ?? ''), [])

    return (
        <TextInput
            {...rest}
            onChange={changeHandler}
            value={value}
            placeholder={placeholder}
            icon='search'
            dataTestId='search-input'
        />
    )
}
