import { observer } from 'mobx-react-lite'

import { Label, LabelColors } from '@/components/base'
import { CompanyInviteStatus } from '@/models/company'

import { CompanyInvitesPageStatusCellProps } from './CompanyInvitesPageStatusCell.types'

const colorByStatus: Record<CompanyInviteStatus, LabelColors> = {
    [CompanyInviteStatus.Pending]: 'orange',
    [CompanyInviteStatus.Accepted]: 'green',
    [CompanyInviteStatus.Canceled]: 'red',
}

const textByStatus: Record<CompanyInviteStatus, string> = {
    [CompanyInviteStatus.Canceled]: 'Canceled',
    [CompanyInviteStatus.Pending]: 'Pending',
    [CompanyInviteStatus.Accepted]: 'Accepted',
}

/**
 * TODO: Sending error status
 */
export const CompanyInvitesPageStatusCell = observer((props: CompanyInvitesPageStatusCellProps) => {
    const { data } = props

    const { status } = data

    return (
        <Label
            text={textByStatus[status]}
            color={colorByStatus[status]}
        />
    )
})
