import React from 'react'

import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router'

import { Text } from '@/components/base'
import { PageLayout } from '@/components/containers'
import { SubmissionLogTable } from '@/pages/UploadsPage'

import { SubmissionLogFilters } from './SubmissionLogFilters'

export const SubmissionLogsPage = observer(() => {
    return (
        <PageLayout gap={16}>
            <Text
                text='Prior Data Uploads'
                variant='subheader'
                color='secondaryBlack'
            />
            <SubmissionLogFilters/>
            <SubmissionLogTable/>
            <Outlet/>
        </PageLayout>
    )
})
