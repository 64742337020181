import { getRequestQueries } from '@/api/utils/getRequestQueries'
import { CompanyInviteStatus } from '@/models/company'
import { AccessLevel } from '@/models/core'

export interface CompanyInviteItem {
    created?: string
    first_name?: string
    last_name?: string
    invitee_id?: number
    company_id?: number
    company_user_group_id?: number
    level: AccessLevel
    email?: string
    status: CompanyInviteStatus
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchCompanyInviteQueryParams {}

export const COMPANY_INVITE_URL = 'company-invite'

export const {
    useItemsQuery: useCompanyInviteQuery,
    useItemUpdateMutation: useCompanyInviteUpdateMutation,
    useItemsCountQuery: useCompanyInviteCountQuery,
    useItemCreateMutation: useCompanyInviteCreateMutation,
    useItemDeleteMutation: useCompanyInviteDeleteMutation,
    useItemByIdQuery: useCompanyInviteByIdQuery,
} = getRequestQueries<CompanyInviteItem, FetchCompanyInviteQueryParams>({
    url: COMPANY_INVITE_URL,
})
