import { observer } from 'mobx-react-lite'

import { ErrorPageBase } from '@/components/base'

import WarningIcon from './assets/warning.svg'
import { InternalUnexpectedErrorPageProps } from './InternalUnexpectedErrorPage.types'

export const InternalUnexpectedErrorPage = observer((props: InternalUnexpectedErrorPageProps) => {
    const { className, rounded, type = 'internal', noBackground } = props

    const handleButtonClick = () => {
        window.location.reload()
    }

    return (
        <ErrorPageBase
            rounded={rounded}
            title='There was an unexpected error'
            // TODO: Make email clickable
            text='Please reload the page and try again. If the problem continues,
please contact support@intelas.com'
            onButtonClick={handleButtonClick}
            buttonText='Reload Page'
            errorName='Unexpected error'
            ErrorIcon={WarningIcon}
            className={className}
            type={type}
            noBackground={noBackground}
        />
    )
})
