import { Tooltip } from '@mui/material'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { Icon, Text } from '@/components/base'

import styles from './AgGridHeaderWithHint.module.scss'
import { AgGridHeaderWithHintProps } from './AgGridHeaderWithHint.types'

/**
 * Custom header with implemented hint message
 */
export const AgGridHeaderWithHint = observer((props: AgGridHeaderWithHintProps) => {
    const { className, column: { colDef }, hint } = props

    return (
        <div className={cn(styles.agGridHeaderWithHint, className)}>
            <Text
                text={colDef.headerName} variant='labelMediumDefault'
                color='colorsSecondaryGrey600'
            />
            <Tooltip title={hint} arrow>
                <span>
                    <Icon name='info' color='colorsSecondaryGrey600'/>
                </span>
            </Tooltip>
        </div>
    )
})
