import { forwardRef } from 'react'

import { TextInput, TextInputProps } from '@/components/base'

export const DatePickerTrigger = forwardRef((props: TextInputProps) => {
    return (
        <TextInput
            {...props}
            readonly
            dataTestId='datePickerInput'
        />
    )
})
