import { Model, model, field } from 'mobx-orm'

import { AssetType } from '@/models/core'

import { api } from '../../http-adapter'

@api('account-group')
@model
export class AccountGroup extends Model {
    @field  code?: string
    @field  name?: string
    @field  type?: AssetType
}
