import React, { useMemo } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { EQ } from 'mobx-orm'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

import { useCompanyByIdQuery } from '@/api/company/company'
import { COMPANY_INVITE_URL } from '@/api/company/companyInvite'
import { useCompanyUserGroupQuery } from '@/api/company/companyUserGroup'
import ObjectFormPopup from '@/components/legacy/models/ObjectFormPopup'
import { useMe } from '@/hooks/core/useMe'
import { CompanyInvite } from '@/models/company'
import { AccessLevel } from '@/models/core'
import { notify } from '@/utils/notify'
import { getRouteConfig } from '@/utils/routing/getRouteConfig'

import styles from './CompaneProfileNewInvitePopup.module.scss'
import { CompanyNewInvitePopupProps } from './CompanyNewInvitePopup.types'

export const CompanyNewInvitePopup = observer((props: CompanyNewInvitePopupProps) => {
    const { backPath } = props

    const navigate = useNavigate()
    const { me } = useMe()

    const copmanyByIdQuery = useCompanyByIdQuery(me.company.id)
    const companyUserGroupByIdQuery = useCompanyUserGroupQuery({
        filter: EQ('company_id', me.company.id),
    })

    const companyUserGorupId = companyUserGroupByIdQuery.data?.[0]?.id

    const inviteObj = useMemo(() => {
        if (companyUserGroupByIdQuery.isLoading || copmanyByIdQuery.isLoading || !companyUserGorupId) return null
        return new CompanyInvite({
            company_id: copmanyByIdQuery.data?.id,
            company_user_group_id: companyUserGorupId,
            level: AccessLevel.Viewer,
        })
    }, [copmanyByIdQuery.isLoading, companyUserGroupByIdQuery.isLoading, companyUserGorupId])

    const handleClose = () => {
        navigate(backPath)
    }

    const toastSuccessMessage = () => (
        <div>Invitation has been sent. All invitations are on the <Link className={styles.toastLink} to={getRouteConfig('COMPANY_PROFILE_INVITES').path}>company profile page</Link></div>
    )

    const queryClient = useQueryClient()

    const handleSuccess = () => {
        queryClient.invalidateQueries({ queryKey: [COMPANY_INVITE_URL] })
        notify.success(toastSuccessMessage)
    }

    if (!inviteObj) {
        return null
    }

    return (
        <ObjectFormPopup
            fields={new Map([
                ['email', {
                    type: 'string',
                    title: 'Email',
                    required: true,
                }],
                ['first_name', {
                    type: 'string',
                    title: 'First Name',
                    required: true,
                }],
                ['last_name', {
                    type: 'string',
                    title: 'Last Name',
                    required: true,
                }],
            ])}
            title={getRouteConfig('COMPANY_PROFILE_INVITES_NEW').title}
            obj={inviteObj}
            onClose={handleClose}
            onSuccess={handleSuccess}
        />
    )
})
