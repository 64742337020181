export enum TimeSettings {
    SINGLE = 'single',
    SERIES = 'series',
    MULTIPLE = 'comparison',
}

export const TIME_SETTINGS_LABELS: Record<TimeSettings, string> = {
    [TimeSettings.SINGLE]: 'Single Period',
    [TimeSettings.SERIES]: 'Time Series',
    [TimeSettings.MULTIPLE]: 'Multiple Periods',
}
