import { getRequestQueries } from '@/api/utils/getRequestQueries'
import { RentRollLedger } from '@/models/rent_roll'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchRentRollLedgerQueryParams {}

export const {
    useItemsQuery: useRentRollLedgerQuery,
    useItemByIdQuery: useRentRollLedgerByIdQuery,
} = getRequestQueries<RentRollLedger, FetchRentRollLedgerQueryParams>({
    url: 'rent-roll-ledger',
})
