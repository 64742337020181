import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { Icon, Text } from '@/components/base'

import styles from './HeaderMenuItem.module.scss'
import { HeaderMenuItemProps } from './HeaderMenuItem.types'

export const HeaderMenuItem = observer((props: HeaderMenuItemProps) => {
    const { className, text, onClick, icon, divider, isActive } = props

    return (
        <MenuItem
            selected={isActive}
            classes={{
                root: cn(styles.headerMenuItem, className, { [styles.headerMenuItem_active]: isActive }),
                selected: styles.headerMenuItem_active,
            }}
            onClick={() => {
                onClick?.()
            }}
            divider={divider}
        >
            <ListItemIcon>
                <Icon
                    name={icon}
                    size='m'
                    color={isActive ? 'colorsPrimaryPurple' : 'colorsSecondaryGrey600'}
                />
            </ListItemIcon>
            <ListItemText>
                <Text
                    variant='smallTextMediumDefault'
                    color={isActive ? 'colorsPrimaryPurple' : 'colorsSecondaryGrey600'}
                >
                    {text}
                </Text>
            </ListItemText>
        </MenuItem>
    )
})
