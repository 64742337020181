import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router'

import ObjectDeletePopup from '@/components/legacy/models/ObjectDeletePopup'
import { Template } from '@/models/csv_builder'
import { getRoute } from '@/utils/routing/getRoute'

// TODO: refactor to use reactQuery
export const RemoveFilePopup = observer(() => {
    const navigate = useNavigate()

    const { id } = useParams()
    const [itemObj, setItemObj] = useState<Template | null>(null)

    useEffect(() => {
        if (!id) {
            console.error('Can\'t get id from params')
            return
        }

        (async () => {
            const itemObj = await Template.findById(parseInt(id)) as Template | null

            if (!itemObj) {
                console.error('Can\'t get Template object')
                return
            }

            setItemObj(itemObj)
        })()
    }, [id])

    return itemObj ? (
        <ObjectDeletePopup
            obj={itemObj}
            title='Delete File'
            warningText={`File ${itemObj.name} will be deleted permanently`}
            onDeleted={() => {
                const route = getRoute('CSV_BUILDER_FILES') + '?updateFilesList=1'
                navigate(route)
            }}
            onClose={(deleted) => {
                if (!deleted) {
                    navigate(getRoute('CSV_BUILDER_FILES'))
                }
            }}
        />
    ) : null
})
