import { ValueType } from 'mobx-orm'

// value to string
/**
 * @deprecated
 */
export function deserialize (value, type: ValueType): string | undefined {
    if (value === undefined) return undefined
    if (value === null) return 'null'
    switch (type) {
        case ValueType.STRING:
            return '' + value
        case ValueType.NUMBER:
            if (isNaN(value) || value === true || value === false) {
                return undefined
            } else {
                return '' + value
            }
        case ValueType.BOOL:
            // I'm not shure that it is string
            return value ? 'true' : 'false'
        case ValueType.DATE:
            return value instanceof Date ? value.toISOString().split('T')[0] : ''
        case ValueType.DATETIME:
            return value instanceof Date ? value.toISOString() : ''
    }
}
