import { me } from '@/components/containers'
import { CompanyFeatureKeys } from '@/models/company/CompanyFeatures'

/**
 * Check feature flag for current company
 * See readme.md for more details about feature flags
 */
export const isFeatureOn = (featureName: CompanyFeatureKeys): boolean => {
    return Boolean(me.companyFeatures?.[featureName])
}
