import { observer } from 'mobx-react-lite'

import { ActionsCell, ActionsCellItem } from '@/components/legacy/tables/cells'
import { useMe } from '@/hooks/core/useMe'
import { resetUserPassword } from '@/pages/MainPage/UserPages/CompanyProfilePage/CompanyProfilePage.utils'
import { notify } from '@/utils/notify'

import { CompanyMembersSubpageActionsCellProps } from './CompanyMembersSubpageActionsCell.types'

export const CompanyMembersSubpageActionsCell = observer((props: CompanyMembersSubpageActionsCellProps) => {
    const company_user = props.data // eslint-disable-line @typescript-eslint/naming-convention

    const { me } = useMe()

    const actionsCellItems: ActionsCellItem[] = [
        {
            icon: 'replace',
            text: 'Reset Password',
            // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            onClick: async () => { await resetUserPassword({ email: company_user.email }) },
            // dividerAfter: true
        },
    ]
    if (company_user.is_active) {
        actionsCellItems.push(
            {
                icon: 'deactivatedUser',
                text: 'Deactivate User',
                onClick: async () => { await company_user.deactivate() },
            },
        )
    } else {
        actionsCellItems.push(
            {
                icon: 'plus',
                text: 'Activate User',
                onClick: async () => { await company_user.activate() },
            },
        )
    }

    if (me.user.is_staff) {
        actionsCellItems.push(
            {
                icon: 'delete',
                text: 'Delete User',
                onClick: async () => {
                    notify.promise(company_user.delete(), {
                        success: 'User was deleted',
                        pending: 'User deleting...',
                        error: 'Request failed',
                    })
                },
            },
        )
    }

    return (
        <ActionsCell
            items={actionsCellItems}
        />
    )
})
