import { action } from 'mobx'
import { Model, model, field, foreign, many } from 'mobx-orm'

import { TrialBalanceTemplate } from './TrialBalanceTemplate'
import { TrialBalanceTemplateApplyingTask } from './TrialBalanceTemplateApplyingTask'
import { api } from '../../http-adapter'
import { Asset } from '../asset'

@api('trial-balance-template-bindings')
@model
export class TrialBalanceTemplateBinding extends Model {
    @field template_id?: number
    @field asset_id?:    number
    @field is_active?:   boolean

    @foreign(TrialBalanceTemplate) readonly template: TrialBalanceTemplate
    @foreign(Asset) readonly asset:                   Asset

    createCheckingTask (): TrialBalanceTemplateApplyingTask {
        return new TrialBalanceTemplateApplyingTask({
            binding_id:    this.id,
            template_id:   this.template_id,
            asset_id:      this.asset_id,
            apply:         false,
            replace:       false,
            delete_excess: false,
        })
    }

    createApplyingTask (): TrialBalanceTemplateApplyingTask {
        return new TrialBalanceTemplateApplyingTask({
            binding_id:    this.id,
            template_id:   this.template_id,
            asset_id:      this.asset_id,
            apply:         true,
            replace:       true,
            delete_excess: false,
        })
    }

    @action
    static async enable (template: TrialBalanceTemplate, asset: Asset): Promise<TrialBalanceTemplateBinding> {
        let binding = template.getBindingForAsset(asset)
        if (binding) {
            binding.is_active = true
        } else {
            binding = new TrialBalanceTemplateBinding({
                asset_id:    asset.id,
                template_id: template.id,
                is_active:   true,
            })
        }
        await binding.save()
        return binding
    }

    @action
    static async disable (template: TrialBalanceTemplate, asset: Asset): Promise<TrialBalanceTemplateBinding | undefined> {
        const binding = template.getBindingForAsset(asset)
        if (binding?.is_active) {
            binding.is_active = false
            await binding.save()
            return binding
        }
    }
}

many(TrialBalanceTemplateBinding, 'template_id')(TrialBalanceTemplate, 'bindings')
