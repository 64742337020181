import React from 'react'

import { useDraggable } from '@dnd-kit/core'
import { observer } from 'mobx-react-lite'

import { DraggableProps } from './Draggable.types'

/**
 * Wrapper component for draggable elements
 */
export const Draggable = observer((props: DraggableProps) => {
    const { id, children, className, data } = props

    const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
        id,
        // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'AnyData ... Remove this comment to see the full error message
        data,
    })

    return (
        <div
            // Helping for debug dnd
            data-uniqid={id}
            className={className}
            ref={setNodeRef}
            {...attributes}
        >
            {
                React.cloneElement(children, {
                    draggableListeners: listeners,
                    isDragging,
                })
            }
        </div>
    )
})
