import { ColDef, ITooltipParams } from '@ag-grid-community/core'
import { ArrayParam, NumberParam, withDefault } from 'use-query-params'

import { TABLE_PER_PAGE_DEFAULT } from '@/components/legacy/tables/AgGridTableLegacy/components/ElementsPerPageSelect'
import { AgGridAccountCell, AgGridAssetCell } from '@/components/tables'
import { formatDate as formatDateString, formatDateTime, formatMonthFull } from '@/utils/date/formatDate'
import { monthIndToDate } from '@/utils/date/monthInd'
import { normalizeShortBackDate } from '@/utils/date/normalizeShortBackDate'

import { formatMoney, formatPercentageNumber, formatDate, formatString, formatPercentageFraction } from './AgGridTable.utils'

/**
 * Cell values for which we do not show the tooltip
 */
const CELL_TOOLTIP_EXCLUDE_VALUES = [
    '—',
    ' ',
    // FIXME: Control this case with config instead of this hardcode
    'No Mapping',
    'empty',
]

export const DEFAULT_TOOLTIP_VALUE_GETTER = ({ valueFormatted }: ITooltipParams) => {
    if (typeof valueFormatted !== 'string' || CELL_TOOLTIP_EXCLUDE_VALUES.some(el => el === valueFormatted)) {
        return null
    }

    return valueFormatted
}

const addZerosToNumber = (n: string | undefined) => {
    const template = '000000000'
    const zeroSubstring = template.substring(0, template.length - (n?.length || 0))
    return `${zeroSubstring}${n ?? ''}`
}

// add 0's in the beggining of number to make them sorted correctly as string
export const STRING_COMPARATOR = (a: string | undefined, b: string | undefined) => {
    const valueA = isNaN(Number(a)) ? a : addZerosToNumber(a)
    const valueB = isNaN(Number(b)) ? b : addZerosToNumber(b)
    return (valueA || '').toLowerCase().localeCompare((valueB || '').toLowerCase())
}

export const DATE_COMPARATOR = (date1: string, date2: string) => {
    const date1Timestamp = new Date(date1).getTime()
    const date2Timestamp = new Date(date2).getTime()

    if (isNaN(date1Timestamp)) {
        return -1
    } else if (isNaN(date2Timestamp)) {
        return 1
    } else {
        return date1Timestamp - date2Timestamp
    }
}

export const DEFAULT_TABLE_OFFSET_QUERY_PARAM_KEY = 'tablePageOffset'
export const DEFAULT_TABLE_LIMIT_QUERY_PARAM_KEY = 'tablePageLimit'

export const getAgGridOffsetQueryParam = (key = DEFAULT_TABLE_OFFSET_QUERY_PARAM_KEY) => ({
    [key]: withDefault(NumberParam, 0),
})

export const getAgGridLimitQueryParam = (key = DEFAULT_TABLE_LIMIT_QUERY_PARAM_KEY) => ({
    [key]: withDefault(NumberParam, TABLE_PER_PAGE_DEFAULT),
})

export const TABLE_SORT_QUERY_PARAM = {
    name: 'tableSortBy',
    type: ArrayParam,
}

/**
 * You can use this if you want default keys for offset and limit query params
 */
export const AG_GRID_DEFAULT_QUERY_PARAMS = {
    ...getAgGridOffsetQueryParam(),
    ...getAgGridLimitQueryParam(),
    // Hack for make types work for this case
} as unknown as { [DEFAULT_TABLE_LIMIT_QUERY_PARAM_KEY]: number, [DEFAULT_TABLE_OFFSET_QUERY_PARAM_KEY]: number }

export const DEFAULT_COL_DEF: ColDef = {
    dndSource: false,
    resizable: true,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    // For not allow columns shrink too much
    minWidth: 100,
    cellStyle: {
        textOverflow: 'clip',
    },
}

export const DEFAULT_COL_TYPES: Record<string, ColDef> = {
    actions: {
        headerClass: 'ag-right-aligned-header',
        cellClass: 'ag-right-aligned-cell',
        width: 70,
        minWidth: 70,
        // @ts-expect-error TS(2322) FIXME: Type '{ headerClass: string; cellClass: string; wi... Remove this comment to see the full error message
        cellContentAlignVertical: true,
        cellStyle: {
            justifyContent: 'flex-end',
        },
        headerName: 'Actions',
    },

    asset: {
        cellRenderer: ({ value, node }) => {
            if (node?.footer) {
                return ''
            }
            return <AgGridAssetCell assetId={value}/>
        },
    },

    account: {
        cellRenderer: ({ value, node }) => {
            if (node?.footer) {
                return ''
            }
            return <AgGridAccountCell accountId={value}/>
        },
    },
    accountTBSourse: {
        cellRenderer: ({ value }) => {
            return <AgGridAccountCell accountId={value} type='trialBalanceSource'/>
        },
    },

    /**
     * Format date to 12/26/2022
     */
    date: {
        valueFormatter: formatDate,
        minWidth: 120,
        comparator: DATE_COMPARATOR,
    },

    /**
     * Format date to 7/27/2023 10:00 AM
     */
    datetime: {
        valueFormatter: ({ value }) => {
            if (!value) {
                return '—'
            }

            return formatDateTime(value)
        },
        minWidth: 120,
        comparator: DATE_COMPARATOR,
    },

    /**
     * Format date to 12/26/2022 without timezone conversion
     */
    localDate: {
        valueFormatter: ({ value }) => {
            if (!value) {
                return '—'
            }

            return formatDateString(normalizeShortBackDate(value))
        },
        minWidth: 120,
        comparator: DATE_COMPARATOR,
    },
    money: {
        headerClass: 'ag-right-aligned-header',
        cellClass: 'ag-right-aligned-cell',
        valueFormatter: formatMoney,
    },
    /**
     * Format monthInd to October 2023
     */
    monthInd: {
        valueFormatter: ({ value }) => {
            if (!value) {
                return '—'
            }

            const date = monthIndToDate(value)
            return formatMonthFull(date)
        },
        minWidth: 120,
        sortable: true,
    },
    percentage: {
        headerClass: 'ag-right-aligned-header',
        cellClass: 'ag-right-aligned-cell',
        valueFormatter: formatPercentageNumber,
    },
    percentageFraction: {
        headerClass: 'ag-right-aligned-header',
        cellClass: 'ag-right-aligned-cell',
        valueFormatter: formatPercentageFraction,
    },
    string: {
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        cellStyle: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
        cellClass: 'agCell_type_string',
        valueFormatter: formatString,
        tooltipValueGetter: DEFAULT_TOOLTIP_VALUE_GETTER,
        comparator: STRING_COMPARATOR,
    },
}
