import { Model, model, field, foreign } from 'mobx-orm'

import { Asset } from './Asset'
import { api } from '../../http-adapter'

@api('unit')
@model
export class Unit extends Model {
    @field asset_id?: number
    @field name?:     string

    @foreign(Asset) readonly asset: Asset
}
