export function findPageByOffset (offset: number, limit: number) {
    if (limit <= 0) {
        console.error('Limit can\'t be less than 0')
        return 0
    }
    if (offset < 0) {
        console.error('Offset can\'t be less than 0')
        return 0
    }

    const page = Math.floor(offset / limit) + 1

    return page
}
