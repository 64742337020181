import { useState, MouseEvent } from 'react'

import { Popover } from '@mui/material'
import cn from 'classnames'
import { EQ } from 'mobx-orm'
import { observer } from 'mobx-react-lite'

import { useCompanyQuery } from '@/api/company/company'
import { Icon, Text } from '@/components/base'
import { CompaniesMenuProps } from '@/components/widgets/CompaniesMenu/CompaniesMenu.types'
import { useMe } from '@/hooks/core/useMe'

import styles from './CompaniesMenu.module.scss'
import { CompaniesMenuItem } from './CompaniesMenuItem'

/**
 * Companies list menu
 */
export const CompaniesMenu = observer((props: CompaniesMenuProps) => {
    const { isMenuExpanded } = props

    const { me, switchCompanyUser } = useMe()

    const companyQuery = useCompanyQuery({
        filter: EQ('__relations', 'features'),
    })

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const availableForPortal = me.availableCompanyUsersForPortal
    const companySelectMode = availableForPortal.length > 1

    const isMenuOpened = Boolean(anchorEl)

    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    const avatarText = (me.company?.name.split(' ')
        .map(word => word[0].toUpperCase())
        .join('').slice(0, 2) ?? '')

    const handleToggleClick = (event: MouseEvent<HTMLDivElement>) => {
        if (companySelectMode) {
            setAnchorEl(anchorEl ? null : event.currentTarget)
        }
    }

    const classNames = cn(
        styles.companiesMenu,
        {
            [styles.companiesMenu_active]: isMenuOpened,
            [styles.pointer]: companySelectMode,
        },
    )

    return (
        <>
            <div
                className={classNames}
                onClick={handleToggleClick}
                data-testid='CompaniesMenu'
            >
                <Text
                    variant='bodyMedium'
                    color='colorsPrimaryPurple'
                    block
                    className={styles.avatarPlaceholder}
                >
                    {avatarText}
                </Text>
                {isMenuExpanded && (
                    <>
                        <div className={styles.nameBox}>
                            <Text
                                color='colorsPrimaryGrey'
                                variant='smallTextSemiboldDefault'
                            >
                                {me.company?.name}
                            </Text>
                        </div>
                        {companySelectMode && (
                            <Icon
                                color='colorsSecondaryGrey600'
                                size='m'
                                name='arrowDown'
                                className={cn(styles.arrow, { [styles.arrow_rotated]: isMenuOpened })}
                            />
                        )}
                    </>
                )}
            </div>
            <Popover
                data-testid='CompaniesMenu-Popover'
                open={isMenuOpened}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                onClose={() => { setAnchorEl(null) }}
            >
                {
                    availableForPortal.map((companyUser, i) => {
                        const company = companyQuery.data?.find(c => c.id === companyUser.company_id)

                        return (
                            <CompaniesMenuItem
                                key={i}
                                isActive={me.companyUser.id === companyUser.id}
                                onClick={() => {
                                    switchCompanyUser(companyUser)
                                }}
                                text={company?.name || ''}
                            />
                        )
                    })
                }
            </Popover>
        </>
    )
})
