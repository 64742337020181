import { Component, useEffect, useState } from 'react'

import { captureException } from '@sentry/react'
import { useLocation } from 'react-router-dom'

import { InternalUnexpectedErrorPage } from '@/pages/errors/internal/InternalUnexpectedErrorPage'

import { ErrorBoundaryProps, ErrorBoundaryInnerProps, ErrorBoundaryInnerState } from './ErrorBoundary.types'

/**
 * We use custom ErrorBoundary component because we need to reset error state
 * from outside of the component when user navigates to another page.
 * That is not possible to do with Sentry.ErrorBoundary component.
 */
export const ErrorBoundary = (props: ErrorBoundaryProps) => {
    const [hasError, setHasError] = useState(false)
    const location = useLocation()

    useEffect(() => {
        if (hasError) {
            setHasError(false)
        }
    }, [location.pathname])

    return (
        <ErrorBoundaryInner
            noBackground={props.noBackground}
            hasError={hasError}
            setHasError={setHasError}
            level={props.level}
        >
            {props.children}
        </ErrorBoundaryInner>
    )
}

class ErrorBoundaryInner extends Component<ErrorBoundaryInnerProps, ErrorBoundaryInnerState> {
    constructor (props: ErrorBoundaryInnerProps) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError () {
        return { hasError: true }
    }

    componentDidUpdate (prevProps: ErrorBoundaryInnerProps) {
        if (!this.props.hasError && prevProps.hasError) {
            this.setState({ hasError: false })
        }
    }

    componentDidCatch (error: Error) {
        this.props.setHasError(true)
        captureException(error, {
            tags: {
                errorBoundary: true,
                page: window.location.pathname,
                // Return '/1' if path '/1/2/3' is passed
                rootPage: window.location.pathname.match(/^\/([^/]+)/)?.[0] ?? null,
                level: this.props.level,
            },
        })
    }

    render () {
        return this.state.hasError
            ? <InternalUnexpectedErrorPage rounded noBackground={this.props.noBackground}/>
            : this.props.children
    }
}
