import { ObjectFormField } from '@/components/legacy/models/ObjectFormPopup/ObjectFormPopup.types'
import { STATES_CODE_LIST } from '@/constants/states'

export const getBasicInformationFormFields = ({
    trialBalanceLedgerOptions,
    rentrollLedgerOptions,
    selectedTrialBalanceLedgers,
    selectedRentRollLedgers,
    onRentrollLedgerChange,
    onTrialBalanceLedgerChange,
}): ObjectFormField => (new Map([
    ['name', {
        type: 'string',
        title: 'Asset Name',
        required: true,
    }],
    ['state', {
        type: 'select-new',
        inputProps: {
            label: 'State',
            required: true,
            placeholder: 'State',
            options: STATES_CODE_LIST.map(value => ({ value, label: value })),
        },
    }],
    ['city', {
        type: 'string',
        title: 'City',
    }],
    ['address', {
        type: 'string',
        title: 'Address',
        required: true,
    }],
    ['property_manager_company', {
        type: 'string',
        title: 'Property Management Firm',
    }],
    ['acquisition_date', {
        type: 'serverDate',
        title: 'Acquisition Date',
        inputProps: {},
    }],
    ['asset_manager_name', {
        type: 'string',
        title: 'Asset Manager',
    }],
    ['ownership_percentage', {
        type: 'string',
        title: 'Ownership Percentage (input value as a decimal)',
    }],
    ['reporting_due_date', {
        type: 'string',
        title: 'Reporting Due Date',
    }],
    ['owner_id', {
        type: 'string',
        title: 'Owner ID',
    }],
    ['custom_id1', {
        type: 'string',
        title: 'CustomID 1',
    }],
    ['custom_id2', {
        type: 'string',
        title: 'CustomID 2',
    }],
    ['software', {
        type: 'string',
        title: 'Accounting System',
    }],
    ['type', {
        type: 'asset-type-select',
        title: 'Asset Type',
        readonly: true,
    }],
    ['fund_name', {
        type: 'string',
        title: 'Fund Name',
    }],
    ['units_count', {
        type: 'string', // TODO: implement number input control and use it
        title: 'Number of units in the asset',
    }],
    ['square_feet', {
        type: 'string', // TODO: implement number input control and use it
        title: 'Total square footage of the asset',
    }],
    ['tbLedger', {
        type: 'select-new',
        inputProps: {
            useCustomOnChange: true,
            label: 'Assign Chart of Accounts',
            multiSelect: true,
            placeholder: 'Chart of Accounts',
            options: trialBalanceLedgerOptions,
            onMultiChange: onTrialBalanceLedgerChange,
            multiSelectCustomValues: selectedTrialBalanceLedgers,
            multiSelectPlaceholder: 'Ledgers Selected',
        },
    }],
    ['rrLedger', {
        type: 'select-new',
        useCustomOnChange: true,
        inputProps: {
            label: 'Assign Rent Roll Ledgers',
            placeholder: 'Rent Roll Ledgers',
            multiSelect: true,
            options: rentrollLedgerOptions,
            onMultiChange: onRentrollLedgerChange,
            multiSelectCustomValues: selectedRentRollLedgers,
            multiSelectPlaceholder: 'Ledgers Selected',
        },
    }],
]))
