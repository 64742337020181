import { observer } from 'mobx-react-lite'

import { useMe } from '@/hooks/core/useMe'
import { CompanyInvitesPage } from '@/pages/MainPage/CommonPages/CompanyInvitesPage'
import { getRouteConfig } from '@/utils/routing/getRouteConfig'

export const CompanyProfileInvites = observer(() => {
    const { me } = useMe()
    return (
        <CompanyInvitesPage invitePopupPath={getRouteConfig('COMPANY_PROFILE_INVITES_NEW').path} companyId={me.companyUser.company_id ?? -1}/>
    )
})
