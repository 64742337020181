import { Loader } from '@/components/base'

import styles from './AgGridTableLoading.module.scss'

export const AgGridTableLoading = () => {
    return (
        <div className={styles.agGridTableLoading}>
            <div className={styles.background}/>
            <Loader className={styles.loader}/>
        </div>
    )
}
