import { useEffect, useRef } from 'react'

export function usePortal (id: string) {
    const rootElemRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const parentElem = document.getElementById(id)

        if (!parentElem) {
            return
        }

        if (!rootElemRef.current) {
            return
        }

        parentElem.appendChild(rootElemRef.current)

        return () => {
            rootElemRef.current?.remove()
        }
    }, [id])

    function getRootElem () {
        if (!rootElemRef.current) {
            const div = document.createElement('div')
            div.style.width = '100%' // to prevent shrink in flex container
            rootElemRef.current = div
        }
        return rootElemRef.current
    }

    return getRootElem()
}
