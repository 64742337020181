import { DateRangePicker } from '@/components/base/DateRangePicker'
import { useInputState } from '@/hooks'

import { YEAR_RANGE_END_QUERY_PARAM, YEAR_RANGE_START_QUERY_PARAM } from './YearRangeInput.constants'
import { YearRangeInputProps } from './YearRangeInput.types'

export const YearRangeInput = (props: YearRangeInputProps) => {
    const { defaultStart, defaultEnd, maxDate, required, label } = props

    const [startParam, setStartParam] = useInputState(YEAR_RANGE_START_QUERY_PARAM)
    const [endParam, setEndParam] = useInputState(YEAR_RANGE_END_QUERY_PARAM)

    if (!startParam && defaultStart) {
        setStartParam(defaultStart)
    }

    if (!endParam && defaultEnd) {
        setEndParam(defaultEnd)
    }

    const dateFrom = startParam ? new Date(startParam, 0) : undefined
    const dateTo = endParam ? new Date(endParam, 0) : undefined

    return (
        <DateRangePicker
            type='year'
            label={label}
            dateFrom={dateFrom}
            dateTo={dateTo}
            maxDate={maxDate}
            onChange={({ dateFrom, dateTo }) => {
                setStartParam(dateFrom ? dateFrom.getFullYear() : undefined)
                setEndParam(dateTo ? dateTo.getFullYear() : undefined)
            }}
            error={required && (!dateFrom || !dateTo)}
        />
    )
}
