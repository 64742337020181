import { useAssetsQuery } from '@/api/asset/asset'

import { AgGridAssetCellProps } from './AgGridAssetCell.types'

export const AgGridAssetCell = (props: AgGridAssetCellProps) => {
    const { assetId } = props

    // Fetch all assets because they are already in the cache
    const assetsQuery = useAssetsQuery()

    const asset = assetsQuery.data?.find(asset => asset.id === assetId)

    return (
        <>
            {
                asset ? (
                    asset.name
                ) : '—'
            }
        </>
    )
}
