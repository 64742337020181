import { ChangeEvent } from 'react'

import { TextInput } from '@/components/base'

import { BuiltInSearchInputProps } from './BuiltInSearchInput.types'

export const BuiltInSearchInput = (props: BuiltInSearchInputProps) => {
    const {
        onChange,
        value,
        placeholder = 'Search',
        icon = 'search',
        className,
        size = 'l',
        dataTestId,
    } = props

    const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const updatedValue = e.target.value
        onChange(updatedValue)
    }

    return (
        <TextInput
            onChange={changeHandler}
            value={value}
            placeholder={placeholder}
            icon={icon}
            className={className}
            dataTestId={dataTestId}
            size={size}
            borderless
        />
    )
}
