import { Text } from '@/components/base'
import { Layout } from '@/components/containers'

import { SelectOptionsGroupTitleProps } from './SelectOptionsGroupTitle.types'

export const SelectOptionsGroupTitle = (props: SelectOptionsGroupTitleProps) => {
    const { text } = props

    return (
        <Layout pt={16} px={12} pb={8}>
            <Text variant='labelMediumDefault' color='colorsSecondaryGrey500'>{text}</Text>
        </Layout>
    )
}
