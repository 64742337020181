import { TrialBalanceTemplateUploadStatus } from '@/models/trial_balance_template'

export const UPLOAD_STATUS_TEXT = {
    [TrialBalanceTemplateUploadStatus.UPLOADED]: 'File Uploaded, Validation in Progress,',
    [TrialBalanceTemplateUploadStatus.VALID]: 'File Imported Successfully',
    [TrialBalanceTemplateUploadStatus.CHECKING]: 'File Imported Successfully',
    [TrialBalanceTemplateUploadStatus.READY]: 'File Imported Successfully',
    [TrialBalanceTemplateUploadStatus.APPLYING]: 'File Imported Successfully',
    [TrialBalanceTemplateUploadStatus.ERROR]: 'File Error',
}
