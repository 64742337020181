import { DateRangePicker } from '@/components/base'
import { useInputState } from '@/hooks'

import { DATE_RANGE_START_QUERY_PARAM, DATE_RANGE_END_QUERY_PARAM } from './DateRangeInput.constants'
import { DateRangeInputProps } from './DateRangeInput.types'

export const DateRangeInput = (props: DateRangeInputProps) => {
    const { maxDate, minDate, defaultStart, defaultEnd, required, label, syncLocalStorage } = props

    const [startParam, setStartParam] = useInputState<Date | null | undefined>(
        {
            ...DATE_RANGE_START_QUERY_PARAM,
            syncLocalStorage,
        })

    const [endParam, setEndParam] = useInputState<Date | null | undefined>(
        {
            ...DATE_RANGE_END_QUERY_PARAM,
            syncLocalStorage,
        })

    if (!startParam && defaultStart) { setStartParam(defaultStart) }
    if (!endParam && defaultEnd) { setEndParam(defaultEnd) }

    return (

        <DateRangePicker
            type='day'
            label={label}
            dateFrom={startParam ?? undefined}
            dateTo={endParam ?? undefined}
            maxDate={maxDate}
            minDate={minDate}
            onChange={({ dateFrom, dateTo }) => {
                setStartParam(dateFrom)
                setEndParam(dateTo)
            }}
            error={required && (!startParam || !endParam)}
        />

    )
}
