import { FC } from 'react'

import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router'

import ObjectDeletePopup from '@/components/legacy/models/ObjectDeletePopup'
import { ADMIN_ROUTES_CONFIG } from '@/core/routes'
import { Asset } from '@/models/asset'

const AssetDeletePopup: FC = observer(() => {
    const { assetId } = useParams()
    const obj = assetId === undefined ? new Asset() : Asset.get(parseInt(assetId)) as Asset
    const navigate = useNavigate()

    // TODO: make something with this dirty hack
    // after object deletion this popup tries to rerender and falls down because
    // object already deleted
    if (!obj) {
        return <></>
    }

    return (
        <ObjectDeletePopup
            obj={obj}
            onClose={(deleted) => {
                if (!deleted) {
                    navigate(-1)
                }
            }}
            onDeleted={() => {
                window.location.href = ADMIN_ROUTES_CONFIG.ASSETS.path
            }}
            title={`Delete asset ${obj.name}`}
            warningText='Are you sure you want to delete? Deleting the asset will remove all mappings related to this asset.'
        />
    )
})

export default AssetDeletePopup
