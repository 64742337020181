import { OWNER_ROUTES_CONFIG } from '@/core/routes'

export const REPORTS_FINANCIALS_SUBHEADER_ITEMS = [
    OWNER_ROUTES_CONFIG.REPORTS_FINANCIALS_PORTFOLIO,
    OWNER_ROUTES_CONFIG.REPORTS_FINANCIALS_INCOME_STATEMENT,
    OWNER_ROUTES_CONFIG.REPORTS_FINANCIALS_BALANCE_SHEET,
    OWNER_ROUTES_CONFIG.REPORTS_FINANCIALS_ACTUAL_BUDGET,
    OWNER_ROUTES_CONFIG.REPORTS_FINANCIALS_PROPERTY_COMPARISON,
    OWNER_ROUTES_CONFIG.REPORTS_FINANCIALS_TRIAL_BALANCE,
    OWNER_ROUTES_CONFIG.REPORTS_FINANCIALS_TB_SUMMARY,
]
