import cn from 'classnames'

import { Icon } from '@/components/base'

import styles from './IconButton.module.scss'
import { IconButtonProps } from './IconButton.types'

export const IconButton = (props: IconButtonProps) => {
    const { className, size = 'm', onClick, icon } = props

    const handleClick = () => {
        onClick?.()
    }

    const iconSize = size === 'm' ? 'l' : 'm'

    return (
        <button className={cn(styles.button, className)} onClick={handleClick}>
            <Icon name={icon} size={iconSize}/>
        </button>
    )
}
