import { DatePicker } from '@/components/base/DatePicker'
import { useInputState } from '@/hooks'

import { YEAR_INPUT_QUERY_PARAM } from './YearInput.constants'
import { YearInputProps } from './YearInput.types'

const { name: yearParamName, type } = YEAR_INPUT_QUERY_PARAM

export const YearInput = (props: YearInputProps) => {
    const {
        paramName = yearParamName,
        defaultYear,
        syncLocalStorage,
        placeholderText = 'Select year',
        required = false,
        ...rest
    } = props

    const [year, setYear] = useInputState({ name: paramName, type, syncLocalStorage })

    if (!year && defaultYear) {
        setYear(defaultYear)
    }

    return (
        <DatePicker
            date={year ? new Date(year, 0) : undefined}
            onChange={date => date && setYear(date.getFullYear())}
            placeholderText={placeholderText}
            error={required && !year}
            type='year'
            icon='arrowDown'
            rotateIcon
            {...rest}
        />
    )
}
