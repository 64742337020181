import { observer } from 'mobx-react-lite'

import { ButtonGroup } from '@/components/base'

import { DateGroupFilterProps } from './DateGroupFilter.types'

// DEPRECATED
export const DateGroupFilter = observer((props: DateGroupFilterProps) => {
    const { rollup, annual, monthly, daily, filter, label = 'Group' } = props

    const choices = []
    // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
    if (daily) choices.push({ title: 'Daily', key: 'daily' })
    // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
    if (monthly) choices.push({ title: 'Monthly', key: 'monthly' })
    // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
    if (annual) choices.push({ title: 'Annual', key: 'annual' })
    // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
    if (rollup) choices.push({ title: 'Rollup', key: 'rollup' })

    return (
        <ButtonGroup
            label={label}
            options={choices}
            selected={filter.value}
            onSelect={(key) => filter.set(key)}
        />
    )
})
