import React from 'react'

import { useCompanyUserQuery } from '@/api/user/companyUser'
import { INTELAS_ADMIN_USER } from '@/constants/texts'
import { userFullname } from '@/utils/mobx-orm/userFullname'

import { UserNameProps } from './UserName.types'

export const UserName = (props: UserNameProps) => {
    const { companyUserId } = props

    const companyUserQuery = useCompanyUserQuery()
    const companyUser = companyUserQuery.data?.find(el => el.id === companyUserId)

    const name = (companyUserQuery.isFetched && !companyUser)
        ? INTELAS_ADMIN_USER
        :  (companyUser ? userFullname(companyUser)  : '')

    return (
        <>{name}</>
    )
}
