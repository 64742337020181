import React from 'react'

import { observer } from 'mobx-react-lite'

import { Modal } from '@/components/base'

import { BigModalProps } from './BigModal.types'

/**
 * Modal component with predefined sizes
 *
 * @todo possibly better to make sizes in Modal component
 */
export const BigModal = observer((props: BigModalProps) => {
    return (
        <Modal
            {...props}
            maxWidth={1032}
            maxHeight={863}
            gap={24}
        />
    )
})
