import React, { forwardRef, RefObject, useEffect, useRef, useState } from 'react'

import { GridReadyEvent, Module } from '@ag-grid-community/core'
// eslint-disable-next-line no-restricted-imports
import { AgGridReact, AgGridReactProps } from '@ag-grid-community/react'
import '@ag-grid-community/styles/ag-grid.css'
import '@ag-grid-community/styles/ag-theme-alpine.css'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { LoadingTemplate } from '@/components/legacy/tables/Table/components/LoadingTemplate'
import { DEFAULT_COL_DEF, TABLE_COLUMN_TYPES_DEFAULT } from '@/components/legacy/tables/Table/Table.constants'
import { useTableModules } from '@/components/tables/utils/useTableModules'
import { lazyWithCatch } from '@/utils/lazyCatch'

import { TableProps, TableThemeClassNames, TableVariants } from './Table.types'
// Can't use modules here because a lof of code using global class names from AgGrid table
import './variantStyles/Table.variant_1.scss'
import './variantStyles/Table.variant_2.scss'
import './variantStyles/Table.variant_3.scss'
import './variantStyles/Table.variant_4.scss'
import './variantStyles/Table.variant_5.scss'

const AgGridReactLazy = lazyWithCatch(() => import('@/components/legacy/tables/AgGridTableLegacy/exports/agGridReact'))

// REFACTOR(ANDREY): Check this cole. May be enum better.
const tableVariantClassNames: Record<TableVariants, string> = {
    1: 'table_variant_1',
    2: 'table_variant_2',
    3: 'table_variant_3',
    4: 'table_variant_4',
    5: 'table_variant_5',
}

const tableThemeClassNames: TableThemeClassNames = {
    alpine: 'ag-theme-alpine',
    'alpine-dark': 'ag-theme-alpine-dark',
}

/**
 * Mutates obj
 */
const addHeaderTooltip = (items) => {
    items.forEach((col) => {
        if (col.headerName?.length) {
            col.headerTooltip = col.headerName
        }

        if (col.children?.length) {
            addHeaderTooltip(col.children)
        }
    })
}

/**
 * Table component based on 'AgGrid' library
 * @deprecated Use AgGridTableLegacy instead
 *
 * TODO: Make last version of table variant as default
 */
export const Table = observer(forwardRef((props: TableProps, ref: RefObject<AgGridReact>) => {
    const {
        loading = false,
        rowData = [],
        hasError = false,
        noRowsText = 'No Rows To Show',
        suppressFitToWith = false,
        fitToWidthConfig,
        theme = 'alpine',
        variant,
        className,
        supressAutoResize,
        columnTypes,
        defaultColDef,
        ...agGridOptions
    } = props

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const ref_ = useRef<AgGridReact>(null)
    ref ||= ref_

    const [isTableReady, setIsTableReady] = useState(false)

    const defaultModules = useTableModules()
    const [tableModules, setTableModules] = useState<Module[]>([])

    useEffect(() => {
        (async () => {
            setTableModules([...(await defaultModules)])
        })()
    }, [defaultModules])

    const hasRowData = Boolean(rowData.length)

    const resizeColumns = () => {
        if (!suppressFitToWith) {
            setTimeout(() => {
                try {
                    ref.current?.api?.sizeColumnsToFit(fitToWidthConfig)
                } catch {}
            })
        }
    }

    const handleGridReady = (params: GridReadyEvent) => {
        const { api } = params

        props.onGridReady?.(params)

        setIsTableReady(true)
        if (!suppressFitToWith) {
            try {
                api?.sizeColumnsToFit(fitToWidthConfig)
            } catch {}
        }
    }

    useEffect(() => {
        if (!supressAutoResize) {
            resizeColumns()
        }
    })

    useEffect(() => {
        if (!supressAutoResize) {
            window.addEventListener('resize', resizeColumns)
            return () => { window.removeEventListener('resize', resizeColumns) }
        }
    }, [])

    useEffect(() => {
        if (!isTableReady) {
            return
        }

        if (!supressAutoResize) {
            resizeColumns()
        }

        // REFACTOR: Fix this timeout
        // Without this hack with setTimeout overlay sometimes not shown
        setTimeout(() => {
            // Hide overlays
            ref.current?.api.hideOverlay()

            // Error overlay
            if (hasError) {
                ref.current?.api.showNoRowsOverlay()
                return
            }

            // Loading overlay
            if (loading) {
                ref.current?.api.showLoadingOverlay()
                return
            }

            // No data overlay
            if (!hasRowData) {
                ref.current?.api.showNoRowsOverlay()
            }
        }, 10)
    }, [loading, isTableReady, hasRowData, hasError])

    const agGridClassNames = cn(
        // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
        tableVariantClassNames[variant],
        tableThemeClassNames[theme],
        className,
    )

    const agGridProps: AgGridReactProps & { ref: RefObject<AgGridReact> } = {
        ...agGridOptions,
        tooltipShowDelay: 0,
        columnTypes: { ...TABLE_COLUMN_TYPES_DEFAULT, ...columnTypes },
        defaultColDef: { ...DEFAULT_COL_DEF, ...defaultColDef },
        onGridReady: handleGridReady,
        loadingOverlayComponent: LoadingTemplate,
        overlayNoRowsTemplate: hasError ? 'Data Loading Error' : noRowsText,
        className: agGridClassNames,
        // Rows hidded if error layout shown
        rowData: hasError ? [] : rowData,
        modules: tableModules,
        ref,
    }

    addHeaderTooltip(agGridProps.columnDefs)

    if (!supressAutoResize) {
        agGridProps.onRowGroupOpened = resizeColumns
    }

    if (!tableModules.length) {
        return null
    }

    return (
        // Super Important for optimization to use only lazy variant
        <AgGridReactLazy {...agGridProps}>
            {props.children}
        </AgGridReactLazy>
    )
}))
