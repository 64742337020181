import { useEffect } from 'react'

import cn from 'classnames'
import { matchPath, useLocation, useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

import { useMe } from '@/hooks/core/useMe'
import { getAvailableNavigationItemsForUser } from '@/utils/routing/getAvailableNavigationItemsForUser'

import styles from './HeaderNavigation.module.scss'
import { HeaderNavigationProps } from './HeaderNavigation.types'

export const HeaderNavigation = (props: HeaderNavigationProps) => {
    const { className, items, baseRoute } = props
    const location = useLocation()
    const navigate = useNavigate()
    const { me } = useMe()

    // If have items available and route is not matched, redirect to first available item
    useEffect(() => {
        if (!items.length) return

        const matchedMenuItem = items.find(item => matchPath({
            path: location.pathname,
            end: true,
        }, item.path))

        const isBaseRouteMatched = baseRoute
            ? matchPath({
                path: location.pathname,
                end: true,
            }, baseRoute.path)
            : false

        // If not menu items matched and base route is matched, redirect to first available item
        if (!matchedMenuItem && isBaseRouteMatched) {
            navigate(items[0].path)
        }
    }, [items, baseRoute, location.pathname])

    return (
        <div className={cn(styles.headerNavigation, className)}>
            {
                getAvailableNavigationItemsForUser(items, me)
                    .map(({ title, path, dataTestId }, i) => {
                        const isActivePath = matchPath({ path, end: false }, location.pathname)

                        return (
                            <Link
                                key={i}
                                data-testid={dataTestId}
                                to={path}
                                className={cn(styles.link, { [styles.link_active]: isActivePath })}
                                onClick={(event) => {
                                    if (isActivePath) {
                                        event.stopPropagation()
                                        event.preventDefault()
                                    }
                                }}
                            >
                                {title}
                            </Link>
                        )
                    })
            }
        </div>
    )
}
