import { BooleanInput } from '@/components/baseInputs'

import { EMPTY_ROWS_INPUT_PARAM } from './EmptyRowsInput.constants'
import { EmptyRowsInputProps } from './EmptyRowsInput.types'

/**
 * Wrapper for BooleanInput with predefined options ['Hide', 'Show']
 */
export const EmptyRowsInput = (props: EmptyRowsInputProps) => {
    const { paramName } = props
    return (
        <BooleanInput
            label='Empty Rows'
            paramName={paramName ?? EMPTY_ROWS_INPUT_PARAM.name}
            options={EMPTY_ROWS_INPUT_PARAM.options}
            type='button-group'
        />
    )
}
