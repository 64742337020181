/**
 * Generate unique names like: "name (1)", "name (2)", "name (3)" etc.
 */
export const getIncrementalName = (nameBase: string, list: string[]): string => {
    let i = 1
    let resName = nameBase
    while (true) {
        const postfix = `(${i})`
        i++
        const nameWithPostfix = `${resName} ${postfix}`
        const doExist = list.some((name) => name === nameWithPostfix)
        if (!doExist) {
            resName = nameWithPostfix
            break
        }
    }
    return resName
}
