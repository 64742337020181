import React from 'react'

import { observer } from 'mobx-react-lite'

import { Label, SelectOptionDefault } from '@/components/base'
import { SelectOptionBase } from '@/components/base/Select/options'

import { SelectOptionWithLabelProps } from './SelectOptionWithLabel.types'

export const SelectOptionWithLabel = observer(<Option = SelectOptionDefault>(props: SelectOptionWithLabelProps<Option>) => {
    const { labelColor, labelWidth, labelText, ...rest } = props

    const startEl = <Label color={labelColor} text={labelText} width={labelWidth}/>

    return (
        <SelectOptionBase startEl={startEl} {...rest}/>
    )
})
