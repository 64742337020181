import { ColDef } from '@ag-grid-community/core'

import { Text } from '@/components/base'
import { formatDate, formatDateTime } from '@/utils/date/formatDate'

/**
 * Cell values for which we do not show the tooltip
 */
const CELL_TOOLTIP_EXCLUDE_VALUES = [
    '—',
    // FIXME: Control this case with config instead of this hardcode
    'No Mapping',
    'empty',
]

export const DEFAULT_COL_DEF: ColDef = {
    dndSource: false,
    resizable: true,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    // For not allow columns shrink too much
    minWidth: 100,
    cellStyle: {
        textOverflow: 'clip',
    },
}

export const DEFAULT_COL_TYPES: Record<string, ColDef> = {
    actions: {
        headerClass: 'ag-right-aligned-header',
        cellClass: 'ag-right-aligned-cell',
        width: 70,
        minWidth: 70,
        // @ts-expect-error TS(2322) FIXME: Type '{ headerClass: string; cellClass: string; wi... Remove this comment to see the full error message
        cellContentAlignVertical: true,
        cellStyle: {
            justifyContent: 'flex-end',
        },
        headerName: 'Actions',
    },
    string: {
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        cellStyle: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
        cellClass: 'agCell_type_string',
        // TODO: Use label instead of '—'
        valueFormatter: ({ value }) => value || '—',
        tooltipValueGetter: ({ valueFormatted }) => {
            if (typeof valueFormatted !== 'string' || CELL_TOOLTIP_EXCLUDE_VALUES.some(el => el === valueFormatted)) {
                return null
            }

            return valueFormatted
        },
    },
    /**
     * Type for handle money values
     */
    money: {
        headerClass: 'ag-right-aligned-header',
        cellClass: 'ag-right-aligned-cell',
        // @ts-expect-error TS(2339) FIXME: Property 'fractionDigits' does not exist on type '... Remove this comment to see the full error message
        valueFormatter: ({ value, fractionDigits = 0 }) => {
            const valueNumber = Number(value)

            if (isNaN(valueNumber)) {
                return '—'
            }

            const format = (val) => val.toLocaleString('en-US', {
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits,
            })

            return (valueNumber < 0) ? `(${format(-valueNumber)})` : format(valueNumber)
        },
    },
    /**
     * Percent: -1,234%
     */
    percentage: {
        headerClass: 'ag-right-aligned-header',
        cellClass: 'ag-right-aligned-cell',
        // @ts-expect-error TS(2339) FIXME: Property 'fractionDigits' does not exist on type '... Remove this comment to see the full error message
        valueFormatter: ({ value, fractionDigits = 0 }) => {
            const valueNumber = Number(value)

            if (isNaN(valueNumber)) {
                return '—'
            }

            const format = (val) => val.toLocaleString('en-US', {
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits,
            })

            return `${format(valueNumber)}%`
        },
    },
    /**
     * Format date to 12/26/2022
     */
    date: {
        valueFormatter: ({ value }) => {
            if (!value) {
                return '—'
            }

            return formatDate(value)
        },
        minWidth: 120,
    },
    /**
     * Format date to 7/27/2023 10:00 AM
     */
    datetime: {
        valueFormatter: ({ value }) => {
            if (!value) {
                return '—'
            }

            return formatDateTime(value)
        },
        minWidth: 120,
    },
}

export const tableCellTextStyle1 = {
    cellRendererSelector: (props) => ({
        component: Text,
        params: {
            color: 'colorsSecondaryGrey600',
            text: props.valueFormatted || props.value,
            variant: 'smallTextMediumDefault',
        },
    }),
}
