import React from 'react'

import { Model } from 'mobx-orm'

interface ModelFormContextProps {
    obj?: Model
    syncURL?: boolean | string
    syncLocalStorage?: boolean | string
}

// @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'Model | und... Remove this comment to see the full error message
export const ModelFormContext = React.createContext<ModelFormContextProps>({ obj: null, syncURL: false, syncLocalStorage: false })
