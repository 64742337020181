import { FC, Suspense, useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router'

import { Header, Loader } from '@/components/base'
import { ContentWithMenu } from '@/components/containers/ContentWithMenu'
import { BOTTOM_MODAL_CONTAINER, SIDE_MODAL_CONTAINER } from '@/constants/ids'
import { CompanyUserMode } from '@/core/modes'
import { useMe } from '@/hooks/core/useMe'
import { Asset } from '@/models/asset'
import { TrialBalanceLedger } from '@/models/ledger'
import { RentRollLedger } from '@/models/rent_roll'
import {
    ADMIN_SIDE_MENU_ITEMS,
    MANAGER_SIDE_MENU_ITEMS, OWNER_SIDE_MENU_ITEMS,
} from '@/pages/MainPage/MainPage.constants'
import { lazyWithCatch } from '@/utils/lazyCatch'

import './MainPage.scss'

const SideMenu = lazyWithCatch(() => import('@/components/widgets/SideMenu'))

// REFACTOR: Styles
export const MainPage: FC = observer(() => {
    const { isAuthenticated, isLoading: isAuthLoading } = useAuth0()

    const { me } = useMe()

    // data that we should load before rendering main page
    const [allAssets] = useState(() => Asset.getQuery())
    const [allTrialBalanceLedgers] = useState(() => TrialBalanceLedger.getQuery())
    const [allRentRollLedgers] = useState(() => RentRollLedger.getQuery())
    // don't requist assets and ledgers before "me" is not ready
    // otherwise if you is staff then you will get all ledgers and assets
    useEffect(() => {
        // FIXME: Legacy mobx-orm way of preloading data
        //  Must be remved with transition to react-query
        if (me.hasAccessToPortal) {
            allAssets.load()
            allTrialBalanceLedgers.load()
            allRentRollLedgers.load()
        }
    }, [me.hasAccessToPortal])

    const isInitialDataLoading = (me.hasAccessToPortal ? (!allAssets.is_ready || !allTrialBalanceLedgers.is_ready || !allRentRollLedgers.is_ready) : false) ||
        (isAuthLoading || !isAuthenticated)

    const loader = <Loader centered/>

    const getMenuItems = () => {
        if (!me.hasAccessToPortal) {
            return []
        }

        if (me.companyUserMode === CompanyUserMode.Owner) {
            return OWNER_SIDE_MENU_ITEMS
        }
        if (me.companyUserMode === CompanyUserMode.Admin) {
            return ADMIN_SIDE_MENU_ITEMS
        }
        if (me.companyUserMode === CompanyUserMode.Manager) {
            return MANAGER_SIDE_MENU_ITEMS
        }
    }

    return (
        <ContentWithMenu
            menu={(
                <SideMenu
                    items={getMenuItems()}
                />
            )}
            content={(
                <>
                    <Header/>
                    <div className='main__center__content'>
                        <Suspense fallback={loader}>
                            <Outlet/>
                        </Suspense>
                    </div>
                    <div id={SIDE_MODAL_CONTAINER}/>
                    <div id={BOTTOM_MODAL_CONTAINER}/>
                </>
            )}
            loading={isInitialDataLoading}
        />
    )
})
