import { TimeSettings } from '@/constants/timeSettings'
import { useInputState } from '@/hooks'

import { TIME_SETTINGS_INPUT_QUERY_PARAM, TIME_SETTINGS_OPTIONS } from './TimeSettingsInput.constants'
import { TimeSettingsInputProps } from './TimeSettingsInput.types'
import { SelectInput } from '../SelectInput'

export const TimeSettingsInput = (props: TimeSettingsInputProps) => {
    const {
        label = 'Time Settings',
        syncLocalStorage,
        ...rest
    } = props

    useInputState({
        ...TIME_SETTINGS_INPUT_QUERY_PARAM,
        syncLocalStorage,
        defaultValue: TimeSettings.SINGLE,
    })

    return (
        <SelectInput
            paramName={TIME_SETTINGS_INPUT_QUERY_PARAM.name}
            paramType={TIME_SETTINGS_INPUT_QUERY_PARAM.type}
            options={TIME_SETTINGS_OPTIONS}
            label={label}
            syncLocalStorage={syncLocalStorage}
            width='162px' // the width of the longest option
            placeholder='Select time settings'
            required
            {...rest}
        />
    )
}
