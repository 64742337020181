import { DataPointItem } from '@/api/dataPulling/dataPoint'
import { Label, LabelProps, Link } from '@/components/base'
import { AgGridTableProps } from '@/components/tables'
import { STRING_COMPARATOR } from '@/components/tables/AgGridTable/AgGridTable.constants'
import { AgGridHeaderWithHint } from '@/components/tables/AgGridTable/components/AgGridHeaderWithHint'
import { DataPointSource, DataPointStatus, DataPointType, dataPointTypeTitles } from '@/models/data_pulling'
import { UserName } from '@/models/user/UserName'
import { DataPointAlertsCell } from '@/pages/UploadsPage'
import { UserNoteIcon } from '@/pages/UploadsPage/UserNoteIcon'
import { downloadFile } from '@/utils/downloadFile'
import { getRoute } from '@/utils/routing/getRoute'

import { SubmissionAssetSelectCell } from './SubmissionAssetSelectCell'
import { SubmissionMonthPickerCell } from './SubmissionMonthPickerCell'

export const SUBMISSION_STATUS_LABELS_CONFIG: Record<string, (errorNum?: number) => LabelProps> = {
    processing: () => ({
        text: 'Processing',
        color: 'orange',
        iconName: 'refresh',
    }),
    error: (errorsNum = 0) => ({
        text: `${errorsNum > 0 ? (errorsNum + ' ') : ''}${errorsNum === 1 ? 'Error' : 'Errors'}`,
        color: 'red',
        iconName: 'info',
    }),
    internalError: () => ({
        text: 'Internal Error',
        color: 'red',
        iconName: 'info',
    }),
    alert: (errorsNum = 0) => ({
        text: `${errorsNum} ${errorsNum === 1 ? 'Alert' : 'Alerts'}`,
        color: 'blue',
        iconName: 'warning',
    }),
    passed: () => ({
        text: 'Passed',
        color: 'green',
        iconName: 'check',
    }),
    uploaded: () => ({
        text: 'Uploaded',
        color: 'green',
        iconName: 'check',
    }),
    empty: () => ({
        text: '—',
        color: 'gray',
    }),
    canceled: () => ({
        text: 'Canceled',
        color: 'gray',
    }),
}

export const gridOptions: Omit<AgGridTableProps, 'query' | 'items'> = {
    rowHeight: 37,
    headerHeight: 48,
    defaultColDef: {
        unSortIcon: true,
        filter: 'agTextColumnFilter',
        resizable: true,
        suppressHeaderMenuButton: true,
        headerComponentParams: {
            menuIcon: 'fa-bars',
        },
        wrapText: true,
        comparator: STRING_COMPARATOR,
    },
    columnDefs: [
        {
            field: 'initial_file_name',
            pinned: 'left',
            headerName: 'File Name',
            minWidth: 400,
            cellContentAlignVertical: true,
            cellRenderer: ({ data }: { data: DataPointItem }) => {
                const path = `data-pulling/download/${data.id}/`
                return (
                    <Link
                        onClick={() => downloadFile(path, data.initial_file_name)}
                        variant='smallTextMediumDefault'
                        maxLines={1}
                        tooltip={data.initial_file_name}
                    >
                        {data.initial_file_name || '—'}
                    </Link>
                )
            },
        },
        {
            field: 'asset_id',
            headerName: 'Asset',
            type: 'string',
            minWidth: 200,
            cellContentAlignVertical: true,
            cellRenderer: SubmissionAssetSelectCell,
            sortable: false,
        },
        {
            field: 'report_date',
            headerName: 'Period',
            type: 'localDate',
            minWidth: 170,
            cellContentAlignVertical: true,
            cellRenderer: SubmissionMonthPickerCell,
        },
        {
            field: 'type',
            headerName: 'Data Type',
            minWidth: 200,
            cellContentAlignVertical: true,
            valueFormatter: ({ value }: { value: DataPointType }) => {
                const title = dataPointTypeTitles.get(value)
                return title || '—'
            },
        },
        {
            headerName: 'Uploaded By',
            sortable: false,
            minWidth: 200,
            type: 'string',
            cellContentAlignVertical: true,
            cellRenderer: ({ data }: { data: DataPointItem }) => {
                if (data.source === DataPointSource.EMAIL) return data.source_email

                const companyUserId = data.submitted_file?.company_user_id || data.parent?.submitted_file?.company_user_id
                if (companyUserId) return <UserName companyUserId={companyUserId}/>

                return '—'
            },
        },
        {
            field: 'created_at',
            headerName: 'Uploaded At',
            type: 'datetime',
            minWidth: 200,
            cellContentAlignVertical: true,
        },
        {
            field: 'id',
            headerName: 'Status',
            minWidth: 200,
            cellContentAlignVertical: true,
            cellRenderer: ({ data: dataPoint }: { data: DataPointItem }) => {
                const errorsNum = dataPoint.submission_errors_num

                if (dataPoint.status === DataPointStatus.INTERNAL_ERROR) {
                    return (
                        <Label {...SUBMISSION_STATUS_LABELS_CONFIG.internalError()}/>
                    )
                }

                if (dataPoint.status === DataPointStatus.ERROR || errorsNum) {
                    return (
                        <Label {...SUBMISSION_STATUS_LABELS_CONFIG.error(errorsNum)}/>
                    )
                }

                if (dataPoint.status === DataPointStatus.DONE) {
                    return (
                        <Label {...SUBMISSION_STATUS_LABELS_CONFIG.passed()}/>
                    )
                }

                if (dataPoint.status === DataPointStatus.PROCESSING || dataPoint.status === DataPointStatus.WAITING_INPUT) {
                    return (
                        <Label {...SUBMISSION_STATUS_LABELS_CONFIG.processing()}/>
                    )
                }

                if (dataPoint.status === DataPointStatus.CANCELED) {
                    return (
                        <Label {...SUBMISSION_STATUS_LABELS_CONFIG.canceled()}/>
                    )
                }

                // Unexpected case
                return (
                    <Label {...SUBMISSION_STATUS_LABELS_CONFIG.empty()}/>
                )
            },
        },
        {
            headerName: 'Alerts',
            minWidth: 150,
            cellContentAlignVertical: true,
            headerComponent: AgGridHeaderWithHint,
            headerComponentParams: {
                hint: 'Data quality alerts identify potential irregularities in the submitted data set. Data has been accepted by the system, but we encourage you to review the list of alerts and determine if any changes need to be made to the underlying data.',
            },
            cellRenderer: ({ data: dataPoint }: { data: DataPointItem }) => {
                return (
                    <DataPointAlertsCell
                        dataPoint={dataPoint}
                        getSidemodalPath={dataPointId => getRoute('UPLOADS_LOGS_ERRORS_BY_ID', { dataPointId })}
                    />
                )
            },
            sortable: false,
        },
        {
            headerName: 'Notes',
            field: 'submitted_file.note',
            cellContentAlignVertical: true,
            minWidth: 150,
            sortable: false,
            cellRenderer: ({ data, value }: { data: DataPointItem, value: string }) => {
                if (value) {
                    return (
                        <UserNoteIcon
                            icon='note'
                            companyUserId={data.submitted_file.company_user_id}
                            dateTime={data.submitted_file.created_at}
                            text={value}
                        />
                    )
                }
            },
        },
    ],
}
