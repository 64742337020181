import React, { useEffect, useMemo } from 'react'

import { UseQueryResult } from '@tanstack/react-query/src/types'
import endOfMonth from 'date-fns/endOfMonth'
import startOfMonth from 'date-fns/startOfMonth'
import { AND, EQ, GTE, IN, LTE } from 'mobx-orm'
import { useQueryParams } from 'use-query-params'

import { useAssetsQuery } from '@/api/asset/asset'
import { useDataPointCountQuery, useDataPointQuery } from '@/api/dataPulling/dataPoint'
import { ASSET_SIDE_MODAL_INPUT_QUERY_PARAM } from '@/components/models/asset'
import { ReactQueryTable } from '@/components/reactQuery'
import {
    AG_GRID_DEFAULT_QUERY_PARAMS,
    DEFAULT_TABLE_OFFSET_QUERY_PARAM_KEY,
    TABLE_SORT_QUERY_PARAM,
} from '@/components/tables'
import { useInputState, useJoin } from '@/hooks'
import { DataPoint, DataPointType, dataPointTypeTitles } from '@/models/data_pulling'
import {
    DATA_SUBMISSION_PERIOD_END_QUERY_PARAM,
    DATA_SUBMISSION_PERIOD_START_QUERY_PARAM,
    DATA_SUBMISSION_TYPE_QUERY_PARAM,
} from '@/pages/UploadsPage/SubmissionLogsPage/SubmissionLogsPage.constants'
import { gridOptions } from '@/pages/UploadsPage/SubmissionLogsPage/SubmissionLogTable/SubmissionLogTable.constants'
import { monthIndToDate } from '@/utils/date/monthInd'
import { getSortString } from '@/utils/models/sortString'

export const SubmissionLogTable = () => {
    const [tablePaginationParams, setTableParams] = useQueryParams<any>(AG_GRID_DEFAULT_QUERY_PARAMS)
    const [tableSortParam] = useInputState(TABLE_SORT_QUERY_PARAM)

    const [fileType, setFileType] = useInputState(DATA_SUBMISSION_TYPE_QUERY_PARAM)
    const [startPeriodDate] = useInputState(DATA_SUBMISSION_PERIOD_START_QUERY_PARAM)
    const [endPeriodDate] = useInputState(DATA_SUBMISSION_PERIOD_END_QUERY_PARAM)
    const [assets] = useInputState(ASSET_SIDE_MODAL_INPUT_QUERY_PARAM)

    const dataPointQueryFilters = AND(
        // Here is all available for user types
        IN('type', [DataPointType.RENT_ROLL, DataPointType.TRIAL_BALANCE, DataPointType.UNRECOGNIZED]),
        // Here we can narrow down to only selected types
        IN('type', fileType as DataPointType[]),
        IN('asset_id', assets as number[]),
        GTE('created_at', startPeriodDate ? startOfMonth(monthIndToDate(startPeriodDate)).toISOString() : undefined),
        LTE('created_at', endPeriodDate ? endOfMonth(monthIndToDate(endPeriodDate)).toISOString() : undefined),
        EQ('__relations', 'parent'),
    )

    useEffect(() => {
        if (!fileType?.length) {
            // 'defaultValue' in input doesn't work here bacause we want to set this filter only once after first render
            setFileType(Array.from(dataPointTypeTitles.keys()).filter((type) => type !== DataPointType.UNRECOGNIZED))
        }
    }, [fileType, setFileType])

    useEffect(() => {
        setTableParams({
            [DEFAULT_TABLE_OFFSET_QUERY_PARAM_KEY]: 0,
        })
    }, [fileType, startPeriodDate, endPeriodDate, assets])

    const datapointQuery = useDataPointQuery({
        pageOffset: tablePaginationParams.tablePageOffset,
        pageLimit: tablePaginationParams.tablePageLimit,
        sortBy: tableSortParam,
        filter: dataPointQueryFilters,
    }, {
        refetchOnWindowFocus: false,
    })

    const datapointCountQuery = useDataPointCountQuery({
        filter: dataPointQueryFilters,
    }, {
        refetchOnWindowFocus: false,
    })

    const assetQuery = useAssetsQuery()

    const tableItems = useJoin(datapointQuery.data || [],
        [{
            lookupArr: assetQuery.data || [],
            baseKey: 'asset_id',
            saveTo: 'asset',
        }],
    )

    const modifiedQuery = useMemo(() => ({ ...datapointQuery, data: tableItems }), [tableItems, datapointQuery])

    return (
        <ReactQueryTable
            query={modifiedQuery as UseQueryResult<DataPoint[]>}
            countQuery={datapointCountQuery}
            defaultSorting={getSortString('created_at', true)}
            {...gridOptions}
        />
    )
}
