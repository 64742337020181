import React, { CSSProperties } from 'react'

import { observer } from 'mobx-react-lite'

import { generatedColors } from '@/styles/tokens/Tokens.constants'

import { LayoutProps } from './Layout.types'

/**
 * @todo Rename to Flex
 */
export const Layout = observer((props: LayoutProps) => {
    const {
        mt,
        mb,
        ml,
        mr,
        mx,
        my,
        pt,
        pb,
        pl,
        pr,
        px,
        py,
        p = 0,
        m = 0,
        gap = 0,
        direction = 'row',
        children,
        className,
        align,
        justify,
        style: styleCustom,
        block = false,
        width,
        minWidth,
        maxWidth,
        height,
        minHeight,
        maxHeight,
        r,
        flexGrow,
        flexShrink,
        flexWrap,
        scroll,
        relative,
        bt = false,
        bb = false,
        bl = false,
        br = false,
        borderColor = 'colorsSecondaryGrey100',
        boxSizing = 'border-box',
        ...htmlProps
    } = props

    const style: CSSProperties = {
        display: block ? 'block' : 'flex',
        marginTop: mt ?? my ?? m,
        marginBottom: mb ?? my ?? m,
        marginLeft: ml ?? mx ?? m,
        marginRight: mr ?? mx ?? m,
        paddingTop: pt ?? py ?? p,
        paddingBottom: pb ?? py ?? p,
        paddingLeft: pl ?? px ?? p,
        paddingRight: pr ?? px ?? p,
        gap,
        flexDirection: direction,
        flexGrow,
        flexShrink,
        flexWrap,
        alignItems: align,
        maxWidth,
        minWidth,
        width,
        height,
        minHeight,
        maxHeight,
        overflowY: scroll === 'y' ? 'auto' : undefined,
        overflowX: scroll === 'x' ? 'auto' : undefined,
        borderRadius: r,
        borderTop: bt ? `1px solid ${generatedColors[borderColor]}` : undefined,
        borderBottom: bb ? `1px solid ${generatedColors[borderColor]}` : undefined,
        borderLeft: bl ? `1px solid ${generatedColors[borderColor]}` : undefined,
        borderRight: br ? `1px solid ${generatedColors[borderColor]}` : undefined,
        justifyContent: justify,
        boxSizing,
        ...styleCustom,
    }

    if (relative) {
        style.position = 'relative'
    }

    return (
        <div {...htmlProps} className={className} style={style}>
            {children}
        </div>
    )
})
