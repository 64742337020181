import { createEnumParam } from 'use-query-params'

import { ReportDatatype } from '@/constants/reportDatatype'

const DatatypeParam = createEnumParam(Object.values(ReportDatatype))

export const REPORT_DATATYPE_INPUT_QUERY_PARAM = {
    type: DatatypeParam,
    name: 'datatype',
}
