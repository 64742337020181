import { Model, model, field, foreign, many } from 'mobx-orm'

import { AssetGroup } from './AssetGroup'
import { api } from '../../http-adapter'
import { CompanyUserGroup } from '../company'
import { AccessLevel } from '../core'

@api('company-user-group-in-asset-group')
@model
export class CompanyUserGroupInAssetGroup extends Model {
    @field company_user_group_id?: number
    @field asset_group_id?:        number
    @field level?:                 AccessLevel

    @foreign(AssetGroup) readonly asset_group:              AssetGroup
    @foreign(CompanyUserGroup) readonly company_user_group: CompanyUserGroup

    get level_name (): string {
        // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
        return AccessLevel[this.level]
    }
}
many(CompanyUserGroupInAssetGroup, 'asset_group_id')(AssetGroup, 'group_permissions')
many(CompanyUserGroupInAssetGroup, 'company_user_group_id')(CompanyUserGroup, 'asset_group_permissions')
