import { AgGridTableProps } from '@/components/tables'

export const gridOptions: Partial<AgGridTableProps> = {
    columnDefs: [
        // Switch cell will be pushed here in JSX
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 200,
            type: 'string',
        },
        {
            field: 'software',
            minWidth: 200,
            headerName: 'Software',
            type: 'string',
        },
    ],
}
