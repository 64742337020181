import styles from '@/components/legacy/tables/AgGridTableLegacy/AgGridTableLegacy.module.scss'
import { AgGridTableProps } from '@/components/legacy/tables/AgGridTableLegacy/AgGridTableLegacy.types'
import { arrayRmDuplicates } from '@/utils/array/ArrayRmDuplicates'

// TODO: Did't for for default col defs
export const modifyColDefItem = (column: AgGridTableProps['columnDefs'][0]) => {
    // Set className for right border
    if (column.rightBorder) {
        column.headerClass = arrayRmDuplicates<string>([styles.rightBorder]
            .concat(column.headerClass as string ?? []))
        // @ts-expect-error: Can't detect Headers group which don't have this field
        column.cellClass = arrayRmDuplicates<string>([styles.rightBorder].concat(column.cellClass ?? []))

        // @ts-expect-error: Can't detect Headers group which don't have this field
        const childrenLength = column.children?.length

        if (childrenLength) {
            // @ts-expect-error: Can't detect Headers group which don't have this field
            const childToModify = column.children[childrenLength - 1]

            if (childToModify) {
                childToModify.headerClass = arrayRmDuplicates<string>([styles.rightBorder]
                    .concat(childToModify.headerClass ?? []))
                childToModify.cellClass = arrayRmDuplicates<string>([styles.rightBorder]
                    .concat(childToModify.cellClass ?? []))
            }
        }

        delete column.rightBorder
    }

    if (column.cellContentAlignCenter) {
        // @ts-expect-error: Can't detect Headers group which don't have this field
        column.cellStyle = { ...(column.cellStyle || {}, { display: 'flex', alignItems: 'center', justifyContent: 'center' }) }
        delete column.cellContentAlignCenter
    }

    if (column.cellContentAlignVertical) {
        // @ts-expect-error: Can't detect Headers group which don't have this field
        column.cellStyle = { ...(column.cellStyle || {}, { display: 'flex', alignItems: 'center' }) }
        delete column.cellContentAlignVertical
    }

    return column
}
