import { Model, model, field, foreign, many } from 'mobx-orm'

import { CompanyUserInLedgerHistory } from './CompanyUserInLedgerHistory'
import { TrialBalanceLedger as Ledger } from './Ledger'
import { api } from '../../http-adapter'
import { CompanyUser } from '../company'
import { AccessLevel } from '../core'

@api('company-user-in-ledger')
@model
export class CompanyUserInLedger extends Model {
    @field ledger_id?:       number
    @field company_user_id?: number
    @field level?:           AccessLevel // TODO: move it to class inheritance

    @foreign(Ledger) readonly ledger:            Ledger
    @foreign(CompanyUser) readonly company_user: CompanyUser

    readonly history: CompanyUserInLedgerHistory[]

    get level_name (): string { // TODO: move it to class inheritance
        // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
        return AccessLevel[this.level]
    }
}
many(CompanyUserInLedger, 'ledger_id')(Ledger, 'personal_permissions')
many(CompanyUserInLedger, 'company_user_id')(CompanyUser, 'ledger_personal_permissions')
