// enum from back/apps/reporting/models.py
export enum ReportDatatype {
    AssetDetails = 'A',
    TrialBalance = 'T',
    RentRoll = 'R',
}

export const REPORT_DATATYPE_LABELS: Record<ReportDatatype, string> = {
    [ReportDatatype.AssetDetails]: 'Asset Details',
    [ReportDatatype.TrialBalance]: 'Trial Balance',
    [ReportDatatype.RentRoll]: 'Rent Roll',
}
