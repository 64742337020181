import { LabelColors } from '@/components/base'

export enum TypeOfChange {
    Created = <any>'+', // don't delete <any>!
    Updated = <any>'~',
    Deleted = <any>'-',
}

export const mappingHistoryChangeNames: Record<TypeOfChange, string> = {
    [TypeOfChange.Created]: 'Created',
    [TypeOfChange.Updated]: 'Updated',
    [TypeOfChange.Deleted]: 'Deleted',
}

export const mappingHistoryChangeLabelColors: Record<TypeOfChange, LabelColors> = {
    [TypeOfChange.Created]: 'green',
    [TypeOfChange.Updated]: 'orange',
    [TypeOfChange.Deleted]: 'red',
}
