import { Me, me } from '@/components/containers'
import { CompanyUserMode } from '@/core/modes'
import { getRouteConfig } from '@/utils/routing/getRouteConfig'

import { HeaderMenuItemType } from './HeaderMenuItem'

const getItemsBymode = (switchMode: (mode: CompanyUserMode) => void) => ({
    [CompanyUserMode.Admin]: {
        icon: 'userSettings',
        text: 'Switch to Admin Mode',
        onClick: switchMode.bind(me, CompanyUserMode.Admin),
        divider: true,
    },
    [CompanyUserMode.Owner]: {
        icon: 'userSettings',
        text: 'Switch to Owner Mode',
        onClick: switchMode.bind(me, CompanyUserMode.Owner),
        divider: true,
    },
    [CompanyUserMode.Manager]: {
        icon: 'userSettings',
        text: 'Switch to Manager Mode',
        onClick: switchMode.bind(me, CompanyUserMode.Manager),
        divider: true,
    },
})

/**
 * Please check flag 'me.hasAccessToPortal' for pushing any mode specific menu items
 */
export const getHeaderMenuItemsConfig = ({ logout, switchMode, me }: { me: Me, logout: () => void, switchMode: (mode: CompanyUserMode) => void }): HeaderMenuItemType[] => {
    const items: HeaderMenuItemType[] = []

    if (!me.isAdministratorMode && me.hasAccessToPortal) {
        items.push(
            {
                icon: 'profile',
                text: getRouteConfig('USER_PROFILE', CompanyUserMode.Owner).title,
                path: getRouteConfig('USER_PROFILE', CompanyUserMode.Owner).path,
            }, {
                icon: 'users',
                text: getRouteConfig('COMPANY_PROFILE', CompanyUserMode.Owner).title,
                path: getRouteConfig('COMPANY_PROFILE', CompanyUserMode.Owner).path,
                divider: true,
            })
    }

    const MODES_ITEM = getItemsBymode(switchMode)

    me.availableModes.forEach(mode => {
        if (mode !== me.companyUserMode) {
            // @ts-expect-error TS(2345) FIXME: Argument of type '{ icon: string; text: string; on... Remove this comment to see the full error message
            items.push(MODES_ITEM[mode])
        }
    })

    items.push({
        icon: 'logout',
        text: 'Logout',
        onClick: logout,
    })
    return items
}
