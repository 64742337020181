import { useMemo } from 'react'

import { useAssetsQuery } from '@/api/asset/asset'

/**
 * Returns manager options for select input based on Asset query
 */
export const useManagerOptions = () => {
    const assetsQuery = useAssetsQuery()

    const managersOptions = useMemo(() =>
        assetsQuery.data
            ?.map((asset) => ({
                value: asset.property_manager_company || '',
                label: asset.property_manager_company || '',
            }))
            // Unique values only
            .reduce<Array<{ value: string, label: string }>>((acc, cur) => {
            if (acc.find((item) => item.value === cur.value)) {
                return acc
            }
            return [...acc, cur]
        }, [])
            .sort((a, b) => (a.value).localeCompare(b.value))
            .filter((item) => item.label.length > 0)
    , [assetsQuery.data])

    return managersOptions
}
