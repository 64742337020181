import { Model, model, field, foreign, many } from 'mobx-orm'

import { Asset } from './Asset'
import { api } from '../../http-adapter'
import { CompanyUserGroup } from '../company'
import { AccessLevel } from '../core'

@api('company-user-group-in-asset')
@model
export class CompanyUserGroupInAsset extends Model {
    @field company_user_group_id?: number
    @field asset_id?:              number
    @field level?:                 AccessLevel

    @foreign(Asset) readonly asset:                         Asset
    @foreign(CompanyUserGroup) readonly company_user_group: CompanyUserGroup

    get level_name (): string {
        // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
        return AccessLevel[this.level]
    }
}
many(CompanyUserGroupInAsset, 'asset_id')(Asset, 'group_permissions')
many(CompanyUserGroupInAsset, 'company_user_group_id')(CompanyUserGroup, 'asset_permissions')
