import { Model, field } from 'mobx-orm'

import { TypeOfChange } from './TypeOfChange'

// TODO: history is readonly model, patch the Adapter to reject create/save/delete
export abstract class HistoryModel extends Model {
    @field readonly history_date:          string // TODO: should be a datetime
    @field readonly history_change_reason: string | null
    @field readonly history_type:          TypeOfChange
    @field readonly history_user_id:       number

    // TODO: foreing to history_user (or company_user?)

    get history_type_name (): string {
        return TypeOfChange[this.history_type]
    }
}
