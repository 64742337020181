import { useEffect } from 'react'

import { useLocation } from 'react-router-dom'

import { HeaderNavigationItem } from '@/components/base'
import { RoutesConfigValue } from '@/core/routes'
import { headerNavigationItems } from '@/store/headerNavigationItems'
import { isFeatureOn } from '@/utils/isFeatureOn'

/**
 * Hook which adds navigation items for page
 */
// @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'RoutesConfi... Remove this comment to see the full error message
export const useHeaderNavigation = (navigationItems: HeaderNavigationItem[], baseRoute: RoutesConfigValue = null) => {
    const location = useLocation()

    useEffect(() => {
        const isHidden = headerNavigationItems.isHidden

        const filteredByFeatureFlags = navigationItems.filter(item => {
            if (item.featureFlag) {
                if (isFeatureOn(item.featureFlag)) {
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }
        })

        headerNavigationItems.setConfig(isHidden ? [] : filteredByFeatureFlags, baseRoute)

        // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        return () => { headerNavigationItems.setConfig([], null) }
    }, [navigationItems, baseRoute, headerNavigationItems.isHidden, location.pathname])
}
