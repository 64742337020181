import { CSSProperties, FC, SVGProps } from 'react'

import * as generatedTextColors from '@/styles/tokens/generatedColors'
import { GeneratedColor } from '@/styles/tokens/Tokens.types'

export type SvgIcon = FC<SVGProps<SVGSVGElement> & { title?: string }>

export type IconComponentByNameList = {
    [key in IconName]: SvgIcon
}

/**
 * Supported icon names list
 */
export const iconNames = [
    'AiSparkles',
    'delete',
    'stop',
    'mail',
    'sidemodal',
    'warning',
    'waring',
    'check',
    'edit',
    'eye',
    'eyeWithCheck',
    'user',
    'cross',
    'refresh',
    'replace',
    'save',
    'users',
    'logout',
    'profile',
    'deactivatedUser',
    'userSettings',
    'arrowDown',
    'arrowUp',
    'arrowRight',
    'arrowLeft',
    'portfolio',
    'builder',
    'adminCenter',
    'info',
    'search',
    'gear',
    'folder',
    'insights',
    'download',
    'dialogArrowLeft',
    'moreHorizontal',
    'moreVertical',
    'asset',
    'insightsGoDown',
    'insightsGoUp',
    'treeNewSubcategory',
    'treeMove',
    'treeRename',
    'chartOfAccounts',
    'loader',
    'plus',
    'plusCircle',
    'expand',
    'collapse',
    'drag',
    'calendar',
    'pencil',
    'copy',
    'note',
    'rows',
    'minus',
    'oneCol',
    'twoCols',
    'threeCols',
    'dialog',
    'grid',
    'document',
    'csv',
    'stickyNote',
] as const

export type IconName = typeof iconNames[number]

export const iconColors: Record<GeneratedColor, string> = { ...generatedTextColors }

export const iconSizes = [
    's',
    'm',
    'l',
] as const

export type IconSize = typeof iconSizes[number]

interface IconBaseProps {
    name: IconName
    style?: CSSProperties
    className?: string
    color?: GeneratedColor
    onClick?: (event) => void
    dataTestId?: string
    ariaHidden?: boolean
}

// We need to define icon size in one of two ways
type IconPropsPredefinedSizes = IconBaseProps & { size?: IconSize, width?: never, height?: never }
type IconPropsCustomSizes = IconBaseProps & { width: number, height: number, size?: never }

export type IconProps = IconPropsPredefinedSizes | IconPropsCustomSizes
