import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'

import { Button, Loader, Text } from '@/components/base'
import { SideModalContainer } from '@/components/containers'

import styles from './SideModal.module.scss'
import { SideModalProps } from './SideModal.types'

export const SideModal = observer((props: SideModalProps) => {
    const {
        className,
        bodyNoPadding,
        children,
        closePath,
        onClose,
        title,
        titleComponent,
        width,
        haveCloseButton = false,
        submitButton,
        cancelButton,
        isLoading = false,
    } = props

    const navigate = useNavigate()

    const handleClose = () => {
        closePath && navigate(closePath)
        onClose?.()
    }

    const handleBgClick = () => {
        handleClose()
    }

    return (
        <SideModalContainer onBgClick={handleBgClick}>
            <div className={cn(styles.sideModal, className)} style={{ width }}>
                <div className={styles.header}>
                    {Boolean(title?.length) && (
                        <div className={styles.title}>
                            <Text
                                color='secondaryBlack'
                                variant='subheader'
                            >
                                {title}
                            </Text>
                            <Button
                                onClick={handleClose}
                                icon='cross'
                                theme='flat'
                                size='l'
                            />
                        </div>
                    )}
                    {titleComponent}
                </div>
                <div className={styles.body}>
                    <div className={cn(styles.bodyContent, bodyNoPadding && styles.bodyContentNoPadding)}>
                        {isLoading ? <Loader/> : children}
                    </div>
                </div>
                {(haveCloseButton || submitButton) && (
                    <div className={styles.buttons}>
                        {
                            submitButton && (
                                <Button
                                    text={submitButton.text ?? 'save'}
                                    onClick={submitButton.onClick}
                                    loading={submitButton.loading}
                                    disabled={submitButton.disabled}
                                />
                            )
                        }
                        {haveCloseButton && (
                            <Button
                                text={cancelButton?.text ?? 'Cancel'}
                                onClick={cancelButton?.onClick ?? handleClose}
                                disabled={cancelButton?.disabled}
                                theme='secondary'
                            />
                        )}
                    </div>
                )}
            </div>
        </SideModalContainer>
    )
})
