import { useState } from 'react'

import { ListItemIcon, ListItemText, MenuItem, Menu } from '@mui/material'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { Icon, Text } from '@/components/base'

import styles from './ActionsCell.module.scss'
import { ActionsCellProps } from './ActionsCell.types'

export const ActionsCell = observer((props: ActionsCellProps) => {
    const { onClose, items, className } = props

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const isOpen = Boolean(anchorEl)

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        if (!haveItems) {
            return
        }
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        setAnchorEl((e.currentTarget.closest('.ag-cell') ?? e.currentTarget) as HTMLElement)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const haveItems = Boolean(items.length)

    return (
        <div className={cn(styles.actionsCell, className)} data-testid='actionsCell'>
            <div
                className={cn(styles.moreIcon)}
            >
                <Icon
                    onClick={handleClick}
                    name='moreHorizontal'
                    color={haveItems ? 'colorsSecondaryGrey600' : 'colorsSecondaryGrey50'}
                    size='l'
                />
            </div>
            <Menu
                className='menu-paper'
                id='basic-menu'
                anchorEl={anchorEl}
                open={isOpen}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: -4, horizontal: 'right' }}
            >
                {items.map(({ onClick, text, icon, dataTestId, dividerAfter }, i) =>
                    (
                        <MenuItem
                            data-testid={dataTestId}
                            key={i}
                            onClick={() => {
                                onClose?.()
                                // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
                                onClick?.()
                                handleClose()
                            }}
                            divider={dividerAfter}
                        >
                            <ListItemIcon>
                                <Icon
                                    name={icon}
                                    size='m'
                                    color='colorsSecondaryGrey600'
                                />
                            </ListItemIcon>
                            <ListItemText>
                                <Text
                                    text={text}
                                    variant='smallTextMediumDefault'
                                    color='secondaryBlack'
                                />
                            </ListItemText>
                        </MenuItem>
                    ),
                )}
            </Menu>
        </div>
    )
})
