import { me } from '@/components/containers'
import { CompanyUserMode } from '@/core/modes'
import {
    ADMIN_ROUTES_CONFIG,
    MANAGER_ROUTES_CONFIG,
    RouterConfigKeys,
    RoutesConfigValue,
    OWNER_ROUTES_CONFIG, COMMON_ROUTES_CONFIG,
} from '@/core/routes'

/**
 * Returns config depending on user mode
 * Use second param for the case if you want to use function not inside component
 */
export const getRouteConfig = (routeName: RouterConfigKeys, userMode?: CompanyUserMode): RoutesConfigValue => {
    if (me.isAdministratorMode || userMode === CompanyUserMode.Admin) {
        return ADMIN_ROUTES_CONFIG?.[routeName] || COMMON_ROUTES_CONFIG?.[routeName]
    } else if (me.isManagerMode || userMode === CompanyUserMode.Manager) {
        return MANAGER_ROUTES_CONFIG?.[routeName] || COMMON_ROUTES_CONFIG?.[routeName]
    } else if (me.isOwnerMode || userMode === CompanyUserMode.Owner) {
        return OWNER_ROUTES_CONFIG?.[routeName] || COMMON_ROUTES_CONFIG?.[routeName]
    }

    throw new Error('User mode not found')
}
