import React from 'react'

import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'

import { Label } from '@/components/base'
import { DataPointStatus } from '@/models/data_pulling'
import {
    SUBMISSION_STATUS_LABELS_CONFIG,
} from '@/pages/UploadsPage/SubmissionLogsPage/SubmissionLogTable/SubmissionLogTable.constants'

import { DataPointAlertsCellProps } from './DataPointAlertsCell.types'

export const DataPointAlertsCell = observer((props: DataPointAlertsCellProps) => {
    const { dataPoint, getSidemodalPath } = props

    const navigate = useNavigate()

    if (!dataPoint) {
        return null
    }

    const submissionErrorNum = dataPoint.submission_errors_num || 0
    const qualityErrorNum = dataPoint.quality_errors_num

    // Error
    if (dataPoint.status === DataPointStatus.ERROR || dataPoint.status === DataPointStatus.INTERNAL_ERROR || submissionErrorNum > 0) {
        return <Label {...SUBMISSION_STATUS_LABELS_CONFIG.empty()}/>
    }

    // Processing
    if (dataPoint.status === DataPointStatus.PROCESSING) {
        return <Label {...SUBMISSION_STATUS_LABELS_CONFIG.processing()}/>
    }

    // Have alerts
    if (qualityErrorNum) {
        return (
            <Label
                {...SUBMISSION_STATUS_LABELS_CONFIG.alert(qualityErrorNum)}
                onClick={() => {
                    const path = getSidemodalPath(dataPoint.id ?? -1) + '?' + new URLSearchParams(window.location.search)
                    navigate(path)
                }}
            />
        )
    }

    // Passed
    if (dataPoint.status === DataPointStatus.DONE) {
        return (<Label {...SUBMISSION_STATUS_LABELS_CONFIG.passed()}/>)
    }

    // Unexpected case
    return (
        <Label {...SUBMISSION_STATUS_LABELS_CONFIG.empty()}/>
    )
})
