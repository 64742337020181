import { Model, model, field, foreign } from 'mobx-orm'

import { Account } from './Account'
import { AccountCategory } from './AccountCategory'
import { CompanyUserGroupInLedger } from './CompanyUserGroupInLedger'
import { CompanyUserInLedger } from './CompanyUserInLedger'
import { api } from '../../http-adapter'
import { Company } from '../company'
import { AssetType } from '../core/AssetType'

@api('ledger')
@model
export class TrialBalanceLedger extends Model {
    @field  name?:       string
    @field  company_id?: number
    @field  type?:       AssetType
    // TODO: add default=True to this field
    @field  is_custom?:  boolean

    @foreign(Company) readonly company: Company

    readonly accounts?:           Account[]
    readonly account_categories?: AccountCategory[]

    readonly personal_permissions?: CompanyUserInLedger[]
    readonly    group_permissions?: CompanyUserGroupInLedger[]

    static get availableTypes (): Set<AssetType> {
        const uniqueStatuses = new Set<AssetType>()
        for (const ledger of TrialBalanceLedger.__cache.values()) {
            // @ts-expect-error TS(2345) FIXME: Argument of type 'AssetType | undefined' is not as... Remove this comment to see the full error message
            uniqueStatuses.add((ledger as TrialBalanceLedger).type)
        }
        return uniqueStatuses
    }
}
