import cn from 'classnames'

import { Icon } from '@/components/base'

import styles from './Loader.module.scss'

interface LoaderProps {
    className?: string
    /**
     * Add styles for loader centering
     */
    centered?: boolean
}

/**
 * @todo Create 'LoaderLayout' which can wrap content
 */
export const Loader = (props: LoaderProps) => {
    const { className, centered = false } = props

    const loaderClassName = cn(className, styles.loader, {
        [styles.centered]: centered,
    })

    return (
        <Icon
            className={loaderClassName}
            name='loader'
            color='colorsPrimaryPurple'
            width={60}
            height={60}
        />
    )
}
