import format from 'date-fns/format'
import { observer } from 'mobx-react-lite'

import { Button } from '@/components/base'

import styles from './PeriodSelect.module.scss'
import { PeriodSelectProps } from './PeriodSelect.types'
import { beginningOfTime } from '../ValidationTrackerPage'

export const PeriodSelect = observer((props: PeriodSelectProps) => {
    const day = new Date(props.year, props.month - 1, 1)

    let allowNext, allowPrev
    if (props.periodType === 'daily') {
        allowNext = !(props.year === beginningOfTime.year && props.month === beginningOfTime.month + 1)
        allowPrev = !(props.year === new Date().getFullYear() && props.month === new Date().getMonth() + 1)
    } else {
        allowNext = props.year !== beginningOfTime.year
        allowPrev = props.year !== new Date().getFullYear()
    }

    return (
        <div className={styles.monthSelect}>
            {
                allowNext && (
                    <Button
                        icon='arrowLeft'
                        onClick={props.onPrevPeriod}
                        theme='flat'
                    />
                )}
            <div className={styles.monthName}>
                {props.periodType === 'monthly' && format(day, 'yyyy')}
                {props.periodType === 'daily' && format(day, 'MMMM yyyy')}
            </div>
            {
                allowPrev && (
                    <Button
                        icon='arrowRight'
                        onClick={props.onNextPeriod}
                        theme='flat'
                    />
                )}
        </div>
    )
})
