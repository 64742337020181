import React, { useRef, useState } from 'react'

import { FilesTreeFileAction, Icon, Popover } from '@/components/base'
import { SelectOptionListBase } from '@/components/base/Select/optionsList'

import { FilesTreeActionsProps } from './FilesTreeActions.types'

export const FilesTreeActions = (props: FilesTreeActionsProps) => {
    const { item, fileActions, className } = props

    const [optionsAnchorEl, setOptionsAnchorEl] = useState<HTMLDivElement | null>(null)
    const optionItemRef = useRef< HTMLDivElement | null >(null)

    return (
        <div
            onClick={(e) => {
                e.stopPropagation()
            }}
        >
            <div ref={optionItemRef} className={className}>
                <Icon
                    name='moreVertical'
                    size='s'
                    onClick={(e) => {
                        e.stopPropagation()
                        setOptionsAnchorEl(optionItemRef.current)
                    }}
                    color='colorsSecondaryGrey600'
                />
            </div>
            <Popover
                maxWidth={160}
                anchorEl={optionsAnchorEl}
                placement='right-start'
                onClose={() => { setOptionsAnchorEl(null) }}
            >
                <SelectOptionListBase<FilesTreeFileAction>
                    maxWidth='160px'
                    options={fileActions}
                    idFn={(el) => el.label}
                    labelFn={el => el.label}
                    onChange={(el) => {
                        el.onClick(item)
                        setOptionsAnchorEl(null)
                    }}
                />
            </Popover>
        </div>
    )
}
