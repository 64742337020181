import { useEffect } from 'react'

import { Outlet } from 'react-router'
import { useMatch, useNavigate } from 'react-router-dom'

import { CompanyUserMode } from '@/core/modes'
import { getRouteConfig } from '@/utils/routing/getRouteConfig'

const DEFAULT_PATH = getRouteConfig('VALIDATION_TRACKER', CompanyUserMode.Owner).path
const PAGE_ROOT_PATH = getRouteConfig('DATA_SUBMISSION', CompanyUserMode.Owner).path

export const DataSubmissionPage = () => {
    const match = useMatch(PAGE_ROOT_PATH)
    const navigate = useNavigate()

    useEffect(() => {
        if (match) {
            navigate(DEFAULT_PATH, { replace: true })
        }
    }, [match, navigate])

    return (<Outlet/>)
}
