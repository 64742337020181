import { useState } from 'react'

import { useRentRollLedgerByIdQuery } from '@/api/rentRoll/rentRollLedger'
import { useRentRollUnmappedQuery } from '@/api/rentRoll/rentRollUnmapped'
import { Button } from '@/components/base'
import { AccordionContainer } from '@/components/containers/AccordionContainer'

import styles from './RentRollUnmappedAlert.module.scss'
import { RentRollUnmappedAlertProps } from './RentRollUnmappedAlert.types'

export const RentRollUnmappedAlert = (props: RentRollUnmappedAlertProps) => {
    const { assetId, ledgerId, onUpdateClick } = props

    const [isOpened, setIsOpened] = useState(false)
    const rentRollUnmappedQuery = useRentRollUnmappedQuery({
        queryParams: {
            asset_id: assetId,
            ledgers_ids: ledgerId,
            request_id: 0,
        },
    }, {
        enabled: Boolean(assetId && ledgerId),
    })
    const unmappedAccounts = rentRollUnmappedQuery.data?.[0]?.accounts ?? []

    const ledgerQuery = useRentRollLedgerByIdQuery(ledgerId ?? '', { enabled: Boolean(ledgerId) })

    const hasWarnings = ledgerId && assetId && ledgerQuery.isFetched &&
        rentRollUnmappedQuery.isFetched && unmappedAccounts.length > 0

    return (
        // @ts-expect-error TS(2322) FIXME: Type 'boolean | 0' is not assignable to type 'bool... Remove this comment to see the full error message
        <AccordionContainer open={hasWarnings}>
            {hasWarnings && (
                <div className={styles.alert}>
                    <div>
                        <div className={styles.message}>
                            {
                                ledgerQuery.data?.is_custom && (
                                    <>
                                        <div>
                                            Note: Unmapped charge codes and values are missing from the data report.
                                            The following {unmappedAccounts.length} charge codes require mapping in the ledger {ledgerQuery.data.name}.
                                        </div>
                                        <Button
                                            onClick={onUpdateClick}
                                            text='Click here to update mappings'
                                            theme='flat'
                                        />
                                    </>
                                )
                            }
                            {
                                ledgerQuery.data && !ledgerQuery.data.is_custom && (
                                    <div>
                                        Note: Unmapped charge codes and values are missing from the data report.
                                        The following {unmappedAccounts.length} charge codes are being mapped to the Intelas Ledger and will be finalized soon.
                                    </div>
                                )
                            }
                        </div>
                        <div className={styles.accounts}>
                            {unmappedAccounts.slice(0, 3).map((account, ind) => {
                                return <div key={ind}>({account.code}) {account.name}</div>
                            })}
                            <AccordionContainer open={isOpened}>
                                {unmappedAccounts.slice(3, 10000).map((account, ind) => {
                                    return <div key={ind}>({account.code}) {account.name}</div>
                                })}
                            </AccordionContainer>
                        </div>
                        {
                            !isOpened && unmappedAccounts.length > 3 && (
                                <Button text={`And ${unmappedAccounts.length - 3} more`} onClick={() => { setIsOpened(true) }} theme='flat'/>
                            )
                        }
                        {
                            isOpened && unmappedAccounts.length > 3 && (
                                <Button text='Less accounts' onClick={() => { setIsOpened(false) }} theme='flat'/>
                            )
                        }
                    </div>
                </div>
            )}
        </AccordionContainer>
    )
}
