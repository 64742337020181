import throttle from 'lodash/throttle'
// eslint-disable-next-line no-restricted-imports
import { Id, toast, ToastContent, ToastOptions } from 'react-toastify'

const toastErrorsCounterByMessage = {}
const throttleByMessage = {}

/**
 * Local toast wrapper for implement throttle for same messages
 */
export const notify = {
    // TODO: Send to sentry
    error: (content: ToastContent<unknown>, options?: ToastOptions | undefined): Id | null => {
        // Can be jsx element
        if (typeof content === 'string') {
            toastErrorsCounterByMessage[content] = (toastErrorsCounterByMessage[content] || 0) + 1

            if (toastErrorsCounterByMessage[content] === 1) {
                // Create throttle fn on first error
                throttleByMessage[content] = throttle(() => {
                    // eslint-disable-next-line no-restricted-properties
                    return toast.error(content, options)
                }, 3000, {
                    trailing: false,
                })
            }

            // Call throttle every error with same message
            return throttleByMessage[content]()
        }
        return null
    },
    success: toast.success,
    warn: toast.warn,
    promise: toast.promise,
}
