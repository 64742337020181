import { useEffect } from 'react'

import { DatePicker } from '@/components/base'
import { useInputState } from '@/hooks'

import { DATE_INPUT_QUERY_PARAM, LOCAL_STORAGE_OPTIONS } from './DateInput.constants'
import { DateInputProps } from './DateInput.types'

const {
    name: dateQueryParamName,
    type: dateQueryParamType,
} = DATE_INPUT_QUERY_PARAM

export const DateInput = (props: DateInputProps) => {
    const {
        label,
        maxDate,
        defaultDate,
        isClearable,
        placeholderText,
        paramName = dateQueryParamName,
        maxWidth,
        filterDate,
        syncLocalStorage,
        paramType = dateQueryParamType,
        onChange,
        ...rest
    } = props

    const [dateParam, setDateParam] = useInputState<Date | null | undefined>(
        {
            name: paramName,
            type: paramType,
            localStorageOptions: LOCAL_STORAGE_OPTIONS,
            syncLocalStorage,
        })

    useEffect(() => {
        if (!dateParam && defaultDate) {
            setDateParam(defaultDate)
        }
    }, [])

    return (
        <DatePicker
            label={label}
            maxDate={maxDate}
            date={dateParam ?? undefined}
            onChange={(date) => {
                onChange?.(date)
                setDateParam(date)
            }}
            filterDate={filterDate}
            isClearable={isClearable}
            placeholderText={placeholderText}
            maxWidth={maxWidth}
            {...rest}
        />
    )
}
