import { Model, model, field, foreign } from 'mobx-orm'

import { User } from '@/models/core/User'

import { Asset } from './Asset'
import { api } from '../../http-adapter'

@api('asset-note')
@model
export class AssetNote extends Model {
    @field asset_id?:  number
    @field user_id?:   number
    @field timestamp?: string
    @field text?:      string

    @foreign(Asset) readonly asset: Asset
    @foreign(User) readonly user:   User
}
