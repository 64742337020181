import { ValueType } from 'mobx-orm'

import { serialize } from './serialize'

/**
 * Get value from QueryParams of URL by name
 * @deprecated
 */
export function getValueFromURL (name: string, type: ValueType = ValueType.STRING) {
    let value
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has(name)) {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        value = serialize(searchParams.get(name), type)
    }
    return value
}
