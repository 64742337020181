import { getRequestQueries } from '@/api/utils/getRequestQueries'
import { Account } from '@/models/ledger'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchAccountQueryParams {}

export interface TBAccountItem extends Account {
    adjusted_accounts_ids?: number[]
}

export const TB_ACCOUNT_URL = 'account'

export const {
    useItemsQuery: useAccountQuery,
    useItemByIdQuery: useAccountByIdQuery,
    useItemUpdateMutation: useAccountUpdateMutation,
    useItemsCountQuery: useAccountCountQuery,
} = getRequestQueries<TBAccountItem, FetchAccountQueryParams>({
    url: TB_ACCOUNT_URL,
})
