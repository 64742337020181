import { useRef, useState } from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { Button, Icon, UploadToastMessage, Text } from '@/components/base'
import { notify } from '@/utils/notify'

import styles from './DrugAndDropArea.module.scss'
import { DrugAndDropAreaProps } from './DrugAndDropArea.types'

export const DrugAndDropArea = observer((props: DrugAndDropAreaProps) => {
    const {
        disabled = false,
        onFilesSelected,
        loading = false,
        allowedFormats,
        label,
    } = props
    const [isFileInArea, setIsFileInArea] = useState(false)

    const fileRef = useRef<HTMLInputElement>()

    const prepareFiles = (files: FileList) => {
        const filesArray = Array.from(files)

        const allowedFiled: File[] = filesArray.filter((file: File) => {
            const fileFormat = file.name.split('.').pop()
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            const isAllowed = allowedFormats.includes(fileFormat.toLowerCase())

            if (isAllowed) {
                return true
            } else {
                notify.error(<UploadToastMessage fileName={file.name} message={'.' + fileFormat + ' format is not allowed'}/>, {
                    autoClose: false,
                })
                return false
            }
        })

        onFilesSelected(allowedFiled)
    }

    const handleDrop = (e) => {
        e.preventDefault()
        setIsFileInArea(false)
        if (loading || disabled || !fileRef.current) {
            return
        }
        prepareFiles(e.dataTransfer.files)
    }

    const handleDragOver = (e) => {
        e.preventDefault()
    }

    const handleAreaClick = () => {
        if (loading || disabled || !fileRef.current) {
            return
        }

        fileRef.current?.click()
    }

    const handleFilesSelected = ({ target }) => {
        prepareFiles(target.files)
        target.value = ''
    }

    const drugAndDropAreaClassName = cn(styles.drugAndDropArea, {
        [styles.disabled]: disabled,
        [styles.fileOver]: isFileInArea,
        [styles.loading]: loading,
    })

    const haveLabel = Boolean(label)

    return (
        <>
            <div
                className={drugAndDropAreaClassName}
                onClick={handleAreaClick}
                onDragOver={handleDragOver}
                onDragEnter={() => {
                    setIsFileInArea(true)
                }}
                onDragLeave={() => {
                    setIsFileInArea(false)
                }}
                onDrop={handleDrop}
            >
                {loading ? (
                    <Icon
                        name='loader'
                        height={40}
                        width={40}
                        color='colorsPrimaryPurple'
                    />
                ) : (
                    <>
                        <Text variant='smallTextSemiboldDefault' color={disabled ? 'strokeGray' : 'secondaryGray'}>
                            Please Drag & Drop or choose a file(s) to upload.
                            <br/>
                            All {allowedFormats.map(format => '.' + format).join(', ')} file types are supported
                        </Text>
                        <Button
                            text='Choose Files'
                            theme='secondary'
                            disabled={disabled}
                            size='s'
                            icon='builder'
                        />
                    </>
                )}
            </div>
            <input
                className={styles.input}
                type='file'
                // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<HTMLInputElement | undefine... Remove this comment to see the full error message
                ref={fileRef}
                multiple
                onChange={handleFilesSelected}
            />
            {haveLabel && (
                <Text
                    text={label}
                    color='secondaryGray'
                    variant='smallTextMediumDefault'
                />
            )}
        </>
    )
})
