import { useState, useRef, useEffect } from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { Button, Loader, Text } from '@/components/base'

import { DEFAULT_HEIGHT, MIN_HEIGHT, MAX_HEIGHT_PERCENTAGE, localStorageKey } from './SlideoutPanel.constants'
import styles from './SlideoutPanel.module.scss'
import { SlideoutPanelProps } from './SlideoutPanel.types'

export const SlideoutPanel = observer((props: SlideoutPanelProps) => {
    const {
        className, title, titleComponent, children, onClose,
        isLoading = false,
        minHeight = MIN_HEIGHT,
        maxHeightPercentage = MAX_HEIGHT_PERCENTAGE,
        disableResize = false,
    } = props

    const [isDragging, setIsDragging] = useState(false)
    const [panelHeight, setPanelHeight] = useState<number>(0)
    const panelRef = useRef<HTMLDivElement>(null)
    const heightRef = useRef(0)
    const maxHeightRef = useRef(0)
    const cursorYRef = useRef(0)

    useEffect(() => {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        const savedHeight = parseInt(localStorage.getItem(localStorageKey), 10)
        setPanelHeight(Number.isNaN(savedHeight) ? DEFAULT_HEIGHT : savedHeight)
    }, [])

    const handleMouseDown = (e: React.MouseEvent) => {
        if (disableResize) {
            return
        }
        e.preventDefault() // Prevents text selection and default browser drag behavior
        setIsDragging(true)
        cursorYRef.current = e.clientY
        heightRef.current = panelHeight

        const parentHeight = panelRef.current?.parentElement?.clientHeight ?? minHeight
        maxHeightRef.current = parentHeight * maxHeightPercentage / 100
    }

    const handleMouseMove = (e: MouseEvent) => {
        if (!isDragging || disableResize) {
            return
        }

        const deltaY = e.clientY - cursorYRef.current
        const newHeight = heightRef.current - deltaY

        if (newHeight < minHeight) {
            setPanelHeight(minHeight)
        } else if (newHeight > maxHeightRef.current) {
            setPanelHeight(maxHeightRef.current)
        } else {
            setPanelHeight(newHeight)
        }
    }

    const handleMouseUp = () => {
        if (!isDragging) {
            return
        }

        localStorage.setItem(localStorageKey, panelHeight.toString())
        setIsDragging(false)
    }

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove)
        document.addEventListener('mouseup', handleMouseUp)

        return () => {
            document.removeEventListener('mousemove', handleMouseMove)
            document.removeEventListener('mouseup', handleMouseUp)
        }
    })

    return (panelHeight === 0 ? null
        : (
            <div
                className={cn(styles.slideoutPanel, className)}
                style={{ height: `${panelHeight}px` }}
                ref={panelRef}
            >
                <div
                    className={cn(styles.handle, { [styles.isDragging]: isDragging, [styles.disableResize]: disableResize })}
                    onMouseDown={handleMouseDown}
                />
                <div className={styles.header}>
                    {Boolean(title?.length) && (
                        <div className={styles.title}>
                            <Text color='secondaryBlack' variant='smallTextSemiboldDefault'>
                                {title}
                            </Text>
                            <Button
                                className={styles.closeButton}
                                onClick={onClose}
                                icon='cross'
                                theme='flat'
                                text='Close'
                            />
                        </div>
                    )}
                    {titleComponent}
                </div>
                <div className={styles.content}>{isLoading ? <Loader centered/> : children}</div>
            </div>
        )
    )
})
