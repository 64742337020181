import { makeAutoObservable } from 'mobx'

import { HeaderNavigationItem } from '@/components/base'
import { RoutesConfigValue } from '@/core/routes'

export class HeaderNavigationItems {
    items: HeaderNavigationItem[] = []
    // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'RoutesConfi... Remove this comment to see the full error message
    baseRoute?: RoutesConfigValue = null
    isHidden = false

    constructor () {
        makeAutoObservable(this)
    }

    // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'RoutesConfi... Remove this comment to see the full error message
    setConfig (items: HeaderNavigationItem[], baseRoute: RoutesConfigValue = null) {
        this.items = items
        this.baseRoute = baseRoute
    }

    hide () {
        this.isHidden = true
    }

    show () {
        this.isHidden = false
    }
}

export const headerNavigationItems = new HeaderNavigationItems()
