import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { Text } from '@/components/base'

import styles from './ProfileMiniTable.module.scss'
import { ProfileMiniTableProps } from './ProfileMiniTable.types'

export const ProfileMiniTable = observer((props: ProfileMiniTableProps) => {
    const { className, rows } = props

    return (
        <div className={className}>
            <table>
                <tbody>
                    {
                        rows.map((row, i) => (
                            <tr key={i}>
                                <td
                                    className={cn(styles.titleCell, styles.cell)}
                                >
                                    <Text color='secondaryLightBlack' variant='smallTextMediumDefault'>{row.title}</Text>
                                </td>
                                <td className={styles.cell}>
                                    {typeof row.value === 'string' ? (
                                        <Text variant='smallTextSemiboldDefault' color='secondaryBlack'>{row.value}</Text>
                                    ) : (
                                        row.value
                                    )}
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
})
