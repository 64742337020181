import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { Icon, Text } from '@/components/base'
import { formatDate } from '@/utils/date/formatDate'

import styles from './UserLabel.module.scss'
import { UserLabelProps } from './UserLabel.types'

export const UserLabel = observer((props: UserLabelProps) => {
    const { className, name, time } = props

    return (
        <div className={cn(styles.userLabel, className)}>
            <div className={styles.avatar}>
                <Icon
                    name='user'
                    color='colorsPrimaryPurple'
                    size='m'
                />
            </div>
            <Text variant='smallTextSemiboldDefault'>{name}</Text>
            {time && (
                <Text
                    className={styles.time} color='secondaryGray'
                    variant='smallTextMediumDefault'
                    text={formatDate(time)}
                />
            )}
        </div>
    )
})
