import { getRequestQueries } from '@/api/utils/getRequestQueries'
import { User } from '@/models/core'

export type MeItem = User

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchMeQueryParams {}

export const {
    useItemsQuery: useMeQuery,
    useItemUpdateMutation: useMeUpdateMutation,
    useItemsCountQuery: useMeCountQuery,
} = getRequestQueries<MeItem, FetchMeQueryParams>({
    url: 'me',
    // Hack to make the response an array
    extractItemsFn: (data) => [data] as User[],
})
