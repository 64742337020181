import React, { useState } from 'react'

import cn from 'classnames'

import { FilesTreeActions, FilesTreeItem, Icon, Text, TextColor } from '@/components/base'
import { BETWEEN_LEVELS_GAP, FIRST_LEVEL_PADDING } from '@/components/base/FilesTree/FilesTree.constants'
import { Layout } from '@/components/containers'

import styles from './FilesTree.module.scss'
import { FilesTreeProps } from './FilesTree.types'

export const FilesTree = (props: FilesTreeProps) => {
    const { items, isItemActive, activeId, folderActions = [], onItemClick, defaultFoldersState = false, fileActions = [] } = props

    const [foldersState, setFoldersState] = useState<Record<string, boolean>>({})

    const [hoveredItem, setHoveredItem] = useState<string | null>(null)

    const handleClick = (item: FilesTreeItem, isFolder: boolean) => {
        if (!isFolder) {
            onItemClick?.(item)
        } else {
            const prevState = foldersState[item.id] ?? defaultFoldersState
            const newState = !prevState
            setFoldersState((state) => ({
                ...state,
                [item.id]: newState,
            }))
        }
    }

    const getItems = (level: number, items: FilesTreeItem[]) => {
        return items.map((item, index) => {
            const isFolder = Boolean(item.children) // It's a folder if has children even if they are empty
            const isFolderOpen = foldersState[item.id] ?? defaultFoldersState
            const hasFolderActiveChild = item.children?.some((child) => (child.id === activeId) || isItemActive?.(child))
            const isActive = isFolder ? (hasFolderActiveChild) : (activeId === item.id || isItemActive?.(item))
            const itemColor: TextColor = isActive ? 'colorsPrimaryPurple' : 'colorsSecondaryGrey600'
            const itemStyle = cn(styles.item, { [styles.item_active]: isActive && !isFolder })
            const isFile = !isFolder

            return (
                <>
                    <Layout
                        justify='space-between'
                        key={index}
                        className={itemStyle}
                        py={4}
                        px={12}
                        style={{
                            paddingLeft: FIRST_LEVEL_PADDING + level * BETWEEN_LEVELS_GAP,
                        }}
                        onClick={() => handleClick(item, isFolder)}
                        // @ts-expect-error
                        onMouseEnter={() => setHoveredItem(item.id)}
                        onMouseLeave={() => setHoveredItem(null)}
                    >
                        <Layout
                            gap={8}
                            align='center'
                        >
                            {isFolder ? <Icon name='folder' color={itemColor}/> : <Icon name='document' color={itemColor}/>}
                            <Text color={itemColor} variant='extraSmallRegularDefault' style={{ userSelect: isFolder ? 'none' : 'auto' }}>{item.name}</Text>
                        </Layout>
                        {(isFolder && (hoveredItem !== item.id)) && (
                            <Icon
                                name={isFolderOpen ? 'arrowUp' : 'arrowDown'}
                                color={itemColor}
                                size='m'
                                style={{ marginLeft: 'auto' }}
                            />
                        )}
                        {Boolean(isFolder && folderActions.length && hoveredItem === item.id) && (
                            <FilesTreeActions
                                item={item}
                                fileActions={folderActions}
                            />
                        )}
                        {Boolean(isFile && fileActions.length) && (
                            <FilesTreeActions item={item} fileActions={fileActions} className={styles.actions}/>
                        )}
                    </Layout>
                    {(item.children?.length && isFolderOpen) ? getItems(level + 1, item.children) : null}
                </>
            )
        })
    }

    return (
        <Layout direction='column' width='100%'>
            {getItems(0, items)}
        </Layout>
    )
}
