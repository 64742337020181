import { createEnumDelimitedArrayParam } from 'use-query-params'

import { AssetDetails, ASSET_DETAIL_LABELS } from '@/constants/assetDetails'

const AssetDetailsParam = createEnumDelimitedArrayParam(Object.values(AssetDetails))

export const ASSET_DETAILS_QUERY_PARAM = {
    type: AssetDetailsParam,
    name: 'asset-details',
}

export const ASSET_DETAILS_OPTIONS = Object.entries(ASSET_DETAIL_LABELS).map(([value, label]) => ({ value, label }))
