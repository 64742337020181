import { Model, model, field } from 'mobx-orm'

import { api } from '../../http-adapter'

export type ReportTypeName = 'trial_balance' | 'rent_roll' | 'commercial_rent_roll' | 'budget'

export const reportTypeTitles: Record<ReportTypeName, string> = {
    trial_balance:        'Trial Balance',
    budget:               'Budget',
    rent_roll:            'Rent Roll',
    commercial_rent_roll: 'Commercial Rent Roll',
}

@api('csv-report-type')
@model
export class ReportType extends Model {
    @field readonly name?: ReportTypeName
}
