import { useEffect } from 'react'

import { QueryParamConfig, useQueryParam } from 'use-query-params'

import { useLocalStorage, UseLocalStorageOptions, useRouteKey } from '@/hooks'
import { useMe } from '@/hooks/core/useMe'

export interface UseInputStateParams<T> {
    name: string
    type: QueryParamConfig<T, T>
    syncLocalStorage?: boolean
    /**
     * this hook is not compatible with withDefaults provided by use-query-params
     */
    defaultValue?: T
    /**
     * Options for 'use-local-storage' library
     */
    localStorageOptions?: UseLocalStorageOptions<T>
}

/**
 * Hook to manage input state and sync it with query params and local storage
 *
 * @todo Parse Date valuef from local storage here
 *  now you need to use localStorageOptions: {parser: parseJSON } every time with DateParam
 */
export const useInputState = <T = unknown>(params: UseInputStateParams<T>) => {
    const {
        name,
        type,
        syncLocalStorage = false,
        localStorageOptions: options,
        defaultValue,
    } = params

    const { me } = useMe()

    const routeKey = useRouteKey()
    const localStorageKey = `${me?.companyUser?.company_id}:${routeKey || ''}:${name}`

    const [valueInStorage, setValueInStorage] = useLocalStorage<T | undefined>(localStorageKey, undefined, options)
    const [value, setValue] = useQueryParam<T>(name, type)

    const shouldUseValueFromStorage = !value && valueInStorage && syncLocalStorage

    useEffect(() => {
        if (!shouldUseValueFromStorage && !value && defaultValue) {
            setValue(defaultValue)
        }
    }, [shouldUseValueFromStorage, value, defaultValue, setValue])

    useEffect(() => {
        if (shouldUseValueFromStorage) {
            setValue(valueInStorage)
        }
    }, [])

    useEffect(() => {
        if (!syncLocalStorage) { return }
        setValueInStorage(value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        setValueInStorage,
        syncLocalStorage,
        JSON.stringify(value),
    ])

    return [
        // to avoid undefined on the 1st render return value from local storage
        (shouldUseValueFromStorage ? valueInStorage : value) || defaultValue,
        setValue,
    ] as const
}
