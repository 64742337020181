import { lazy } from 'react'

import { captureException } from '@sentry/react'

import { notify } from '@/utils/notify'

/**
 * Handler for 'React.lazy' error catching
 */
export const lazyWithCatch = (importFn) => {
    const promise = new Promise((resolve) => {
        importFn()
            .then(res => resolve(res))
            .catch((error) => {
                console.error(error)
                notify.error('Internet connection error. Try to reload the page')

                captureException('Chunk loading error', {
                    contexts: {
                        bundleLoadingError: error,
                    },
                })
            })
    })

    // @ts-expect-error TS(2322) FIXME: Type 'Promise<unknown>' is not assignable to type ... Remove this comment to see the full error message
    return lazy(() => promise)
}
