import { getRequestQueries } from '@/api/utils/getRequestQueries'

export interface TrialBalanceUnmappedItem {
    asset_id: number
    code: string
    id: number
    name: string
}

export interface FetchTrialBalanceUnmappedQueryResponse {
    accounts: TrialBalanceUnmappedItem[]
}

export interface FetchTrialBalanceUnmappedQueryParams {
    asset_id: number
    ledger_id: number
}

export const {
    useItemsQuery: useTrialBalanceUnmappedQuery,
} = getRequestQueries<TrialBalanceUnmappedItem, FetchTrialBalanceUnmappedQueryParams>({
    url: 'trial-balance-unmapped',
    extractItemsFn: (data: FetchTrialBalanceUnmappedQueryResponse) => data.accounts,
})
