import { observer } from 'mobx-react-lite'

import { DatePicker } from '@/components/base/DatePicker'

import { DateFilterProps } from './DateFilter.types'

// TODO: move it to MOBX-ORM or utils
function isValidDate (date: Date): boolean {
    return date instanceof Date && !isNaN(date as any)
}

export const DateFilter = observer((props: DateFilterProps) => {
    const { filter, label = 'Date' } = props
    return (
        <DatePicker
            label={label}
            date={isValidDate(filter.value) ? filter.value : undefined}
            onChange={(date) => { filter.set(date && isValidDate(date) ? date : undefined) }}
        />
    )
})
