import isValid from 'date-fns/isValid'
import { observer } from 'mobx-react-lite'

import { DateRangePicker as BaseMonthRangePicker } from '@/components/base'
import { dateToMonthInd, monthIndToDateNoTimezoneReset } from '@/utils/date/monthInd'

import { MonthRangePickerLegacyProps } from './MonthRangePickerLegacy.types'

/**
 * @deprecated Use `MonthRangePicker` instead.
 */
export const MonthRangePickerLegacy = observer((props: MonthRangePickerLegacyProps) => {
    const { from, to, label = 'Time Period' } = props

    const dateFrom = from.value ? monthIndToDateNoTimezoneReset(from.value) : undefined
    const dateTo = to.value ? monthIndToDateNoTimezoneReset(to.value) : undefined

    return (
        <BaseMonthRangePicker
            type='month'
            label={label}
            dateFrom={dateFrom}
            dateTo={dateTo}
            onChange={({ dateFrom, dateTo }) => {
                if (isValid(dateFrom)) from.set(dateFrom ? dateToMonthInd(dateFrom) : undefined)
                if (isValid(dateTo)) to.set(dateTo ? dateToMonthInd(dateTo) : undefined)
            }}
        />
    )
})
