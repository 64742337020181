import { Model, model, field, foreign, many } from 'mobx-orm'

import { DataPoint } from './DataPoint'
import { api } from '../../http-adapter'

@api('data-point-error')
@model
export class DataPointError extends Model {
    @field data_point_id:  number
    @field place?:         string
    @field error_text?:    string
    @field current_value?: string
    @field code?:          string

    @foreign(DataPoint) readonly submitted_file: DataPoint

    static DATA_SUBMISSION_LEVEL = 200
}

many(DataPointError, 'submitted_file_id')(DataPoint, 'errors')
