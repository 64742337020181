import { ValueType } from 'mobx-orm'

// string to value
/**
 * @deprecated
 */
export function serialize (value: string | undefined, type: ValueType) {
    if (value === undefined) {
        return undefined
    }
    if (value === 'null') {
        return null
    }
    if (value === '') {
        return undefined
    }
    let x
    switch (type) {
        case ValueType.STRING:
            return value
        case ValueType.NUMBER:
            x = parseInt(value)
            if (isNaN(x)) x = undefined
            return x
        case ValueType.BOOL:
            // I'm not shure that it is string
            return value === 'true' ? true : value === 'false' ? false : undefined
        case ValueType.DATE:
        case ValueType.DATETIME:
            return new Date(value)
    }
}
