import { useEffect, useRef, useState } from 'react'

import getDaysInMonth from 'date-fns/getDaysInMonth'
import { EQ, Query } from 'mobx-orm'
import { observer } from 'mobx-react-lite'
import { Outlet, useNavigate } from 'react-router'

import { Text } from '@/components/base'
import { PageLayout } from '@/components/containers'
import { TABLE_PER_PAGE_DEFAULT } from '@/components/legacy/tables/AgGridTableLegacy/components/ElementsPerPageSelect'
import { PaginationPane } from '@/components/legacy/tables/PaginationPane'
import { CompanyUserMode } from '@/core/modes'
import { ADMIN_ROUTES_CONFIG } from '@/core/routes'
import { useMe } from '@/hooks/core/useMe'
import http from '@/http.service'
import { Asset } from '@/models/asset'
import { Company, CompanyUser } from '@/models/company'
import { DataPointErrorCode } from '@/models/data_pulling/DataPointErrorCode'
import { ValidationTrackerFilters } from '@/pages/MainPage/CommonPages/ValidationTrackerPage/ValidationTrackerFilters'
import { getRouteConfig } from '@/utils/routing/getRouteConfig'

import { PeriodSelect } from './PeriodSelect'
import { ResultsTable } from './ResultsTable'
import styles from './ValidationTrackerPage.module.scss'
import { FileType, PeriodType, StatusType } from './ValidationTrackerPage.types'

export const ValidationTrackerPage = observer(() => {
    const navigate = useNavigate()

    const { me } = useMe()

    const urlParams = new URLSearchParams(window.location.search)

    const pageParams: any = {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        companyId: parseInt(urlParams.get('companyId')),
        propertyManager: urlParams.get('propertyManager') || '',
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        assetId: parseInt(urlParams.get('assetId')),
        fileType: urlParams.get('fileType') as FileType,
        periodType: urlParams.get('periodType') as PeriodType,
        statusType: urlParams.get('statusType') as StatusType,
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        currentYear: parseInt(urlParams.get('currentYear')),
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        currentMonth: parseInt(urlParams.get('currentMonth')),
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        errorCode: parseInt(urlParams.get('errorCode')) || 0,
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        __offset: parseInt(urlParams.get('__offset')),
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        __limit: parseInt(urlParams.get('__limit')),
    }

    const PAGE_PATH = me.companyUserMode === CompanyUserMode.Admin
        ? ADMIN_ROUTES_CONFIG.VALIDATION_TRACKER.path
        : getRouteConfig('VALIDATION_TRACKER').path

    const updatePageParams = (newParams: any = {}) => {
        const p = { ...pageParams, ...newParams }
        const uri = `${PAGE_PATH}?${new URLSearchParams(p)}`
        navigate(uri)
    }

    const isParamsSet = () => pageParams.fileType && pageParams.periodType &&
        pageParams.currentYear && pageParams.currentMonth

    const updatePeriod = (delta:(1 | -1)) => {
        let newDate
        if (pageParams.periodType === 'monthly') {
            newDate = { currentYear: pageParams.currentYear + delta, currentMonth: pageParams.currentMonth }
        } else {
            const month = pageParams.currentMonth + delta
            newDate = {
                currentMonth: (month - 1 + 12) % 12 + 1,
                currentYear: pageParams.currentYear + (Math.ceil(month / 12) - 1),
            }
        }

        // Check new month is not less than beginningOfTime and not greater than current month
        newDate.currentYear = Math.min(newDate.currentYear, new Date().getFullYear())
        newDate.currentYear = Math.max(newDate.currentYear, beginningOfTime.year)
        newDate.currentMonth = newDate.currentYear < new Date().getFullYear() ? newDate.currentMonth : Math.min(newDate.currentMonth, new Date().getMonth() + 1)
        newDate.currentMonth = newDate.currentYear > beginningOfTime.year ? newDate.currentMonth : Math.max(newDate.currentMonth, beginningOfTime.month + 1)

        updatePageParams(newDate)
    }

    /**
     * Load data
     */

    const [isDataReady, setIsDataReady] = useState(false)
    const [isDataError, setIsDataError] = useState(false)
    const requestId = useRef<any>(0)
    const [trackerData, setTrackerData] = useState({}) as any

    const loadData = async () => {
        if (!isParamsSet()) {
            return
        }

        requestId.current += 1
        setIsDataReady(false)

        pageParams.companyId = me.company.id || pageParams.companyId
        const uri = `data-pulling/validation-tracker?${new URLSearchParams({ ...pageParams, requestId: requestId.current })}`

        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        const response = await http.get(uri, me.companyUser ? { headers: { 'company-user-id': `${me.companyUser.id}` } } : undefined)

        if (response.data.request_id === requestId.current) {
            setTrackerData(response.data)
            setIsDataError(false)
            setIsDataReady(true)
        }
    }

    useEffect(() => {
        loadData()
    }, [JSON.stringify(pageParams)])

    /**
     * Loading companies, assets, and company users
     */

    const [companyQuery] = useState(() => Company.getQuery() as Query<Company>)
    const [companyUserQuery] = useState(() => CompanyUser.getQuery())
    const [errorCodeQuery] = useState(() => DataPointErrorCode.getQuery() as Query<Company>)
    const [assetQuery, setAssetQuery] = useState(null)

    useEffect(() => {
        companyQuery.load()
        companyUserQuery.load()
        errorCodeQuery.load()
    }, [])

    useEffect(() => {
        const filter = pageParams.companyId ? { filter: EQ('company_id', pageParams.companyId) } : {}
        const query = Asset.getQuery(filter)
        // @ts-expect-error TS(2345) FIXME: Argument of type 'Query<Model>' is not assignable ... Remove this comment to see the full error message
        setAssetQuery(query)
        query.load()
    }, [pageParams.companyId])

    // @ts-expect-error TS(2339) FIXME: Property 'is_ready' does not exist on type 'never'... Remove this comment to see the full error message
    const isReady = companyQuery.is_ready && companyUserQuery.is_ready && assetQuery?.is_ready

    /**
     * Setting default filters
     */

    useEffect(() => {
        if (isReady && !isParamsSet() && companyQuery.items.length) {
            updatePageParams({
                companyId: me.company.id || 0,
                assetId: 0,
                fileType: 'all',
                periodType: 'monthly',
                currentYear: (new Date()).getFullYear(),
                currentMonth: (new Date()).getMonth() + 1,
                statusType: 'all',
                __offset: 0,
                __limit: TABLE_PER_PAGE_DEFAULT,
            })
        }
    }, [isReady])

    return (
        <>
            <PageLayout
                loading={!isReady || !isDataReady || !isParamsSet()}
                gap={24}
            >
                <ValidationTrackerFilters assetQuery={assetQuery}/>
                {
                    isParamsSet() && !isDataError && (
                        <div className={styles.table}>
                            <PeriodSelect
                                periodType={pageParams.periodType}
                                year={pageParams.currentYear}
                                month={pageParams.currentMonth}
                                onNextPeriod={() => { updatePeriod(1) }}
                                onPrevPeriod={() => { updatePeriod(-1) }}
                            />
                            <ResultsTable
                                data={trackerData.result}
                                holidays={trackerData.holidays}
                                fileType={pageParams.fileType}
                                periodType={pageParams.periodType}
                                statusType={pageParams.statusType}
                                errorCode={pageParams.errorCode}
                                year={pageParams.currentYear}
                                month={pageParams.currentMonth}
                                assetQuery={!pageParams.assetId ? assetQuery : Asset.getQuery({ filter: EQ('id', pageParams.assetId) })}
                                searchString={(new URLSearchParams(pageParams)).toString()}
                            />
                            <div className={styles.paginationPane}>
                                <PaginationPane
                                    recordsCount={trackerData.count}
                                    offset={pageParams.__offset}
                                    limit={pageParams.__limit}
                                    onPageChanged={(offset, limit) => { updatePageParams({ __offset: offset, __limit: limit }) }}
                                />
                            </div>
                        </div>
                    )}
                {
                    isDataError &&
                        <Text>Unexpected error</Text>
                }
            </PageLayout>
            <Outlet context={{ onLoad: loadData }}/>
        </>
    )
})

export const beginningOfTime = { year: 2022, month: 1 }

export const getMonthDays = function (year, month) {
    const daysNum = getDaysInMonth(new Date(year, month - 1, 1))
    return Array.from(Array(daysNum).keys()).map(day => new Date(year, month - 1, day + 1))
}

export const getYearMonths1st = function (year, startMonth = 0, endMonth = 11): Date[] {
    return Array.from(Array(endMonth - startMonth + 1).keys()).map(month => new Date(year, month + startMonth, 1))
}

export const getYearsFromBeginning = function () {
    const currentYear = new Date().getFullYear()
    return Array.from(Array(currentYear - beginningOfTime.year + 1).keys())
        .map(year => new Date(year + beginningOfTime.year, 0, 1))
}

export const getMonthsFromBeginning = function (): Date[] {
    const currentYear = new Date().getFullYear()
    let months = []
    Array.from(Array(currentYear - beginningOfTime.year + 1).keys()).forEach((year) => {
        year += beginningOfTime.year
        // @ts-expect-error TS(2322) FIXME: Type 'Date[]' is not assignable to type 'never[]'.
        months = [
            ...months,
            ...getYearMonths1st(
                year,
                year === beginningOfTime.year ? beginningOfTime.month : 0,
                year === currentYear ? new Date().getMonth() : 11,
            ),
        ]
    })
    return months.reverse()
}
