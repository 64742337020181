import { getRequestQueries } from '@/api/utils/getRequestQueries'
import { TrialBalanceLedger } from '@/models/ledger'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchTrialBalanceLedgerQueryParams {}

export const LEDGER_URL = 'ledger'

export const {
    useItemsQuery: useTrialBalanceLedgerQuery,
    useItemUpdateMutation: useTrialBalanceLedgerUpdateMutation,
    useItemsCountQuery: useTrialBalanceLedgerCountQuery,
    useItemByIdQuery: useTrialBalanceLedgerByIdQuery,
} = getRequestQueries<TrialBalanceLedger, FetchTrialBalanceLedgerQueryParams>({
    url: LEDGER_URL,
})
