import { getRequestQueries } from '@/api/utils/getRequestQueries'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchDataPointErrorQueryParams {}

export interface DataPointErrorItem {
    id: number
    data_point_id: number
    place?: string
    error_text?: string
    current_value?: string
    code?: string
}

export const {
    useItemsQuery: useDataPointErrorQuery,
    useItemUpdateMutation: useDataPointErrorUpdateMutation,
    useItemsCountQuery: useDataPointErrorCountQuery,
} = getRequestQueries<DataPointErrorItem, FetchDataPointErrorQueryParams>({
    url: 'data-point-error',
    errorMessageUpdate: 'Failed to update data point error',
    errorMessageCreate: 'Failed to create data point error',
})
