import { action, observable } from 'mobx'
import { field, foreign, Model, model } from 'mobx-orm'

import { ReportType } from '@/models/csv_builder/ReportType'
import { randomString } from '@/utils/randomString'

import { api } from '../../http-adapter'

@api('csv-builtin-field-option')
@model
export class BuiltinFieldOption extends Model {
    @field readonly source_label?:   string
    @field readonly report_type_id?: number
    @field readonly name?:           string

    /**
     * Need this for DnD
     */
    @observable
        randomDndId = randomString()

    @action
    updateRandomDndId () {
        this.randomDndId = randomString()
    }

    @foreign(ReportType) readonly report_type: ReportType
}
