import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { Text } from '@/components/base'

import styles from './FiltersContainer.module.scss'
import { FiltersContainerProps } from './FiltersContainer.types'

export const FiltersContainer = observer((props: FiltersContainerProps) => {
    const { children, className, required, hideTitle = true, gap, width } = props

    const rootClassName = cn(styles.filters, className, {
        [styles.required]: required,
    })

    return (
        <div className={rootClassName} style={{ width }}>
            {!hideTitle && (
                <Text
                    text={required ? 'Required Fields:' : 'Filters:'}
                    color='secondaryGray'
                    variant='labelSemiboldDefault'
                />
            )}
            <div
                className={styles.inputsList}
                style={{ gap }}
            >
                {children}
            </div>
        </div>
    )
})
