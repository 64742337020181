import { generatePath } from 'react-router'

import { AgGridTableProps } from '@/components/legacy/tables/AgGridTableLegacy'
import { NavigateButtonCell } from '@/components/legacy/tables/cells'
import { ListInTooltipCell } from '@/components/tables/cells'
import { getRouteConfig } from '@/utils/routing/getRouteConfig'

export const INTRO_CONTENT = (
    <div>
        Templates are mappings between source accounts and target accounts that can be applied to multiple assets simultaneously.
    </div>
)

export const getSubtitleText = (count: number) => count
    ? `You have ${count} template${count > 1 ? 's' : ''}`
    : 'You have no templates yet'

export const TEMPLATE_LIST_GRID_OPTIONS: Omit<AgGridTableProps, 'query' | 'items'> = {
    rowHeight: 48,
    headerHeight: 32,
    columnDefs: [
        {
            field: 'name',
            headerName: 'Template Name',
            type: 'string',
            cellContentAlignVertical: true,
            rightBorder: true,
        },
        {
            headerName: 'Target Ledger',
            field: 'ledger.name',
            type: 'string',
            cellContentAlignVertical: true,
            minWidth: 235,
            rightBorder: true,
        },
        {
            headerName: 'Assets',
            sortable: false,
            type: 'string',
            cellContentAlignVertical: true,
            minWidth: 235,
            rightBorder: true,
            cellRendererSelector: ({ data }) => ({
                component: ListInTooltipCell,
                params: {
                    list: data.activeBindings.map((binding) => binding.asset?.name).sort(),
                    maxHeight: 400,
                },
            }),
        },
        {
            headerName: 'Actions',
            sortable: false,
            maxWidth: 162,
            minWidth: 162,
            lockPosition: 'right',
            cellContentAlignVertical: true,
            cellRendererSelector: ({ data }) => ({
                component: NavigateButtonCell,
                params: {
                    type: 'primaryButton',
                    buttonText: 'Go to Template',
                    path: generatePath(getRouteConfig('ASSET_MAPPING_TEMPLATES_BY_ID').path, { templateId: data.id }),
                },
            }),
        },
    ],
}
