import React from 'react'

import cn from 'classnames'

import { useRentRollLedgerByIdQuery } from '@/api/rentRoll/rentRollLedger'
import { Layout } from '@/components/containers'
import { LEDGER_SELECT_INPUT_QUERY_PARAM } from '@/components/models/ledger'
import { useInputState } from '@/hooks'

export const CustomMappingHeaderCell = () => {
    const [ledgerFilter] = useInputState(LEDGER_SELECT_INPUT_QUERY_PARAM)

    const rentRollLedgerByIdQuery = useRentRollLedgerByIdQuery(ledgerFilter as number, {
        enabled: !!ledgerFilter,
    })

    const title = rentRollLedgerByIdQuery.data?.name?.toUpperCase()

    return (
        <Layout
            title={title}
            align='center'
            className={cn()}
            style={{ width: '100%' }}
        >
            {title}
        </Layout>
    )
}
