import React from 'react'

import { observer } from 'mobx-react-lite'
import { generatePath, useParams } from 'react-router'

import { ADMIN_ROUTES_CONFIG } from '@/core/routes'
import { CompanyNewInvitePopup } from '@/pages/MainPage/CommonPages/CompanyInvitesPage/CompanyNewInvitePopup'

export const CompanyInvitesPopup = observer(() => {
    const { companyId } = useParams()

    return (
        <CompanyNewInvitePopup
            backPath={generatePath(ADMIN_ROUTES_CONFIG.COMPANY_USER_INVITES.path, { companyId })}
        />
    )
})
