import cn from 'classnames'

import IntelasLogoIcon from '@/assets/images/logo.svg'
import IntelasLogoSmallIcon from '@/assets/images/logoSmall.svg'

import styles from './IntelasLogo.module.scss'
import { IntelasLogoProps } from './IntelasLogo.types'

export const IntelasLogo = (props: IntelasLogoProps) => {
    const { className, isMenuExpanded } = props

    const logoClassName = cn(styles.header, className)

    return (
        <div className={logoClassName}>
            {isMenuExpanded ? (
                <IntelasLogoIcon height={24} className={styles.logo_full}/>
            ) : (
                <IntelasLogoSmallIcon height={24} width={24}/>
            )}
        </div>
    )
}
