import { useEffect, useMemo, useRef, useState } from 'react'

import { AgGridReact } from '@ag-grid-community/react'
import { observer } from 'mobx-react-lite'

import { Switch } from '@/components/base'
import { AgGridTableLegacy } from '@/components/legacy/tables/AgGridTableLegacy'
import { SwitchHeaderCell } from '@/components/tables'
import { Asset } from '@/models/asset'
import {
    MappingTemplateApplyTableProps,
} from '@/pages/MainPage/UserPages/MappingPage/MappingTemplatesPage/MappingTemplateEditPage'

import { gridOptions } from './MappingTemplateApplyTable.constants'

export const MappingTemplateApplyTable = observer((props: MappingTemplateApplyTableProps) => {
    const { template, assets, onChange } = props

    const tableRef = useRef<AgGridReact>(null)

    const [bindings, setBindings] = useState(new Set<number>())

    useEffect(
        () => {
            setBindings(assets.items.reduce(
                (acc, asset) => {
                    if (template.isBoundToAsset(asset)) {
                        // @ts-expect-error TS(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
                        acc.add(asset.id)
                    }
                    return acc
                },
                new Set<number>(),
            ))
        },
        [template, assets.timestamp],
    )

    useEffect(
        () => onChange(bindings),
        [onChange, bindings],
    )

    const updatedGridOptions = useMemo(() => ({
        ...gridOptions,
        columnDefs: [
            {
                headerComponent: SwitchHeaderCell,
                headerComponentParams: {
                    checked: assets.items.length === bindings.size,
                    onChange: (checked: boolean) =>
                        // @ts-expect-error TS(2345) FIXME: Argument of type 'Set<number | undefined>' is not ... Remove this comment to see the full error message
                        setBindings(checked ? new Set(assets.items.map(asset => asset.id)) : new Set()),
                },
                minWidth: 130,
                width: 130,
                cellContentAlignVertical: true,
                cellRenderer: ({ data: asset }: { data: Asset }) => (
                    <Switch
                        // @ts-expect-error TS(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
                        checked={bindings.has(asset.id)}
                        onChange={checked => {
                            const changedBindings = new Set(bindings)
                            if (checked) {
                                // @ts-expect-error TS(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
                                changedBindings.add(asset.id)
                            } else {
                                // @ts-expect-error TS(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
                                changedBindings.delete(asset.id)
                            }
                            setBindings(changedBindings)
                        }}
                    />
                ),
            },
            ...gridOptions.columnDefs ?? [],
        ],
    }), [bindings, assets.timestamp])

    return (
        <AgGridTableLegacy
            ref={tableRef}
            query={assets}
            {...updatedGridOptions}
        />
    )
})
