import { observer } from 'mobx-react-lite'
import { generatePath, useNavigate } from 'react-router'

import { useMe } from '@/hooks/core/useMe'
import { CompanyUser } from '@/models/company'
import { CompanyMembersSubpage } from '@/pages/MainPage/CommonPages/CompanyMembersSubpage/CompanyMembersSubpage'
import { getRouteConfig } from '@/utils/routing/getRouteConfig'

export const CompanyProfileMembers = observer(() => {
    const navigate = useNavigate()
    const { me } = useMe()

    const handleRowClick = ({ data }: { data: CompanyUser }) => {
        navigate(generatePath(getRouteConfig('COMPANY_PROFILE_MEMBERS_BY_ID').path, {
            userId: data.id?.toString(),
        }))
    }

    return (
        <CompanyMembersSubpage
            companyId={me.companyUser.company_id ?? -1}
            invitePopupPath={getRouteConfig('COMPANY_PROFILE_INVITES_NEW').path}
            handleRowClick={handleRowClick}
        />
    )
})
