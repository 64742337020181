import React, { useEffect, useState } from 'react'

import { useAssetsQuery } from '@/api/asset/asset'
import { useDataPointUpdateMutation } from '@/api/dataPulling/dataPoint'
import { Select } from '@/components/base'
import { Asset } from '@/models/asset'
import { DataPointType } from '@/models/data_pulling'
import { notify } from '@/utils/notify'

import { SubmissionAssetSelectCellProps } from './SubmissionAssetSelectCell.types'

export const SubmissionAssetSelectCell = (props: SubmissionAssetSelectCellProps) => {
    const { value, data } = props

    const assetQuery = useAssetsQuery()
    const dataPointUpdateMutation = useDataPointUpdateMutation({ disableItemsInvalidation: true })

    const selectedOption = assetQuery.data?.find((item) => item.id?.toString() === value?.toString())

    const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null)

    // Set initial value if there is any
    useEffect(() => {
        if (!selectedAsset && selectedOption) {
            setSelectedAsset(selectedOption)
        }
    }, [selectedOption])

    if (data.type !== DataPointType.UNRECOGNIZED) {
        return <>{selectedOption?.name}</>
    }

    const handleAssetChange = ([option]: Asset[]) => {
        setSelectedAsset(option)
        dataPointUpdateMutation.mutateAsync({
            id: data.id as number,
            updatedItemData: {
                asset_id: option.id,
            },
        }).catch(() => {
            notify.error('Failed to update asset')
        })
    }

    return (
        <Select<Asset>
            width='150px'
            onChange={handleAssetChange}
            options={assetQuery.data || []}
            selected={selectedAsset ? [selectedAsset] : []}
            labelFn={(asset) => asset.name?.toString() || ''}
            idFn={(asset) => asset.id?.toString() || ''}
        />
    )
}
