import { useContext, useState } from 'react'

import { observer } from 'mobx-react-lite'

import { ButtonGroup } from '@/components/base'
import { ModelInput } from '@/utils/mobx-orm'

import { DateGroupInputProps } from './DateGroupInput.types'
import { ModelFormContext } from '../../ModelFromContext'

export const DateGroupInput = observer((props: DateGroupInputProps) => {
    const { obj, field, filter, syncURL, syncLocalStorage, label = 'Group', rollup, annual, monthly, daily } = props
    const modelForm = useContext(ModelFormContext)

    const [modelInput] = useState(() => new ModelInput({
        obj: obj || modelForm.obj,
        field,
        filter,
        syncURL: syncURL || modelForm.syncURL,
        syncLocalStorage: syncLocalStorage || modelForm.syncLocalStorage,
    }))

    const choices = []
    // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
    if (daily) choices.push({ title: 'Daily', key: 'daily' })
    // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
    if (monthly) choices.push({ title: 'Monthly', key: 'monthly' })
    // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
    if (annual) choices.push({ title: 'Annual', key: 'annual' })
    // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
    if (rollup) choices.push({ title: 'Rollup', key: 'rollup' })

    return (
        <ButtonGroup
            label={label}
            options={choices}
            selected={modelInput.value}
            onSelect={(key) => modelInput.set(key)}
        />
    )
})
