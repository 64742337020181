export const buttonsAlertsBgDisable = '#ffffff66'
export const buttonsAlertsBgFocused = '#EFF8FF'
export const buttonsAlertsBgHover = '#EFF8FF'
export const buttonsAlertsBgPrimary = '#ffffff'
export const buttonsAlertsBorderDisable = '#84CAFF'
export const buttonsAlertsBorderFocused = '#2166B2'
export const buttonsAlertsBorderFocused2Px = '#D1E9FF'
export const buttonsAlertsBorderHover = '#2166B2'
export const buttonsAlertsBorderLoading = '#2166B2'
export const buttonsAlertsBorderPressed = '#194F8A'
export const buttonsAlertsBorderPrimary = '#2E90FA'
export const buttonsAlertsIconDisable = '#84CAFF'
export const buttonsAlertsIconFocused = '#2166B2'
export const buttonsAlertsIconHover = '#2166B2'
export const buttonsAlertsIconPressed = '#194F8A'
export const buttonsAlertsIconPrimary = '#2E90FA'
export const buttonsAlertsTextDisable = '#84CAFF'
export const buttonsAlertsTextFocused = '#2166B2'
export const buttonsAlertsTextHover = '#2166B2'
export const buttonsAlertsTextLoading = '#2166B2'
export const buttonsAlertsTextPressed = '#194F8A'
export const buttonsAlertsTextPrimary = '#2E90FA'
export const buttonsDestructiveBgDisable = '#F9B3B6'
export const buttonsDestructiveBgFocused = '#EB0A15'
export const buttonsDestructiveBgHover = '#EB0A15'
export const buttonsDestructiveBgLoading = '#F9B3B6'
export const buttonsDestructiveBgPressed = '#D60913'
export const buttonsDestructiveBgPrimary = '#EF3B44'
export const buttonsDestructiveBorderFocused = '#F9B3B6'
export const buttonsDestructiveIconPrimary = '#ffffff'
export const buttonsDestructiveTextLoader = '#D60913'
export const buttonsDestructiveTextPrimary = '#ffffff'
export const buttonsFlatPIconDisable = '#dbdbff'
export const buttonsFlatPIconFocused = '#4141a3'
export const buttonsFlatPIconHover = '#4141a3'
export const buttonsFlatPIconPressed = '#33337e'
export const buttonsFlatPIconPrimary = '#5c5ce5'
export const buttonsFlatPTextDisable = '#dbdbff'
export const buttonsFlatPTextFocused = '#4141a3'
export const buttonsFlatPTextHover = '#4141a3'
export const buttonsFlatPTextPressed = '#33337e'
export const buttonsFlatPTextPrimary = '#5c5ce5'
export const buttonsFlatWIconDisable = '#4141a3'
export const buttonsFlatWIconFocused = '#e3e3ff'
export const buttonsFlatWIconHover = '#e3e3ff'
export const buttonsFlatWIconPressed = '#ffffff'
export const buttonsFlatWIconPrimary = '#ffffff'
export const buttonsFlatWTextDisable = '#4141a3'
export const buttonsFlatWTextFocused = '#e3e3ff'
export const buttonsFlatWTextHover = '#e3e3ff'
export const buttonsFlatWTextPressed = '#ffffff'
export const buttonsFlatWTextPrimary = '#ffffff'
export const buttonsPrimaryPBgDisable = '#dbdbff'
export const buttonsPrimaryPBgFocused = '#4141a3'
export const buttonsPrimaryPBgHover = '#4141a3'
export const buttonsPrimaryPBgLoading = '#dbdbff'
export const buttonsPrimaryPBgPressed = '#33337e'
export const buttonsPrimaryPBgPrimary = '#5c5ce5'
export const buttonsPrimaryPBorderFocused = '#e3e3ff'
export const buttonsPrimaryPIconLoading = 'gradient'
export const buttonsPrimaryPIconPrimary = '#ffffff'
export const buttonsPrimaryPTextLoading = '#4141a3'
export const buttonsPrimaryPTextPrimary = '#ffffff'
export const buttonsPrimaryWBgDisable = '#ffffff66'
export const buttonsPrimaryWBgFocused = '#f7f7fc'
export const buttonsPrimaryWBgHover = '#f7f7fc'
export const buttonsPrimaryWBgLoading = '#f7f7fc'
export const buttonsPrimaryWBgPressed = '#ffffff'
export const buttonsPrimaryWBgPrimary = '#ffffff'
export const buttonsPrimaryWBorderFocused = '#e3e3ff'
export const buttonsPrimaryWIconDisable = '#bdc1cc'
export const buttonsPrimaryWIconHoverFocusedLoading = '#4141a3'
export const buttonsPrimaryWIconPressed = '#33337e'
export const buttonsPrimaryWIconPrimary = '#5c5ce5'
export const buttonsPrimaryWTextDisable = '#bdc1cc'
export const buttonsPrimaryWTextHoverFocusedLoading = '#4141a3'
export const buttonsPrimaryWTextPressed = '#33337e'
export const buttonsPrimaryWTextPrimary = '#5c5ce5'
export const buttonsSecondaryPBgDisable = '#ffffff66'
export const buttonsSecondaryPBgFocused = '#f7f7fc'
export const buttonsSecondaryPBgHover = '#f7f7fc'
export const buttonsSecondaryPBgPressed = '#efeffc'
export const buttonsSecondaryPBgPrimary = '#ffffff'
export const buttonsSecondaryPBorderDisable = '#dbdbff'
export const buttonsSecondaryPBorderFocused = '#4141a3'
export const buttonsSecondaryPBorderFocused2px = '#e3e3ff'
export const buttonsSecondaryPBorderHover = '#4141a3'
export const buttonsSecondaryPBorderLoader = '#4141a3'
export const buttonsSecondaryPBorderPressed = '#33337e'
export const buttonsSecondaryPBorderPrimary = '#5c5ce5'
export const buttonsSecondaryPIconDisable = '#dbdbff'
export const buttonsSecondaryPIconFocused = '#4141a3'
export const buttonsSecondaryPIconHover = '#4141a3'
export const buttonsSecondaryPIconPressed = '#33337e'
export const buttonsSecondaryPIconPrimary = '#5c5ce5'
export const buttonsSecondaryPTextDisable = '#dbdbff'
export const buttonsSecondaryPTextFocused = '#4141a3'
export const buttonsSecondaryPTextHover = '#4141a3'
export const buttonsSecondaryPTextLoading = '#4141a3'
export const buttonsSecondaryPTextPressed = '#33337e'
export const buttonsSecondaryPTextPrimary = '#5c5ce5'
export const buttonsSecondaryWBgPressedHovered = '#191c3266'
export const buttonsSecondaryWBorderDisable = '#4141a3'
export const buttonsSecondaryWBorderFocused = '#f7f7fc'
export const buttonsSecondaryWBorderFocused2px = '#ffffff66'
export const buttonsSecondaryWBorderHover = '#f7f7fc'
export const buttonsSecondaryWBorderLoader = '#ffffff'
export const buttonsSecondaryWBorderPressed = '#ccccf7'
export const buttonsSecondaryWBorderPrimary = '#ffffff'
export const buttonsSecondaryWIconDisable = '#4141a3'
export const buttonsSecondaryWIconFocused = '#f7f7fc'
export const buttonsSecondaryWIconHover = '#f7f7fc'
export const buttonsSecondaryWIconPressed = '#ccccf7'
export const buttonsSecondaryWIconPrimary = '#ffffff'
export const buttonsSecondaryWTextDisable = '#4141a3'
export const buttonsSecondaryWTextFocused = '#f7f7fc'
export const buttonsSecondaryWTextHover = '#f7f7fc'
export const buttonsSecondaryWTextLoading = '#ffffff'
export const buttonsSecondaryWTextPressed = '#ccccf7'
export const buttonsSecondaryWTextPrimary = '#ffffff'
export const colorsGradientLogo = 'linear-gradient(100deg, #5bd5ff 0%, #922eff 100%)'
export const colorsGradientWhite = 'linear-gradient(180deg, #ffffff00 64.12%, #ffffff 100%)'
export const colorsPrimaryGrey = '#232732'
export const colorsPrimaryPurple = '#5c5ce5'
export const colorsPrimaryWhite = '#ffffff'
export const colorsSecondaryGrey100 = '#dee0e6'
export const colorsSecondaryGrey200 = '#cdd1d9'
export const colorsSecondaryGrey300 = '#bdc1cc'
export const colorsSecondaryGrey400 = '#9ba3b3'
export const colorsSecondaryGrey50 = '#eef0f2'
export const colorsSecondaryGrey500 = '#7a8499'
export const colorsSecondaryGrey600 = '#596580'
export const colorsSecondaryGrey700 = '#475166'
export const colorsSecondaryGrey800 = '#353d4d'
export const colorsSecondaryLightPurple100 = '#f2f2ff'
export const colorsSecondaryLightPurple200 = '#ebebff'
export const colorsSecondaryLightPurple300 = '#e3e3ff'
export const colorsSecondaryLightPurple400 = '#dbdbff'
export const colorsSecondaryLightPurple50 = '#fafaff'
export const colorsSecondaryLightPurple500 = '#cacaff'
export const colorsSecondaryLightPurple600 = '#B2B2FF'
export const colorsSecondaryLightPurple700 = '#A3A3FF'
export const colorsSecondaryLightPurple800 = '#8686FF'
export const colorsSecondaryLightPurple900 = '#7676FF'
export const colorsSecondaryPurple100 = '#ccccf7'
export const colorsSecondaryPurple200 = '#b4b4f3'
export const colorsSecondaryPurple25 = '#f7f7fc'
export const colorsSecondaryPurple300 = '#9292ee'
export const colorsSecondaryPurple400 = '#7d7dea'
export const colorsSecondaryPurple50 = '#efeffc'
export const colorsSecondaryPurple600 = '#5454d0'
export const colorsSecondaryPurple700 = '#4141a3'
export const colorsSecondaryPurple800 = '#33337e'
export const colorsSecondaryPurple900 = '#272760'
export const colorsTetrialBlue100 = '#D1E9FF'
export const colorsTetrialBlue200 = '#B2DDFF'
export const colorsTetrialBlue300 = '#84CAFF'
export const colorsTetrialBlue400 = '#53B1FD'
export const colorsTetrialBlue50 = '#EFF8FF'
export const colorsTetrialBlue500 = '#2E90FA'
export const colorsTetrialBlue600 = '#2A83E4'
export const colorsTetrialBlue700 = '#2166B2'
export const colorsTetrialBlue800 = '#194F8A'
export const colorsTetrialBlue900 = '#133C69'
export const colorsTetrialGreen100 = '#B4E4B4'
export const colorsTetrialGreen200 = '#90D78F'
export const colorsTetrialGreen300 = '#5EC45C'
export const colorsTetrialGreen400 = '#3EB93D'
export const colorsTetrialGreen50 = '#E7F6E7'
export const colorsTetrialGreen500 = '#0EA70C'
export const colorsTetrialGreen600 = '#0D980B'
export const colorsTetrialGreen700 = '#0A7709'
export const colorsTetrialGreen800 = '#085C07'
export const colorsTetrialGreen900 = '#064605'
export const colorsTetrialOrange100 = '#FFF0E1'
export const colorsTetrialOrange200 = '#FFDFC1'
export const colorsTetrialOrange300 = '#f6bd81'
export const colorsTetrialOrange400 = '#FF9838'
export const colorsTetrialOrange50 = '#FFF5EB'
export const colorsTetrialOrange500 = '#E68932'
export const colorsTetrialOrange600 = '#BF722A'
export const colorsTetrialOrange700 = '#995B22'
export const colorsTetrialOrange800 = '#734419'
export const colorsTetrialOrange900 = '#593514'
export const colorsTetrialRed100 = '#FDE7E8'
export const colorsTetrialRed200 = '#F9B3B6'
export const colorsTetrialRed300 = '#f68e93'
export const colorsTetrialRed400 = '#F25B62'
export const colorsTetrialRed50 = '#fcf7f7'
export const colorsTetrialRed500 = '#EF3B44'
export const colorsTetrialRed600 = '#EB0A15'
export const colorsTetrialRed700 = '#D60913'
export const colorsTetrialRed800 = '#A7070F'
export const colorsTetrialRed900 = '#81060C'
export const colorsTransparent100 = 'rgba(255,255,255,0)'
export const colorsTransparentOverlay = '#191c3266'
export const colorsTransparentWhite40 = '#ffffff66'
