import { getRequestQueries } from '@/api/utils/getRequestQueries'
import { Account as TrialBalanceAccount } from '@/models/ledger'
import { Account as RentRollAccount } from '@/models/rent_roll'

type Account = RentRollAccount | TrialBalanceAccount

export interface SourceAccountMapPredictionsItem {
    account: Account
    /**
     * Range: 0.0 - 1.0
     */
    confidence_score: number
}

export interface FetchSourceAccountMapPredictionsQueryParams {
    source_account_id: number
    ledger_id: number
}

export const {
    useItemsQuery: useSourceAccountMapPredictionsQuery,
    useItemUpdateMutation: useSourceAccountMapPredictionsUpdateMutation,
    useItemsCountQuery: useSourceAccountMapPredictionsCountQuery,
} = getRequestQueries<SourceAccountMapPredictionsItem, FetchSourceAccountMapPredictionsQueryParams>({
    url: 'source-account-map-predictions',
})
