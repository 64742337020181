import { TextColor } from '@/components/base'
import * as generatedTextColors from '@/styles/tokens/generatedColors'

/**
 * TODO: Use only global generatedColors and stop using this
 *  Need this file only for smooth migration
 * @deprecated
 */
export const textLegacyColors = {
    secondaryBlack: '#232732',
    primaryPurple: '#5c5ce5',
    statusRed: '#e54545',
    secondaryLightBlack: '#596580',
    statusDarkGreen: '#00876f',
    statusDarkOrange: '#cc8438',
    secondaryWhite: '#ffffff',
    secondaryGray: '#99a4bf',
    statusBlue: '#41abd9',
    strokeGray: '#dde1ea',
}

export const textColors: Record<TextColor, string> = {
    ...textLegacyColors,
    ...generatedTextColors,
}
