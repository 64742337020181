import { NumberParam } from 'use-query-params'

export const YEAR_RANGE_START_QUERY_PARAM = {
    name: 'start-year',
    type: NumberParam,
}

export const YEAR_RANGE_END_QUERY_PARAM = {
    name: 'end-year',
    type: NumberParam,
}
