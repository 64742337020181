import { DatePicker } from '@/components/base'
import { useInputState } from '@/hooks'
import { dateToMonthInd, monthIndToDate } from '@/utils/date/monthInd'

import { MONTH_INPUT_QUERY_PARAM } from './MonthInput.constants'
import { MonthInputProps } from './MonthInput.types'

const { name: monthParamName, type: paramType } = MONTH_INPUT_QUERY_PARAM

export const MonthInput = (props: MonthInputProps) => {
    const {
        paramName = monthParamName,
        defaultMonth,
        syncLocalStorage,
        placeholderText = 'Select month',
        required = false,
        ...rest
    } = props

    const [month, setMonth] = useInputState({ name: paramName, type: paramType, syncLocalStorage })

    if (!month && defaultMonth) {
        setMonth(defaultMonth)
    }

    return (
        <DatePicker
            date={month ? monthIndToDate(month) : undefined}
            onChange={date => date && setMonth(dateToMonthInd(date))}
            placeholderText={placeholderText}
            error={required && !month}
            type='month'
            icon='arrowDown'
            rotateIcon
            {...rest}
        />
    )
}
