import { Model, model, field } from 'mobx-orm'

import { CompanyFeatures } from './CompanyFeatures'
import { CompanyUser } from './CompanyUser'
import { CompanyUserGroup } from './CompanyUserGroup'
import { api } from '../../http-adapter'
import { AssetGroup } from '../asset'

@api('company')
@model
export class Company extends Model {
    @field name?:                    string
    @field location?:                string
    @field comments_group_id?:       string
    @field is_active?:               boolean
    @field use_scheduled_tb_upload?: boolean
    @field use_export?:              boolean
    @field features?:                CompanyFeatures

    readonly company_users:       CompanyUser[]
    readonly company_user_groups: CompanyUserGroup[]
    readonly asset_groups:        AssetGroup[]
}
