import { useCompanyInviteUpdateMutation } from '@/api/company/companyInvite'
import { useCompanyInviteSendCreateMutation } from '@/api/company/companyInviteSend'
import { ActionsCell, ActionsCellItem } from '@/components/legacy/tables/cells'
import { CompanyInviteStatus } from '@/models/company'
import { notify } from '@/utils/notify'

import { CompanyInvitesPageActionsCellProps } from './CompanyInvitesPageActionsCell.types'

export const CompanyInvitesPageActionsCell = (props: CompanyInvitesPageActionsCellProps) => {
    const { data } = props

    const isStatusCanceled = data.status === CompanyInviteStatus.Canceled
    const isStatusPending = data.status === CompanyInviteStatus.Pending

    const companyInviteUpdateMutation = useCompanyInviteUpdateMutation()
    const companyInviteSendCreateMutation = useCompanyInviteSendCreateMutation()

    const handleResend = async () => {
        const promises: Array<Promise<unknown>> = []

        if (isStatusCanceled) {
            const promiseUpdate = companyInviteUpdateMutation.mutateAsync({
                id: data.id as number,
                updatedItemData: { status: CompanyInviteStatus.Pending },
            })

            promises.push(promiseUpdate)
        }

        const promiseInvite = companyInviteSendCreateMutation.mutateAsync({
            company_invite_id: data.id,
        })

        promises.push(promiseInvite)

        notify.promise(Promise.all(promises), {
            success: 'Invitation has been sent',
            pending: 'Request pending',
            error: 'Request failed',
        })
    }

    const handleRevoke = async () => {
        const promiseUpdate = companyInviteUpdateMutation.mutateAsync({
            id: data.id as number,
            updatedItemData: { status: CompanyInviteStatus.Canceled },
        })

        notify.promise(
            promiseUpdate,
            {
                success: 'Invitation for this user has been cancelled',
                pending: 'Request pending',
                error: 'Request failed',
            },
        )
    }

    const actionsCellItems: ActionsCellItem[] = []

    if (isStatusCanceled || isStatusPending) {
        actionsCellItems.push({
            icon: 'replace',
            text: 'Resend Invitation',
            dividerAfter: !isStatusCanceled,
            onClick: handleResend,
        })
    }

    if (isStatusPending) {
        actionsCellItems.push({
            icon: 'deactivatedUser',
            text: 'Revoke Invitation',
            onClick: handleRevoke,
        })
    }

    return (
        <ActionsCell
            items={actionsCellItems}
        />
    )
}
