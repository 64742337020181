import { SourceAccountMapPredictionsItem } from '@/api/trialBalance/sourceAccountMapPredictions'
import { LabelColors } from '@/components/base'
import { Account } from '@/models/ledger'

export const selectOptionsMappingLabelProps = (suggestedItem: SourceAccountMapPredictionsItem): { labelWidth: string, labelColor: LabelColors, labelText: string } => ({
    labelWidth: '44px',
    labelColor: suggestedItem.confidence_score >= 0.5 ? 'blue' : 'orange',
    labelText: suggestedItem.confidence_score >= 0.5 ? Math.floor(suggestedItem.confidence_score * 100) + '%' : '<50%',
})

export const getAccountItemLabel = (account: Pick<Account, 'name' | 'code'>) => (account?.name || account?.code) ? `${account.code || ''} ${account.name || ''}`.trim() : ''
