import { fsEvent } from '@/utils/fullstory/fsEvent'

export const fsDownloadEvent = (name = 'Untitled') => {
    fsEvent({
        name: `${name}: CSV Download`,
        properties: {
            pathname: window.location.pathname,
        },
    })
}
