import { model, field, foreign } from 'mobx-orm'

import { TrialBalanceTemplateBinding } from './TrialBalanceTemplateBindings'
import { TrialBalanceTemplateMap } from './TrialBalanceTemplateMap'
import { TrialBalanceTemplateUpload } from './TrialBalanceTemplateUpload'
import { api } from '../../http-adapter'
import { Asset } from '../asset/Asset'
import { Company } from '../company/Company'
import { TrialBalanceLedger } from '../ledger/Ledger'
import { Model } from '../model'

@api('trial-balance-template')
@model
export class TrialBalanceTemplate extends Model {
    @field company_id?: number
    @field ledger_id?:  string
    @field name?:       string

    readonly maps:     TrialBalanceTemplateMap[]
    readonly uploads:  TrialBalanceTemplateUpload[]
    readonly bindings: TrialBalanceTemplateBinding[]

    @foreign(Company) readonly company:           Company
    @foreign(TrialBalanceLedger) readonly ledger: TrialBalanceLedger

    get activeBindings (): TrialBalanceTemplateBinding[] {
        return this.bindings.filter(binding => binding.is_active)
    }

    isBoundToAsset (asset: Asset): boolean {
        // @ts-expect-error TS(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
        return this.getBindingForAsset(asset)?.is_active
    }

    getBindingForAsset (asset: Asset): TrialBalanceTemplateBinding | undefined {
        for (const binding of this.bindings) {
            if (binding.asset_id === asset.id) {
                return binding
            }
        }
    }
}
