import { useState } from 'react'

import { XAND as AND, StringInput, NumberInput, XEQ as EQ } from 'mobx-orm'
import { observer } from 'mobx-react-lite'

import { AgGridTableLegacy } from '@/components/legacy/tables/AgGridTableLegacy'
import { DataPoint } from '@/models/data_pulling'
import {
    gridOptions,
} from '@/pages/MainPage/CommonPages/ValidationTrackerPage/DataPointSideModal/FileHistory/FileHistory.constants'
import {
    FileHistoryProps,
} from '@/pages/MainPage/CommonPages/ValidationTrackerPage/DataPointSideModal/FileHistory/FileHistory.types'

import styles from './FileHistory.module.scss'

const FileHistory = observer(({ dataPoint }: FileHistoryProps) => {
    const [typeInput] = useState(() => new StringInput({
        value: dataPoint.type,
    }))
    const [assetIdInput] = useState(() => new NumberInput({
        value: dataPoint.asset_id,
    }))
    const [reportDateInput] = useState(() => new StringInput({
        value: dataPoint.report_date,
    }))

    const [historyQuery] = useState(() => {
        return DataPoint.getQueryXPage({
            filter: AND(
                EQ('type', typeInput),
                EQ('asset_id', assetIdInput),
                EQ('report_date', reportDateInput),
            ),
            autoupdate: true,
        })
    })

    return (
        <div className={styles.container}>
            <AgGridTableLegacy
                query={historyQuery}
                {...gridOptions}
            />
        </div>
    )
})

export { FileHistory }
