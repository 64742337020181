import React, { FC } from 'react'

import { observer } from 'mobx-react-lite'

import { AccountCategoryInputProps } from './AccountCategoryInput.types'
import AccountCategoryPicker from './AccountCategoryPicker'
import AccountCategoryReadOnly from './AccountCategoryReadOnly'

const AccountCategoryInput: FC<AccountCategoryInputProps> = observer((props: AccountCategoryInputProps) => {
    const { value, readonly, placeholder } = props

    return (
        <>
            {
                readonly &&
                <AccountCategoryReadOnly value={value} placeholder={placeholder}/>
            }
            {
                !readonly &&
                <AccountCategoryPicker {...props}/>
            }
        </>
    )
})

export default AccountCategoryInput
