import React from 'react'

import cn from 'classnames'

import { Layout } from '@/components/containers'

import styles from './InfoTile.module.scss'
import { InfoTileProps } from './InfoTile.types'

export const InfoTile = (props: InfoTileProps) => {
    const { title, description, color, onClick } = props

    const infoTileClassNames = cn(styles.infoTile, {
        [styles['color_' + color]]: color,
        [styles.clickable]: onClick,
    })

    return (
        <Layout
            className={infoTileClassNames}
            px={12}
            py={8}
            direction='column'
        >
            <span className={styles.title}>{title}</span>
            <span className={styles.description}>{description}</span>
        </Layout>
    )
}
