import http from '@/http.service'
import { notify } from '@/utils/notify'

/**
 * Reset password util
 */
export const resetUserPassword = async ({ email, onRequest, onResponse }: { email: string, onRequest?: VoidFunction, onResponse?: VoidFunction }) => {
    const formData = new FormData()
    formData.append('email', email)

    onRequest?.()

    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    const resetPromise = http.post('/auth/password/reset/', formData, {
        // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
        baseURL: null,
        headers: { 'Content-Type': 'multipart/form-data' },
    })

    // eslint-disable-next-line no-async-promise-executor
    const promiseWrapper = new Promise(async (resolve, reject) => {
        try {
            await resetPromise
            resolve(null)
        } catch (e) {
            reject(e)
        } finally {
            onResponse?.()
        }
    })

    notify.promise(
        promiseWrapper,
        {
            pending: 'Password reset',
            success: 'A password reset email has been sent',
            error: 'Request failed',
        },
    )
}
