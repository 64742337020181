export enum TimeComparisonPeriod {
    MTD = 'mtd',
    PMMTD = 'pmmtd',
    PYMTD = 'pymtd',
    YTD = 'ytd',
    PYYTD = 'pyytd',
}

export const TIME_COMPARISON_PERIOD_LABELS: Record<TimeComparisonPeriod, string> = {
    [TimeComparisonPeriod.MTD]: 'MTD',
    [TimeComparisonPeriod.PMMTD]: 'Prior Month',
    [TimeComparisonPeriod.PYMTD]: 'Prior Year MTD',
    [TimeComparisonPeriod.YTD]: 'YTD',
    [TimeComparisonPeriod.PYYTD]: 'Prior Year YTD',
}
