import { Asset } from '@/models/asset'

import { AssetGroupWithAssetIds } from './AssetListSideModal.types'

export const getGroups = (options: Asset[]): AssetGroupWithAssetIds[] => {
    const groupsMap: Record<number, AssetGroupWithAssetIds> = options.reduce((acc, option) => {
        option.groups?.forEach((group) => {
            if (!group.id) { return }
            if (!acc[group.id]) {
                acc[group.id] = { ...group, assetIds: [] }
            }
            acc[group.id].assetIds.push(option.id)
        })
        return acc
    }, {})

    return Object.values(groupsMap)
}
