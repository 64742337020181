import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { Alert, Confirm } from '@/components/base'
import http from '@/http.service'
import { AsyncTaskStatus } from '@/models/AsyncTaskModel'
import { TrialBalanceTemplateApplyingTask } from '@/models/trial_balance_template'
import { notify } from '@/utils/notify'
import { setIntervalUntilAuthenticated } from '@/utils/setInterval'

import { CONFIRMATION_TEXT, APPLY_TEMPLATE_TASK_ENDPOINT, SUCCESS_TEXT, ERROR_TEXT } from './ApplyAllTemplatesModal.constants'
import { ApplyAllTemplatesModalProps, TBTemplateApplyTaskGroupResponse } from './ApplyAllTemplatesModal.types'

export const ApplyAllTemplatesModal = (props: ApplyAllTemplatesModalProps) => {
    const {
        bindings, onClose, onDone,
        confirmText = 'Apply All Templates',
        cancelText = 'Back',
        content = CONFIRMATION_TEXT,
        requestAllFields = false,
    } = props
    const { isAuthenticated } = useAuth0()
    const [isLoading, setIsLoading] = useState(false)
    const [taskGroupId, setTaskGroupId] = useState<string | null>(null)
    const [taskStatuses, setTaskStatuses] = useState<TrialBalanceTemplateApplyingTask[] | null>(null)

    const applyTemplates = async () => {
        setIsLoading(true)
        try {
            // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            const { data } = await http.post<TBTemplateApplyTaskGroupResponse>(
                `${APPLY_TEMPLATE_TASK_ENDPOINT}group/`,
                bindings.map(binding => binding.createApplyingTask().raw_data),
                { meta: { errorToast: ERROR_TEXT } },
            )
            setTaskGroupId(data.group_id)
        } catch {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!taskGroupId) { return }

        const intervalId = setIntervalUntilAuthenticated(async () => {
            try {
                // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                const { data } = await http.get<TrialBalanceTemplateApplyingTask[]>(
                    `${APPLY_TEMPLATE_TASK_ENDPOINT}?${requestAllFields ? '' : '__fields&'}group_id=${taskGroupId}`,
                    { meta: { errorToast: ERROR_TEXT } },
                )
                setTaskStatuses(data)
            } catch {
                clearInterval(intervalId)
                setIsLoading(false)
                setTaskGroupId(null)
                setTaskStatuses(null)
            }
        }, 2000, isAuthenticated)

        return () => clearInterval(intervalId)
    }, [taskGroupId, isAuthenticated, requestAllFields])

    useEffect(() => {
        if (!taskStatuses || !taskGroupId) { return }

        if (!taskStatuses?.length) {
            console.error('Applying tasks not found')
            return
        }

        const isAllDone = taskStatuses.every((task) => !task.inProgress)
        if (!isAllDone) { return }

        const isAnyFailed = taskStatuses.some(({ status }) => status === AsyncTaskStatus.FAILURE)
        if (isAnyFailed) {
            setIsLoading(false)
            setTaskGroupId(null)
            setTaskStatuses(null)
            notify.error(ERROR_TEXT)
            onClose()
            onDone?.(taskStatuses)
            return
        }

        const isAllSuccess = taskStatuses.every(({ status }) => status === AsyncTaskStatus.SUCCESS)
        if (isAllSuccess) {
            setIsLoading(false)
            setTaskGroupId(null)
            setTaskStatuses(null)
            notify.success(SUCCESS_TEXT)
            onClose()
            onDone?.(taskStatuses)
        }
    }, [taskStatuses, taskGroupId, onClose, onDone])

    return (
        <Confirm
            confirmText={confirmText}
            onConfirm={applyTemplates}
            cancelText={cancelText}
            onCancel={onClose}
            isLoading={isLoading}
            disableEscapeKeyDown
            disableOverlayClick
        >
            <Alert type='warning' content={content}/>
        </Confirm>
    )
}
