import { useMemo } from 'react'

import { useLocation, useNavigate } from 'react-router'

type UseQueryParamList = Record<string, string>
type UseQueryParamRes<T> = [params: T, updateParam: (...params: Array<{ key: string, val: string | number }>) => void]

/**
 * Helper hook to get and update query params
 *
 * @deprecated use package 'use-query-params' instead
 */
export const useQueryParam = <T = UseQueryParamList>(): UseQueryParamRes<Partial<T>> => {
    const location = useLocation()
    const navigate = useNavigate()

    const urlSearchParams = useMemo(() => (new URLSearchParams(location.search)), [location.search])
    const params = useMemo(() => (
        [...urlSearchParams.entries()].reduce((obj, [key, value]) => {
            obj[key] = value
            return obj
        }, {}) as unknown as T
    ), [location.search])

    const updateParams = (...params) => {
        params.forEach(({ key, val }) => urlSearchParams.set(key, val))
        navigate(location.pathname + '?' + urlSearchParams.toString())
    }

    return [params, updateParams]
}
