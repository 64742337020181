import { useState } from 'react'

import { QueryPage } from 'mobx-orm'
import { observer } from 'mobx-react-lite'
import { generatePath, useNavigate, useParams, useOutletContext } from 'react-router'

import { Alert, Confirm } from '@/components/base'
import { CompanyUserMode } from '@/core/modes'
import { TrialBalanceTemplateMap } from '@/models/trial_balance_template'
import { notify } from '@/utils/notify'
import { getRouteConfig } from '@/utils/routing/getRouteConfig'

import { EDIT_TEMPLATE_MESSAGES } from '../MappingTemplateEditPage.constants'

const returnPath = getRouteConfig('ASSET_MAPPING_TEMPLATES_BY_ID', CompanyUserMode.Owner).path

export const MappingTemplateDeleteMappingPage = observer(() => {
    const navigate = useNavigate()
    const { templateId, mappingId } = useParams()
    const { templateMapQuery } = useOutletContext<{ templateMapQuery: QueryPage<TrialBalanceTemplateMap> }>()

    const [isLoading, setIsLoading] = useState(false)

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const templateMap = TrialBalanceTemplateMap.get(parseInt(mappingId)) as TrialBalanceTemplateMap

    const goToTemplatePage = () => navigate(generatePath(returnPath, { templateId }), { replace: true })

    const removeMapping = async () => {
        try {
            setIsLoading(true)
            await templateMap.delete()
            notify.success(EDIT_TEMPLATE_MESSAGES.removed)
            templateMapQuery.load()
            goToTemplatePage()
        } catch (e) {
            // TODO: show errors
        }
        setIsLoading(false)
    }

    return (
        <Confirm
            confirmText='Remove Mapping'
            onConfirm={removeMapping}
            isLoading={isLoading}
            cancelText='Cancel'
            onCancel={goToTemplatePage}
            disableEscapeKeyDown
            disableOverlayClick
        >
            <Alert type='warning' content='Are you sure you want to remove this row?'/>
        </Confirm>
    )
})
