import { useEffect } from 'react'

import * as Sentry from '@sentry/react'

import { MODEL_NAME_BY_ID } from '@/core/modes'
import { useMe } from '@/hooks/core/useMe'

export const useUserDataInSentry = () => {
    const { me: { company, user, companyUserMode } } = useMe()

    useEffect(() => {
        Sentry.configureScope((scope) => {
            scope.setTag('companyName', company?.name ?? null)
            scope.setTag('companyId', company?.id ?? null)
            scope.setTag('mode', MODEL_NAME_BY_ID[companyUserMode] ?? null)
            scope.setUser({
                id: user?.id,
                email: user?.email,
                name: user?.fullName,
            })
        })
    }, [])
}
