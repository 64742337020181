import { useCallback, useEffect, useMemo, useState } from 'react'

import { BuiltInSearchInput, Button, Icon, Line, SelectOptionsGroupTitle } from '@/components/base'
import popoverStyles from '@/components/base/Popover/Popover.module.scss'
import { ListHeader } from '@/components/base/Select/headers'
import { SelectOptionListBase } from '@/components/base/Select/optionsList'
import { Layout, SideModalContainer } from '@/components/containers'
import { MAPPING_ENGINE_ONE_MODULE_WIDTH } from '@/components/widgets/MappingEngineSideModal/MappingEngineSideModal.constants'
import { Asset } from '@/models/asset'
import { generatedColors } from '@/styles/tokens/Tokens.constants'

import { isAssetArraysEqual, labelFn } from './AssetListSideModal.constants'
import { AssetGroupWithAssetIds, AssetListSideModalProps } from './AssetListSideModal.types'
import { getGroups } from './AssetListSideModal.utils'

/**
 * Side modal for selecting assets
 *
 * TODO: Use 'SideModalOptions' component inside
 */
export const AssetListSideModal = (props: AssetListSideModalProps) => {
    const {
        options, selected, onClose, onSave, multiSelect = false, required = false,
    } = props

    const [filterValue, setFilterValue] = useState<string>('')
    const [selectedOptions, setSelectedOptions] = useState<Asset[]>(selected)

    useEffect(() => setSelectedOptions(selected), [selected])

    const groups = useMemo(() => getGroups(options), [options])
    const hasGroups = useMemo(() => groups.length > 0, [groups])

    const selectedGroups = useMemo(() => {
        if (!groups.length) { return [] }
        const selectedAssetIds = selectedOptions.map((asset) => asset.id)
        return groups.filter((group) => group.assetIds.every((id) => selectedAssetIds.includes(id)))
    }, [groups, selectedOptions])

    const isSelectionChanged = useMemo(
        () => !isAssetArraysEqual(selectedOptions, selected),
        [selectedOptions, selected],
    )

    const headerText = useMemo(
        () => multiSelect
            ? selectedOptions.length
                ? `${selectedOptions.length} asset(s) selected` : 'Select 1 or more asset(s)'
            : 'Select an asset',
        [multiSelect, selectedOptions.length],
    )

    const filteredOptions = useMemo(
        () => {
            const filterRegex = new RegExp(filterValue, 'i')
            return options.filter((option) => !filterValue || filterRegex.test(labelFn(option)))
        },
        [filterValue, options],
    )

    const handleSelectOption = useCallback((option: Asset) => {
        const isSelected = selectedOptions.some((asset) => asset.id === option.id)

        if (multiSelect) {
            const newSelected = isSelected
                ? selectedOptions.filter((asset) => asset.id !== option.id)
                : [...selectedOptions, option]
            setSelectedOptions(newSelected)
        } else {
            setSelectedOptions(isSelected ? [] : [option])
        }
    }, [multiSelect, selectedOptions])

    const handleSelectGroup = useCallback((group: AssetGroupWithAssetIds) => {
        const assetsInGroup = options.filter((asset) => group.assetIds.includes(asset.id as number))
        const isSelected = selectedGroups.includes(group)

        if (isSelected) {
            const otherSelectedGroups = selectedGroups.filter((selectedGroup) => selectedGroup !== group)
            const assetsInOtherSelectedGroups = otherSelectedGroups
                .flatMap((group) => options.filter((asset) => group.assetIds.includes(asset.id as number)))
            const assetsToRemove = assetsInGroup.filter((asset) => !assetsInOtherSelectedGroups.includes(asset))
            setSelectedOptions(selectedOptions.filter((asset) => !assetsToRemove.includes(asset)))
        } else {
            setSelectedOptions([...new Set([...selectedOptions, ...assetsInGroup])])
        }
    }, [options, selectedGroups, selectedOptions])

    const handleSelectAll = useCallback(() => setSelectedOptions(options), [options])
    const handleClearAll = useCallback(() => setSelectedOptions([]), [])

    return (
        <SideModalContainer onBgClick={onClose}>
            <Layout
                gap={4}
                my={4}
                mr={4}
            >
                <Layout
                    className={popoverStyles.popover}
                    direction='column'
                    width={MAPPING_ENGINE_ONE_MODULE_WIDTH}
                    relative
                >
                    <ListHeader
                        content={[headerText]}
                        endContent={(
                            <Layout gap={8}>
                                <Icon
                                    name='cross'
                                    onClick={onClose}
                                    color='colorsSecondaryGrey600'
                                />
                            </Layout>
                        )}
                    />
                    <Layout py={4}>
                        <BuiltInSearchInput
                            onChange={(value) => setFilterValue(value)}
                            value={filterValue}
                            placeholder='Search'
                            dataTestId='search'
                        />
                    </Layout>
                    {multiSelect && (
                        <>
                            <Layout
                                style={{ backgroundColor: generatedColors.colorsSecondaryPurple25 }}
                                px={12}
                                py={8}
                                gap={12}
                                justify='space-between'
                            >
                                <Button
                                    theme='flat' text='Select All'
                                    onClick={handleSelectAll}
                                    dataTestId='selectAll'
                                />
                                <Button
                                    theme='flat' text='Clear All'
                                    onClick={handleClearAll}
                                    dataTestId='clearAll'
                                />
                            </Layout>
                            {hasGroups && (
                                <>
                                    <Line/>
                                    <SelectOptionsGroupTitle text='Groups'/>
                                    <Layout scroll='y' maxHeight='200px' flexShrink={0}>
                                        <SelectOptionListBase<AssetGroupWithAssetIds>
                                            width='100%'
                                            options={groups}
                                            selected={selectedGroups}
                                            labelFn={group => group.name ?? ''}
                                            idFn={(group) => group.id?.toString()}
                                            onChange={handleSelectGroup}
                                        />
                                    </Layout>
                                    <Line/>
                                    <SelectOptionsGroupTitle text='Assets'/>
                                </>
                            )}
                        </>
                    )}
                    <Layout flexGrow={1} scroll='y' minHeight='200px'>
                        <SelectOptionListBase<Asset>
                            width='100%'
                            options={filteredOptions}
                            selected={selectedOptions}
                            labelFn={labelFn}
                            idFn={(asset) => asset?.id?.toString()}
                            onChange={handleSelectOption}
                        />
                    </Layout>
                    <Line/>
                    <Layout px={12} py={8} justify='space-between'>
                        <Button
                            text='Reset'
                            theme='flat'
                            disabled={!isSelectionChanged}
                            onClick={() => setSelectedOptions(selected)}
                        />
                        <Layout flexGrow={1}/>
                        <Button
                            disabled={selectedOptions.length === 0 && required}
                            text='Apply'
                            onClick={() => onSave(selectedOptions)}
                        />
                    </Layout>
                </Layout>
            </Layout>
        </SideModalContainer>
    )
}
