import * as Sentry from '@sentry/react'
import format from 'date-fns/format'

import {
    DATE_FNS_DEFAULT_FORMAT_FULL_DATE,
    DATE_FNS_DEFAULT_FORMAT_FULL_DATE_TIME,
    DATE_FNS_DEFAULT_FORMAT_MONTH_FULL,
} from '@/constants/dates'

/**
 * Returns date in format 12/26/2022 using date-fns
 */
export const formatDate = (date: string | Date) => {
    // TODO: Delete after fixing the issue https://intelas.sentry.io/issues/5712622237
    Sentry.configureScope(scope => {
        scope.setExtra('formatDateParam', date)
    })

    return format(new Date(date), DATE_FNS_DEFAULT_FORMAT_FULL_DATE)
}

/**
 * Returns date in format of 7/27/2023 10:00 AM
 */
export const formatDateTime = (date: string | Date) => {
    return format(new Date(date), DATE_FNS_DEFAULT_FORMAT_FULL_DATE_TIME)
}

/**
 * Returns date in format of February 2022
 */
export const formatMonthFull = (date: string | Date) => {
    return format(new Date(date), DATE_FNS_DEFAULT_FORMAT_MONTH_FULL)
}
