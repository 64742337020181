import { getRequestQueries } from '@/api/utils/getRequestQueries'

export interface ReportingReportItem {
    id: number
    name: string
    /**
     * If 'parent_id' exists, 'company_id' is not required
     * Need to be declared if there is no parent
     */
    company_id?: number
    parent_id?: number
    table_ids: number[]
    updated_at: string
    order: number
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchReportingReportQueryParams {}

export const {
    useItemsQuery: useReportingReportQuery,
    useItemUpdateMutation: useReportingReportUpdateMutation,
    useItemCreateMutation: useReportingReportCreateMutation,
    useItemsCountQuery: useReportingReportCountQuery,
    useItemDeleteMutation: useReportingReportDeleteMutation,
    useItemByIdQuery: useReportingReportByIdQuery,
} = getRequestQueries<ReportingReportItem, FetchReportingReportQueryParams>({
    url: '/reporting/report',
    errorMessageUpdate: 'Failed to update report',
    errorMessageDelete: 'Failed to delete report',
    errorMessageCreate: 'Failed to create report',
})
