import { useState } from 'react'

import { useAssetByIdQuery } from '@/api/asset/asset'
import { useTrialBalanceLedgerByIdQuery } from '@/api/ledger/trialBalanceLedger'
import { useTrialBalanceUnmappedQuery } from '@/api/ledger/trialBalanceUnmapped'
import { Button, Text } from '@/components/base'
import { Layout } from '@/components/containers'
import { AccordionContainer } from '@/components/containers/AccordionContainer'

import styles from './TrialBalanceUnmappedAlert.module.scss'
import { TrialBalanceUnmappedAlertProps } from './TrialBalanceUnmappedAlert.types'

export const TrialBalanceUnmappedAlert = (props: TrialBalanceUnmappedAlertProps) => {
    const { assetId, ledgerId, onUpdateClick } = props
    const [isOpened, setIsOpened] = useState(false)

    const trialBalanceUnmappedQuery = useTrialBalanceUnmappedQuery({
        queryParams: {
            asset_id: assetId,
            ledger_id: ledgerId,
        },
    }, {
        enabled: Boolean(assetId && ledgerId),
    })

    const ledgerQuery = useTrialBalanceLedgerByIdQuery(ledgerId)
    const assetQuery = useAssetByIdQuery(assetId)

    const haveWarnings = Boolean(ledgerId && assetId && ledgerQuery.isFetched && assetQuery.isFetched && trialBalanceUnmappedQuery.isFetched && trialBalanceUnmappedQuery.data?.length)

    return (
        <AccordionContainer open={haveWarnings}>
            {haveWarnings && (
                <div className={styles.alert}>
                    <div>
                        <div className={styles.message}>
                            {
                                ledgerQuery.data?.is_custom && (
                                    <>
                                        <Text variant='smallTextMediumDefault'>
                                            Note: Unmapped accounts and values are missing from the report.
                                            The following {trialBalanceUnmappedQuery.data?.length} source accounts require mapping in the ledger {ledgerQuery.data.name}.
                                        </Text>
                                        &nbsp;
                                        <Button
                                            onClick={onUpdateClick}
                                            text='Click here to update mappings'
                                            theme='flat'
                                        />
                                    </>
                                )
                            }
                            {
                                ledgerQuery.data && !ledgerQuery.data.is_custom && (
                                    <Text variant='smallTextMediumDefault'>
                                        Note: Unmapped accounts and values are missing from the report.
                                        The following {trialBalanceUnmappedQuery.data?.length} source accounts are being mapped to the Intelas Ledger and will be finalized soon.
                                    </Text>
                                )
                            }
                        </div>
                        <div className={styles.accounts}>
                            <AccordionContainer open={isOpened} className={styles.accordion}>
                                <Layout direction='column' gap={4}>
                                    {trialBalanceUnmappedQuery.data?.map((account, ind) => (
                                        <Text variant='smallTextMediumDefault' key={ind}>({account.code}) {account.name}</Text>
                                    ))}
                                </Layout>
                            </AccordionContainer>
                        </div>
                        {
                            (!isOpened && Boolean(trialBalanceUnmappedQuery.data?.length)) && (
                                <Button
                                    text='Show all unmapped accounts' onClick={() => { setIsOpened(true) }} theme='flat'
                                    icon='arrowDown'
                                />
                            )
                        }
                        {
                            (isOpened && Boolean(trialBalanceUnmappedQuery.data?.length)) && (
                                <Button
                                    text='Less accounts' onClick={() => { setIsOpened(false) }} theme='flat'
                                    icon='arrowUp'
                                />
                            )
                        }
                    </div>
                </div>
            )}
        </AccordionContainer>
    )
}
