import cn from 'classnames'

import { Icon } from '@/components/base/Icon'
import { SelectTriggerComponent } from '@/components/base/Select'
import { InputBox } from '@/components/containers'

import styles from './SelectTriggerBase.module.scss'

export const SelectTriggerBase: SelectTriggerComponent = (props) => {
    const {
        children = null,
        open = false,
        icon = null,
        testId,
        readonly = false,
        ...rest
    } = props

    const triggerIconClassName = cn(styles.triggerIcon, {
        [styles.triggerIcon_open]: open,
    })

    return (
        <InputBox
            isActive={open}
            {...rest}
        >
            <button
                className={styles.button}
                data-testid={testId}
                disabled={readonly}
            >
                {icon && (
                    <Icon
                        name={icon}
                        className={styles.customIcon}
                        color='colorsSecondaryGrey600'
                    />
                )}
                <div className={styles.childrenContainer}>{children}</div>
                {!readonly && (
                    <Icon
                        className={triggerIconClassName}
                        name='arrowDown'
                        color={open ? 'colorsPrimaryPurple' : 'colorsSecondaryGrey600'}
                    />
                )}

            </button>
        </InputBox>
    )
}
