import { getRequestQueries } from '@/api/utils/getRequestQueries'
import { Asset } from '@/models/asset'
import { DataPointError, DataPointSource, DataPointStatus, DataPointType } from '@/models/data_pulling'
import { SubmittedFile } from '@/models/data_submission'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchDataPointQueryParams {}

export interface DataPointItem {
    id: number
    etl_data_point_id?: number
    submitted_file_id?: number
    status?: DataPointStatus
    type?: DataPointType
    asset_id?: number
    report_date?: string
    as_of_date?: string
    as_of_date_from_file?: string
    s3_bucket_name?: string
    s3_file_name?: string
    initial_file_name?: string
    etl_created_at?: string
    etl_finished_at?: string
    is_canceled?: boolean
    created_at: string
    web_triggered_at?: string
    finished_at?: string
    source?: DataPointSource
    errors_num?: number
    submission_errors_num?: number
    quality_errors_num?: number
    /**
     * Email from which the data was uploaded
     */
    source_email?: string
    errors: DataPointError[]
    submitted_file: SubmittedFile
    active_children: DataPointItem[]
    parent?: { submitted_file?: SubmittedFile }
    asset?: Asset
}

export const {
    useItemsQuery: useDataPointQuery,
    useItemByIdQuery: useDataPointByIdQuery,
    useItemUpdateMutation: useDataPointUpdateMutation,
    useItemsCountQuery: useDataPointCountQuery,
} = getRequestQueries<DataPointItem, FetchDataPointQueryParams>({
    url: 'data-point',
    errorMessageUpdate: 'Failed to update data point',
    errorMessageCreate: 'Failed to create data point',
})
