import { FilterTreeItem } from './FilterTree.types'

/**
 * @param items - array of FilterTreeItem
 * @returns -1 if none are selected, 0 if some are selected, 1 if all children are selected
 */
export const getSelectionInChildren = (items: FilterTreeItem[], selectedIds: Set<string | number>): -1 | 0 | 1 => {
    if (!items.length) { return -1 }
    let childrenCount = 0
    let selectedChildrenCount = 0

    const helper = (items: FilterTreeItem[]) => {
        items.forEach((item) => {
            if (item.children?.length) {
                helper(item.children)
            } else {
                childrenCount++
                if (selectedIds.has(item.id)) {
                    selectedChildrenCount++
                }
            }
        })
    }
    helper(items)

    return selectedChildrenCount === 0 ? -1 : selectedChildrenCount === childrenCount ? 1 : 0
}
