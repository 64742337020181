import { FC, useState } from 'react'

import Dialog from '@mui/material/Dialog'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'

import { Icon, Loader } from '@/components/base'
import { ObjectFormLegacy } from '@/components/legacy/models/ObjectFormLegacy'

import styles from './ObjectFormPopup.module.scss'
import { ObjectFormPopupProps } from './ObjectFormPopup.types'

export const ObjectFormPopup: FC<ObjectFormPopupProps> = observer((props: ObjectFormPopupProps) => {
    const { fields, title, obj, onClose, onSuccess, closePath, visible = true } = props
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()

    const closeDialog = (isSubmitted) => {
        if (!isLoading) {
            onClose?.(obj, isSubmitted)
            closePath && (navigate(closePath))
        }
    }

    const handleSubmit = (obj) => {
        closeDialog(true)
        onSuccess?.(obj)
    }

    if (!visible) return null

    return (
        <Dialog
            open
            onClose={closeDialog}
            style={{ backgroundColor: 'rgb(92, 92, 229, 0.05)' }}
            classes={{
                paper: styles.dialogPaper,
            }}
        >
            <div className='page-dialog-content'>
                {
                    isLoading &&
                    <div className='page-dialog-overlay'><div><Loader/></div></div>
                }
                <div className='header'>
                    <div className='title'>{title}</div>
                    <div className='sep'/>
                    <div className='icon-close' onClick={() => { closeDialog(false) }}><Icon name='cross'/></div>
                </div>
                <div className='body'>
                    <ObjectFormLegacy
                        obj={obj}
                        fields={fields}
                        spinnerOff
                        onLoading={setIsLoading}
                        onCancel={() => { closeDialog(false) }}
                        onSubmit={handleSubmit}
                    />
                </div>
            </div>
        </Dialog>
    )
})
