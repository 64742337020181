import { model, field, many, foreign } from 'mobx-orm'

import { HistoryModel } from '@/models/core/HistoryModel'

import { CompanyUserGroupInLedger } from './CompanyUserGroupInLedger'
import { TrialBalanceLedger as Ledger } from './Ledger'
import { api } from '../../http-adapter'
import { CompanyUserGroup } from '../company'
import { AccessLevel } from '../core'

@api('company-user-group-in-ledger-history')
@model
export class CompanyUserGroupInLedgerHistory extends HistoryModel {
    @field readonly company_user_group_in_ledger_id: number
    @field readonly ledger_id:                       number
    @field readonly level:                           number
    @field readonly company_user_group_id:           number

    @foreign(Ledger) readonly ledger:                       Ledger
    @foreign(CompanyUserGroup) readonly company_user_group: CompanyUserGroup

    get level_name (): string {
        return AccessLevel[this.level]
    }
}
many(CompanyUserGroupInLedgerHistory, 'company_user_group_in_ledger_id')(CompanyUserGroupInLedger, 'history')
