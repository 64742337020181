import React from 'react'

import { observer } from 'mobx-react-lite'

import { CompanyNewInvitePopup } from '@/pages/MainPage/CommonPages/CompanyInvitesPage'

interface CompanyProfileNewInvitePopupProps {
    backPath: string
}

export const CompanyProfileNewInvitePopup = observer((props: CompanyProfileNewInvitePopupProps) => {
    const { backPath } = props

    return (
        <CompanyNewInvitePopup backPath={backPath}/>
    )
})
