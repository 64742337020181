import { observer } from 'mobx-react-lite'

import { Text } from '@/components/base'

import { AgGridPaginationLeftPanelProps } from './AgGridPaginationLeftPanel.types'

export const AgGridPaginationLeftPanel = observer((props: AgGridPaginationLeftPanelProps) => {
    const { className, query } = props

    const firstElemOnPage = query.offset + 1
    let lastElemOnPage = query.offset + query.limit

    if (lastElemOnPage > query.total) {
        lastElemOnPage = query.total
    }

    return (
        <Text
            variant='labelMediumDefault' color='secondaryLightBlack'
            className={className}
        >
            {firstElemOnPage}-{lastElemOnPage} of {query.total}
        </Text>
    )
})
