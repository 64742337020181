import { createEnumParam } from 'use-query-params'

import { TimeInterval, TIME_INTERVAL_LABELS } from '@/constants/timeInterval'

const TimeIntervalParam = createEnumParam(Object.values(TimeInterval))

export const TIME_INTERVAL_INPUT_QUERY_PARAM = {
    type: TimeIntervalParam,
    name: 'time-interval',
}

export const TIME_INTERVAL_OPTIONS = Object.entries(TIME_INTERVAL_LABELS).map(([value, label]) => ({ value, label }))
