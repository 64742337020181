import { model, field } from 'mobx-orm'

import { api } from '../../http-adapter'
import { Model } from '../model'

// TODO: MobxORM need to ReadOnlyModel
// TODO: MobxORM don't need to observe everything, data should come to non-field also
@api('trial-balance-map')
@model
export class TrialBalanceMap extends Model {
    @field code?:       string
    @field name?:       string
    @field asset_name?: string

    @field intelas_account_map_id?: number
    @field intelas_account_id?:     number
    @field intelas_account_code?:   string
    @field intelas_account_name?:   string

    @field custom_account_map_id?: number
    @field custom_account_id?:     number
    @field custom_account_code?:   string
    @field custom_account_name?:   string
}
