import { useEffect, useState } from 'react'

import { Select, SelectOptionDefault } from '@/components/base'
import { useInputState } from '@/hooks'

import { SelectInputProps } from './SelectInput.types'

const idFn = (option: SelectOptionDefault) => option?.value?.toString?.()

/**
 * Simple select input works with default option type
 * {@link SelectOptionDefault}
 */
export const SelectInput = (props: SelectInputProps) => {
    const {
        testId,
        required = false,
        paramName,
        paramType,
        options,
        multiSelect,
        syncLocalStorage,
        ...rest
    } = props

    const [param, setParam] = useInputState({ name: paramName, type: paramType, syncLocalStorage })
    const [selected, setSelected] = useState<SelectOptionDefault[]>([])

    useEffect(() => {
        if (!options?.length) {
            setSelected([])
            return
        }

        const selectedIds = (multiSelect ? param as unknown[] : [param]) || []
        const selectedOptions = options.filter((option) => selectedIds.includes(idFn(option)))

        setSelected(selectedOptions)

        // Need this to reset selected value in the case if options are changed and selected value is not in the new options
        if (selectedOptions.length < selectedIds.length) {
            setParam(selectedOptions.map(option => idFn(option)))
        }
    }, [param, options, setParam])

    return (
        <Select
            testId={testId}
            idFn={idFn}
            options={options}
            selected={selected}
            onChange={(options) => {
                if (options.length) {
                    multiSelect
                        ? setParam(options.map((option) => idFn(option)))
                        : setParam(idFn(options[0]))
                } else {
                    setParam(multiSelect ? [] : undefined)
                }
            }}
            error={required && selected.length === 0}
            multiSelect={multiSelect}
            {...rest}
        />
    )
}
