import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'

import { ErrorPageBase } from '@/components/base'

import { InternalForbiddenPageProps } from './InternalForbiddenPage.types'

export const InternalForbiddenPage = observer((props: InternalForbiddenPageProps) => {
    const { className, rounded, type = 'internal' } = props

    const navigate = useNavigate()

    const handleButtonClick = () => {
        navigate('/')
    }

    return (
        <ErrorPageBase
            title='Page Forbidden'
            text='We are sorry, but you do not have access to this page'
            onButtonClick={handleButtonClick}
            buttonText='Back to home page'
            type={type}
            errorName='403 error'
            errorText='403'
            className={className}
            rounded={rounded}
        />
    )
})
