import { observer } from 'mobx-react-lite'

import styles from './AgGridTableNoRows.module.scss'

export const AgGridTableNoRows = observer((props: any) => {
    return (
        <div className={styles.agGridTableNoRows}>
            <div className={styles.background}/>
            <div> {props.noRowsMessageFunc()} </div>
        </div>
    )
})
