import { useEffect, useMemo, useState } from 'react'

import { Checkbox, Icon } from '@/components/base'
import { Layout, LayoutSpace } from '@/components/containers'

import styles from './FilterTreeSearch.module.scss'
import { FilterTreeSearchProps } from './FilterTreeSearch.types'
import { filterTreeItems } from './FilterTreeSearch.utils'
import { FilterTreeItem } from '../FilterTree/FilterTree.types'
import { getSelectionInChildren } from '../FilterTree/FilterTree.utils'

export const FilterTreeSearch = (props: FilterTreeSearchProps) => {
    const { items, selected, expandAll, onExpand, onFilter, onChange } = props

    const [searchText, setSearchText] = useState<string>('')
    const [filteredItems, setFilteredItems] = useState<FilterTreeItem[]>([])

    useEffect(() => {
        if (!searchText) {
            onFilter?.(items ?? [])
            setFilteredItems(items ?? [])
            return
        }

        const filtered = filterTreeItems(items, searchText)
        onFilter?.(filtered)
        setFilteredItems(filtered)
    }, [items, searchText])

    const selectedIds = useMemo(() => new Set(selected), [selected])
    const childrenSelection = getSelectionInChildren(filteredItems ?? [], selectedIds)

    const changeSelection = () => {
        const newSelected = new Set(selectedIds)

        const changeChildrenSelection = (items: FilterTreeItem[], selectAll: boolean) => {
            items.forEach((item) => {
                if (item.children?.length) {
                    changeChildrenSelection(item.children, selectAll)
                } else {
                    if (selectAll) {
                        newSelected.add(item.id)
                    } else {
                        newSelected.delete(item.id)
                    }
                }
            })
        }

        if (childrenSelection === 1) {
            changeChildrenSelection(filteredItems ?? [], false)
        } else {
            changeChildrenSelection(filteredItems ?? [], true)
        }

        onChange?.([...newSelected])
    }

    return (
        <Layout gap={6 as LayoutSpace} align='center'>
            <Icon
                name={expandAll ? 'arrowDown' : 'arrowRight'}
                size='s'
                onClick={() => onExpand?.(!expandAll)}
            />
            <Checkbox
                size='s'
                checked={childrenSelection === 1}
                indeterminate={!childrenSelection}
                onChange={changeSelection}
                dataTestId='checkbox-search'
            />
            <input
                type='text'
                placeholder='Search...'
                className={styles.inputField}
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
                data-testid='search-input'
            />
        </Layout>
    )
}
