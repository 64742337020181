import { useEffect } from 'react'

import * as Sentry from '@sentry/react'
import { useLocation } from 'react-router'
import { createRoutesFromChildren, matchRoutes, useNavigationType } from 'react-router-dom'

const ignoreSentryItems = [
    // Downloading is broken on preprod in the case if file was uploaded on prod
    {
        url: '/api/data-pulling/download/',
        code: 500,
        active: process.env.NODE_ENV !== 'preprod',
    },
    {
        url: ' /api/data-pulling/download/',
        code: 500,
        active: process.env.NODE_ENV !== 'preprod',
    },
]

export const sentryInit = () => {
    const sentryUrl = process.env.SENTRY_ENDPOINT

    if (sentryUrl) {
        Sentry.init({
            dsn: sentryUrl,
            environment: process.env.ENVIRONMENT,
            integrations: [
                new Sentry.BrowserProfilingIntegration(),
                new Sentry.BrowserTracing({
                    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                        useEffect,
                        useLocation,
                        useNavigationType,
                        createRoutesFromChildren,
                        matchRoutes,
                    ),
                }),
                new Sentry.Replay(),
            ],
            release: process.env.RELEASE_VERSION,
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            beforeSend (event) {
                // TODO: Need for debug. Delete after 1 october 2024.
                // eslint-disable-next-line no-console
                console.log('sentry event', event)

                const pathname = event.request?.url?.length ? new URL(event.request.url).pathname : ''

                const exception = event.exception?.values?.[0]
                for (const item of ignoreSentryItems) {
                    if (item.active && pathname.startsWith(item.url) && exception && exception.type === 'Error' && exception.value?.includes(item.code.toString())) {
                        return null
                    }
                }

                return event
            },
        })
    }

    window.addEventListener('visibilitychange', () => {
        const isTabOpen = document.visibilityState === 'visible'

        Sentry.configureScope(function (scope) {
            scope.setTag('isTabOpen', isTabOpen)
        })
    })
}
