import { useEffect, useMemo, useState } from 'react'

import { Tooltip } from '@mui/material'
import format from 'date-fns/format'
import { EQ } from 'mobx-orm'
import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router'

import { useAssetsQuery } from '@/api/asset/asset'
import { useDataPointByIdQuery } from '@/api/dataPulling/dataPoint'
import { useDataPointErrorQuery } from '@/api/dataPulling/dataPointError'
import { Alert, Button, Icon, SideModal, Text } from '@/components/base'
import { ASSET_SIDE_MODAL_INPUT_QUERY_PARAM, ASSET_TYPE_SELECT_INPUT_QUERY_PARAM } from '@/components/models/asset'
import { ReactQueryTable } from '@/components/reactQuery'
import { AgGridTableProps } from '@/components/tables/AgGridTable'
import { STRING_COMPARATOR } from '@/components/tables/AgGridTable/AgGridTable.constants'
import {
    CUSTOM_LEDGER_ID_QUERY_PARAM,
} from '@/components/widgets/mappings/MappingTrialBalance/MappingTrialBalance.constants'
import { RouterConfigKeys } from '@/core/routes'
import { useMe } from '@/hooks/core/useMe'
import http from '@/http.service'
import { DataPointSource, DataPointStatus, DataPointType } from '@/models/data_pulling'
import { UserName } from '@/models/user/UserName'
import { DataSubmissionInternalErrorMessage } from '@/pages/MainPage/CommonPages/DataSubmissionPage'
import {
    UNMAPPED_ONLY_QUERY_PARAM,
} from '@/pages/MainPage/UserPages/MappingPage/MappingRentRollPage/MappingRentRollPage.constants'
import { normalizeShortBackDate } from '@/utils/date/normalizeShortBackDate'
import { getRoute } from '@/utils/routing/getRoute'

import styles from './FileErrorsSideDataPointModal.module.scss'
import {
    FileErrosSideDataPointModalProps,
} from './FileErrorsSideDataPointModal.types'

/**
 * Uses 'data_point_id' as in input parameter and shows ONLY data point errors (no data-submision errors)
 */
export const FileErrorsDataPointSideModal = observer((props: FileErrosSideDataPointModalProps) => {
    const { closePathKey } = props

    const { me } = useMe()

    const { dataPointId } = useParams()

    const navigate = useNavigate()

    const assetQuery = useAssetsQuery()
    const { data: dataPoint } = useDataPointByIdQuery(dataPointId)
    const dataPointErrorQuery = useDataPointErrorQuery({
        filter: EQ('data_point_id', dataPoint?.id),
    })

    const submissionErrorsNum = dataPoint?.submission_errors_num
    const qualityErrorsNum = dataPoint?.quality_errors_num

    let tableTitle: string | null = null
    let titleHint: string | null = null

    if (dataPoint?.status === DataPointStatus.ERROR) {
        tableTitle = 'Submission Errors (1)'
    } else if (submissionErrorsNum) {
        tableTitle = `Submission Errors (${submissionErrorsNum})`
        titleHint = 'Helps identify reasons for failed submissions. Possible errors include incorrect file format, invalid asset name, invalid characters, or unrecognized data. All submission errors must be resolved before data is accepted by the system.'
    } else if (qualityErrorsNum) {
        tableTitle = `Data Quality Alerts (${qualityErrorsNum})`
        titleHint = 'Data quality alerts identify potential irregularities in the submitted data set. Data has been accepted by the system, but we encourage you to review the list of alerts and determine if any changes need to be made to the underlying data.'
    }

    const [unmappedLedgersIds, setUnmappedLedgersIds] = useState([])
    const loadUnmappedLedgersIds = async () => {
        if (dataPoint?.id) {
            const urlParams = new URLSearchParams({ dataPointId: dataPoint?.id.toString() })
            // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            const response = await http.get(
                `data-pulling/data-point-unmapped-ledgers?${urlParams}`,
                { headers: me.isAdministratorMode ? {} : { 'company-user-id': `${me.companyUser.id}` } },
            )
            setUnmappedLedgersIds(response.data.ledgers_ids)
        }
    }

    useEffect(() => {
        loadUnmappedLedgersIds()
    }, [dataPointErrorQuery.data])

    const closePath = `${getRoute(closePathKey)}?${new URLSearchParams(window.location.search)}`

    const user = useMemo(() => {
        if (dataPoint?.source === DataPointSource.EMAIL) return dataPoint.source_email
        if (dataPoint?.submitted_file?.company_user_id) return <UserName companyUserId={dataPoint?.submitted_file.company_user_id}/>

        return '—'
    }, [dataPoint])

    if (!dataPoint) {
        return (
            <SideModal
                closePath={closePath}
                width={700}
                haveCloseButton
                title='Submitted file details is loading'
                isLoading
            />
        )
    }

    const asset = assetQuery.data?.find(asset => asset.id === dataPoint.asset_id)

    const navigateToMappingPage = (ledger_id) => {
        const isTrialBalanceType = dataPoint.type === DataPointType.TRIAL_BALANCE
        const routeName: RouterConfigKeys = isTrialBalanceType ? 'ASSET_MAPPING_TRIAL_BALANCE' : 'ASSET_MAPPING_RENT_ROLL'

        navigate(getRoute(routeName, {}, {
            [ASSET_SIDE_MODAL_INPUT_QUERY_PARAM.name]: dataPoint.asset_id ?? '',
            [ASSET_TYPE_SELECT_INPUT_QUERY_PARAM.name]: asset?.type || '',
            [CUSTOM_LEDGER_ID_QUERY_PARAM.name]: String(ledger_id),
            [UNMAPPED_ONLY_QUERY_PARAM.name]: 1,
        }))
    }

    return (
        <SideModal
            closePath={closePath}
            width={700}
            haveCloseButton
            title={dataPoint.initial_file_name}
            titleComponent={(
                <div className={styles.head}>
                    <div className={styles.headRow}>
                        <div className={styles.headRowCell}>
                            <div className={styles.headRowCellTitle}>
                                <Text variant='smallTextMediumDefault' color='secondaryLightBlack'>Report date:</Text>
                            </div>
                            <div className={styles.headRowCellValue}>
                                <Text variant='smallTextSemiboldDefault' color='secondaryBlack'>
                                    {
                                        dataPoint.report_date
                                            ? format(
                                                normalizeShortBackDate(dataPoint.report_date),
                                                dataPoint.type === DataPointType.RENT_ROLL ? 'MMM d yyyy' : 'MMM yyyy')
                                            : '-'
                                    }
                                </Text>
                            </div>
                        </div>
                        <div className={styles.headRowCell}>
                            <div className={styles.headRowCellTitle}>
                                <Text variant='smallTextMediumDefault' color='secondaryLightBlack'>Uploaded At:</Text>
                            </div>
                            <div className={styles.headRowCellValue}>
                                <Text variant='smallTextSemiboldDefault' color='secondaryBlack'>
                                    {dataPoint.created_at && format(new Date(dataPoint.created_at), 'MMM d yyyy, h:mm a')}
                                </Text>
                            </div>
                        </div>
                    </div>
                    <div className={styles.headRow}>
                        <div className={styles.headRowCell}>
                            <div className={styles.headRowCellTitle}>
                                <Text variant='smallTextMediumDefault' color='secondaryLightBlack'>Asset:</Text>
                            </div>
                            <div className={styles.headRowCellValue}>
                                <Text variant='smallTextSemiboldDefault' color='secondaryBlack'>{asset?.name || '—'}</Text>
                            </div>
                        </div>
                        <div className={styles.headRowCell}>
                            <div className={styles.headRowCellTitle}>
                                <Text variant='smallTextMediumDefault' color='secondaryLightBlack'>Uploaded By:</Text>
                            </div>
                            <div className={styles.headRowCellValue}>
                                <Text variant='smallTextSemiboldDefault' color='secondaryBlack'>
                                    {user}
                                </Text>
                            </div>
                        </div>
                    </div>
                    <div className={styles.headRow}>
                        <div className={styles.headRowCell}>
                            <div className={styles.headRowCellTitle}>
                                <Text variant='smallTextMediumDefault' color='secondaryLightBlack'>Company:</Text>
                            </div>
                            <div className={styles.headRowCellValue}>
                                <Text variant='smallTextSemiboldDefault' color='secondaryBlack'>
                                    {
                                        dataPoint.submitted_file?.company_user
                                            ? dataPoint.submitted_file?.company_user.company.name
                                            : 'Intelas'
                                    }
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        >
            <div className={styles.modalContent}>
                <div className={styles.contentTopPane}>
                    {
                        titleHint && (
                            <Tooltip
                                title={titleHint} arrow
                                placement='top-end'
                            >
                                <span className={styles.infoIconContainer}>
                                    <Icon
                                        name='info' color='colorsSecondaryPurple700'
                                        size='l'
                                    />
                                </span>
                            </Tooltip>
                        )
                    }
                    <Text
                        block
                        text={tableTitle}
                        color='secondaryBlack'
                        variant='smallTextSemiboldDefault'
                    />
                    <div className={styles.sep}/>
                    {
                        ((dataPoint.status !== DataPointStatus.ERROR) && !!unmappedLedgersIds.length) && (
                            <Button
                                text='Update Mappings'
                                theme='alert'
                                onClick={() => navigateToMappingPage(unmappedLedgersIds[0])}
                            />
                        )
                    }
                </div>
                {dataPoint.status === DataPointStatus.INTERNAL_ERROR && (
                    <Alert
                        content={<DataSubmissionInternalErrorMessage/>}
                        type='error'
                        flexGrow={0}
                    />
                )}
                {(dataPoint.status !== DataPointStatus.INTERNAL_ERROR && Boolean(submissionErrorsNum || qualityErrorsNum)) && (
                    <ReactQueryTable
                        query={dataPointErrorQuery}
                        hideStatusBar
                        {...gridOptions}
                    />
                )}
            </div>
        </SideModal>
    )
})

const gridOptions: Omit<AgGridTableProps, 'query' | 'items'> = {
    context: {},
    columnTypes: {
        place: { width: 100 },
        error_text: {
            width: 900,
            cellRenderer: (params) => {
                return <div className={styles.errorTextCell}>{params.data.error_text}</div>
            },
        },
    },
    defaultColDef: {
        resizable: false,
        suppressHeaderMenuButton: true,
        wrapText: true,
        autoHeight: true,
        comparator: STRING_COMPARATOR,
    },
    columnDefs: [
        { field: 'place', headerName: 'Place', type: 'place', width: 100 },
        { field: 'error_text', headerName: 'Error', type: 'error_text' },
    ],
}
