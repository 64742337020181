import { observer } from 'mobx-react-lite'

import { Text } from '@/components/base'

import { UploadToastMessageProps } from './UploadToastMessage.types'

export const UploadToastMessage = observer((props: UploadToastMessageProps) => {
    const { fileName, message } = props

    return (
        <div>
            <Text text={fileName} variant='smallTextSemiboldDefault'/>
            <br/>
            <Text variant='smallTextMediumDefault' text={message}/>
        </div>
    )
})
