import { tableCellTextStyle1 } from '@/components/legacy/tables/AgGridTableLegacy/AgGridTableLegacy.constants'
import { STRING_COMPARATOR } from '@/components/tables/AgGridTable/AgGridTable.constants'
import styles from '@/pages/MainPage/CommonPages/ValidationTrackerPage/DataPointSideModal/FileInfo/FileInfo.module.scss'

export const gridOptions = {
    context: {},
    columnTypes: {
        place: { width: 100 },
        error_text: {
            width: 900,
            cellRenderer: (params) => {
                return <div className={styles.errorTextCell}>{params.data.error_text}</div>
            },
        },
    },
    defaultColDef: {
        resizable: true,
        suppressHeaderMenuButton: true,
        wrapText: true,
        autoHeight: true,
        comparator: STRING_COMPARATOR,
        ...tableCellTextStyle1,
    },
    columnDefs: [
        { field: 'place', headerName: 'Row #', type: 'place', cellContentAlignVertical: true },
        { field: 'error_text', headerName: 'Error', type: 'error_text', cellContentAlignVertical: true },
    ],
    sortModel: {},
}
