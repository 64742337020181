import { FilterTreeItem } from '../FilterTree/FilterTree.types'

/**
 * Returns a copy of filtered tree items (by children and parent names).
 */
export const filterTreeItems = (items: FilterTreeItem[] = [], text: string): FilterTreeItem[] => {
    if (!items.length) {
        return []
    }

    const helper = (items: FilterTreeItem[], text: string, includeChildren = false): FilterTreeItem[] => {
        return items.map((item) => {
            const isParentMatch = item.name.toLowerCase().includes(text.toLowerCase())
            const children = item.children ? helper(item.children, text, isParentMatch || includeChildren) : undefined
            const hasChildren = Boolean(children?.length)
            const isMatch = item.name.toLowerCase().includes(text.toLowerCase()) || hasChildren || isParentMatch || includeChildren

            return {
                ...item,
                children: isMatch ? children : undefined,
            }
        }).filter((item) => item.children || item.name.toLowerCase().includes(text.toLowerCase()))
    }
    return helper(items, text)
}
