import { useEffect, useState } from 'react'

import { ButtonGroup } from '@/components/base'
import { useInputState } from '@/hooks'

import { BUTTON_GROUP_INPUT_QUERY_PARAM } from './ButtonGroupInput.constants'
import { ButtonGroupInputProps } from './ButtonGroupInput.types'
const queryParamType = BUTTON_GROUP_INPUT_QUERY_PARAM.type

export const ButtonGroupInput = (props: ButtonGroupInputProps) => {
    const { paramName, options, ...rest } = props
    const stringKeyOptions = (options ?? []).map(({ title, key }) => ({ title, key: key.toString() }))

    const [param, setParam] = useInputState({ name: paramName, type: queryParamType })
    const [selected, setSelected] = useState<string | undefined>(param ?? undefined)

    useEffect(() => {
        if (!options?.length) {
            setSelected(undefined)
            return
        }

        const selectedOption = stringKeyOptions.find((option) => option.key === param)
        if (selectedOption) {
            setSelected(selectedOption.key)
        } else {
            // reset to the first option
            setParam(stringKeyOptions[0].key)
        }
    }, [param, options, setParam, stringKeyOptions])

    return (
        <ButtonGroup
            options={stringKeyOptions}
            selected={selected}
            onSelect={(key) => setParam(key.toString())}
            {...rest}
        />
    )
}
