/**
 * Checking if user authenticated and page is open
 * Pause on inactive tab
 */
export const setIntervalUntilAuthenticated = (callback: () => void, timeout: number, isAuthenticated: boolean) => {
    // eslint-disable-next-line no-restricted-globals
    const intervalId = setInterval(() => {
        if (document.hidden) {
            return
        }

        if (!isAuthenticated) {
            clearInterval(intervalId)
            return
        }

        callback()
    }, timeout)

    return intervalId
}
