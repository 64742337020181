import { Model, model, field, foreign } from 'mobx-orm'

import { Asset } from '@/models/asset'
import { CompanyUser } from '@/models/company'

import { api } from '../../http-adapter'

@api('asset-tb-source-readiness')
@model
export class AssetTBSourceReadiness extends Model {
    @field asset_id:      number
    @field month_ind:     number  // year * 12 + (__zero-based__ month number)
    @field is_ready?:     boolean // if exist and true - pending status
    @field initiated_by?: number

    @foreign(Asset) readonly asset:                                   Asset
    @foreign(CompanyUser, 'initiated_by') readonly initiated_by_user: CompanyUser
}
