import { Model, model, field, foreign } from 'mobx-orm'

import { Asset } from '@/models/asset'
import { RentRollLedger } from '@/models/rent_roll'

import { api } from '../../http-adapter'

@api('asset-rent-roll')
@model
export class AssetRentRoll extends Model {
    @field asset_id?:  number
    @field ledger_id?: number
    @field order:      number

    @foreign(Asset) readonly asset:           Asset
    @foreign(RentRollLedger) readonly ledger: RentRollLedger
}
