import { observer } from 'mobx-react-lite'

import { Link } from '@/components/base'

export const DataSubmissionInternalErrorMessage = observer(() => {
    return (
        <>
            Internal Error - please
            <Link
                variant='smallTextMediumDefault'
                onClick={() => (window as any).Intercom?.('showMessages')}
            > contact Intelas</Link>
        </>
    )
})
