/**
 * Transforms search string to a object
 */
export const getQueryStringToObject = (search: string): Record<string, string> => {
    const urlSearchParams: URLSearchParams = new URLSearchParams(search)

    const params: Record<string, string> = {}
    urlSearchParams.forEach((value, key) => {
        params[key] = value
    })
    return params
}
