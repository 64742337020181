import { model, field } from 'mobx-orm'

import { api } from '../../http-adapter'
import { Model } from '../model'

@api('rent-roll-map')
@model
export class RentRollMap extends Model {
    @field code?:       string // Source Account Code
    @field asset_name?: string

    @field intelas_account_map_id?: number
    @field intelas_account_id?:     number
    @field intelas_account_code?:   string

    @field custom_account_map_id?: number
    @field custom_account_id?:     number
    @field custom_account_code?:   string
}
