import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { Text } from '@/components/base'

import styles from './FormBox.module.scss'
import { FormBoxProps } from './FormBox.types'

export const FormBox = observer((props: FormBoxProps) => {
    const { className, title, body, noBottom, noTop, bgColor, maxWidth = '550px', width } = props

    return (
        <div
            style={{ maxWidth, width }}
            className={cn(styles.formBox, className, { [styles.noBottom]: noBottom, [styles.noTop]: noTop, [styles['bgColor_' + bgColor]]: bgColor })}
        >
            {title && (
                <div className={styles.header}>
                    <Text
                        color='secondaryBlack'
                        variant='bodySemiboldDefault'
                    >
                        {title}
                    </Text>
                </div>
            )}
            <div className={styles.body}>
                {body}
            </div>
        </div>
    )
})
