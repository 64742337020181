import { Model, field } from 'mobx-orm'

import { setIntervalUntilAuthenticated } from '@/utils/setInterval'

export enum AsyncTaskStatus {
    PENDING = 'PENDING',    // task in a queue, but for UI it mean "in progress"
    STARTED = 'STARTED',
    RETRY   = 'RETRY',
    FAILURE = 'FAILURE',
    SUCCESS = 'SUCCESS',
}

export abstract class AsyncTaskModel extends Model {
    @field initiated_by_id?: number
    @field status?:          AsyncTaskStatus
    @field created_at?:      string  // created request
    @field started_at?:      string  // started processing
    @field finished_at?:     string
    // NOTE: don't declare "result" field here,
    // because it will be overwritten by child class with proper type

    constructor (...args) {
        super(args)
        // auto refresh when we in progress status
        // TODO: setIntervalUntilAuthenticated ???
        const idInterval = setIntervalUntilAuthenticated(() => {
            if (this.id !== undefined) {
                if (this.status === null ||
                    this.status === undefined ||
                    this.status === AsyncTaskStatus.PENDING ||
                    this.status === AsyncTaskStatus.STARTED ||
                    this.status === AsyncTaskStatus.RETRY) {
                    this.refresh()
                } else {
                    clearInterval(idInterval)
                }
            }
        }, 3000, true)
    }

    get inProgress () {
        // I hope PENDING is short lived status
        return this.status === AsyncTaskStatus.STARTED ||
            this.status === AsyncTaskStatus.PENDING ||
            this.status === null ||
            this.status === undefined
    }
}
