import { Model, model, field, foreign } from 'mobx-orm'

import { Company } from './Company'
import { CompanyInviteSend } from './CompanyInviteSend'
import { CompanyUser } from './CompanyUser'
import { CompanyUserGroup } from './CompanyUserGroup'
import { api } from '../../http-adapter'
import { AccessLevel } from '../core'

export enum CompanyInviteStatus {
    Pending = 1,
    Accepted = 2,
    Canceled = 3,
}

@api('company-invite')
@model
export class CompanyInvite extends Model {
    @field created?:               string
    @field first_name?:            string
    @field last_name?:             string
    @field invitee_id?:            number
    @field company_id?:            number
    @field company_user_group_id?: number
    @field level:                  AccessLevel
    @field email?:                 string
    @field status:                 CompanyInviteStatus

    @foreign(Company) readonly company:                     Company
    @foreign(CompanyUserGroup) readonly company_user_group: CompanyUserGroup
    @foreign(CompanyUser, 'invitee_id') readonly invitee:   CompanyUser

    readonly sends: CompanyInviteSend[]
}
