import { ValueFormatterParams } from '@ag-grid-community/core'

import styles from '@/components/tables/AgGridTable/AgGridTable.module.scss'
import { AgGridTableProps } from '@/components/tables/AgGridTable/AgGridTable.types'
import { arrayRmDuplicates } from '@/utils/array/ArrayRmDuplicates'
import { formatDate as formatDateString } from '@/utils/date/formatDate'
import { normalizeShortBackDate } from '@/utils/date/normalizeShortBackDate'

// TODO: Did't for for default col defs
export const modifyColDefItem = (column: AgGridTableProps['columnDefs'][0]) => {
    // Set className for right border
    if (column.rightBorder) {
        column.headerClass = arrayRmDuplicates<string>([styles.rightBorder]
            .concat(column.headerClass as string ?? []))
        // @ts-expect-error: Can't detect Headers group which don't have this field
        column.cellClass = arrayRmDuplicates<string>([styles.rightBorder].concat(column.cellClass ?? []))

        // @ts-expect-error: Can't detect Headers group which don't have this field
        const childrenLength = column.children?.length

        if (childrenLength) {
            // @ts-expect-error: Can't detect Headers group which don't have this field
            const childToModify = column.children[childrenLength - 1]

            if (childToModify) {
                childToModify.headerClass = arrayRmDuplicates<string>([styles.rightBorder]
                    .concat(childToModify.headerClass ?? []))
                childToModify.cellClass = arrayRmDuplicates<string>([styles.rightBorder]
                    .concat(childToModify.cellClass ?? []))
            }
        }

        delete column.rightBorder
    }

    if (column.cellContentAlignCenter) {
        // @ts-expect-error: Can't detect Headers group which don't have this field
        column.cellStyle = { ...(column.cellStyle || {}, { display: 'flex', alignItems: 'center', justifyContent: 'center' }) }
        delete column.cellContentAlignCenter
    }

    if (column.cellContentAlignVertical) {
        // @ts-expect-error: Can't detect Headers group which don't have this field
        column.cellStyle = { ...(column.cellStyle || {}, { display: 'flex', alignItems: 'center' }) }
        delete column.cellContentAlignVertical
    }

    return column
}

// Value formatters

// 1,000.00 or (1,000.00)
export const formatMoney = (params: ValueFormatterParams): string => {
    const { value, node } = params
    if (node?.group && !node?.footer && node?.expanded) {
        return ''
    }

    // @ts-expect-error Custom property 'fractionDigits'
    const decimals = params.fractionDigits ?? params.colDef?.fractionDigits ?? 0
    const valueNumber = Number(value)

    if (!value || isNaN(valueNumber) || valueNumber === 0) {
        return '—'
    }

    const format = (val: number) => val.toLocaleString('en-US', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    })

    const formattedValue = format(valueNumber)
    if (Number(formattedValue) === 0) {
        return '—'
    }

    return (valueNumber < 0) ? `(${format(-valueNumber)})` : format(valueNumber)
}

// 1000.00 or -1000.00
export const formatNumber = (params: ValueFormatterParams): string => {
    const { value } = params
    // @ts-expect-error Custom property 'fractionDigits'
    const decimals = params.fractionDigits ?? params.colDef?.fractionDigits ?? 0
    const valueNumber = Number(value)

    if (isNaN(valueNumber) || valueNumber === 0) {
        return '—'
    }

    const format = (val: number) => val.toLocaleString('en-US', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    })

    return format(valueNumber)
}

// -12.34 => -12.34%
export const formatPercentageNumber = (params: ValueFormatterParams): string => {
    const { value, node } = params
    if (node?.group && !node?.footer && node?.expanded) {
        return ''
    }

    // @ts-expect-error Custom property 'fractionDigits'
    const decimals = params.fractionDigits ?? params.colDef?.fractionDigits ?? 0
    const valueNumber = Number(value)

    if (!value || isNaN(valueNumber) || valueNumber === 0) {
        return '—'
    }

    const format = (val: number) => val.toLocaleString('en-US', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    })

    const formattedValue = format(valueNumber)
    if (Number(formattedValue) === 0) {
        return '—'
    }

    return `${formattedValue}%`
}

// ! BE AWARE: We have different format of percentage values in reports
// -0.1234 => -12.34%
export const formatPercentageFraction = (params: ValueFormatterParams): string => {
    const { value, node } = params
    if (!value && node?.group && !node?.footer && node?.expanded) {
        return ''
    }

    // @ts-expect-error Custom property 'fractionDigits'
    const decimals = params.fractionDigits ?? params.colDef?.fractionDigits ?? 0
    const valueNumber = Number(value)

    if (isNaN(valueNumber) || valueNumber === 0) {
        return '—'
    }

    const format = (val: number) => val.toLocaleString('en-US', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    })

    const formattedValue = format(valueNumber * 100)
    if (Number(formattedValue) === 0) {
        return '—'
    }

    return `${formattedValue}%`
}

// 12/26/2022 without timezone conversion
export const formatDate = (params: ValueFormatterParams): string => {
    const { value } = params

    if (!value) {
        return '—'
    }

    return formatDateString(normalizeShortBackDate(value))
}

export const formatString = (params: ValueFormatterParams): string => {
    const { value } = params

    if (!value) {
        return '—'
    }

    return value
}
