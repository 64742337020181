import { useState } from 'react'

import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Alert, Confirm, SideModal, TextInput } from '@/components/base'
import { notify } from '@/utils/notify'

import { MappingTemplateEditNameModalProps } from './MappingTemplateEditNameModal.types'
import { EDIT_TEMPLATE_MESSAGES } from '../MappingTemplateEditPage.constants'
export const MappingTemplateEditNameModal = observer((props: MappingTemplateEditNameModalProps) => {
    const { template, onClose } = props

    const [isSaving, setIsSaving] = useState(false)
    const [isPopupOpen, setIsPopupOpen] = useState(false)

    const saveTemplateName = async () => {
        // TODO: name validation
        setIsSaving(true)
        try {
            await template.save()
            notify.success(EDIT_TEMPLATE_MESSAGES.nameChanged)
            onClose()
        } catch (e) {
            // TODO: backend validation & errors
            // templateMap.__errors?.__all__
            console.error('Some error')
        }
        setIsSaving(false)
    }

    const resetTemplateName = () => {
        runInAction(() => { template.name = template.__init_data.name })
        setIsPopupOpen(false)
        onClose()
    }

    const closeModal = () => {
        if (template.is_changed) {
            setIsPopupOpen(true)
            return
        }
        onClose()
    }

    return (
        <>
            {isPopupOpen && (
                <Confirm
                    confirmText='Back to Editing'
                    onConfirm={() => setIsPopupOpen(false)}
                    cancelText='Close without Saving'
                    onCancel={resetTemplateName}
                    disableEscapeKeyDown
                    disableOverlayClick
                >
                    <Alert type='warning' content='You have unsaved changes'/>
                </Confirm>
            )}

            <SideModal
                width={528}
                title='Edit template name'
                onClose={closeModal}
                submitButton={{
                    onClick: saveTemplateName,
                    text: 'Save Changes',
                    loading: isSaving,
                    disabled: !template.is_changed,
                }}
                cancelButton={{
                    text: 'Reset Changes',
                    onClick: resetTemplateName,
                }}
                haveCloseButton={template.is_changed && !isSaving}
            >
                <TextInput
                    label='Template name'
                    value={template.name}
                    onChange={(e) => runInAction(() => { template.name = e.target.value })}
                />
            </SideModal>
        </>
    )
})
