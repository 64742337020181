import { RoutesConfigValue } from '@/core/routes'

export type PackageRouteConfigKeys =
    'PACKAGES' |
    'PACKAGES_DASHBOARD' |
    'PACKAGES_APPROVAL' |
    'PACKAGES_APPROVAL_OVERVIEW' |
    'PACKAGES_APPROVAL_MAPPINGS' |
    'PACKAGES_APPROVAL_FINANCIAL' |
    'PACKAGES_APPROVAL_FINANCIAL_ACTUAL_BUDGET' |
    'PACKAGES_APPROVAL_FINANCIAL_PROPERTY_COMPARISON' |
    'PACKAGES_APPROVAL_FINANCIAL_TRIAL_BALANCE' |
    'PPA' |
    'PPA_ADJUSTMENT' |
    'PPA_TRIAL_BALANCE'

export type PackageRoutesConfig = {
    [key in PackageRouteConfigKeys]: RoutesConfigValue
}

export const PACKAGE_ROUTES_CONFIG: PackageRoutesConfig = {
    PACKAGES: {
        title: 'Packages',
        path: '/packages',
        featureFlags: ['packages'],
    },
    PACKAGES_DASHBOARD: {
        title: 'Dashboard',
        path: '/packages/dashboard',
    },
    PACKAGES_APPROVAL: {
        title: 'Approval',
        path: '/packages/:id/approval',
    },
    PACKAGES_APPROVAL_OVERVIEW: {
        title: 'Overview',
        path: '/packages/:id/approval/overview',
    },
    PACKAGES_APPROVAL_MAPPINGS: {
        title: 'Mappings',
        path: '/packages/:id/approval/mappings',
    },
    PACKAGES_APPROVAL_FINANCIAL: {
        title: 'Financials',
        path: '/packages/:id/approval/financial',
    },
    PACKAGES_APPROVAL_FINANCIAL_ACTUAL_BUDGET: {
        title: 'Actual vs Budget',
        path: '/packages/:id/approval/financial/actual-budget',
    },
    PACKAGES_APPROVAL_FINANCIAL_PROPERTY_COMPARISON: {
        title: 'Property Comparison',
        path: '/packages/:id/approval/financial/property-comparison',
    },
    PACKAGES_APPROVAL_FINANCIAL_TRIAL_BALANCE: {
        title: 'Trial Balance',
        path: '/packages/:id/approval/financial/trial-balance',
    },
    PPA: {
        title: 'PPA',
        featureFlags: ['prior_period_adjustment'],
        path: '/packages/ppa',
    },
    PPA_ADJUSTMENT: {
        title: 'Prior Period Adj.',
        featureFlags: ['prior_period_adjustment'],
        path: '/packages/ppa/adjustment',
    },
    PPA_TRIAL_BALANCE: {
        title: 'Trial Balance',
        path: '/packages/ppa/trial-balance',
        featureFlags: ['prior_period_adjustment'],
    },
}
