import { getRequestQueries } from '@/api/utils/getRequestQueries'
import { Asset } from '@/models/asset'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchAssetsQueryParams {}

export const {
    useItemsQuery: useAssetsQuery,
    useItemUpdateMutation: useAssetUpdateMutation,
    useItemsCountQuery: useAssetsCountQuery,
    useItemByIdQuery: useAssetByIdQuery,
} = getRequestQueries<Asset, FetchAssetsQueryParams>({
    url: 'asset',
    errorMessageUpdate: 'Failed to update asset',
})
