import { runInAction } from 'mobx'
import { Model, model, field, foreign, many } from 'mobx-orm'

import { notify } from '@/utils/notify'

import { Company } from './Company'
import { CompanyUserInCompanyUserGroup } from './CompanyUserInCompanyUserGroup'
import { api } from '../../http-adapter'
import { CompanyUserInAsset, CompanyUserInAssetGroup } from '../asset'
import { User } from '../core'
import { CompanyUserInLedger } from '../ledger'

/**
 * Users which have been invited to this company with any access
 */
@api('company-user')
@model
export class CompanyUser extends Model {
    @field user_id?:             number
    @field company_id?:          number
    @field first_name?:          string
    @field last_name?:           string
    @field username?:            string
    @field is_staff?:            boolean
    @field is_active?:           boolean
    @field is_owner?:            boolean
    @field is_property_manager?: boolean
    /**
     * REFACTOR: Add this field on backend
     */
    @field email?:               string

    @foreign(Company) readonly company: Company
    @foreign(User) readonly user:       User

    links:                            CompanyUserInCompanyUserGroup[]
    ledger_personal_permissions:      CompanyUserInLedger[]
    asset_personal_permissions:       CompanyUserInAsset[]
    asset_group_personal_permissions: CompanyUserInAssetGroup[]

    get full_name (): string {
        // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        return (this.first_name || this.last_name) ? `${this.first_name} ${this.last_name}` : this.username
    }

    get label (): string {
        return this.full_name
        // (me.companyUser.company_id !== this.company_id
        //     ? ` (${this.company.name})`
        //     : '')
    }

    async activate () {
        runInAction(() => (this.is_active = true))
        await this.save()
        notify.promise(this.save(), {
            success: 'User was activated',
            pending: 'Activating...',
            error:   'Request failed',
        })
    }

    async deactivate () {
        runInAction(() => (this.is_active = false))
        notify.promise(this.save(), {
            success: 'User was deactivated',
            pending: 'Deactivating...',
            error:   'Request failed',
        })
    }
}

many(CompanyUser, 'company_id')(Company, 'company_users')
