import { Model, model, field, foreign, many } from 'mobx-orm'

import { AssetInAssetGroup } from './AssetInAssetGroup'
import { CompanyUserGroupInAssetGroup } from './CompanyUserGroupInAssetGroup'
import { CompanyUserInAssetGroup } from './CompanyUserInAssetGroup'
import { api } from '../../http-adapter'
import { Company } from '../company'

/**
 * Asset Groups
 */
@api('asset-group')
@model
export class AssetGroup extends Model {
    @field parent_id?:  number
    @field company_id?: number
    @field name?:       string

    @foreign(Company) readonly company: Company

    readonly parent:               AssetGroup
    readonly children:             AssetGroup[]
    readonly links:                AssetInAssetGroup[]
    readonly personal_permissions: CompanyUserInAssetGroup[]
    readonly group_permissions:    CompanyUserGroupInAssetGroup[]
}
foreign(AssetGroup, 'parent_id')(AssetGroup.prototype, 'parent')
many(AssetGroup, 'parent_id')(AssetGroup, 'children')
many(AssetGroup, 'company_id')(Company, 'asset_groups')
