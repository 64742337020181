import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'

import { ErrorPageBase } from '@/components/base'

import { InternalNotFoundPageProps } from './InternalNotFoundPage.types'

export const InternalNotFoundPage = observer((props: InternalNotFoundPageProps) => {
    const { className, rounded, type = 'internal' } = props

    const navigate = useNavigate()

    const handleButtonClick = () => {
        navigate('/')
    }

    return (
        <ErrorPageBase
            title='Page not found'
            text='The page you are looking for does not exist'
            onButtonClick={handleButtonClick}
            buttonText='Back to home page'
            type={type}
            errorName='404 error'
            errorText='404'
            className={className}
            rounded={rounded}
        />
    )
})
