import { ColDef } from '@ag-grid-community/core'

import { NavigateButtonCell } from '@/components/legacy/tables/cells'

/**
 * Cell values for which we do not show the tooltip
 */
const CELL_TOOLTIP_EXCLUDE_VALUES = [
    '—',
    'No Mapping',
    'empty',
]

export const DEFAULT_COL_DEF: ColDef = {
    suppressHeaderMenuButton: true,
    /**
     * We can move it under 'string' type later
     */
    tooltipValueGetter: ({ valueFormatted }) => {
        if (typeof valueFormatted !== 'string' || CELL_TOOLTIP_EXCLUDE_VALUES.some(el => el === valueFormatted)) {
            return null
        }

        return valueFormatted
    },
}

export const TABLE_COLUMN_TYPES_DEFAULT: Record<string, ColDef> = {
    actions: {
        headerClass: 'ag-right-aligned-header',
        cellClass: 'ag-right-aligned-cell',
        width: 50,
        minWidth: 50,
        suppressHeaderMenuButton: true,
        headerName: 'Actions',
    },
    string: {
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        valueFormatter: ({ value }) => value || '—',
    },
    /**
     * Requeres `NavigateButtonCellProps` interface in `cellRendererParams` field
     *
     * If you want to use `generatePath` util do this trough `cellRendererSelector` field
     */
    navigateButton: {
        cellRenderer: NavigateButtonCell,
    },
    /**
     * Type for handle money values
     */
    money: {
        headerClass: 'ag-right-aligned-header',
        cellClass: 'ag-right-aligned-cell',
        // @ts-expect-error TS(2339) FIXME: Property 'fractionDigits' does not exist on type '... Remove this comment to see the full error message
        valueFormatter: ({ value, fractionDigits = 0 }) => {
            const valueNumber = Number(value)

            if (isNaN(valueNumber)) {
                return '—'
            }

            const format = (val) => val.toLocaleString('en-US', {
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits,
            })

            return (valueNumber < 0) ? `(${format(-valueNumber)})` : format(valueNumber)
        },
        comparator: (valueA, valueB) => parseFloat(valueA) < parseFloat(valueB) ? -1 : 1,
    },
    percentage: {
        headerClass: 'ag-right-aligned-header',
        cellClass: 'ag-right-aligned-cell',
        // @ts-expect-error TS(2339) FIXME: Property 'fractionDigits' does not exist on type '... Remove this comment to see the full error message
        valueFormatter: ({ value, fractionDigits = 0 }) => {
            const valueNumber = Number(value)

            if (isNaN(valueNumber)) {
                return '—'
            }

            const format = (val) => val.toLocaleString('en-US', {
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits,
            })

            return `${format(valueNumber)}%`
        },
    },
}
