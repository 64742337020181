import { RoutesConfigValue } from '@/core/routes'

export type ReportsFinancialsKeys =
    'REPORTS_FINANCIALS' |
    'REPORTS_FINANCIALS_PORTFOLIO' |
    'REPORTS_FINANCIALS_INCOME_STATEMENT' |
    'REPORTS_FINANCIALS_BALANCE_SHEET' |
    'REPORTS_FINANCIALS_ACTUAL_BUDGET' |
    'REPORTS_FINANCIALS_PROPERTY_COMPARISON' |
    'REPORTS_FINANCIALS_TRIAL_BALANCE' |
    'REPORTS_FINANCIALS_TB_SUMMARY'

export type ReportsFinancialsRoutesConfig = {
    [key in ReportsFinancialsKeys]: RoutesConfigValue
}

export const REPORTS_FINANCIALS_ROUTES_CONFIG: ReportsFinancialsRoutesConfig = {
    REPORTS_FINANCIALS: {
        title: 'Financials',
        path: '/reports/financials',
        featureFlags: ['reporting'],
    },
    REPORTS_FINANCIALS_PORTFOLIO: {
        title: 'Portfolio',
        path: '/reports/financials/portfolio',
    },
    REPORTS_FINANCIALS_INCOME_STATEMENT: {
        title: 'Income Statement',
        path: '/reports/financials/income-statement',
    },
    REPORTS_FINANCIALS_BALANCE_SHEET: {
        title: 'Balance Sheet',
        path: '/reports/financials/balance-sheet',
        featureFlags: ['report_balance_sheet'],
    },
    REPORTS_FINANCIALS_ACTUAL_BUDGET: {
        title: 'Actual vs Budget',
        path: '/reports/financials/actual-budget',
    },
    REPORTS_FINANCIALS_PROPERTY_COMPARISON: {
        title: 'Property Comparison',
        path: '/reports/financials/property-comparison',
    },
    REPORTS_FINANCIALS_TRIAL_BALANCE: {
        title: 'Trial Balance',
        path: '/reports/financials/trial-balance',
    },
    REPORTS_FINANCIALS_TB_SUMMARY: {
        title: 'TB Summary',
        path: '/reports/financials/tb-summary',
    },
}
