import React from 'react'

import { Auth0Provider } from '@auth0/auth0-react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'

import { getAuthConfig } from '@/globals'

import { AuthContextProps } from './AuthContext.types'

export const AuthContext = observer((props: AuthContextProps) => {
    const { children } = props
    const navigate = useNavigate()

    return (
        <Auth0Provider {...(getAuthConfig(navigate))}>
            {children}
        </Auth0Provider>
    )
})
