import React from 'react'

import addYears from 'date-fns/addYears'

import { useAssetsQuery } from '@/api/asset/asset'
import { FiltersContainer } from '@/components/base'
import { MonthRangeInput, SelectInput } from '@/components/baseInputs'
import { AssetSelectWithSideModalInput } from '@/components/models/asset'
import { dataPointTypeTitles } from '@/models/data_pulling'
import {
    DATA_SUBMISSION_TYPE_QUERY_PARAM,
} from '@/pages/UploadsPage/SubmissionLogsPage/SubmissionLogsPage.constants'
import { dateToMonthInd } from '@/utils/date/monthInd'

export const SubmissionLogFilters = () => {
    const assetQuery = useAssetsQuery()

    const typeOptions = Array.from(dataPointTypeTitles.entries()).map(([value, label]) => ({
        value,
        label,
    }))

    return (
        <FiltersContainer>
            <AssetSelectWithSideModalInput
                label='Asset'
                options={assetQuery.data}
                multiSelect
                defaultActiveOptions='none'
            />
            <SelectInput
                label='Type'
                multiSelect
                options={typeOptions}
                placeholder='Select Type'
                paramName={DATA_SUBMISSION_TYPE_QUERY_PARAM.name}
                paramType={DATA_SUBMISSION_TYPE_QUERY_PARAM.type}
                width='150px'
            />
            <MonthRangeInput
                label='Upload Date'
                defaultStart={dateToMonthInd(addYears(new Date(), -1))}
                defaultEnd={dateToMonthInd(new Date())}
            />
        </FiltersContainer>
    )
}
