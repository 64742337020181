import { useContext, useEffect, useState } from 'react'

import { ValueType } from 'mobx-orm'
import { observer } from 'mobx-react-lite'

import { LegacySelect, LegacySelectItemData } from '@/components/base'
import { ModelFormContext } from '@/components/legacy/models/core/ModelFromContext'
import { Account as TrialBalanceAccount } from '@/models/ledger'
import { ModelInput } from '@/utils/mobx-orm/ModelInput'

import { TrialBalanceAccountInputProps } from './TrialBalanceAccountInput.types'

// TODO: it's very similar to TrialBalanceLedgerInput.tsx, could we create a generic component for this?
// TODO: it can be some SelectInput.tsx model component
export const TrialBalanceAccountInput = observer((props: TrialBalanceAccountInputProps) => {
    const {
        obj, field = 'account_id',
        filter, options,
        syncURL, syncLocalStorage,
        label = 'Account',
        placeholder = 'Choose Account...',
        onChange,
        ...rest
    } = props
    const modelForm = useContext(ModelFormContext)
    const [modelInput] = useState(() => new ModelInput({
        obj: obj || modelForm.obj,
        field,
        filter,
        options,
        syncURL: syncURL || modelForm.syncURL,
        syncLocalStorage: syncLocalStorage || modelForm.syncLocalStorage,
        type: ValueType.NUMBER,
    }))

    // destructore
    useEffect(() => {
        return () => {
            modelInput.destroy()
        }
    }, [])

    const onChangeHandler = (option: LegacySelectItemData) => {
        modelInput.set(option.value)
        onChange?.(option)
    }

    return (
        <>
            <LegacySelect
                {...rest}
                label={label}
                // @ts-expect-error TS(2322) FIXME: Type '{ label: string; value: number | undefined; ... Remove this comment to see the full error message
                options={modelInput.options.items.map((account: TrialBalanceAccount) => ({ label: `${account.code} - ${account.name}`, value: account.id }))}
                customValue={modelInput.value}
                placeholder={modelInput.options.is_loading ? 'loading' : placeholder}
                onChange={onChangeHandler}
                error={modelInput.obj?.__errors?.[field]}
                hint={modelInput.obj?.__errors?.[field]}
                searchPlaceholder='Search by code or name...'
                search
            />
        </>
    )
})
