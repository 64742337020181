import { useMemo } from 'react'

import { FilesTreeItem } from '@/components/base'
import { OWNER_ROUTES_CONFIG } from '@/core/routes'
import { filtersPathItemsByAccess } from '@/core/routes/routes.utils'
import {
    REPORTS_FINANCIALS_SUBHEADER_ITEMS,
} from '@/pages/MainPage/UserPages/ReportsPage/ReportsFinancialsPage/ReportsFinancialsPage.constants'
import {
    REPORTS_RENTROLL_SUBHEADER_ITEMS,
} from '@/pages/MainPage/UserPages/ReportsPage/ReportsRentRollTabPage/ReportsRentRollTabPage.constants'

export const useStandardReports = () => {
    const standardReportsFinancials: FilesTreeItem[] = useMemo(() => filtersPathItemsByAccess(REPORTS_FINANCIALS_SUBHEADER_ITEMS).map((item) => ({
        id: item.path,
        path: item.path,
        name: item.title,
    })), [])

    const standardReportsRR: FilesTreeItem[] = useMemo(() => filtersPathItemsByAccess(REPORTS_RENTROLL_SUBHEADER_ITEMS).map((item) => ({
        id: item.path,
        path: item.path,
        name: item.title,
    })), [])

    const standardReports = useMemo(() => {
        const tree: FilesTreeItem[] = []

        if (standardReportsFinancials.length > 0) {
            tree.push({
                id: OWNER_ROUTES_CONFIG.REPORTS_FINANCIALS.path,
                name: OWNER_ROUTES_CONFIG.REPORTS_FINANCIALS.title,
                children: standardReportsFinancials,
            })
        }

        if (standardReportsRR.length > 0) {
            tree.push({
                id: OWNER_ROUTES_CONFIG.REPORTS_RENT_ROLL.path,
                name: OWNER_ROUTES_CONFIG.REPORTS_RENT_ROLL.title,
                children: standardReportsRR,
            })
        }

        return tree
    }, [standardReportsFinancials, standardReportsRR])

    return standardReports
}
