import { getRequestQueries } from '@/api/utils/getRequestQueries'
import { SubmittedFileError, SubmittedFileStatus, SubmittedFileType } from '@/models/data_submission'

import { DataPointItem } from './dataPoint'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchDataSubmissionQueryParams {}

export interface DataSubmissionItem {
    id: number
    initial_file_name?: string
    type?: SubmittedFileType
    source?: string
    status?: SubmittedFileStatus
    error_body?: string
    created_at: string
    asset_id?: number
    company_user_id: number
    report_date?: string
    errors_num?: number
    submission_errors_num?: number
    quality_errors_num?: number
    data_point_id?: number
    note?: string
    parent_id?: number
    errors: SubmittedFileError[]
    data_point?: DataPointItem
}

export const {
    useItemsQuery: useDataSubmissionQuery,
    useItemUpdateMutation: useDataSubmissionUpdateMutation,
    useItemByIdQuery: useDataSubmissionByIdQuery,
    useItemsCountQuery: useDataSubmissionCountQuery,
} = getRequestQueries<DataSubmissionItem, FetchDataSubmissionQueryParams>({
    url: 'data-submission',
    errorMessageUpdate: 'Failed to update. Please try again.',
})
