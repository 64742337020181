import { useEffect, useState } from 'react'

import getYear from 'date-fns/getYear'
import range from 'lodash/range'
import { observer } from 'mobx-react-lite'
import ReactDatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { mixpanelTrack } from '@/utils/mixpanel'

import { DATE_FORMATS } from './DatePicker.constants'
import style from './DatePicker.module.scss'
import { DatePickerProps } from './DatePicker.types'
import { DatePickerHeader } from './DatePickerHeader'
import { DatePickerTrigger } from './DatePickerTrigger'

// TODO(Aleksey): Make loading of this module async
// TODO: fix click on outside label
export const DatePicker = observer((props: DatePickerProps) => {
    const {
        date = null,
        onChange,
        filterDate,
        type = 'day',
        minDate,
        maxDate,
        customLabel,
        onOpenChange,
        width,
        maxWidth,
        label,
        hint,
        error,
        labelInside,
        readonly = false,
        disabled = false,
        icon,
        iconPos = 'end',
        rotateIcon = false,
        isClearable,
        placeholderText,
        offset,
        // Need this to make it work in AgGrid cells
        rootId = 'root',
    } = props

    const [selectedDate, setSelectedDate] = useState<Date | null>(null)
    const [open, setOpen] = useState(false)

    // Max/min years +10 and -10 years by default
    const startYear = minDate ? getYear(minDate) : (getYear(new Date()) - 10)
    const endYear = maxDate ? getYear(maxDate) : (getYear(new Date()) + 10)
    const datepickerYears = range(startYear, endYear + 1)

    useEffect(() => {
        setSelectedDate(date)
    }, [date])

    useEffect(() => {
        onOpenChange?.(open)
    }, [open])

    const handleClickOutside = (event) => {
        // FIXME: This logic can be broken during MUI library removal
        // If 'Popover' open (for choosing year, or month) than skip click outside
        if (!event.currentTarget.activeElement.classList.contains('MuiPopover-paper')) {
            setOpen(false)
            // reset date on calendar close
            setSelectedDate(date)
        }
    }

    const handleClickInside = () => {
        if (readonly) {
            return
        }

        setOpen(true)
    }

    const customHeader = (args: ReactDatePickerCustomHeaderProps) => (
        <DatePickerHeader
            isMonthPicker={type === 'month' || type === 'quarter'}
            years={datepickerYears}
            {...args}
        />
    )

    return (
        // this div is needed for correct work in flexbox with gap property,
        // because react-datepicker inserts extra element in DOM that add extra space
        <div>
            <ReactDatePicker
                showPopperArrow={false}
                open={open}
                selected={selectedDate}
                onYearChange={(newDate) => setSelectedDate(newDate)}
                onMonthChange={(newDate) => setSelectedDate(newDate)}
                onChange={(newDate) => {
                    mixpanelTrack('Date Change', {
                        date: newDate?.toISOString(),
                    })

                    setSelectedDate(newDate)
                    onChange?.(newDate)
                    setOpen(false)
                }}
                onSelect={(newDate) => {
                    setSelectedDate(newDate)
                    // trigger onChage only if date was changed
                    if (date?.getTime() !== newDate?.getTime()) {
                        onChange?.(newDate)
                    }
                    setOpen(false)
                }}
                filterDate={filterDate}
                calendarClassName={style.calendarClassName}
                dayClassName={() => style.day}
                monthClassName={() => style.day}
                wrapperClassName={style.wrapper}
                popperClassName={style.popper}
                popperModifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: offset ?? [0, -6],
                        },
                    }]}
                weekDayClassName={() => style.dayName}
                onClickOutside={handleClickOutside}
                onInputClick={handleClickInside}
                onKeyDown={handleClickInside}
                customInput={customLabel || (
                    <DatePickerTrigger
                        width={width}
                        maxWidth={maxWidth}
                        label={label}
                        hint={hint}
                        error={error}
                        labelInside={labelInside}
                        icon={isClearable && selectedDate ? 'cross' : icon}
                        iconPos={iconPos}
                        rotateIcon={rotateIcon && open}
                        onIconClick={isClearable
                            ? () => {
                                setSelectedDate(null)
                                onChange?.(null)
                                setOpen(false)
                            }
                            : undefined
                        }
                    />
                )}
                dateFormat={DATE_FORMATS[type]}
                placeholderText={placeholderText}
                renderCustomHeader={type === 'year' ? undefined : customHeader}
                maxDate={maxDate ?? new Date(endYear, 11, 31)}
                minDate={minDate ?? new Date(startYear, 0, 1)}
                showMonthYearPicker={type === 'month'}
                showQuarterYearPicker={type === 'quarter'}
                showYearPicker={type === 'year'}
                showFourColumnMonthYearPicker
                disabled={disabled}
                portalId={rootId}
            />
        </div>
    )
})
