import React, { useRef, useState } from 'react'

import format from 'date-fns/format'

import { useDataPointUpdateMutation } from '@/api/dataPulling/dataPoint'
import { DatePicker } from '@/components/base'
import { DATE_FNS_DEFAULT_FORMAT_MONTH_FULL, DATE_FNS_SERVER_DATE } from '@/constants/dates'
import { DataPointType } from '@/models/data_pulling'
import { normalizeShortBackDate } from '@/utils/date/normalizeShortBackDate'
import { notify } from '@/utils/notify'

import { SubmissionMonthPickerCellProps } from './SubmissionMonthPickerCell.types'

export const SubmissionMonthPickerCell = (props: SubmissionMonthPickerCellProps) => {
    const { value, data } = props

    const dataPointUpdateMutation = useDataPointUpdateMutation({ disableItemsInvalidation: true })
    const reportDate = value ? normalizeShortBackDate(value) : null

    // HACK: Need this for avoid double request
    const lastDate = useRef<Date | null>(reportDate)
    const [date, setDate] = useState<Date | null>(reportDate)

    if (data.type !== DataPointType.UNRECOGNIZED) {
        return <>{reportDate ? format(reportDate, DATE_FNS_DEFAULT_FORMAT_MONTH_FULL) : '—'}</>
    }

    const handleDateChange = async (newDate: Date) => {
        if (lastDate.current && (lastDate.current.getTime() === newDate.getTime())) {
            return
        }
        lastDate.current = newDate
        setDate(newDate)
        dataPointUpdateMutation.mutateAsync({
            id: data.id as number,
            updatedItemData: {
                report_date: format(newDate, DATE_FNS_SERVER_DATE),
            },
        }).catch(() => {
            notify.error('Failed to update report date')
        })
    }

    return (
        <div style={{ zIndex: 1000, position: 'relative' }} key={data.id}>
            <DatePicker
                type='month'
                date={date}
                onChange={handleDateChange}
                maxWidth='150px'
                icon='arrowDown'
                rotateIcon
            />
        </div>
    )
}
