import { CsvExportParams } from '@ag-grid-community/core'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { Button } from '@/components/base'
import { fsDownloadEvent } from '@/utils/fullstory/fsDownloadEvent'

import { DownloadTableDataButtonProps } from './DownloadTableDataButton.types'

export const DownloadTableDataButton = observer((props: DownloadTableDataButtonProps) => {
    const {
        excludeColumnKeys = [],
        className,
        columnKeys,
        tableRef,
        dataTestId,
        text,
        fsEventName,
    } = props

    const exportParams: CsvExportParams = {}

    if (columnKeys?.length) {
        // By default excluding actions column
        excludeColumnKeys.push('actions')

        exportParams.columnKeys = columnKeys.filter(el => !excludeColumnKeys.includes(el))
    }

    return (
        <Button
            icon='download'
            text={text || 'Download to CSV'}
            dataTestId={dataTestId}
            className={cn(className)}
            onClick={() => {
                tableRef.current?.api.exportDataAsCsv(exportParams)
                fsDownloadEvent(fsEventName)
            }}
            theme='secondary'
        />
    )
})
