import { useMemo } from 'react'

import get from 'lodash/get'
import set from 'lodash/set'

interface LookupItem {
    lookupArr: any[]
    baseKey?: string
    lookupKey?: string
    saveTo: string
}
/**
 * @todo Typechecking
 *
 * Need lodash here for chaining paths to values
 */
export const useJoin = (
    baseArr: Array<Record<any, any>>,
    lookupConfig: LookupItem[],
) => {
    return useMemo(() => {
        let result = [...baseArr]

        for (const config of lookupConfig) {
            const { lookupArr, baseKey = 'id', lookupKey = 'id', saveTo } = config

            result = result.map(item => {
                const newItem = {
                    ...item,
                }

                const foundValue = lookupArr.find(lookupItem => lookupItem[lookupKey] === get(item, baseKey))

                if (foundValue) {
                    set(newItem, saveTo, foundValue)
                }

                return newItem
            })
        }

        return result
    }, [baseArr, lookupConfig])
}
