import { Text } from '@/components/base/Text'

import { SelectOptionDefault, SelectSelectedItemsProps } from '../../Select.types'

export const SelectSelectedItemsBase =
    <Option = SelectOptionDefault>(props: SelectSelectedItemsProps<Option>) => {
        const {
            selected = [],
            labelFn,
            placeholder = '',
            multiSelectPlaceholder = 'Items',
            active = false,
        } = props

        const getTextValue = () => {
            if (selected.length === 1) {
                return labelFn(selected[0])
            } else {
                return `${multiSelectPlaceholder} (${selected.length.toString()})`
            }
        }

        if (selected.length === 0) {
            return (
                <Text
                    color='colorsSecondaryGrey600'
                    variant='smallTextMediumDefault'
                    text={placeholder}
                    whiteSpace='nowrap'
                    overflow='hidden'
                    textOverflow='ellipsis'
                    block
                    dataTestId='selected-items'
                    elementTitle={placeholder}
                />
            )
        }

        return (
            <Text
                variant='smallTextMediumDefault'
                text={getTextValue()}
                elementTitle={getTextValue()}
                color={active ? 'colorsPrimaryPurple' : 'colorsPrimaryGrey'}
                whiteSpace='nowrap'
                overflow='hidden'
                textOverflow='ellipsis'
                block
                dataTestId='selected-items'
            />
        )
    }
