import http from '../http.service'

// need to sync front-end and back-end versions
export function checkBackendVersion () {
    window.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
            // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            http.get('/version/').then(
                (response) => {
                    if (response.data.version !== process.env.RELEASE_VERSION) {
                        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
                        const count = JSON.parse(localStorage.getItem('version-updated-count'))
                        if (count < 3) {
                            localStorage.setItem('version-updated-count', JSON.stringify(count + 1))
                        } else {
                            console.warn('Version of backend is not equal to frontend version')
                        }

                        if (process.env.NODE_ENV !== 'development') {
                            location.reload()
                        }
                    } else {
                        localStorage.setItem('version-updated-count', JSON.stringify(0))
                    }
                },
                (error) => console.error('Cannot get backend version:', error),
            )
        }
    })
}
