import { useEffect, useMemo } from 'react'

import { generatePath, matchPath, useLocation, useNavigate, useParams } from 'react-router'

import { Tabs } from '@/components/base'
import { filtersPathItemsByAccess } from '@/core/routes/routes.utils'

import { NavigaionSubtabsProps } from './NavigaionSubtabs.types'

/**
 * Wrapper under Tabs component, for adopt it for react-router
 */
export const NavigaionSubtabs = (props: NavigaionSubtabsProps) => {
    const { items, ...rest } = props

    const { pathname } = useLocation()
    const navigate = useNavigate()
    const params = useParams()

    const filteredItems = filtersPathItemsByAccess(items)

    useEffect(() => {
        const matchedPath = filteredItems.find(item => matchPath({
            path: item.path,
            end: false, // To match children routes (modals and etc)
        }, pathname))

        if (!matchedPath) {
            if (!filteredItems.length) {
                if (pathname !== '/') {
                    navigate('/', { replace: true })
                }
            } else {
                navigate(generatePath(filteredItems[0].path, params), { replace: true })
            }
        }
    }, [filteredItems, navigate, params, pathname])

    const optionsWithTitles = filteredItems
        .reduce((acc, route) => (acc.set(route.path, route.title)), new Map())

    const handleTabChange = (route: string) => {
        const item = filteredItems.find(item => item.path === route)
        // Sending current params from url and allowing to send a custom object here
        navigate(generatePath(route, Object.assign(params, item?.params)), { replace: true })
    }

    // Need this for make strict comparison work in Tabs component
    const activePathNameTemplate = useMemo(
        () => filteredItems.find(item => matchPath(item.path, pathname))?.path,
        [filteredItems, pathname],
    )

    return (
        <Tabs
            {...rest}
            value={activePathNameTemplate}
            options={optionsWithTitles}
            onChange={handleTabChange}
        />
    )
}
