import { generatePath } from 'react-router'

import { Button } from '@/components/base'
import { tableCellTextStyle1 } from '@/components/legacy/tables/AgGridTableLegacy/AgGridTableLegacy.constants'
import { STRING_COMPARATOR } from '@/components/tables/AgGridTable/AgGridTable.constants'
import { formatDateTime } from '@/utils/date/formatDate'
import { downloadFile } from '@/utils/downloadFile'

export const gridOptions = {
    context: {},
    defaultColDef: {
        resizable: true,
        suppressHeaderMenuButton: true,
        wrapText: true,
        autoHeight: true,
        comparator: STRING_COMPARATOR,
        ...tableCellTextStyle1,
    },
    columnTypes: {
        download: {
            headerClass: 'ag-right-aligned-header',
            cellStyle: { justifyContent: 'flex-end' },
            cellRendererSelector: (props) => ({
                component: Button,
                params: {
                    icon: 'download',
                    theme: 'secondary',
                    onClick: () => {
                        const DOWNLOAD_PATH = 'data-pulling/download/:dataPointId/'
                        downloadFile(
                            generatePath(DOWNLOAD_PATH, { dataPointId: `${props.data.id}` }),
                            props.data.initial_file_name,
                        )
                    },
                },
            }),
        },
    },
    columnDefs: [
        {
            field: 'initial_file_name',
            headerName: 'File',
            width: 1000,
            type: 'string',
            cellContentAlignVertical: true,
        },
        {
            field: 'as_of_date',
            headerName: 'Upload Date',
            width: 450,
            valueFormatter: ({ value }) => formatDateTime(value),
            cellContentAlignVertical: true,
        },
        {
            field: '',
            sortable: false,
            headerName: 'Actions',
            width: 100,
            type: 'download',
            cellContentAlignVertical: true,
        },
    ],
    sortModel: {},
}
