import { observer } from 'mobx-react-lite'

export const ObserverCell = observer((props: any) => {
    const fields = props.column.userProvidedColDef.field.split('.')
    // instead using {props.value} we need to get value from props.data, mobx will detect changes than
    let value = props.data
    try {
        for (const field of fields) {
            value = value[field] ?? '—'
        }
    } catch {
        value = '—'
    }
    return (
        <>
            {value}
        </>
    )
})
