import { getRequestQueries } from '@/api/utils/getRequestQueries'
import { TrialBalanceMap } from '@/models/ledger'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchTrialBalanceMapQueryParams {}

export const TRIAL_BALANCE_MAP_URL = 'trial-balance-map'

export const {
    useItemsQuery: useTrialBalanceMapQuery,
    useItemUpdateMutation: useTrialBalanceMapUpdateMutation,
    useItemsCountQuery: useTrialBalanceMapCountQuery,
    useDownloadCSV: useTrialBalanceMapDownloadCSV,
} = getRequestQueries<TrialBalanceMap, FetchTrialBalanceMapQueryParams>({
    url: TRIAL_BALANCE_MAP_URL,
})
