import { useMemo } from 'react'

import { Model, Query, Selector } from 'mobx-orm'

interface UseQueryExtraParams {
    autoupdate?: boolean
    isReady?: boolean
}

/**
 * @deprecated use useQueryX
 * @fixme remove any
  */
export const useQuery = (model: typeof Model & any, options?: Selector, extraParams?: UseQueryExtraParams) => {
    return useMemo(() => {
        const query = model.getQuery(options) as Query<typeof model>
        // @ts-expect-error TS(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
        query.autoupdate = extraParams?.autoupdate
        query.__is_ready = extraParams?.isReady ?? false
        return query
    }, [])
}
