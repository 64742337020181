import { Button, Text } from '@/components/base'

import styles from './DetailsBox.module.scss'
import { DetailsBoxProps } from './DetailsBox.types'

export const DetailsBox = (props: DetailsBoxProps) => {
    const { title, onClose, children } = props

    return (
        <div className={styles.detailsBox}>
            <div className={styles.title}>
                {(typeof title === 'string')
                    ? (<Text variant='bodySemiboldDefault' color='colorsSecondaryGrey600'>{title}</Text>)
                    : (title)}
                <Button
                    icon='cross'
                    theme='flat'
                    onClick={onClose}
                    dataTestId='close-button'
                />
            </div>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    )
}
