import React, { useEffect, useRef, useState } from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { useLocation, useNavigate } from 'react-router'

import { Layout } from '@/components/containers'
import { CompaniesMenu } from '@/components/widgets'
import { IntelasLogo } from '@/components/widgets/SideMenu/IntelasLogo'
import {
    ACTIVE_MENU_GROUP_LOCAL_STORAGE_KEY,
} from '@/components/widgets/SideMenu/SideMenu.constants'
import { getAvailablePaths } from '@/core/routes/routes.utils'
import { useLocalStorage } from '@/hooks'
import { useMe } from '@/hooks/core/useMe'

import styles from './SideMenu.module.scss'
import { SideMenuProps } from './SideMenu.types'
import { SideMenuItem } from './SideMenuItem'

/**
 * Main collapsable menu on the left side
 */
export const SideMenu = observer((props: SideMenuProps) => {
    const { items, defaultState = false } = props

    const { me } = useMe()

    const location = useLocation()
    const navigate = useNavigate()

    const [activeGroup, setActiveGroup] = useLocalStorage(ACTIVE_MENU_GROUP_LOCAL_STORAGE_KEY, null)
    const [isMenuExpanded, setIsMenuExpanded] = useState(defaultState)
    const [isMenuItemsExpanded, setIsMenuItemsExpanded] = useState(defaultState)

    const isDevMode = process.env.NODE_ENV === 'development'

    const menuRef = useRef(null)

    useEffect(() => {
        if (location.pathname === '/') {
            const availablePaths = new Set(getAvailablePaths())
            const firstAvailableItem = items.find(item => item.path && availablePaths.has(item.path))
            if (firstAvailableItem?.path) {
                navigate(firstAvailableItem.path)
            }
        }
    }, [items, location.pathname, navigate])

    const handleGroupToggle = (title) => {
        setActiveGroup(activeGroup === title ? null : title)
    }

    const openMenu = () => {
        setIsMenuExpanded(true)
    }

    const closeMenu = () => {
        setIsMenuExpanded(false)
    }

    useEffect(() => {
        if (!isMenuExpanded) {
            setIsMenuItemsExpanded(false)
        }

        setTimeout(() => {
            setIsMenuItemsExpanded(isMenuExpanded)
            // 100ms is open animation time
        }, 100)
    }, [isMenuExpanded])

    return (
        <div
            className={cn(styles.menu, { [styles.menu_expanded]: isMenuExpanded })}
            onMouseEnter={openMenu}
            onMouseLeave={closeMenu}
            ref={menuRef}
        >
            <IntelasLogo isMenuExpanded={isMenuItemsExpanded}/>
            {!me.isAdministratorMode && <CompaniesMenu isMenuExpanded={isMenuItemsExpanded}/>}
            <Layout direction='column'>
                {
                    items
                        .filter(item => ((item.dev && isDevMode) || !item.dev))
                        .map((item, i) => (
                            <SideMenuItem
                                isMenuExpanded={isMenuItemsExpanded}
                                key={i}
                                itemConfig={item}
                                isOpened={activeGroup === item.title}
                                onToggleGroup={handleGroupToggle}
                                childrenItems={item.children?.map((child, j) => (
                                    <SideMenuItem
                                        isMenuExpanded={isMenuItemsExpanded}
                                        key={j}
                                        itemConfig={child}
                                    />
                                ))}
                            />
                        ))
                }
            </Layout>
        </div>
    )
})
