import { getRequestQueries } from '@/api/utils/getRequestQueries'
import { CompanyUserGroupInAsset, CompanyUserGroupInAssetGroup } from '@/models/asset'
import { CompanyUserGroupInLedger } from '@/models/ledger'

export interface CompanyUserGroupItem {
    id: number
    parent_id?: number
    company_id?: number
    name?: string

    ledger_permissions: CompanyUserGroupInLedger[]
    asset_permissions: CompanyUserGroupInAsset[]
    asset_group_permissions: CompanyUserGroupInAssetGroup[]
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchCompanyUserGroupQueryParams {}

export const COMPANY_USER_GROUP_URL = 'company-user-group'

export const {
    useItemsQuery: useCompanyUserGroupQuery,
    useItemUpdateMutation: useCompanyUserGroupUpdateMutation,
    useItemsCountQuery: useCompanyUserGroupCountQuery,
    useItemCreateMutation: useCompanyUserGroupCreateMutation,
    useItemDeleteMutation: useCompanyUserGroupDeleteMutation,
    useItemByIdQuery: useCompanyUserGroupByIdQuery,
} = getRequestQueries<CompanyUserGroupItem, FetchCompanyUserGroupQueryParams>({
    url: COMPANY_USER_GROUP_URL,
})
