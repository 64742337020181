import { TimeInterval } from '@/constants/timeInterval'
import { useInputState } from '@/hooks'

import { TIME_INTERVAL_OPTIONS, TIME_INTERVAL_INPUT_QUERY_PARAM } from './TimeIntervalInput.constants'
import { TimeIntervalInputProps } from './TimeIntervalInput.types'
import { SelectInput } from '../SelectInput'

export const TimeIntervalInput = (props: TimeIntervalInputProps) => {
    const {
        label = 'Time Interval',
        syncLocalStorage,
        ...rest
    } = props

    useInputState({
        ...TIME_INTERVAL_INPUT_QUERY_PARAM,
        syncLocalStorage,
        defaultValue: TimeInterval.MONTH,
    })

    return (
        <SelectInput
            paramName={TIME_INTERVAL_INPUT_QUERY_PARAM.name}
            paramType={TIME_INTERVAL_INPUT_QUERY_PARAM.type}
            options={TIME_INTERVAL_OPTIONS}
            label={label}
            syncLocalStorage={syncLocalStorage}
            width='110px' // the width of the longest option
            optionsMinWidth='110px'
            optionsWidth='auto'
            placeholder='Select time interval'
            required
            {...rest}
        />
    )
}
