import React, { useState } from 'react'

import { TextInput } from '@/components/base'

import { TableSearchInputProps } from './TableSearchInput.types'

// TODO: should it be moved to /components/tables/AgGridTable/components ?
export const TableSearchInput = (props: TableSearchInputProps) => {
    const [value, setValue] = useState('')
    const { tableRef, placeholder = 'Search', ...inputProps } = props

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedValue = e.target.value
        setValue(updatedValue)
        tableRef.current?.api.setGridOption('quickFilterText', updatedValue)
    }

    return (
        <TextInput
            {...inputProps}
            onChange={changeHandler}
            value={value}
            placeholder={placeholder}
            icon='search'
            dataTestId='input'
        />
    )
}
