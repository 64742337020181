import { CompanyUserMode } from '@/core/modes'
import { RoutesConfigValue } from '@/core/routes'
import { getRouteConfig } from '@/utils/routing/getRouteConfig'

export const REPORTS_RENTROLL_SUBHEADER_ITEMS: RoutesConfigValue[] = [
    getRouteConfig('REPORTS_RENT_ROLL_RENT_ROLL', CompanyUserMode.Owner),
    getRouteConfig('REPORTS_RENT_ROLL_OCCUPANCY', CompanyUserMode.Owner),
    getRouteConfig('REPORTS_RENT_ROLL_LEASE_EXPIRATIONS', CompanyUserMode.Owner),
    getRouteConfig('REPORTS_RENT_ROLL_TOP_TENATS', CompanyUserMode.Owner),
]
