import { useEffect, useMemo } from 'react'

import { QueryX, Model } from 'mobx-orm'

import { MultiQuery } from '@/utils/mobx-orm/MultiQuery'

/**
 * Join multiple query states into one
 */
export function useJoinedQuery<M extends Model> (baseQuery: QueryX<M>, joinQueries: Array<QueryX<Model>>) {
    const query = useMemo(() => new MultiQuery([baseQuery, ...joinQueries], () => baseQuery.items), [baseQuery, joinQueries])

    useEffect(() => () => query.destroy(), [query])

    return query
}
