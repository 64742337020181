/* eslint no-console: */
// TODO: this file should moved to Mobx-ORM after testing
import { action, makeObservable, observable, reaction } from 'mobx'
import { Model, Query, SingleFilter, ValueType } from 'mobx-orm'

import { deserialize, serialize } from './'

/**
 * @deprecated
 */
export interface ModelInputProps {
    obj?: Model
    field?: string
    filter?: SingleFilter
    options?: Query<Model>
    syncURL?: string | boolean
    syncLocalStorage?: string | boolean
    type?: ValueType
    autoReset?: (modelInput: ModelInput) => void
    debug?: boolean
}
export class ModelInput {
    @observable value
    @observable error = ''

    readonly obj: Model
    readonly field: string
    readonly filter: SingleFilter
    readonly options: Query<Model>
    readonly syncURL: string
    readonly syncLocalStorage: string
    readonly type: ValueType

    readonly debug: boolean

    readonly disposers = []

    constructor (props: ModelInputProps) {
        makeObservable(this)
        // @ts-expect-error TS(2322) FIXME: Type 'Model | undefined' is not assignable to type... Remove this comment to see the full error message
        this.obj = props.obj
        // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        this.field = props.field
        // @ts-expect-error TS(2322) FIXME: Type 'SingleFilter | undefined' is not assignable ... Remove this comment to see the full error message
        this.filter = props.filter
        // @ts-expect-error TS(2322) FIXME: Type 'Query<Model> | undefined' is not assignable ... Remove this comment to see the full error message
        this.options = props.options
        this.syncURL = props.syncURL === true ? '' : props.syncURL as string
        this.syncLocalStorage = props.syncLocalStorage === true ? '' : props.syncLocalStorage as string
        this.type = props.type || ValueType.STRING
        // @ts-expect-error TS(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
        this.debug = props.debug

        if (this.obj && this.field) {
            // update from obj
            // @ts-expect-error TS(2345) FIXME: Argument of type 'IReactionDisposer' is not assign... Remove this comment to see the full error message
            this.disposers.push(reaction(
                () => this.obj[this.field],
                (value) => {
                    if (this.value !== value) {
                        this.debug && console.log(`[Debug ModelInput] input.${this.field} <- obj.${this.field}`, this.value, value)
                        this.set(value)
                    }
                },
                { fireImmediately: true },
            ))
            // watch the errors
            // @ts-expect-error TS(2345) FIXME: Argument of type 'IReactionDisposer' is not assign... Remove this comment to see the full error message
            this.disposers.push(reaction(
                () => this.obj?.__errors?.[this.field],
                (value) => {
                    if (this.error !== value) {
                        this.setError(value)
                    }
                },
                { fireImmediately: true },
            ))
        }

        // update from filter
        if (this.filter) {
            // @ts-expect-error TS(2345) FIXME: Argument of type 'IReactionDisposer' is not assign... Remove this comment to see the full error message
            this.disposers.push(reaction(
                () => this.filter.value,
                (value) => {
                    if (this.value !== value) {
                        this.debug && console.log(`[Debug ModelInput] input.${this.field} <- filter.${this.filter.field}`, this.value, value)
                        this.set(value)
                    }
                },
                { fireImmediately: true },
            ))
        }
        // @ts-expect-error TS(2345) FIXME: Argument of type '() => void' is not assignable to... Remove this comment to see the full error message
        this.syncURL !== undefined && this.disposers.push(this.doSyncURL())
        if (this.syncLocalStorage !== undefined && this.obj && this.field) {
            const name = `${this.syncLocalStorage}${this.field}`
            // @ts-expect-error TS(2345) FIXME: Argument of type '() => void' is not assignable to... Remove this comment to see the full error message
            this.disposers.push(this.doSyncLocalStorage())
            // clear localStorage on obj.is_changed === false
            // it happens after apply or cancel changes
            // @ts-expect-error TS(2345) FIXME: Argument of type 'IReactionDisposer' is not assign... Remove this comment to see the full error message
            this.disposers.push(reaction(
                () => this.obj.is_changed,
                (value) => { !value && localStorage.removeItem(name) },
            ))
        }

        // register autoReset callback if options was changed
        // @ts-expect-error TS(2345) FIXME: Argument of type '() => IReactionDisposer' is not ... Remove this comment to see the full error message
        props.autoReset && this.options && this.disposers.push(() => {
            return reaction(
                // don't watch on options.items, because it is array
                // we can watch on option.filter state, it's equal but we have to wait next tick
                () => this.options.filters.URLSearchParams.toString(),
                // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                () => setTimeout(() => props.autoReset(this)),
                { fireImmediately: true },
            )
        })

        this.debug && console.log('constructor', this.field)
    }

    destroy () {
        this.debug && console.log('destroy', this.field)
        while (this.disposers.length) {
            // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            this.disposers.pop()()
        }
    }

    @action
    setError (value) {
        this.error = value
    }

    @action
    set (value) {
        // update input value
        this.debug && console.log('[Debug ModelInput] pre', value, this.value)
        this.value = value
        this.debug && console.log('[Debug ModelInput] after', value, this.value)
        // update obj.field
        if (this.obj && this.field && this.obj[this.field] !== value) {
            this.debug && console.log(`[Debug ModelInput] obj.${this.field} <- input`, this.obj[this.field], this.value)
            this.obj[this.field] = this.value
        }
        // update filter
        if (this.filter && this.filter.value !== value) {
            this.debug && console.log(`[Debug ModelInput] filter.${this.filter.field} <- input`, this.filter.value, this.value)
            this.filter.set(value)
        }
    }

    doSyncLocalStorage (): () => void {
        const name = `${this.syncLocalStorage}${this.field}`
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        const value = serialize(localStorage.getItem(name), this.type)
        if (this.value !== value) {
            this.set(value)
        }
        return reaction(
            () => this.value,
            (value) => {
                // console.log('do local', value)
                if (value !== undefined) {
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
                    localStorage.setItem(name, deserialize(value, this.type))
                }
            },
            { fireImmediately: true },
        )
    }

    doSyncURL (): () => void {
        const name = this.field
            ? `${this.syncURL}${this.field}`
            : `${this.syncURL}${this?.filter.field}`
        this.debug && console.log('[Debug ModelInput] syncURL', name)
        // init from URL Search Params
        const searchParams = new URLSearchParams(window.location.search)
        if (searchParams.has(name)) {
            // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
            this.set(serialize(searchParams.get(name), this.type))
        }
        // watch for changes and update URL
        return reaction(
            () => this.value,
            (value) => {
                const searchParams = new URLSearchParams(window.location.search)
                if ((value === '' || value === undefined || (Array.isArray(value) && !value.length))) {
                    this.debug && console.log(`[Debug ModelInput] url.${name} <- input.${this.field}`, value)
                    searchParams.delete(name)
                } else {
                    this.debug && console.log(`[Debug ModelInput] url.${name} <- input.${this.field}`, value)
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
                    searchParams.set(name, deserialize(this.value, this.type))
                }
                // update URL
                window.history.pushState(null, '', `${window.location.pathname}?${searchParams.toString()}`)
            },
            { fireImmediately: true },
        )
    }
}
