import { Select } from '@/components/base'

import { AssetTypeSelectProps, AssetTypeSelectOption } from './AssetTypeSelect.types'

export const AssetTypeSelect = (props: AssetTypeSelectProps) => {
    const {
        options,
        placeholder = 'Select Asset Class',
        optionsWidth = '148px',
        optionsMinWidth = '148px',
        ...rest
    } = props

    return (
        <Select<AssetTypeSelectOption>
            options={options}
            labelFn={(option) => option.label}
            idFn={(option) => option.value}
            placeholder={placeholder}
            optionsWidth={optionsWidth}
            optionsMinWidth={optionsMinWidth}
            {...rest}
        />
    )
}
