import { NumberParam } from 'use-query-params'

export const MONTH_RANGE_START_QUERY_PARAM = {
    name: 'start-month',
    type: NumberParam,
    syncLocalStorage: false,
}

export const MONTH_RANGE_END_QUERY_PARAM = {
    name: 'end-month',
    type: NumberParam,
    syncLocalStorage: false,
}
