import React, { useEffect, useMemo, useState } from 'react'

import { generatePath, matchPath, Outlet, useNavigate, useParams } from 'react-router'
import { useLocation } from 'react-router-dom'

import {
    ReportingFolderItem,
    useReportingFolderCreateMutation, useReportingFolderDeleteMutation,
    useReportingFolderQuery, useReportingFolderUpdateMutation,
} from '@/api/reportingData/reportingFolder'
import {
    useReportingReportCreateMutation,
    useReportingReportDeleteMutation,
    useReportingReportQuery,
} from '@/api/reportingData/reportingReport'
import { Alert, Button, Confirm, FilesTree, FilesTreeItem, Loader, Popup, TabsProps, TextInput } from '@/components/base'
import { Layout } from '@/components/containers'
import { SubMenu } from '@/components/widgets'
import { OWNER_ROUTES_CONFIG } from '@/core/routes'
import { ReportTypes } from '@/pages/MainPage/UserPages/ReportsPage/ReportPage.types'
import {
    DEFAUILT_REPORT_NAME,
    DEFAULT_REPORT_FOLDER_NAME,
    getReportsTree,
} from '@/pages/MainPage/UserPages/ReportsPage/ReportsPage.constants'
import { useStandardReports } from '@/pages/MainPage/UserPages/ReportsPage/ReportsPage.hooks'
import { getIncrementalName } from '@/utils/getIncrementalName'
import { isFeatureOn } from '@/utils/isFeatureOn'
import { getQueryStringToObject } from '@/utils/routing/getQueryStringToObject'
import { getRoute } from '@/utils/routing/getRoute'

export const ReportsPage = () => {
    const navigate = useNavigate()
    const { id: customReportId } = useParams()

    const standardReports = useStandardReports()
    const [activeReportsList, setActiveReportsList] = useState<FilesTreeItem[]>([])
    const [showDeleteFolderData, setShowDeleteFolderData] = useState<FilesTreeItem | null>(null)
    const [showRenameFolderData, setShowRenameFolderData] = useState<FilesTreeItem | null>(null)
    const [newFolderName, setNewFolderName] = useState<string>('')

    const location = useLocation()

    const [activeReportType, setActiveReportType] = useState<ReportTypes | undefined>(() => {
        const customReportsPageMatch = Boolean(matchPath({
            path: OWNER_ROUTES_CONFIG.REPORTS_CUSTOM.path,
            end: true,
        }, location.pathname))

        const standardReportsPageMatch = Boolean(matchPath({
            path: OWNER_ROUTES_CONFIG.REPORTS.path,
            end: false,
        }, location.pathname)) && !customReportsPageMatch

        // Identify initial state from url
        return customReportsPageMatch ? 'custom' : standardReportsPageMatch ? 'standard' : undefined
    })

    const [reportForDelete, setReportForDelete] = useState<FilesTreeItem | null>()

    const reportingReportQuery = useReportingReportQuery({}, {
        enabled: activeReportType === 'custom',
    })
    const reportingFolderQuery = useReportingFolderQuery()
    const reportingReportCreateMutation = useReportingReportCreateMutation()
    const reportingReportDeleteMutation = useReportingReportDeleteMutation()
    const reportingFolderCreateMutation = useReportingFolderCreateMutation()
    const reportingFolderDeleteMutation = useReportingFolderDeleteMutation()
    const reportingFolderUpdateMutation = useReportingFolderUpdateMutation()

    useEffect(() => {
        // Configure list of custom reports
        if (reportingReportQuery.data && reportingReportQuery.isFetched && reportingFolderQuery.isFetched && activeReportType === 'custom') {
            const customTree = getReportsTree(reportingFolderQuery.data!, reportingReportQuery.data, null)
            setActiveReportsList(customTree)
        } else if (activeReportType === 'standard') {
            // Configure list of standard reports
            setActiveReportsList(standardReports)
        }
    }, [reportingReportQuery.isFetched, reportingFolderQuery.isFetched, activeReportType, reportingReportQuery.data, reportingFolderQuery.data])

    const isRootPath = useMemo(() => Boolean(matchPath({
        path: location.pathname,
        end: true,
    }, OWNER_ROUTES_CONFIG.REPORTS.path)), [location.pathname])

    // Standard reports navigation logic
    useEffect(() => {
        if (activeReportType !== 'standard') {
            return
        }

        if (isRootPath) {
            const reportPath = standardReports[0].children?.[0].path || standardReports[0].path
            reportPath && (navigate(reportPath))
        }
    }, [location.pathname, activeReportType])

    // Custom reports navigation logic
    useEffect(() => {
        if (activeReportType !== 'custom' || !reportingReportQuery.isFetched) {
            return
        }

        // Possible only for custom reports
        const isEmptyPath = Boolean(matchPath({
            path: location.pathname,
            end: true,
        }, OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_EMPTY.path))

        if ((isEmptyPath || isRootPath) && ((reportingReportQuery.data?.length || 0) > 0)) {
            const id = reportingReportQuery.data?.[0]?.id
            const reportPath = id ? generatePath(OWNER_ROUTES_CONFIG.REPORTS_CUSTOM.path, { id }) : null
            reportPath && (navigate(reportPath))
        } else if (reportingReportQuery.data?.length === 0) {
            navigate(OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_EMPTY.path)
        }
    }, [activeReportType, reportingReportQuery.isFetched, reportingReportQuery.data, location.pathname])

    const handleTreeItemClick = (item: FilesTreeItem) => {
        item.path && (navigate(item.path))
    }

    const handleReportTypeChange = (key: ReportTypes) => {
        // eslint-disable-next-line no-console -- Delete after 20 jul
        console.log('ReportTypeChange before click', activeReportType)

        setActiveReportType(key)

        navigate(OWNER_ROUTES_CONFIG.REPORTS.path)
    }

    const handleCreateReport = async (parentItem?: FilesTreeItem | null) => {
        const newReportName = getIncrementalName(DEFAUILT_REPORT_NAME, reportingReportQuery.data!.map((report) => report.name))

        const newItem = await reportingReportCreateMutation.mutateAsync({
            name: newReportName,
            parent_id: parentItem?.id ? Number(parentItem?.id) : undefined,
        })

        const editModeLink = getRoute('REPORTS_CUSTOM_EDIT', { id: newItem.id })
        navigate(editModeLink)
    }

    const handleCreateFolder = async (parentItem: FilesTreeItem | null): Promise<ReportingFolderItem> => {
        const name = getIncrementalName(DEFAULT_REPORT_FOLDER_NAME, reportingFolderQuery.data?.map((report) => report.name) ?? [])
        const newItem = await reportingFolderCreateMutation.mutateAsync({
            name,
            parent_id: parentItem?.id ? Number(parentItem.id) : undefined,
        })
        return newItem
    }

    const handleDeleteReport = async () => {
        if (!reportForDelete) {
            return
        }

        const matchedPath = matchPath(OWNER_ROUTES_CONFIG.REPORTS_CUSTOM.path, reportForDelete.path?.toString() || '')
        const id = matchedPath?.params.id

        if (id) {
            await reportingReportDeleteMutation.mutateAsync({
                id,
            })
        }

        if (customReportId === id) {
            navigate(OWNER_ROUTES_CONFIG.REPORTS.path)
        }

        setReportForDelete(null)
    }

    const handleDeleteFolder = async () => {
        if (!showDeleteFolderData) {
            return
        }

        const reportsInFolder = reportingReportQuery.data?.filter((report) => report.parent_id === showDeleteFolderData.id)

        for (const report of reportsInFolder || []) {
            await reportingReportDeleteMutation.mutateAsync({
                id: report.id,
            })
        }

        const foldersInFolder = reportingFolderQuery.data?.filter((folder) => folder.parent_id === showDeleteFolderData.id)

        for (const folder of foldersInFolder || []) {
            await reportingFolderDeleteMutation.mutateAsync({
                id: folder.id,
            })
        }

        const isUserOnDeletedReport = reportsInFolder?.some((report) => report.id.toString() === customReportId)
        if (isUserOnDeletedReport) {
            navigate(OWNER_ROUTES_CONFIG.REPORTS.path)
        }

        await reportingFolderDeleteMutation.mutateAsync({
            id: showDeleteFolderData.id,
        })

        setShowDeleteFolderData(null)
    }

    const handleRenameFolder = async () => {
        if (!showRenameFolderData) {
            return
        }

        await reportingFolderUpdateMutation.mutateAsync({
            id: Number(showRenameFolderData.id),
            updatedItemData: {
                name: newFolderName,
            },
        })

        setShowRenameFolderData(null)
    }

    const navItems: TabsProps['options'] = useMemo(() => {
        const useCustomReports = isFeatureOn('custom_reports')
        const res = new Map<ReportTypes, string >([])

        res.set('standard', 'Standard')

        if (useCustomReports) {
            res.set('custom', 'Custom')
        }

        return res
    }, [])

    const handleEditClick = (report: FilesTreeItem) => {
        const matchedPath = matchPath(OWNER_ROUTES_CONFIG.REPORTS_CUSTOM.path, report.path?.toString() || '')
        const id = matchedPath?.params.id
        const editModeLink = id ? getRoute('REPORTS_CUSTOM_EDIT', { id }, getQueryStringToObject(location.search)) : null
        editModeLink && (navigate(editModeLink))
    }

    const useCustomReports = isFeatureOn('custom_reports')

    const isCustomReportsLoading = !reportingReportQuery.isFetched || !reportingFolderQuery.isFetched

    return (
        <>
            <SubMenu
                onChangeTab={handleReportTypeChange}
                navItems={navItems}
                activeNavItem={activeReportType}
                title={OWNER_ROUTES_CONFIG.REPORTS.title}
                headerButtonActions={useCustomReports ? [
                    {
                        label: 'Create Folder',
                        onClick: () => handleCreateFolder(null),
                    },
                    {
                        label: 'Create Report',
                        onClick: () => handleCreateReport(),
                    }] : []}
            >
                {((activeReportsList.length === 0) && (activeReportType === 'custom')) ? (
                    <Layout justify='center' mt={12} width='100%'>
                        {(!isCustomReportsLoading) ? (
                            <Button
                                text='Create a New Report'
                                theme='secondary'
                                icon='plus'
                                loading={reportingReportCreateMutation.isPending}
                                onClick={() => handleCreateReport(null)}
                            />
                        ) : (
                            <Loader centered/>
                        )}
                    </Layout>
                ) : (
                    <FilesTree
                        onItemClick={handleTreeItemClick}
                        items={activeReportsList}
                        isItemActive={(item) => {
                            if (!item.path) return false
                            const matchedPath = matchPath(
                                { path: item.path, end: false },
                                location.pathname)
                            return Boolean(matchedPath)
                        }}
                        defaultFoldersState
                        folderActions={activeReportType === 'custom' ? [
                            {
                                label: 'Create Folder',
                                onClick: handleCreateFolder,
                            },
                            {
                                label: 'Create Report',
                                onClick: (item) => handleCreateReport(item),
                            },
                            {
                                label: 'Rename Folder',
                                onClick: (item) => {
                                    setNewFolderName(item.name) // Set initial value
                                    setShowRenameFolderData(item)
                                },
                            },
                            {
                                label: 'Delete Folder',
                                onClick: (item) => setShowDeleteFolderData(item),
                            },
                        ] : []}
                        fileActions={activeReportType === 'custom' ? [{
                            label: 'Edit',
                            onClick: handleEditClick,
                        },
                        {
                            label: 'Delete',
                            onClick: (item) => setReportForDelete(item),
                        },
                        ] : []}
                    />
                )}
            </SubMenu>
            {Boolean(showDeleteFolderData) && (
                <Confirm
                    confirmText='Delete Folder'
                    isLoading={reportingFolderDeleteMutation.isPending || reportingReportDeleteMutation.isPending || reportingFolderDeleteMutation.isPending}
                    onConfirm={handleDeleteFolder}
                    onCancel={() => setShowDeleteFolderData(null)}
                >
                    <Alert type='warning'>
                        Are you sure you want to delete this folder and all the reports inside it?
                    </Alert>
                </Confirm>
            )}
            {Boolean(reportForDelete) && (
                <Confirm
                    confirmText='Delete Report'
                    isLoading={reportingReportDeleteMutation.isPending}
                    onConfirm={handleDeleteReport}
                    onCancel={() => setReportForDelete(null)}
                >
                    <Alert type='warning'>
                       Are you sure you want to delete this report?
                    </Alert>
                </Confirm>
            )}
            {Boolean(showRenameFolderData) && (
                <Popup
                    confirmText='Rename Folder'
                    isLoading={reportingFolderUpdateMutation.isPending}
                    onConfirm={handleRenameFolder}
                    onClose={() => setShowRenameFolderData(null)}
                    content={(
                        <Layout mb={32}>
                            <TextInput
                                label='Folder name'
                                placeholder='Enter new folder name'
                                value={newFolderName}
                                onChange={(e) => {
                                    setNewFolderName(e.target.value)
                                }}
                            />
                        </Layout>
                    )}
                />
            )}
            <Outlet/>
        </>
    )
}
