import { useEffect, useState } from 'react'

import { Button, TextInput } from '@/components/base'
import { Layout } from '@/components/containers'

import { TextInputEditableProps } from './TextInputEditable.types'

/**
 * Text input which can be integrated with backend editing logic
 */
export const TextInputEditable = (props: TextInputEditableProps) => {
    const { onSave, loading, ...rest } = props

    const [editMode, setEditMode] = useState(false)
    const [value, setValue] = useState(rest.value)
    const [errorMessage, setErrorMessage] = useState('')

    const handleSave = () => {
        if (editMode) {
            if (!value || (value === '')) {
                setErrorMessage('Field cannot be empty')
                return
            }
            onSave(value)
        }

        setEditMode(true)
    }

    useEffect(() => {
        if (loading) {
            setEditMode(false)
        }
    }, [loading])

    return (
        <Layout gap={8} align='flex-end'>
            <TextInput
                {...rest}
                error={errorMessage.length > 0}
                hint={errorMessage}
                value={value}
                readonly={!editMode}
                onChange={(e) => setValue(e.target.value)}
                endEl={(
                    <Button
                        size='s'
                        theme='flat'
                        disabled={loading}
                        icon={loading ? 'loader' : editMode ? 'check' : 'pencil'}
                        onClick={handleSave}
                    />
                )}
            />
        </Layout>
    )
}
