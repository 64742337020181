import { useState } from 'react'

import { Button, Text } from '@/components/base'
import { Layout } from '@/components/containers'
import { AccordionContainer } from '@/components/containers/AccordionContainer'

import styles from './AlertAccordion.module.scss'
import { AlertAccordionProps } from './AlertAccordion.types'

export const AlertAccordion = (props: AlertAccordionProps) => {
    const { items, description, showAllBtnText, showLessBtnText, defaultCount = 3 } = props

    const [isOpened, setIsOpened] = useState(false)

    const haveItems = Boolean(items.length)

    return (
        <AccordionContainer open={haveItems}>
            {haveItems && (
                <div className={styles.alert}>
                    <div>
                        <div className={styles.message}>
                            <>
                                <Text variant='smallTextMediumDefault'>
                                    {description}
                                </Text>
                            </>
                        </div>
                        <div className={styles.accounts}>
                            <Layout direction='column' gap={4}>
                                {(!isOpened ? items.slice(0, defaultCount) : items).map((item, i) => (
                                    <Text variant='smallTextMediumDefault' key={i + item}>{item}</Text>
                                ))}
                            </Layout>
                        </div>
                        {defaultCount < items.length && (
                            <>
                                {
                                    (!isOpened && haveItems) && (
                                        <Button
                                            text={showAllBtnText} onClick={() => { setIsOpened(true) }} theme='flat'
                                            icon='arrowDown'
                                        />
                                    )
                                }
                                {
                                    (isOpened && haveItems) && (
                                        <Button
                                            text={showLessBtnText} onClick={() => { setIsOpened(false) }} theme='flat'
                                            icon='arrowUp'
                                        />
                                    )
                                }
                            </>
                        )}
                    </div>
                </div>
            )}
        </AccordionContainer>
    )
}
