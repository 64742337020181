import { Model, model, field, foreign } from 'mobx-orm'

import { Asset } from '@/models/asset'
import { TrialBalanceLedger } from '@/models/ledger'

import { api } from '../../http-adapter'

@api('asset-trial-balance')
@model
export class AssetTrialBalance extends Model {
    @field asset_id?:  number
    @field ledger_id?: number
    @field order:      number

    @foreign(Asset) readonly asset:               Asset
    @foreign(TrialBalanceLedger) readonly ledger: TrialBalanceLedger
}
