import { Model, model, field, foreign, many } from 'mobx-orm'

import { Account } from './Account'
import { RentRollLedger as Ledger } from './Ledger'
import { api } from '../../http-adapter'

@api('rent-roll-category')
@model
export class AccountCategory extends Model {
    @field name?:      string
    @field ledger_id?: number
    @field parent_id?: number
    @field lft?:       number
    @field rght?:      number
    @field tree_id?:   number
    @field level?:     number

    @foreign(Ledger) readonly ledger: Ledger

    parent:   AccountCategory
    children: AccountCategory[]
    accounts: Account[]

    get parents () {
        const parents: AccountCategory[] = []
        let current: AccountCategory = this // eslint-disable-line @typescript-eslint/no-this-alias
        while (current.parent) {
            parents.push(current.parent)
            current = current.parent
        }
        return parents
    }
}
foreign(AccountCategory, 'parent_id')(AccountCategory.prototype, 'parent')
many(AccountCategory, 'parent_id')(AccountCategory, 'children')
