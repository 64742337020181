import { PointerEvent, useEffect, useState } from 'react'

import { BuiltInSearchInput, Button, Popover } from '@/components/base'
import { Layout } from '@/components/containers'
import { generatedColors } from '@/styles/tokens/Tokens.constants'
import { mixpanelTrack } from '@/utils/mixpanel'

import { SelectOptionBase } from './options'
import { SelectOptionListBase } from './optionsList/SelectOptionListBase'
import { OPTION_LIST_DEFAULT_MAX_HEIGHT, OPTION_LIST_DEFAULT_MAX_WIDTH } from './Select.constants'
import styles from './Select.module.scss'
import { SelectProps, SelectOptionDefault } from './Select.types'
import { SelectSelectedItemsBase } from './selectedItems'
import { SelectTriggerBase } from './triggers'

/**
 * Generic component for select with options
 * @description
 * {@link SelectOptionDefault} is used as the default type for option.<br>
 * For correct type checking provide your own `OptionType`, `labelFn` and `idFn`.
 * ```typescript
 *
 * interface CustomOption { id: number; name: string }
 * <Select<CustomOption>
 *     labelFn={(option) => option.name}
 *     idFn={(option) => option.id.toString()}
 *     {...otherProps}
 * />
 * ```
 *
 * @todo
 * NOTE: Not implemented props:
 * ```
 * - sorted
 * - headerComponent
 * - beforeOptionsComponent
 * - afterOptionsComponent
 * ```
 */
export const Select = <Option = SelectOptionDefault>(props: SelectProps<Option>) => {
    const {
        options = [],
        selected = [],

        onClose,
        onChange,
        labelFn = (option): string => option?.label?.toString?.(),
        idFn = (option): string => option?.value?.toString?.(),

        placeholder,
        multiSelectPlaceholder,
        noItemsPlaceholder,
        searchPlaceholder,

        clearText,

        disabled = false,
        readonly = false,
        multiSelect = false,
        hideSelectAll = false,
        unselectOnClick = false,
        search = false,
        searchOptionsThreshold = 5,

        optionsWidth,
        optionsMinWidth,
        optionsMaxWidth = `${OPTION_LIST_DEFAULT_MAX_WIDTH}px`,
        optionsMaxHeight = `${OPTION_LIST_DEFAULT_MAX_HEIGHT}px`,

        triggerComponent: Trigger = SelectTriggerBase,
        selectedItemsComponent: SelectedItems = SelectSelectedItemsBase,
        optionsComponent: OptionsComponent = SelectOptionListBase,
        optionComponent: OptionComponent = SelectOptionBase,

        afterOptionsComponent = null,

        defaultActiveOptions = 'none',

        testId,
        dataReady = true,

        label,

        ...rest
    } = props

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    const [filterValue, setFilterValue] = useState<string>(null)
    const filterRegex = new RegExp(filterValue, 'i')
    const open = Boolean(anchorEl)

    const filteredOptions = options
        .filter((option) => !filterValue || filterRegex.test(labelFn(option)))

    const handleClick = (e: PointerEvent<HTMLElement>) => {
        if (readonly || disabled) {
            return
        }

        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        onClose?.()
    }

    const handleChange = (clickedOption: Option) => {
        const isAlreadySelected = selected.some((option) => idFn(option) === idFn(clickedOption))

        if (multiSelect) {
            const newSelected = isAlreadySelected
                ? selected.filter((option) => idFn(option) !== idFn(clickedOption))
                : [...selected, clickedOption]
            onChange(newSelected)
        } else {
            isAlreadySelected && unselectOnClick ? onChange([]) : onChange([clickedOption])
            handleClose()
        }

        mixpanelTrack('Select Change', {
            label,
        })
    }

    useEffect(() => {
        if (!dataReady) {
            return
        }

        if (!selected.length && options.length) {
            if (defaultActiveOptions === 'first') {
                onChange([options[0]])
            } else if (defaultActiveOptions === 'all' && multiSelect) {
                onChange(options)
            }
        }
    }, [options, selected, dataReady, defaultActiveOptions, multiSelect])

    const handleSelectAll = () => {
        onChange(options)
    }

    const handleClearAll = () => {
        if (defaultActiveOptions === 'first' && options.length) {
            onChange([options[0]])
        } else {
            onChange([])
        }
    }

    const searchIsOn = search || (options.length > searchOptionsThreshold)

    return (
        <>
            <Trigger
                open={open}
                readonly={readonly}
                disabled={disabled}
                onClick={handleClick}
                testId={testId || 'trigger'}
                label={label}
                {...rest}
            >
                <SelectedItems
                    selected={selected}
                    labelFn={labelFn}
                    idFn={idFn}
                    placeholder={placeholder}
                    multiSelectPlaceholder={multiSelectPlaceholder}
                    active={open}
                />
            </Trigger>
            <Popover
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                {searchIsOn && (
                    <div className={styles.search}>
                        <BuiltInSearchInput
                            onChange={(value) => { setFilterValue(value) }}
                            value={filterValue}
                            placeholder={searchPlaceholder}
                            dataTestId='search'
                        />
                    </div>
                )}
                {multiSelect && !hideSelectAll && (
                    <Layout
                        style={{ backgroundColor: generatedColors.colorsSecondaryPurple25 }}
                        px={12}
                        py={8}
                        gap={12}
                        justify='space-between'
                    >
                        <Button
                            theme='flat' text='Select All'
                            onClick={handleSelectAll}
                            dataTestId='selectAll'
                        />
                        <Button
                            theme='flat'
                            text={clearText || 'Clear All'}
                            onClick={handleClearAll}
                            dataTestId='clearAll'
                        />
                    </Layout>
                )}

                <OptionsComponent
                    optionComponent={OptionComponent}
                    options={filteredOptions}
                    selected={selected}
                    labelFn={labelFn}
                    idFn={idFn}
                    onChange={handleChange}
                    noItemsPlaceholder={noItemsPlaceholder}
                    width={optionsWidth}
                    minWidth={optionsMinWidth}
                    maxWidth={optionsMaxWidth}
                    maxHeight={optionsMaxHeight}
                />
                {afterOptionsComponent}
            </Popover>
        </>
    )
}
