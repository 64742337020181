import { observer } from 'mobx-react-lite'
import { Outlet, useNavigate } from 'react-router'

import { useCompanyInviteQuery } from '@/api/company/companyInvite'
import { Button } from '@/components/base'
import { ReactQueryTable } from '@/components/reactQuery'
import { getRouteConfig } from '@/utils/routing/getRouteConfig'

import { gridOptions } from './CompanyInvitesPage.constants'
import styles from './CompanyInvitesPage.module.scss'
import { CompanyInvitesPageProps } from './CompanyInvitesPage.types'

export const CompanyInvitesPage = observer((props: CompanyInvitesPageProps) => {
    const { invitePopupPath } = props

    const navigate = useNavigate()

    const companyInviteQuery = useCompanyInviteQuery()

    return (
        <>
            <div className={styles.companyInvitesSubpage}>
                <div className={styles.headerBox}>
                    <div
                        className={styles.filters}
                    />
                    <Button
                        icon='plus'
                        text={getRouteConfig('COMPANY_PROFILE_INVITES_NEW').title}
                        onClick={() => { navigate(invitePopupPath) }}
                    />
                </div>
                <ReactQueryTable
                    query={companyInviteQuery}
                    {...gridOptions}
                />
            </div>
            <Outlet/>
        </>
    )
})
