import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { InputBox } from '@/components/containers'

import styles from './Textarea.module.scss'
import { TextareaProps } from './Textarea.types'

/**
 * Textarea
 */
export const Textarea = observer((props: TextareaProps) => {
    const {
        onChange,
        value = '',
        label,
        className,
        testId = 'textarea',
        disabled = false,
        readonly = false,
        labelInside = false,
        maxWidth,
        width = '100%',
        placeholder,
        rows = 3,
    } = props

    const textareaClassName = cn(styles.textarea, className, {
        // [styles.resize]: resize,
    })

    return (
        <InputBox
            disabled={disabled}
            label={label}
            maxWidth={maxWidth}
            className={className}
            labelInside={labelInside}
            width={width}
            testId={testId}
        >
            <textarea
                className={textareaClassName}
                value={value}
                disabled={disabled}
                readOnly={readonly}
                placeholder={placeholder}
                onChange={onChange}
                rows={rows}
            />
        </InputBox>
    )
})
