import { getRequestQueries } from '@/api/utils/getRequestQueries'
import { CompanyInviteSendStatus } from '@/models/company'

export interface CompanyInviteSendItem {
    company_invite_id?: number
    timestamp?: string
    email?: string
    status: CompanyInviteSendStatus
    details?: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchCompanyInviteSendQueryParams {}

export const COMPANY_INVITE_SEND_URL = 'company-invite-send'

export const {
    useItemsQuery: useCompanyInviteSendQuery,
    useItemUpdateMutation: useCompanyInviteSendUpdateMutation,
    useItemsCountQuery: useCompanyInviteSendCountQuery,
    useItemCreateMutation: useCompanyInviteSendCreateMutation,
    useItemDeleteMutation: useCompanyInviteSendDeleteMutation,
} = getRequestQueries<CompanyInviteSendItem, FetchCompanyInviteSendQueryParams>({
    url: COMPANY_INVITE_SEND_URL,
})
