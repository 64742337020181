import React, { forwardRef, RefObject } from 'react'

import { AgGridReact } from '@ag-grid-community/react'

import { AgGridTable } from '@/components/tables/AgGridTable'

import { ReactQueryTableProps } from './ReactQueryTable.types'

export const ReactQueryTable = forwardRef((props: ReactQueryTableProps, ref: RefObject<AgGridReact>) => {
    const { query, countQuery } = props

    const allItemsHaveId = query.data?.every((item) => item.id)

    return (
        <AgGridTable
            ref={ref}
            getRowId={allItemsHaveId ? ({ data }: { data: { id: string } }) => data.id : undefined}
            items={query.data as unknown[]}
            totalItemsCount={countQuery?.data}
            // Show only initial loading
            loading={!query.isFetched}
            {...props}
        />
    )
})
