import { Model, model, field, foreign, many } from 'mobx-orm'

import { SubmittedFile } from './SubmittedFile'
import { api } from '../../http-adapter'

@api('data-submission-error')
@model
export class SubmittedFileError extends Model {
    @field submitted_file_id: number
    @field place?:            string
    @field error_text?:       string
    @field current_value?:    string
    @field code?:             string

    @foreign(SubmittedFile) readonly submitted_file: SubmittedFile

    static DATA_SUBMISSION_LEVEL = 200
}

many(SubmittedFileError, 'submitted_file_id')(SubmittedFile, 'errors')
