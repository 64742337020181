import { Model, model, field } from 'mobx-orm'

import { api } from '../../http-adapter'

@api('data-point-error-code')
@model
export class DataPointErrorCode extends Model {
    @field code:             number
    @field error_name?:      string
    @field user_error_name?: string
    @field type?:            string
}
