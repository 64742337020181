import { ChangeEvent } from 'react'

import cn from 'classnames'

import { Text } from '@/components/base/Text'

import CheckMark from './checkMark.svg'
import styles from './Switch.module.scss'
import { SwitchProps } from './Switch.types'

export const Switch = (props: SwitchProps) => {
    const {
        onChange,
        disabled,
        checked,
        className,
        label,
        labelPlacement = 'end',
    } = props

    const switchClassNames = cn(styles.switch, className, {
        [styles.checked]: checked,
        [styles.disabled]: disabled,
        [styles.labelPlacementStart]: labelPlacement === 'start',
    })

    const thumbClassNames = cn(styles.thumb, {
        [styles.thumb_checked]: checked,
    })

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange?.(e.target.checked)
    }

    return (
        <label className={switchClassNames}>
            <input
                className={styles.input}
                type='checkbox'
                role='switch'
                checked={checked}
                disabled={disabled}
                onChange={handleChange}
            />
            <span className={styles.track} aria-hidden='true'>
                <span className={thumbClassNames} aria-hidden='true'>
                    {checked && (<CheckMark/>)}
                </span>
            </span>
            {label && (
                (typeof label === 'string')
                    ? (
                        <Text
                            text={label}
                            color='colorsSecondaryGrey600'
                            variant='smallTextMediumDefault'
                        />
                    )
                    : label
            )}
        </label>
    )
}
