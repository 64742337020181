import { useAccountQuery } from '@/api/account'
import { useSourceAccountQuery } from '@/api/sourceAccount'

import { AgGridAccountCellProps } from './AgGridAccountCell.types'

export const AgGridAccountCell = (props: AgGridAccountCellProps) => {
    const { accountId, type } = props

    // Fetch all because they are already in the cache
    const accountQuery = useAccountQuery({}, {
        enabled: type === undefined,
    })
    const sourceAccountsQuery = useSourceAccountQuery({}
        , {
            enabled: type === 'trialBalanceSource',
        })

    const data = type === undefined
        ? accountQuery.data
        : type === 'trialBalanceSource'
            ? sourceAccountsQuery.data : []

    // @ts-expect-error
    const account = data?.find(el => el.id === Number(accountId))

    return (
        <>
            {
                account ? (
                    `${account.code} ${account.name}`
                ) : ''
            }
        </>
    )
}
