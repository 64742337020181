export const enum CompanyUserMode {
    Admin = 1,
    Owner = 2,
    Manager = 3,
}

export const MODEL_NAME_BY_ID: Record<CompanyUserMode, string> = {
    [CompanyUserMode.Admin]: 'Admin',
    [CompanyUserMode.Owner]: 'Owner',
    [CompanyUserMode.Manager]: 'Manager',
}
