import { generatePath, useNavigate, useParams } from 'react-router'

import { ActionsCell, ActionsCellItem } from '@/components/legacy/tables/cells'
import { CompanyUserMode } from '@/core/modes'
import { getRouteConfig } from '@/utils/routing/getRouteConfig'

import { MappingListActionsCellProps } from './MappingListActionsCell.types'

const editPath = getRouteConfig('ASSET_MAPPING_TEMPLATES_BY_ID_ADD_MAPPING', CompanyUserMode.Owner).path
const deletePath = getRouteConfig('ASSET_MAPPING_TEMPLATES_BY_ID_DELETE_MAPPING', CompanyUserMode.Owner).path

export const MappingListActionsCell = (props: MappingListActionsCellProps) => {
    const { data } = props

    const navigate = useNavigate()
    const { templateId } = useParams()

    const actionsCellItems: ActionsCellItem[] = [
        {
            icon: 'pencil',
            text: 'Edit Mapping',
            onClick: () => {
                // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                navigate(generatePath(editPath, { templateId, mappingId: data.id.toString() }))
            },
        },
        {
            icon: 'delete',
            text: 'Delete Mapping',
            onClick: () => {
                // TODO: popup with confirmation should not use separate page, we have to path context inside pupup
                // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                navigate(generatePath(deletePath, { templateId, mappingId: data.id.toString() }))
            },
        },
    ]

    return (
        <ActionsCell
            items={actionsCellItems}
        />
    )
}
