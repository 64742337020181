import { Model, model, field, foreign } from 'mobx-orm'

import { Template } from './Template'
import { api } from '../../http-adapter'

@api('csv-template-field')
@model
export class TemplateField extends Model {
    @field template_id?:  number
    @field source_label?: string
    @field new_name?:     string
    @field order?:        number

    @foreign(Template) readonly template: Template
}
