import { useMemo } from 'react'

import getMonth from 'date-fns/getMonth'
import getYear from 'date-fns/getYear'
import { observer } from 'mobx-react-lite'

import { Icon, LegacySelect } from '@/components/base'

import { DATEPICKER_MONTHS } from './DatePickerHeader.constants'
import style from './DatePickerHeader.module.scss'
import { DatePickerHeaderProps } from './DatePickerHeader.types'

// TODO: Change arrow buttons to IconButton (new component)
export const DatePickerHeader = observer((props: DatePickerHeaderProps) => {
    const {
        isMonthPicker,
        decreaseYear,
        increaseYear,
        decreaseMonth,
        increaseMonth,
        changeYear,
        changeMonth,
        date,
        years,
        prevYearButtonDisabled,
        nextYearButtonDisabled,
        nextMonthButtonDisabled,
        prevMonthButtonDisabled,
    } = props

    const handleNextMonthClick = () => {
        if (!nextMonthButtonDisabled) {
            increaseMonth()
        }
    }

    const handleNextYearClick = () => {
        if (!nextYearButtonDisabled) {
            increaseYear()
        }
    }

    const handlePrevMonthClick = () => {
        if (!prevMonthButtonDisabled) {
            decreaseMonth()
        }
    }

    const handlePrevYearClick = () => {
        if (!prevYearButtonDisabled) {
            decreaseYear()
        }
    }

    const yearsOptions = useMemo(() => years.map((option) => ({
        value: option.toString(),
        label: option.toString(),
    })), [])

    const monthsOptions = useMemo(() => DATEPICKER_MONTHS.map((option, i) => ({
        value: i.toString(),
        label: option,
    })), [])

    return (
        <div className={style.navigationHeader}>
            <Icon
                dataTestId='prevButton'
                name='arrowLeft'
                size='l'
                color={((isMonthPicker && prevYearButtonDisabled) || (!isMonthPicker && prevMonthButtonDisabled)) ? 'colorsSecondaryGrey400' : 'colorsPrimaryPurple'}
                onClick={isMonthPicker ? handlePrevYearClick : handlePrevMonthClick}
            />
            <div className={style.selectsBox}>
                <LegacySelect
                    placeholder='Year'
                    options={yearsOptions}
                    customValue={getYear(date)?.toString()}
                    onChange={({ value }) => {
                        changeYear(Number(value))
                    }}
                />
                {!isMonthPicker && (
                    <LegacySelect
                        placeholder='Month'
                        options={monthsOptions}
                        customValue={getMonth(date)?.toString()}
                        onChange={({ value }) => {
                            changeMonth(Number(value))
                        }}
                    />
                )}
            </div>
            <Icon
                dataTestId='nextButton'
                name='arrowRight'
                color={((isMonthPicker && nextYearButtonDisabled) || (!isMonthPicker && nextMonthButtonDisabled)) ? 'colorsSecondaryGrey400' : 'colorsPrimaryPurple'}
                size='l'
                onClick={isMonthPicker ? handleNextYearClick : handleNextMonthClick}
            />
        </div>
    )
})
