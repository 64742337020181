import { Downloader } from '@ag-grid-community/csv-export'
import { Model as BaseModel, QueryBase, QueryX } from 'mobx-orm'

import { getFullFilter, HttpAdapter } from '@/http-adapter'
import http from '@/http.service'
import { notify } from '@/utils/notify'

interface DownloadCSVParams<M extends Model> {
    query?:              QueryBase<M> | QueryX<M>
    id?:                 string
    name?:               string
    onException?:        (e: Error) => void
    showWarningIfEmpty?: boolean
}

export class Model extends BaseModel {
    static __adapter: HttpAdapter<Model>

    static async downloadCSV<M extends typeof Model, T extends InstanceType<M>> (this: M, params: DownloadCSVParams<T> & ({ query: DownloadCSVParams<T>['query'] } | { id: DownloadCSVParams<T>['id'] })) {
        const {
            query,
            id,
            name,
            onException = (e) => console.error(e),
            showWarningIfEmpty = false,
        } = params

        let queryString = ''
        if (query != null) {
            const queryParams = new URLSearchParams(query instanceof QueryX ? query.URLSearchParams : getFullFilter(query.selector))
            queryParams.delete('__limit')
            queryParams.delete('__offset')
            queryString = `?${queryParams.toString()}`
        }
        const additionalPath = id ? `${id}/csv` : 'csv'
        const url = `${this.__adapter.endpoint}${additionalPath}/${queryString}`
        try {
            const { data } = await http.get!(url)

            if (!data && showWarningIfEmpty) {
                notify.warn('No data to download')
                return
            }

            const packagedFile = new Blob([data], { type: 'text/plain' })
            let filename = `${name || this.__adapter.endpoint}.csv`
            filename = filename.replace('/', '')
            Downloader.download(filename, packagedFile)
        } catch (e) {
            onException(e)
        }
    }
}
