/*
 * Get obj from Backend or create new one empty, then call callback(obj) and return obj.
    * @param Model - Model class
    * @param id - id of object
    * @param callback - function that will be called with obj as argument, use it for setter of React.useState
    * @returns obj
 */
// TODO: MOBX-ORM: move to mobx-orm
export const getOrCreate = async (Model, id: number, callback) => {
    let obj
    if (id) {
        obj = await Model.__adapter.get(id)
    }
    // ignore if we have no object with this id, just create new one empty
    if (!obj) {
        obj = new Model()
    }
    if (callback) {
        callback(obj)
    }
    return obj
}
