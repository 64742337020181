import { Model, model, field, foreign, many } from 'mobx-orm'

import { CompanyUserGroupInLedgerHistory } from './CompanyUserGroupInLedgerHistory'
import { TrialBalanceLedger as Ledger } from './Ledger'
import { api } from '../../http-adapter'
import { CompanyUserGroup } from '../company'
import { AccessLevel } from '../core'

@api('company-user-group-in-ledger')
@model
export class CompanyUserGroupInLedger extends Model {
    @field ledger_id?:             string
    @field company_user_group_id?: number
    @field level?:                 AccessLevel

    @foreign(Ledger) readonly ledger:                       Ledger
    @foreign(CompanyUserGroup) readonly company_user_group: CompanyUserGroup

    readonly history: CompanyUserGroupInLedgerHistory[]

    get level_name (): string {
        // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
        return AccessLevel[this.level]
    }
}
many(CompanyUserGroupInLedger, 'ledger_id')(Ledger, 'group_permissions')
many(CompanyUserGroupInLedger, 'company_user_group_id')(CompanyUserGroup, 'ledger_permissions')
