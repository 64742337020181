import React, { Suspense } from 'react'

import { observer } from 'mobx-react-lite'

import { ErrorBoundary, Loader } from '@/components/base'
import { SUBMENU_CONTAINER_ID } from '@/constants/ids'

import styles from './ContentWithMenu.module.scss'
import { ContentWithMenuProps } from './ContentWithMenu.types'

export const ContentWithMenu = observer((props: ContentWithMenuProps) => {
    const { menu, content, loading } = props

    const loader = <Loader centered/>

    return (
        <div className={styles.contentWithMenu}>
            <div className={styles.menu}>
                {!loading && (
                    <Suspense fallback={null}>
                        {menu}
                    </Suspense>
                )}
            </div>
            <div id={SUBMENU_CONTAINER_ID}/>
            <div className={styles.content}>
                <div className={styles.contentContainer}>
                    {
                        loading
                            ? (
                                loader
                            ) : (
                                <ErrorBoundary level='content'>
                                    {content}
                                </ErrorBoundary>
                            )
                    }
                </div>
            </div>
        </div>
    )
})
