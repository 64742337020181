import { observer } from 'mobx-react-lite'

import { DateRangePicker } from '@/components/base'
import { dateToMonthInd, monthIndToDateNoTimezoneReset } from '@/utils/date/monthInd'

import { DateMonthPeriodFilterProps } from './DateMonthPeriodFilter.types'

// TODO: move it to utils
function isValidDate (date?: Date): boolean {
    return date instanceof Date && !isNaN(date as any)
}

// DEPRECATED
// REFACTORING: if RangePicker is only month picker then it should use month index format instead of Date
export const DateMonthPeriodFilter = observer((props: DateMonthPeriodFilterProps) => {
    const { from, to, label = 'Time Period' } = props

    const dateFrom = from.value ? monthIndToDateNoTimezoneReset(from.value) : undefined
    const dateTo = to.value ? monthIndToDateNoTimezoneReset(to.value) : undefined

    return (
        <DateRangePicker
            type='month'
            label={label}
            dateFrom={dateFrom}
            dateTo={dateTo}
            onChange={({ dateFrom, dateTo }) => {
                if (isValidDate(dateFrom)) from.set(dateFrom ? dateToMonthInd(dateFrom) : undefined)
                if (isValidDate(dateTo)) to.set(dateTo ? dateToMonthInd(dateTo) : undefined)
            }}
        />
    )
})
