import { computed } from 'mobx'
import { QueryX, Model } from 'mobx-orm'

export class MultiQuery<M extends Model> extends QueryX<M> {
    protected queries: Array<QueryX<Model>>
    protected calcItems: () => M[]

    constructor (queries: Array<QueryX<Model>>, calcItems: () => M[]) {
        super({})

        this.queries = queries
        this.calcItems = calcItems
    }

    @computed
    get isReady () {
        return this.queries.every(q => q.isReady)
    }

    @computed
    get isLoading () {
        return this.queries.some(q => q.isLoading)
    }

    @computed
    get error () {
        return this.queries.map(q => q.error).filter(Boolean).join('\n')
    }

    @computed
    get items () {
        return this.calcItems()
    }

    /*
    * @deprecated use isLoading instead
    */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    get is_loading () {
        return this.isLoading
    }

    /*
    * @deprecated use isReady instead
    */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    get is_ready () {
        return this.isReady
    }
}
