import React from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { generatedColors } from '@/styles/tokens/Tokens.constants'

import styles from './Line.module.scss'
import { LineProps } from './Line.types'

export const Line = observer((props: LineProps) => {
    const {
        color = 'colorsSecondaryGrey100',
        direction = 'horizontal',
        size = null,
        alignSelf,
    } = props

    const measure = size ? direction === 'horizontal' ? 'width' : 'height' : null
    return (
        <hr
            className={cn(styles.line, { [styles['direction_' + direction]]: direction })}
            // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
            style={{ backgroundColor: generatedColors[color], [measure]: size, alignSelf }}
        />
    )
})
