import { HeaderNavigationItem } from '@/components/base'
import { Me } from '@/components/containers'
import { getAvailableRoutes } from '@/core/routes/routes.utils'

export const getAvailableNavigationItemsForUser = (items: HeaderNavigationItem[], me: Me): HeaderNavigationItem[] => {
    const availableRoutes = getAvailableRoutes(me)
    const availablePaths = Object.values(availableRoutes).map(el => el.path)

    return items.filter(({ path }) => {
        if (availablePaths && !availablePaths.includes(path)) {
            return false
        }

        return true
    })
}
