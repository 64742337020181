import React from 'react'

import { useReportingReportCreateMutation } from '@/api/reportingData/reportingReport'
import { Button, Text } from '@/components/base'
import { Layout, PageLayout } from '@/components/containers'
import { OWNER_ROUTES_CONFIG } from '@/core/routes'
import { DEFAUILT_REPORT_NAME } from '@/pages/MainPage/UserPages/ReportsPage/ReportsPage.constants'

export const CustomReportsEmptyPage = () => {
    const reportingReportCreateMutation = useReportingReportCreateMutation()

    const handleCreateReport = () => {
        reportingReportCreateMutation.mutate({
            name: DEFAUILT_REPORT_NAME,
        })
    }

    return (
        <PageLayout>
            <Layout
                gap={12}
                direction='column'
                align='center'
                justify='center'
                height='100%'
            >
                <Text color='colorsSecondaryGrey300' variant='header'>{OWNER_ROUTES_CONFIG.REPORTS_CUSTOM_EMPTY.title}</Text>
                <Layout gap={4} justify='center'>
                    <Text variant='smallTextMediumDefault' color='colorsSecondaryGrey600'>You can</Text>
                    <Button
                        text='create a new report'
                        theme='flat'
                        onClick={handleCreateReport}
                    />
                </Layout>
            </Layout>
        </PageLayout>
    )
}
