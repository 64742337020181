import { BooleanInputOptions, BOOLEAN_INPUT_QUERY_PARAM } from '@/components/baseInputs'

export const EMPTY_ROWS_INPUT_PARAM: {
    name: string
    type: typeof BOOLEAN_INPUT_QUERY_PARAM.type
    options: BooleanInputOptions
} = {
    name: 'empty-rows',
    type: BOOLEAN_INPUT_QUERY_PARAM.type,
    options: [[false, 'Hide'], [true, 'Show']],
}
