import { observer } from 'mobx-react-lite'

import { LegacySelectItemData } from '@/components/base'
import { ListHeader } from '@/components/base/Select/headers'
import { SelectCell } from '@/components/legacy/tables/cells'
import { useQuery } from '@/hooks/useQuery'
import { Account as TrialBalanceAccount } from '@/models/ledger'
import { EDIT_TEMPLATE_MESSAGES } from '@/pages/MainPage/UserPages/MappingPage/MappingTemplatesPage/MappingTemplateEditPage/MappingTemplateEditPage.constants'
import { notify } from '@/utils/notify'

import { AccountSelectCellProps } from './AccountSelectCell.types'

/**
 * @todo Replace with 'MappingEngineCell'
 */
export const AccountSelectCell = observer((props: AccountSelectCellProps) => {
    const { data: templateMap } = props
    const ledgerId = templateMap.template?.ledger_id
    const accountQuery = useQuery(TrialBalanceAccount)

    const filteredByLedger = accountQuery.items.filter((account) => account.ledger_id === ledgerId)
    const options = filteredByLedger.map((account) => ({ value: account.id, label: `${account.code} ${account.name}` }))

    const onChange = async (option: LegacySelectItemData) => {
        templateMap.account_id = Number(option.value)

        try {
            await templateMap.save()
            notify.success(EDIT_TEMPLATE_MESSAGES.mappingChanged)
        } catch (e) {
            const accountIdError = templateMap?.__errors?.account_id
            const uniqueError = templateMap?.__errors?.non_field_errors?.[0]
            accountIdError && notify.error(`Error: ${accountIdError} ${EDIT_TEMPLATE_MESSAGES.mappingDidNotChange}`)
            uniqueError && notify.error(`Error: ${uniqueError} ${EDIT_TEMPLATE_MESSAGES.mappingDidNotChange}`)
            templateMap.refresh()
        }
    }

    const header = (
        <ListHeader
            content={[
                'Target Account for Source:',
                `${templateMap.source_account_code} ${templateMap.source_account_name}`,
            ]}
        />
    )

    return (
        <SelectCell
            content = {templateMap.account?.name}
            options={options}
            customValue={templateMap.account_id}
            onChange={onChange}
            headerComponent={header}
            searchPlaceholder='Search by code or name'
            search
        />
    )
})
