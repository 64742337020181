import { Switch, Text } from '@/components/base'
import { Layout } from '@/components/containers'

import { SwitchHeaderCellProps } from './SwitchHeaderCell.types'

export const SwitchHeaderCell = (props: SwitchHeaderCellProps) => {
    const { onChange, checked, disabled, text = 'Select all' } = props

    return (
        <Layout gap={8} align='center'>
            <Switch checked={checked} disabled={disabled} onChange={val => onChange(val)}/>
            <Text text={text} variant='labelMediumDefault' color='colorsSecondaryGrey600'/>
        </Layout>
    )
}
