import {
    DATE_FNS_DEFAULT_FORMAT_FULL_DATE,
    DATE_FNS_DEFAULT_FORMAT_MONTH_SHORT,
    DATE_FNS_DEFAULT_FORMAT_QUARTER_SHORT,
    DATE_FNS_DEFAULT_FORMAT_YEAR,
} from '@/constants/dates'

import { DatePickerType } from './DatePicker.types'

export const DATE_FORMATS: Record<DatePickerType, string> = {
    day: DATE_FNS_DEFAULT_FORMAT_FULL_DATE,
    month: DATE_FNS_DEFAULT_FORMAT_MONTH_SHORT,
    quarter: DATE_FNS_DEFAULT_FORMAT_QUARTER_SHORT,
    year: DATE_FNS_DEFAULT_FORMAT_YEAR,
}

export const DATE_PICKER_LABELS: Record<DatePickerType, string> = {
    day: 'Date',
    month: 'Month',
    quarter: 'Quarter',
    year: 'Year',
}
