import { model, field, foreign } from 'mobx-orm'

import { AssetInTemplate } from '@/models/csv_builder/AssetInTemplate'
import { normalizeShortBackDate } from '@/utils/date/normalizeShortBackDate'

import { CompanyUserGroupInTemplate } from './CompanyUserGroupInTemplate'
import { CompanyUserInTemplate } from './CompanyUserInTemplate'
import { ReportType } from './ReportType'
import { TemplateField } from './TemplateField'
import { api } from '../../http-adapter'
import { TrialBalanceLedger as TBLedger } from '../ledger'
import { Model } from '../model'
import { RentRollLedger as RRLedger } from '../rent_roll'

@api('csv-template')
@model
export class Template extends Model {
    @field name?:           string
    @field tb_ledger_id?:   number
    @field rr_ledger_id?:   number
    @field report_type_id?: number
    /**
     * @private: Use normalized startDateLocal instead
     */
    @field start_date?:     string
    /**
     * @private: Use normalized endDateLocal instead
     */
    @field end_date?:       string
    @field updated_at?:     string
    @field as_of_date?:     string

    get endDateLocal (): Date {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        return normalizeShortBackDate(this.end_date)
    }

    get startDateLocal (): Date {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        return normalizeShortBackDate(this.start_date)
    }

    get asOfDateLocal (): Date | null {
        return this.as_of_date ? normalizeShortBackDate(this.as_of_date) : null
    }

    @foreign(TBLedger) readonly tb_ledger:     TBLedger
    @foreign(RRLedger) readonly rr_ledger:     RRLedger
    @foreign(ReportType) readonly report_type: ReportType

    readonly fields?: TemplateField[]

    readonly personal_permissions?: CompanyUserInTemplate[]
    readonly group_permissions?:    CompanyUserGroupInTemplate[]

    readonly asset_in_template?: AssetInTemplate[]
}
