import { useContext, useEffect, useState } from 'react'

import { ValueType } from 'mobx-orm'

import { ModelInput } from '@/utils/mobx-orm'

import { ModelIdProps } from './ModelId.types'
import { ModelFormContext } from '../ModelFromContext'

// Set filter value to model id
export const ModelId = (props: ModelIdProps) => {
    const { filter, syncURL, syncLocalStorage } = props
    const modelForm = useContext(ModelFormContext)

    const [modelInput] = useState(() => new ModelInput({
        obj: modelForm.obj,
        field: 'id',
        filter,
        syncURL: syncURL || modelForm.syncURL,
        syncLocalStorage: syncLocalStorage || modelForm.syncLocalStorage,
        type: ValueType.NUMBER,
    }))

    // destructore
    useEffect(() => {
        return () => {
            modelInput.destroy()
        }
    }, [])

    return null
}
