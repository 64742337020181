export const DATE_FNS_DEFAULT_FORMAT_FULL_DATE = 'MM/dd/yyyy'
// 7/27/2023 10:00 AM
export const DATE_FNS_DEFAULT_FORMAT_FULL_DATE_TIME = 'MM/dd/yyyy hh:mm a'
// January 2020
export const DATE_FNS_DEFAULT_FORMAT_MONTH_FULL = 'MMMM yyyy'
// Jan 2020
export const DATE_FNS_DEFAULT_FORMAT_MONTH_SHORT = 'MMM yyyy'
// Q1 2020
export const DATE_FNS_DEFAULT_FORMAT_QUARTER_SHORT = 'QQQ yyyy'
// 2020
export const DATE_FNS_DEFAULT_FORMAT_YEAR = 'yyyy'
// Default date format that we send to the server
export const DATE_FNS_SERVER_DATE = 'yyyy-MM-dd'
