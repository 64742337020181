import { getRequestQueries } from '@/api/utils/getRequestQueries'

export interface SettingsItem {
    DEBUG: boolean
    INTERCOM_APPID: string
    VELT_API_KEY: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchSettingsQueryParams {}

export const {
    useItemsQuery: useSettingsQuery,
    useItemUpdateMutation: useSettingsUpdateMutation,
    useItemsCountQuery: useSettingsCountQuery,
} = getRequestQueries<SettingsItem, FetchSettingsQueryParams>({
    url: 'settings',
    // Hack to make types compatible
    extractItemsFn: (data) => [data] as SettingsItem[],
})
