import { createEnumDelimitedArrayParam } from 'use-query-params'

import { TimeComparisonPeriod } from '@/constants/timeComparisonPeriods'

const TimeComparisonParam = createEnumDelimitedArrayParam(Object.values(TimeComparisonPeriod), ',')

export const TIME_COMPARISON_INPUT_QUERY_PARAM = {
    type: TimeComparisonParam,
    name: 'comparison',
}
