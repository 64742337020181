import { useEffect, useState } from 'react'

import { Tooltip } from '@mui/material'
import format from 'date-fns/format'
import { XEQ as EQ, NumberInput } from 'mobx-orm'
import { observer } from 'mobx-react-lite'
import { generatePath, useNavigate } from 'react-router'

import { useAssetsQuery } from '@/api/asset/asset'
import { Alert, Button, Icon, Text } from '@/components/base'
import { AgGridTableLegacy } from '@/components/legacy/tables/AgGridTableLegacy'
import { ASSET_SIDE_MODAL_INPUT_QUERY_PARAM, ASSET_TYPE_SELECT_INPUT_QUERY_PARAM } from '@/components/models/asset'
import {
    CUSTOM_LEDGER_ID_QUERY_PARAM,
} from '@/components/widgets/mappings/MappingTrialBalance/MappingTrialBalance.constants'
import { RouterConfigKeys } from '@/core/routes'
import { useMe } from '@/hooks/core/useMe'
import http from '@/http.service'
import { DataPointError, DataPointType } from '@/models/data_pulling'
import { DataSubmissionInternalErrorMessage } from '@/pages/MainPage/CommonPages/DataSubmissionPage'
import {
    gridOptions,
} from '@/pages/MainPage/CommonPages/ValidationTrackerPage/DataPointSideModal/FileInfo/FileInfo.constants'
import {
    FileInfoProps,
} from '@/pages/MainPage/CommonPages/ValidationTrackerPage/DataPointSideModal/FileInfo/FileInfo.types'
import { UNMAPPED_ONLY_QUERY_PARAM } from '@/pages/MainPage/UserPages/MappingPage/MappingRentRollPage/MappingRentRollPage.constants'
import { downloadFile } from '@/utils/downloadFile'
import { getRoute } from '@/utils/routing/getRoute'

import styles from './FileInfo.module.scss'

const FileInfo = observer(({ dataPoint }: FileInfoProps) => {
    const navigate = useNavigate()

    const { me } = useMe()
    const assetsQuery = useAssetsQuery()

    const [dataPointIdInput] = useState(() => new NumberInput({
        value: dataPoint.id,
    }))

    const [dataPointErrorsQuery] = useState(() => DataPointError.getQueryXPage({ filter: EQ('data_point_id', dataPointIdInput) }))

    const [unmappedLedgersIds, setUnmappedLedgersIds] = useState([])
    const loadUnmappedLedgersIds = async () => {
        const headers = me.companyUser ? { 'company-user-id': `${me.companyUser.id}` } : {}
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        const response = await http.get(
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            `data-pulling/data-point-unmapped-ledgers?${new URLSearchParams({ dataPointId: dataPoint.id.toString() })}`,
            // @ts-expect-error TS(2322) FIXME: Type '{ 'company-user-id': string; } | { 'company-... Remove this comment to see the full error message
            { headers },
        )
        setUnmappedLedgersIds(response.data.ledgers_ids)
    }

    useEffect(() => {
        dataPointErrorsQuery.autoupdate = true
        loadUnmappedLedgersIds()
    }, [])

    const submissionErrorsNum = dataPoint.submission_errors_num
    const qualityErrorsNum = dataPoint.quality_errors_num

    let tableTitle: string | null = null
    let titleHint: string | null = null

    if (dataPoint.have_internal_error_status) {
        tableTitle = 'Submission Errors (1)'
    } else if (submissionErrorsNum) {
        tableTitle = `Submission Errors (${submissionErrorsNum})`
        titleHint = 'Helps identify reasons for failed submissions. Possible errors include incorrect file format, invalid asset name, invalid characters, or unrecognized data. All submission errors must be resolved before data is accepted by the system.'
    } else if (qualityErrorsNum) {
        tableTitle = `Data Quality Alerts (${qualityErrorsNum})`
        titleHint = 'Data quality alerts identify potential irregularities in the submitted data set. Data has been accepted by the system, but we encourage you to review the list of alerts and determine if any changes need to be made to the underlying data.'
    }

    const DOWNLOAD_PATH = 'data-pulling/download/:dataPointId/'

    const navigateToMappingPage = (ledger_id) => {
        const isTrialBalanceType = dataPoint.type === DataPointType.TRIAL_BALANCE
        const routeName: RouterConfigKeys = isTrialBalanceType ? 'ASSET_MAPPING_TRIAL_BALANCE' : 'ASSET_MAPPING_RENT_ROLL'

        navigate(getRoute(routeName, {}, {
            [ASSET_SIDE_MODAL_INPUT_QUERY_PARAM.name]: dataPoint.asset_id as number,
            [ASSET_TYPE_SELECT_INPUT_QUERY_PARAM.name]: assetsQuery.data?.find((asset) => asset.id === dataPoint.asset_id)?.type || '',
            [CUSTOM_LEDGER_ID_QUERY_PARAM.name]: String(ledger_id),
            [UNMAPPED_ONLY_QUERY_PARAM.name]: 1,
        }))
    }

    return (
        <div className={styles.container}>
            <div>
                <Text variant='smallTextSemiboldDefault' color='secondaryBlack'>{dataPoint.initial_file_name}</Text>
            </div>
            <div className={styles.head}>
                <div className={styles.headRow}>
                    <div className={styles.headRowCell}>
                        <div className={styles.headRowCellTitle}>
                            <Text variant='smallTextMediumDefault' color='secondaryLightBlack'>Uploaded At:</Text>
                        </div>
                        <div className={styles.headRowCellValue}>
                            <Text variant='smallTextSemiboldDefault' color='secondaryBlack'>
                                {dataPoint.etl_created_at && format(new Date(dataPoint.etl_created_at), 'MMM d yyyy, h:mm a')}
                            </Text>
                        </div>
                    </div>
                    <div className={styles.headRowCell}>
                        <div className={styles.headRowCellTitle}>
                            <Text variant='smallTextMediumDefault' color='secondaryLightBlack'>Asset:</Text>
                        </div>
                        <div className={styles.headRowCellValue}>
                            <Text variant='smallTextSemiboldDefault' color='secondaryBlack'>{dataPoint.asset?.name || '–'}</Text>
                        </div>
                    </div>
                </div>
                <div className={styles.headRow}>
                    <div className={styles.headRowCell}>
                        <div className={styles.headRowCellTitle}>
                            <Text variant='smallTextMediumDefault' color='secondaryLightBlack'>Uploaded By:</Text>
                        </div>
                        <div className={styles.headRowCellValue}>
                            <Text variant='smallTextSemiboldDefault' color='secondaryBlack'>
                                {
                                    dataPoint.submitted_file?.company_user
                                        ? dataPoint.submitted_file?.company_user.full_name
                                        : 'Intelas'
                                }
                            </Text>
                        </div>
                    </div>
                    {
                        dataPoint.initial_file_name && (
                            <div className={styles.headRowCell}>
                                <div className={styles.headRowCellTitle}>
                                    <Text variant='smallTextMediumDefault' color='secondaryLightBlack'>File:</Text>
                                </div>
                                <div className={styles.headRowCellValue}>
                                    <a
                                        className={styles.goToDetailsLink}
                                        onClick={() => downloadFile(
                                            generatePath(DOWNLOAD_PATH, { dataPointId: `${dataPoint.id}` }),
                                            dataPoint.initial_file_name,
                                        )}
                                        href='javascript:void(0)'
                                    >Download</a>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            <div className={styles.modalContent}>
                <div className={styles.contentTopPane}>
                    {
                        titleHint && (
                            <Tooltip
                                title={titleHint} arrow
                                placement='top-end'
                            >
                                <span className={styles.infoIconContainer}>
                                    <Icon
                                        name='info' color='colorsSecondaryPurple700'
                                        size='l'
                                    />
                                </span>
                            </Tooltip>
                        )
                    }
                    <Text
                        block
                        text={tableTitle}
                        color='secondaryBlack'
                        variant='smallTextSemiboldDefault'
                    />
                    <div className={styles.sep}/>
                    {
                        !dataPoint.have_internal_error_status && !!unmappedLedgersIds.length && (
                            <Button
                                text='Update Mappings'
                                theme='alert'
                                onClick={() => navigateToMappingPage(unmappedLedgersIds[0])}
                            />
                        )
                    }
                </div>
                {dataPoint.have_internal_error_status && (
                    <Alert
                        content={<DataSubmissionInternalErrorMessage/>}
                        type='error'
                    />
                )}
                {
                    !dataPoint.have_internal_error_status && (Boolean(submissionErrorsNum || qualityErrorsNum)) && (
                        <AgGridTableLegacy
                            query={dataPointErrorsQuery}
                            {...gridOptions}
                        />
                    )
                }
            </div>
        </div>
    )
})

export { FileInfo }
