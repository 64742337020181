import { AssetTypeCell } from '@/components/legacy/models/asset/cell/AssetTypeCell'
import { ObserverCell } from '@/components/legacy/tables/cells'
import { AgGridTableProps } from '@/components/tables'

export const gridOptions: Partial<AgGridTableProps> = {
    columnDefs: [
        // Switch cell will be pushed here in JSX
        {
            field: 'name',
            headerName: 'Name',
            type: 'string',
        },
        {
            field: 'city',
            headerName: 'City',
            type: 'string',
        },
        {
            field: 'state',
            headerName: 'State',
            type: 'string',
        },
        {
            headerName: 'Asset Class',
            type: 'string',
            field: 'type',
            cellRendererSelector: (props) => ({
                component: AssetTypeCell,
                params: {
                    customData: props.data,
                },
            }),
        },
        {
            field: 'property_manager_company',
            headerName: 'Property Manager',
            type: 'string',
            cellRenderer: ObserverCell,
        },
    ],
}
