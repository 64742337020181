// TODO: Configure connection through the webpack
export const VELT_DIALOG_STYLES = `
    snippyly-user-avatar.comment-dialog-user-avatar {
        display: none !important;
    }

    .modal-messages > .modal-message-wrap .modal-author__text {
        padding-left: 0 !important;
    }

    div.modal-div .snippyly-modal-header {
        display: none !important;
    }

    .modal-div.sidebar:hover, .modal-div.sidebar.selected {
      border-radius: 4px !important;
    }

    .modal-div .input-text-wrap {
        border-radius: 4px !important;
    }

    .modal-div.no-comments {
        border: none !important;
    }

    .email-chip {
       background: unset !important;
       color: #5c5ce5 !important;
    }

    div.reply-div input-text-wrap ng-star-inserted {
        border: solid 1px #{generatedColors.$colorsSecondaryGrey100} !important;
    }

    .modal-div.no-comments .reply-div {
        border: solid 1px #dee0e6 !important;
    }

    div[data-velt-element="true"][data-velt-comment-disabled="true"].s-sidebar-container .s-sidebar {
        border-radius: 4px !important;
    }

    .s-sidebar {
        border-radius: 4px !important;
    }
`
