import { useState } from 'react'

import { observer } from 'mobx-react-lite'

import { Button } from '@/components/base'
import { fsDownloadEvent } from '@/utils/fullstory/fsDownloadEvent'
import { notify } from '@/utils/notify'

import { DownloadCSVButtonProps } from './DownloadCSVButton.types'

export const DownloadCSVButton = observer((props: DownloadCSVButtonProps) => {
    const { model, query, fsEventName } = props
    const [isFileDownloading, setIsFileDownloading] = useState(false)

    const handleCsvDownload = async () => {
        setIsFileDownloading(false)
        try {
            await model.downloadCSV({ query })
            fsDownloadEvent(fsEventName)
        } catch (error) {
            console.error(error)
            notify.error(error.message)
        } finally {
            setIsFileDownloading(false)
        }
    }

    return (
        <div style={{ margin: 'auto 0 0 auto' }}>
            <Button
                dataTestId='download-csv-button'
                icon='download'
                text='Download to CSV'
                theme='secondary'
                loading={isFileDownloading}
                onClick={handleCsvDownload}
            />
        </div>
    )
})
