import { model, field, foreign } from 'mobx-orm'

import { api } from '../../http-adapter'
import { Asset } from '../asset'
import { AsyncTaskModel } from '../AsyncTaskModel'

@api('trial-balance-template-applying-task')
@model
export class TrialBalanceTemplateApplyingTask extends AsyncTaskModel {
    @field binding_id?:    number
    @field template_id?:   number
    @field asset_id?:      number
    @field apply?:         boolean
    @field replace?:       boolean
    @field delete_excess?: boolean
    @field result?:        ResultOfApplyTask

    @foreign(Asset) readonly asset: Asset
}

export interface ResultOfApplyTask {
    /**
     * count of existing mappings that are the same as in the template
    */
    duplicate:            number
    /**
     * count of mappings that exists on asset but not presented in the template and deleted
    */
    deleted:              number
    /**
     * count of mappings that have same source account as in the template and replaced
    */
    replaced:             number
    /**
     * count of new mappings created
     */
    created:              number
    /**
     * count of mappings that exists on asset but not presented in the template
    */
    would_delete:         number
    /**
     * count of mappings that have same source account as in the template
    */
    would_replace:        number
    /**
     * count of new mapping will be created
     */
    would_create:         number
    /**
     * TODO: count of source accounts in template that was not found at asset
    */
    missing_source_codes: string[]
}
