import { model, field, many, foreign } from 'mobx-orm'

import { HistoryModel } from '@/models/core/HistoryModel'

import { CompanyUserGroupInTemplate } from './CompanyUserGroupInTemplate'
import { Template } from './Template'
import { api } from '../../http-adapter'
import { CompanyUserGroup } from '../company'
import { AccessLevel } from '../core'

@api('company-user-group-in-csv-template-history')
@model
export class CompanyUserGroupInTemplateHistory extends HistoryModel {
    @field readonly company_user_group_in_template_id: number
    @field readonly template_id:                       number
    @field readonly level:                             number
    @field readonly company_user_group_id:             number

    @foreign(Template) readonly template:                   Template
    @foreign(CompanyUserGroup) readonly company_user_group: CompanyUserGroup

    get level_name (): string {
        return AccessLevel[this.level]
    }
}
many(CompanyUserGroupInTemplateHistory, 'company_user_group_in_template_id')(CompanyUserGroupInTemplate, 'history')
