import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router'

import ObjectFormPopup from '@/components/legacy/models/ObjectFormPopup'
import { ADMIN_ROUTES_CONFIG } from '@/core/routes'
import { Template } from '@/models/csv_builder'
import { getRouteConfig } from '@/utils/routing/getRouteConfig'

// TODO: refactor to use reactQuery
export const FileRenamePopup = observer(() => {
    const { id } = useParams()

    const [itemObj, setItemObj] = useState<Template | null>(null)

    useEffect(() => {
        if (!id) {
            console.error('Can\'t get id from params')
            return
        }

        (async () => {
            const itemObj = await Template.findById(parseInt(id)) as Template | null

            if (!itemObj) {
                console.error('Can\'t get Template object')
                return
            }

            setItemObj(itemObj)
        })()
    }, [id])

    return itemObj ? (
        <ObjectFormPopup
            fields={new Map([
                ['name', { type: 'string', title: 'Name' }],
            ])}
            title={getRouteConfig('CSV_BUILDER_FILES_EDIT')?.title}
            obj={itemObj}
            closePath={ADMIN_ROUTES_CONFIG.CSV_BUILDER_FILES.path}
        />
    ) : null
})
