import React from 'react'

import { observer } from 'mobx-react-lite'

import { Alert, BaseModal, Button } from '@/components/base'
import { Layout } from '@/components/containers'

import styles from './MappingTEmplateApplyCloseWarningModal.module.scss'
import { MappingTemplateApplyCloseWarningModalProps } from './MappingTemplateApplyCloseWarningModal.types'

export const MappingTemplateApplyCloseWarningModal = observer((props: MappingTemplateApplyCloseWarningModalProps) => {
    const { onConfirm, onCancel } = props

    const alertContent = (
        'You have unsaved changes'
    )

    const modalContent = (
        <Layout
            direction='column' gap={24} align='center'
            justify='space-between'
        >
            <Alert content={alertContent} type='warning' className={styles.alert}/>
            <Layout
                maxWidth={269}
                gap={8}
                direction='column'
                className={styles.buttons}
            >
                <Button
                    theme='primary' text='Back to Editing' onClick={onConfirm}
                    block
                />
                <Button
                    theme='secondary' text='Close without Saving' onClick={onCancel}
                    block
                />
            </Layout>
        </Layout>
    )

    return (
        <BaseModal
            maxWidth={648}
            radius={4}
            padding={24}
            minHeight={200}
        >
            {modalContent}
        </BaseModal>
    )
})
