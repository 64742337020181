import { getRequestQueries } from '@/api/utils/getRequestQueries'
import { CompanyFeatures } from '@/models/company/CompanyFeatures'

export interface CompanyItem {
    id: number
    name?: string
    location?: string
    is_active?: boolean
    use_expor?: boolean
    use_scheduled_tb_upload: boolean
    features?: CompanyFeatures
    use_export?: boolean
    comments_group_id?: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchCompanyQueryParams {}

export const {
    useItemsQuery: useCompanyQuery,
    useItemUpdateMutation: useCompanyUpdateMutation,
    useItemsCountQuery: useCompanyCountQuery,
    useItemByIdQuery: useCompanyByIdQuery,
} = getRequestQueries<CompanyItem, FetchCompanyQueryParams>({
    url: 'company',
    errorMessageUpdate: 'Failed to update company',
})
