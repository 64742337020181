import { ChangeEvent } from 'react'

import cn from 'classnames'

import { Icon, IconName } from '@/components/base'
import { GeneratedColor } from '@/styles/tokens/Tokens.types'

import { ICON_SIZE_MAP } from './Checkbox.constants'
import styles from './Checkbox.module.scss'
import { CheckboxProps } from './Checkbox.types'

export const Checkbox = (props: CheckboxProps) => {
    const { checked, disabled = false, indeterminate = false, inputProps, onChange, size = 'm', dataTestId } = props

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange?.(e.target.checked)
    }

    const checkboxClassNames = cn(styles.checkbox, {
        [styles.checked]: checked,
        [styles.disabled]: disabled,
        [styles.indeterminate]: indeterminate,
        [styles[`size_${size}`]]: size,
    })

    const checkBoxIcon: IconName | null = indeterminate ? 'minus' : checked ? 'check' : null
    const iconColor: GeneratedColor = (checked || indeterminate) ? 'colorsPrimaryWhite' : 'colorsPrimaryPurple'

    return (
        <label className={checkboxClassNames} data-testid={dataTestId}>
            <input
                type='checkbox'
                checked={checked}
                disabled={disabled}
                onChange={handleInputChange}
                className={styles.checkBoxInput}
                {...inputProps}
            />
            {checkBoxIcon && (
                <Icon
                    name={checkBoxIcon}
                    color={iconColor}
                    className={styles.checkboxIcon}
                    width={ICON_SIZE_MAP[size]}
                    height={ICON_SIZE_MAP[size]}
                />
            )}
        </label>
    )
}
