export enum TimeInterval {
    MONTH = 'month',
    QUARTER = 'quarter',
    YEAR = 'year',
}

export const TIME_INTERVAL_LABELS: Record<TimeInterval, string> = {
    [TimeInterval.MONTH]: 'Month',
    [TimeInterval.QUARTER]: 'Quarter',
    [TimeInterval.YEAR]: 'Year',
}
