import { useEffect, useMemo } from 'react'

import { Model, QueryXProps } from 'mobx-orm'

interface UseQueryExtraParams {
    isReady?: boolean
}

export const useQueryX = <M extends typeof Model>(model: M, options?: QueryXProps<InstanceType<M>>, extraParams?: UseQueryExtraParams) => {
    const query = useMemo(() => {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'QueryXProps<InstanceType<M>> | u... Remove this comment to see the full error message
        const query = model.getQueryX(options)
        // For the case, when data fetched on upper lvl
        query.__is_ready = extraParams?.isReady ?? false
        return query
    }, [])

    // destroy input on unmount
    useEffect(() => {
        return () => {
            query.destroy()
        }
    }, [])

    return query
}
