import { observer } from 'mobx-react-lite'

import { TextInput } from '@/components/base'

import { StringInput2Props } from './StringInput2.types'

// TODO: debounce input from lobash
export const StringInput2 = observer((props: StringInput2Props) => {
    const { input, ...rest } = props

    return (
        <TextInput
            {...rest}
            value={input.value}
            onChange={(e) => { input.set(e.target.value) }}
        />
    )
})
