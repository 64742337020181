import { observer } from 'mobx-react-lite'

import { Label, LabelColors } from '@/components/base'

import { LabelStatusProps, LabelStatusVariants } from './LabelStatus.types'

const CONFIG_BY_VARIANT: Record<LabelStatusVariants, { color: LabelColors, text: string }> = {
    active: {
        color: 'green',
        text: 'Active',
    },
    inactive: {
        color: 'red',
        text: 'Inactive',
    },
}

export const LabelStatus = observer((props: LabelStatusProps) => {
    const { className, variant } = props

    const config = CONFIG_BY_VARIANT[variant]

    return (
        <Label
            className={className}
            {...config}
        />
    )
})
