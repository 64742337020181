import { Text } from '@/components/base'

const INFO = [
    `New source accounts that are present in the upload file but not in the
    existing template will be added.`,
    `Source account mappings that exist in the template but are not included
    in the file upload will remain unchanged in the template.`,
    `Source accounts in the file with identical mappings to the original
    template will remain unchanged.`,
    `When a source account in the file has a different mapping than
    the one in the existing template, the new mapping upload will overwrite the old one.`,
]

export const UPLOAD_TEXT = INFO.map((text, i) => (
    <Text
        variant='labelMediumDefault'
        color={i === 3 ? 'colorsTetrialRed600' : 'colorsSecondaryGrey400'}
        text={text} key={i}
    />
))
