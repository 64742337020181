import { useEffect, useMemo } from 'react'

import { Input } from 'mobx-orm'

type InitInputFunc = () => Input<any>

export const useInput = (initFunc: InitInputFunc) => {
    const input = useMemo(initFunc, [])

    // destroy input on unmount
    useEffect(() => {
        return () => {
            input.destroy()
        }
    }, [])

    return input
}
