import { Text } from '@/components/base'

import styles from './SelectNoOptionsBase.module.scss'
import { SelectNoOptionsBaseProps } from './SelectNoOptionsBase.types'

export const SelectNoOptionsBase = (props: SelectNoOptionsBaseProps) => {
    const { placeholder } = props
    return (
        <li className={styles.wrapper}>
            <Text
                variant='smallTextMediumDefault'
                color='colorsSecondaryGrey600'
                text={placeholder}
            />
        </li>
    )
}
