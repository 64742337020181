import React, { useState } from 'react'

import { Menu, MenuItem } from '@mui/material'
import cn from 'classnames'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Icon, Text } from '@/components/base'

import styles from './ElementsPerPageSelect.module.scss'
import { ElementsPerPageSelectProps } from './ElementsPerPageSelect.types'

export const TABLE_ELEMENTS_PER_PAGE_CONFIG = [50, 100, 500, 1000]
export const TABLE_PER_PAGE_DEFAULT = TABLE_ELEMENTS_PER_PAGE_CONFIG[0]

export const ElementsPerPageSelect = observer((props: ElementsPerPageSelectProps) => {
    const { query } = props

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleItemClick = (event) => {
        setAnchorEl(null)
        runInAction(() => {
            query.setPageSize(event.target.value)
        })
    }

    const isOpen = Boolean(anchorEl)

    return (
        <div className={styles.elementsPerPageSelect}>
            <Text variant='labelMediumDefault' color='secondaryBlack'>Rows per page:</Text>
            <div onClick={handleMenuOpen} className={styles.number}>
                <Text
                    variant='labelMediumDefault'
                    id='demo-simple-select-label'
                    color='secondaryBlack'
                >
                    {query.limit}
                </Text>
                <Icon
                    name='arrowDown'
                    size='m'
                    color='colorsSecondaryGrey600'
                    className={cn(styles.arrow, { [styles.arrow_open]: isOpen })}
                />
            </div>
            <Menu
                anchorEl={anchorEl}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                container={document.body}
                onClose={() => { setAnchorEl(null) }}
            >
                {
                    TABLE_ELEMENTS_PER_PAGE_CONFIG.map(val => (
                        <MenuItem
                            key={val}
                            value={val}
                            onClick={handleItemClick}
                        >
                            {val}
                        </MenuItem>
                    ))
                }
            </Menu>
        </div>
    )
})
