import React, { useState, useEffect } from 'react'

import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router'
import { useMatch, useNavigate } from 'react-router-dom'

import { PageLayout } from '@/components/containers'
import { useHeaderNavigation } from '@/hooks/useHeaderNavigation'
import {
    Company,
    CompanyUserGroup,
    CompanyUserInCompanyUserGroup,
    CompanyInvite, CompanyUser,
} from '@/models/company'
import {
    getCompanyProfileNavigationItems,
} from '@/pages/MainPage/UserPages/CompanyProfilePage/CompanyProfilePage.constants'
import { isQueriesReady } from '@/utils/models'
import { getRoute } from '@/utils/routing/getRoute'
import { getRouteConfig } from '@/utils/routing/getRouteConfig'

export const CompanyProfilePage = observer(() => {
    const [companyQuery] = useState(() => Company.getQuery())
    const [companyUserGroupQuery] = useState(() => CompanyUserGroup.getQuery())
    const [companyUserInCompanyUserGroupQuery] = useState(() => CompanyUserInCompanyUserGroup.getQuery())
    const [companyInviteQuery] = useState(() => CompanyInvite.getQuery())
    const [companyUserQuery] = useState(() => CompanyUser.getQuery())

    const navigate = useNavigate()
    const match = useMatch(getRouteConfig('COMPANY_PROFILE').path)

    const queries = [
        companyQuery, companyUserGroupQuery, companyUserInCompanyUserGroupQuery, companyInviteQuery, companyUserQuery,
    ]

    useHeaderNavigation(getCompanyProfileNavigationItems())

    useEffect(() => {
        if (match) {
            navigate(getRoute('COMPANY_PROFILE_DETAILS'), { replace: true })
        }
    }, [match])

    useEffect(() => {
        for (const query of queries) {
            query.load()
        }
    }, [queries])

    const isReady = isQueriesReady(...queries)

    return (
        <PageLayout
            loading={!isReady}
        >
            {/* Conditions for render content after all data fetched */}
            {isReady && (<Outlet/>)}
        </PageLayout>
    )
})
