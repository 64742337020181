import { IconName } from '@/components/base'
import { MappingEngineMode } from '@/components/widgets/MappingEngineSideModal/MappingEngineSideModal.types'

export const MAPPING_ENGINE_ONE_MODULE_WIDTH = '360px'

export const MAPPING_ENGINE_ICON_BY_MODE: Record<MappingEngineMode, IconName> = {
    [MappingEngineMode.oneCol]: 'oneCol',
    [MappingEngineMode.twoCols]: 'twoCols',
    [MappingEngineMode.threeCols]: 'threeCols',
}

export const MAPPING_ENGINE_MODE_LOCAL_STORAGE_KEY = 'mappingEngineMode'
