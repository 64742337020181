import { useEffect } from 'react'

import { observer } from 'mobx-react-lite'

import { Button, Text } from '@/components/base'

import { ElementsPerPageSelect } from './ElementsPerPageSelect'
import styles from './PaginationPane.module.scss'
import { PaginationProps } from './PaginationPane.types'

export const PaginationPane = observer((props: PaginationProps) => {
    const { recordsCount, offset, limit, onPageChanged } = props

    const currentPage = offset / limit
    const pagesCount = Math.ceil(recordsCount / limit)

    useEffect(() => {
        if (offset >= recordsCount) {
            onPageChanged(Math.floor(recordsCount / limit) * limit, limit)
        }
    }, [offset, limit, recordsCount])

    return (
        <div className={styles.pagination}>
            <Text
                variant='labelMediumDefault' color='secondaryLightBlack'
            >
                {offset + 1}-{Math.min(offset + limit, recordsCount)} of {recordsCount}
            </Text>
            <div style={{ flex: 1 }}/>
            <ElementsPerPageSelect
                pageSize={limit}
                onPageSizeChanged={(pageSize) => { onPageChanged(Math.floor(offset / pageSize) * pageSize, pageSize) }}
            />
            <div className={styles.prevNext}>
                <Button
                    theme='secondary'
                    icon='arrowLeft'
                    size='s'
                    disabled={offset === 0}
                    onClick={() => { onPageChanged(offset - limit, limit) }}
                    className={styles.arrowButton}
                />
                <Text
                    variant='labelMediumDefault' color='secondaryBlack'
                    className={styles.pageNumber}
                >
                    {currentPage + 1} / {pagesCount}
                </Text>
                <Button
                    theme='secondary'
                    icon='arrowRight'
                    size='s'
                    disabled={offset + limit >= recordsCount}
                    onClick={() => { onPageChanged(offset + limit, limit) }}
                    className={styles.arrowButton}
                />
            </div>
        </div>
    )
})
