import { generatePath } from 'react-router'

import { RouterConfigKeys } from '@/core/routes'
import { getRouteConfig } from '@/utils/routing/getRouteConfig'

/**
 * Generates route with query params
 * Based on react router utils
  */
export const getRoute = (
    /**
     * Route key name
     * From here: front/src/core/routes/routes.constants.ts
     *
     * @warning: Route name must exist in routes config
     * @warning: Keys for different user modes must be the same
     */
    routeName: RouterConfigKeys,
    /**
     * Params for route
     */
    routeParams: Record<string, string | number> = {},
    /**
     * Query params
     */
    queryParams: Record<string, string | number> = {},
): string => {
    const routeConfig = getRouteConfig(routeName)
    const route = routeConfig?.path

    if (!route) {
        console.error(`Route ${routeName} not found`)
        return '/'
    }

    const routeParamsStringified = Object.keys(routeParams).reduce<Record<string, string>>((acc, key) => {
        acc[key] = String(routeParams[key])
        return acc
    }, {})

    // Generate the path with the route parameters
    let path = generatePath(route, routeParamsStringified)

    // If there are query parameters, append them to the path
    if (queryParams) {
        const queryParamsStringified = Object.keys(queryParams).reduce<Record<string, string>>((acc, key) => {
            acc[key] = String(queryParams[key])
            return acc
        }, {})
        const query = new URLSearchParams(queryParamsStringified)
        const queryString = query.toString()
        if (queryString) {
            path += `?${query.toString()}`
        }
    }

    return path
}
