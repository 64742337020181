import { observer } from 'mobx-react-lite'

import { TextInput } from '@/components/base'

import { TextFilterProps } from './TextFilter.types'

export const TextFilter = observer((props: TextFilterProps) => {
    const { filter, ...inputProps } = props
    return (
        <TextInput
            {...inputProps}
            value={filter.value}
            onChange={e => { filter.set(e.target.value?.length ? e.target.value : undefined) }}
        />
    )
})
