import { getRequestQueries } from '@/api/utils/getRequestQueries'
import { SourceAccount } from '@/models/rent_roll'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RentRollUnmappedItem {
    ledgerId: number
    accounts: SourceAccount[]
}

export interface FetchTrialBalanceUnmappedQueryResponse {
    accounts: Record<number, SourceAccount[]>
    request_id: number
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchRentRollUnmappedQueryParams {}

export const {
    useItemsQuery: useRentRollUnmappedQuery,
} = getRequestQueries<RentRollUnmappedItem, FetchRentRollUnmappedQueryParams>({
    url: 'rent-roll-unmapped',
    extractItemsFn: (data: FetchTrialBalanceUnmappedQueryResponse) => Object.entries(data.accounts)
        .map(([ledgerId, accounts]) => ({ ledgerId: Number(ledgerId), accounts })),
})
