import { useEffect, useMemo, useState } from 'react'

import { ButtonGroup, Switch } from '@/components/base'
import { useInputState } from '@/hooks'

import { defaultBooleanInputOptions, BOOLEAN_INPUT_QUERY_PARAM } from './BooleanInput.constants'
import { BooleanInputProps } from './BooleanInput.types'

const { type: queryParamType, name: queryParamName } = BOOLEAN_INPUT_QUERY_PARAM

/**
 * Boolean input component
 * @property `type` defines the component to render: `button-group`, `checkbox`, `switch`
 * @todo `checkbox` is not implemented yet
 */
export const BooleanInput = (props: BooleanInputProps) => {
    const {
        type = 'button-group',
        paramName = queryParamName,
        options = defaultBooleanInputOptions,
        label,
        ...rest
    } = props

    const [param, setParam] = useInputState({ name: paramName, type: queryParamType })
    const [selected, setSelected] = useState(Boolean(param))

    const boolKeyOptions = useMemo(() => options.map(([value, title]) => ({ title, key: value })), [options])
    const stringKeyOptions = useMemo(() => options.map(([value, title]) => ({ title, key: value.toString() })), [options])

    useEffect(() => {
        if (type === 'button-group') {
            const selectedOption = boolKeyOptions.find((option) => option.key === Boolean(param))
            if (selectedOption) {
                setSelected(selectedOption.key)
            } else {
                // reset to the first option
                setParam(boolKeyOptions[0].key)
            }
        } else if (type === 'switch') {
            setSelected(Boolean(param))
        }
    }, [param, options, setParam, boolKeyOptions, type])

    const components = useMemo(() => ({
        'button-group': (
            <ButtonGroup
                options={stringKeyOptions}
                selected={typeof selected === 'boolean' ? selected.toString() : undefined}
                onSelect={(key) => setParam(key === 'true')}
                label={label}
                {...rest}
            />
        ),
        switch: (
            <Switch
                checked={selected}
                onChange={(checked) => setParam(checked)}
                label={label}
                {...rest}
            />
        ),
        // TODO // checkbox: <input type='checkbox'/>,
    }), [label, rest, selected, setParam, stringKeyOptions])

    return (components[type])
}
