import { me as meLegacy, Me } from '@/components/containers'
import { CompanyUserMode } from '@/core/modes'
import { ADMIN_ROUTES_CONFIG, OWNER_ROUTES_CONFIG, MANAGER_ROUTES_CONFIG } from '@/core/routes/routes.constants'
import {
    AdminRoutesConfig,
    ManagerRoutesConfig,
    OwnerRoutesConfig,
    RouterConfigKeys,
    RoutesConfigValue,
} from '@/core/routes/routes.types'
import { isFeatureOn } from '@/utils/isFeatureOn'

export const getRoutesConfig = (me: Me) => {
    if (me.companyUserMode === CompanyUserMode.Owner) {
        return OWNER_ROUTES_CONFIG
    }
    if (me.companyUserMode === CompanyUserMode.Admin) {
        return ADMIN_ROUTES_CONFIG
    }
    if (me.companyUserMode === CompanyUserMode.Manager) {
        return MANAGER_ROUTES_CONFIG
    }
}

/**
 * ! Will be working only inside <App/>
 *
 * Use this for filtering available items for menus ar App.tsx pages availability. For 'navigate' you can use constants
 *
 * @todo: use memoize or make it as a hook. We need calculate routes only once (after login)
 */
export const getAvailableRoutes = (meFromHook?: Me): Record<Partial<RouterConfigKeys>, RoutesConfigValue> => {
    const routes: Record<CompanyUserMode, AdminRoutesConfig | OwnerRoutesConfig | ManagerRoutesConfig> = {
        [CompanyUserMode.Admin]: ADMIN_ROUTES_CONFIG,
        [CompanyUserMode.Owner]: OWNER_ROUTES_CONFIG,
        [CompanyUserMode.Manager]: MANAGER_ROUTES_CONFIG,
    }

    const me = meFromHook ?? meLegacy

    const routesByMode = routes[me.companyUserMode]

    const routesFilteredByAccess = Object.entries(routesByMode ?? {}).reduce((acc, [key, config]) => {
        const featureOn = !config.featureFlags?.length
            ? true
            : (me.isAdministratorMode && config.ignoreFeatureFlagsInAdminMode) ? true : config.featureFlags.every(feature => isFeatureOn(feature))

        const isStaff = me.user.is_staff

        // FIXME: Not obvious logic with 'staffOnly' flag
        //  Need to be removed as sood as possible
        if (featureOn && (config.staffOnly ? isStaff : true)) {
            acc[key] = config
        }

        return acc
    }, {})

    return routesFilteredByAccess as Record<Partial<RouterConfigKeys>, RoutesConfigValue>
}

export const getAvailablePaths = (): string[] => {
    return Object.values(getAvailableRoutes()).map(el => el.path)
}

export const filtersPathItemsByAccess = <T>(items: Array<T & { path: string }>): T[] => {
    const availablePaths = getAvailablePaths()
    return items.filter(({ path }) => {
        return availablePaths.includes(path)
    })
}
