import { AgGridTableProps } from '@/components/tables'

import {
    CompanyInvitesPageActionsCell,
} from './CompanyInvitesPageActionsCell'
import {
    CompanyInvitesPageStatusCell,
} from './CompanyInvitesPageStatusCell'

export const gridOptions: Omit<AgGridTableProps, 'query' | 'items'> = {
    columnDefs: [
        {
            field: 'name',
            headerName: 'Name',
            type: 'string',
            valueGetter: (params) => {
                const name = [params.data.first_name, params.data.last_name].filter(el => Boolean(el)).join(' ')

                return name.length ? name : '—'
            },
        },
        {
            type: 'string',
            field: 'email',
            headerName: 'Email',
        },
        {
            sortable: true,
            unSortIcon: true,
            field: 'status',
            headerName: 'Status',
            cellRenderer: CompanyInvitesPageStatusCell,
        },
        {
            type: 'actions',
            headerName: 'Actions',
            cellRenderer: CompanyInvitesPageActionsCell,
        },
    ],
}
