import { NumberParam } from 'use-query-params'

import {
    CUSTOM_LEDGER_ID_QUERY_PARAM,
} from '@/components/widgets/mappings/MappingTrialBalance/MappingTrialBalance.constants'

export const MAPPING_ENGINE_CELL_QUERY_PARAMS_CONFIG = {
    mappingModalOpenForId: NumberParam,
    [CUSTOM_LEDGER_ID_QUERY_PARAM.name]: NumberParam,
}
