import { action, observable } from 'mobx'
import { field, foreign, Model, model } from 'mobx-orm'

import { ReportType } from '@/models/csv_builder/ReportType'
import { randomString } from '@/utils/randomString'

import { api } from '../../http-adapter'

@api('csv-special-field-option')
@model
export class BuiltinSpecialFieldOption extends Model {
    @field readonly source_label?: string
    @field readonly name?:         string

    /**
     * Need this for DnD
     */
    @observable
        randomDndId = randomString()

    @action
    updateRandomDndId () {
        this.randomDndId = randomString()
    }

    @foreign(ReportType) readonly report_type: ReportType
}
