import { useEffect, useMemo } from 'react'

import { Model } from 'mobx-orm'

type GetQueryPageXOptions = Parameters<typeof Model.getQueryXPage>[0]

export const useQueryXPage = <M extends typeof Model>(model: M, options?: GetQueryPageXOptions) => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'QueryXProps<Model> | undefined' ... Remove this comment to see the full error message
    const query = useMemo(() => model.getQueryXPage(options), [])

    // destroy input on unmount
    useEffect(() => {
        return () => {
            query.destroy()
        }
    }, [])

    return query
}
