import { getRequestQueries } from '@/api/utils/getRequestQueries'
import { SubmittedFileError } from '@/models/data_submission'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FetchDataSubmissionErrorQueryParams {}

export const {
    useItemsQuery: useDataSubmissionErrorQuery,
    useItemUpdateMutation: useDataSubmissionErrorUpdateMutation,
    useItemsCountQuery: useDataSubmissionErrorCountQuery,
} = getRequestQueries<SubmittedFileError, FetchDataSubmissionErrorQueryParams>({
    url: 'data-submission-error',
})
