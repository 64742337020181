import parseJSON from 'date-fns/parseJSON'
import { DateParam } from 'use-query-params'

export const DATE_INPUT_QUERY_PARAM = {
    name: 'date',
    type: DateParam,
}

export const LOCAL_STORAGE_OPTIONS = {
    parser: parseJSON,
}

export const DATE_INPUT_QUERY_PARAM_FOR_INPUT_STATE = {
    ...DATE_INPUT_QUERY_PARAM,
    localStorageOptions: LOCAL_STORAGE_OPTIONS,
}
