import { Select } from '@/components/base'
import { useInputState } from '@/hooks'
import { TrialBalanceLedger } from '@/models/ledger'
import { RentRollLedger } from '@/models/rent_roll'

import { LEDGER_SELECT_INPUT_QUERY_PARAM } from './LedgerSelectInput.constants'
import { LedgerSelectInputProps } from './LedgerSelectInput.types'

const { name: paramName, type: queryParamType } = LEDGER_SELECT_INPUT_QUERY_PARAM

export const LedgerSelectInput = (props: LedgerSelectInputProps) => {
    const {
        queryParamName = paramName,
        placeholder = 'Select Ledger',
        required = false,
        options,
        searchPlaceholder = 'Search ledger name',
        syncLocalStorage,
        defaultActiveOptions = 'first',
        ...rest
    } = props

    const [param, setParam] = useInputState({ name: queryParamName, type: queryParamType, syncLocalStorage })
    // Need this to avaid reset in Select with async data on first render
    // const [optionsReady, setOptionsReady] = useState(dataReady)

    const selectedOptions = options?.find((option) => option.id === param)

    return (
        <Select<TrialBalanceLedger | RentRollLedger>
            options={options}
            selected={selectedOptions ? [selectedOptions] : []}
            placeholder={placeholder}
            onChange={(options) => options.length ? setParam(options[0].id) : setParam(undefined)}
            labelFn={(obj) => obj.name}
            idFn={(obj) => obj.id.toString()}
            error={required && !selectedOptions}
            searchPlaceholder={searchPlaceholder}
            optionsWidth='unset'
            defaultActiveOptions={defaultActiveOptions}
            {...rest}
        />
    )
}
