import { useEffect, useMemo, useState } from 'react'

import { reaction } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Alert, BaseModal, Button, Icon, Text } from '@/components/base'
import { Layout } from '@/components/containers'
import { TrialBalanceTemplateApplyingTask } from '@/models/trial_balance_template'
import { notify } from '@/utils/notify'

import styles from './MappingTemplateApplyConfirmModal.module.scss'
import { MappingTemplateApplyConfirmModalProps } from './MappingTemplateApplyConfirmModal.types'

export const MappingTemplateApplyConfirmModal = observer((props: MappingTemplateApplyConfirmModalProps) => {
    const { template, onClose, onConfirm } = props

    const [applyingTasks, setApplyingTasks] = useState<TrialBalanceTemplateApplyingTask[]>([])
    const checkingTasks = useMemo(
        () => template.activeBindings.map(binding => binding.createCheckingTask()),
        [template],
    )

    // run checking tasks after modal is opened
    useEffect(() => {
        (async () => {
            try {
                await Promise.all(checkingTasks.map(task => task.save()))
            } catch (e) {
                notify.error('Unexpected error.')
                onClose()
            }
        })()
    }, [checkingTasks, onClose])

    useEffect(() => {
        // if all applying tasks are finished - close modal
        return reaction(
            () => {
                let isApplyingTasksFinished = applyingTasks.length > 0
                for (const task of applyingTasks) {
                    if (task.inProgress) {
                        isApplyingTasksFinished = false
                        break
                    }
                }
                return isApplyingTasksFinished
            },
            (isApplyingTasksFinished) => {
                if (isApplyingTasksFinished) {
                    notify.success('Template was applied successfully to selected assets')
                    onConfirm()
                }
            },
        )
    }, [applyingTasks, onConfirm])

    const handleConfirm = async () => {
        // create and run applying tasks
        const tasks = []
        for (const binding of template.activeBindings) {
            // @ts-expect-error TS(2345) FIXME: Argument of type 'TrialBalanceTemplateApplyingTask... Remove this comment to see the full error message
            tasks.push(binding.createApplyingTask())
        }
        setApplyingTasks(tasks)
        try {
            // @ts-expect-error TS(2339) FIXME: Property 'save' does not exist on type 'never'.
            await Promise.all(tasks.map(task => task.save()))
        } catch (e) {
            notify.error('Unexpected error.')
        }
    }

    let isCheckingInProgress = false
    for (const task of checkingTasks) {
        if (task.inProgress) {
            isCheckingInProgress = true
            break
        }
    }
    let isApplyingInProgress = false
    for (const task of applyingTasks) {
        if (task.inProgress) {
            isApplyingInProgress = true
            break
        }
    }

    const isLoading = isCheckingInProgress || isApplyingInProgress

    return (
        <BaseModal
            maxWidth={648}
            radius={4}
            padding={24}
            minHeight={isLoading ? 354 : 'auto'}
        >
            {isLoading ? (
                <div className={styles.loadingBlock}>
                    <Layout mb={16}>
                        <Icon
                            name='loader'
                            width={32} height={32}
                            color='colorsPrimaryPurple'
                        />
                    </Layout>
                    <Text
                        text='Processing, please wait.'
                        variant='smallTextMediumDefault'
                    />
                    <Text
                        text='This may take several minutes.'
                        variant='smallTextMediumDefault'
                    />
                </div>
            ) : (
                <Layout direction='column' gap={64} align='center'>
                    <Alert type='warning' className={styles.alert}>
                        Are you sure you want to apply the template to the selected assets?
                    </Alert>
                    <Layout
                        maxWidth={269}
                        gap={8}
                        direction='column'
                        className={styles.buttons}
                    >
                        <Button
                            theme='primary' text='Apply Template' onClick={handleConfirm}
                            block
                        />
                        <Button
                            theme='secondary' text='Back' onClick={onClose}
                            block
                        />
                    </Layout>
                </Layout>
            )}
        </BaseModal>
    )
})
