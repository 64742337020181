import { observer } from 'mobx-react-lite'

import { FormBox } from '@/components/base'

import { FormBoxListProps } from './FormBoxList.types'

export const FormBoxList = observer((props: FormBoxListProps) => {
    const { className, content, noBottom } = props

    return (
        <div>
            {
                content.map((item, i) => (
                    <FormBox
                        {...item}
                        key={i}
                        className={className}
                        // If it's not last or have noBottom prop
                        noBottom={i !== content.length - 1 || noBottom}
                        noTop={i !== 0}
                    />
                ))
            }
        </div>
    )
})
