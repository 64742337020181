import { generatePath } from 'react-router'

import { ReportingFolderItem } from '@/api/reportingData/reportingFolder'
import { ReportingReportItem } from '@/api/reportingData/reportingReport'
import { FilesTreeItem } from '@/components/base'
import { OWNER_ROUTES_CONFIG } from '@/core/routes'

export const getReportsTree = (folders: ReportingFolderItem[], reports: ReportingReportItem[], parent: number | null): FilesTreeItem[] => {
    const normalizedFolders = folders
        .filter((folder) => folder.parent_id === parent)
        .map((folder) => ({
            id: folder.id,
            name: folder.name,
            children: getReportsTree(folders, reports, folder.id),
        }))

    const normalizedFiles: FilesTreeItem[] = reports
        .filter((report) => report.parent_id === parent)
        .map((report) => {
            const path = generatePath(OWNER_ROUTES_CONFIG.REPORTS_CUSTOM.path, { id: report.id })

            return {
                // Use path here to keep logic of 'starndard' and 'custom' reports compatible
                //  Can be replaced to id when 'standard' reports will be removed or replaced with custom reports functionality
                id: path,
                name: report.name,
                path,
            }
        })

    return [...normalizedFolders, ...normalizedFiles]
}

export const DEFAUILT_REPORT_NAME = 'New Report'
export const DEFAULT_REPORT_FOLDER_NAME = 'New Folder'
