import { IconName } from '@/components/base'

import { AlertType } from './Alert.types'

export const iconNameByType: Record<AlertType, IconName> = {
    info: 'info',
    warning: 'info',
    alert: 'info',
    error: 'waring',
    success: 'check',
}
